import React from "react";
import { useState, useEffect } from "react";
import { Grid, Button, Pagination, Typography } from "@mui/material";
import InvoicesTable from "../../UserDshboard/Components/InvoicesTable";
import { get_data } from "../../../Api";
import {
  adminInvoicesTableHeader,
  formatPrice,
  useDatabaseConstants,
} from "../../../Constants/globalConstants";
import LineChartComponent from "../Components/ChartComponent";
import StatisticsCard from "../Components/StatisticsCard";
import AdminDashboardSkeleton from "../../UserDshboard/Components/skeleton/AdminDashboardSkeleton";

export default function Sales() {
  
  const [invoices, setInvoices] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [totalSales, setTotalSales] = useState("");
  // const { app_color } = useDatabaseConstants();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const [plansStats, setPlanStats] = useState([]);
  const [totalPages, setTotalPages] = useState(null);

  const sales_page = {
    width: "100%",
  };

  const page_container = {
    width: "100%",
    margin: "2% 0",
    background: "transparent",
  };

  const fetchInvoices = async (pageNumber) => {
    const response = await get_data(
      `billing/get-all-billings?page_number=${pageNumber}`
    );
    if (response?.status) {
      setInvoices(response?.data?.billings);
      setTotalPages(response?.data?.totalPages);
      setTableLoading(false);
    }
  };

  const fetchPlanStats = async () => {
    const response = await get_data("billing/get-total-payment-amount-by-plan");
    if (response?.status) {
      setPlanStats(response?.data);
    }
  };

  useEffect(() => {
    // fetchInvoices();
    fetchPlanStats();
  }, []);

  useEffect(() => {
    if (currentPage) {
      fetchInvoices(currentPage);
    }
  }, [currentPage]);

  const stats_item = [
    {
      title: plansStats?.[0]?.planDetails?.name,
      number: formatPrice(plansStats?.[0]?.totalPaymentAmount),
      icon: "sales-icon",
      bg: "white",
      total_users: plansStats?.[0]?.userCount,
      color: "black",
    },
    {
      title: plansStats?.[1]?.planDetails?.name,
      number: formatPrice(plansStats?.[1]?.totalPaymentAmount),
      bg: "#F6C000",
      icon: "all-users-icon",
      total_users: plansStats?.[1]?.userCount,
      color: "white",
    },
    {
      title: plansStats?.[2]?.planDetails?.name,
      number: formatPrice(plansStats?.[2]?.totalPaymentAmount),
      icon: "sub-admins-icon",
      bg: "#181C32",
      total_users: plansStats?.[2]?.userCount,
      color: "white",
    }
  ];

  const plansStatsFunc = () => {
    return (
      <>
        <Grid container spacing={3} style={{ width: "100%" }}>
          {stats_item?.map((item, i) => {
            return (
              <>
                <Grid item md={3}>
                  <StatisticsCard item={item} index={i} type="plans" />
                </Grid>
              </>
            );
          })}
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid container spacing={0} style={sales_page}>
        <div style={page_container}>
          {tableLoading ? (
            <>
              <AdminDashboardSkeleton />
            </>
          ) : (
            <>
              <InvoicesTable
                data={invoices}
                count={invoices?.length}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setData={setInvoices}
                type="admin"
                tableHeader={adminInvoicesTableHeader}
                totalPages={totalPages}
              />
            </>
          )}
        </div>
      </Grid>
    </>
  );
}
