import React from "react"
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Testimonials from "../Components/Testimonials";
import AboutHeroSection from "../Components/AboutHeroSection";
import AboutCompanySection from "../Components/AboutCompanySection";
import AboutServices from "../Components/AboutServices";
import AboutMission from "../Components/AboutMission";
import BottomBar from "../Components/BottomBar";

export default function About() {

    return (
        <>
            <Header />
            <AboutHeroSection />
            <div className="faq-section">
                <AboutCompanySection />
                <AboutServices />
            </div>
            <AboutMission />
            <Footer />
            <BottomBar />
        </>
    )
}