import React, { useState } from "react";
import { Grid, Button } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";
import SignupSignModal from "./SignupLoginModal";
import { useSelector } from "react-redux";
import FunctionConstant from "../../Constants/functionConstant";
import MenuComponents from "./MenuComponents";
import MenuDrawer from "./MenuDrawer";

export default function Header(props) {

    const navigate = useNavigate();
    const theme = useTheme();
    const { user_data, isLoggedIn } = useSelector(state => state.user);
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'));
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const [modalOpen, setModalOpen] = useState(false);
    const { handleBtnClick } = FunctionConstant({ setModalOpen })

    const handleLogoclick = () => {
        navigate('/')
        window.scrollTo(0, 0);
    }

    return (
        <div className="main-header">
            <Grid container spacing={0} style={{ padding: '0 3%', margin: 0 }}>
                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                    <img src={`/images/dilsey-logo.svg`} style={{ width: matches_md ? '100%' : '50%', cursor: 'pointer' }} onClick={handleLogoclick} />
                </Grid>

                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <MenuComponents color={'black'} shadow="0 4px 22px 0 #6F6F6F33" />
                </Grid>

                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                    {
                        matches_md ?
                            <>
                                {/* <div><MenuIcon style={{ width: 40, height: 40, cursor: 'pointer' }} /></div> */}
                                <MenuDrawer
                                    modalOpen={modalOpen}
                                    setModalOpen={setModalOpen}
                                />
                            </>
                            :
                            <>
                                <Button
                                    onClick={handleBtnClick}
                                    variant='contained' className='header-btn'
                                    style={{
                                        fontSize: 13,
                                        fontWeight: 600
                                    }}>
                                    {isLoggedIn ? 'My Account' : 'Register/Login'}
                                </Button>
                            </>
                    }
                </Grid>
            </Grid>

            <SignupSignModal modalOpen={modalOpen} setModalOpen={setModalOpen} />

        </div>
    )
}