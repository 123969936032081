import { useContext, useState } from "react";
import CustomToggle from "./CustomToggle";
import { useDispatch, useSelector } from "react-redux";
import { getFieldName, handleCreateActivity, secondaryDarkColor, useDatabaseConstants } from "../../../Constants/globalConstants";
import { Button, Grid } from "@mui/material";
import { post_data } from "../../../Api";
import { login } from "../../../redux/slices/user-slice";
import SuccessModal from "./SuccessModal";
import { DarkModeContext } from "./DarkModeContext";

export default function UserPrivacy({ type, setUser, user }) {

    const { isLoggedIn, user_data } = useSelector(state => state.user);
    const [payload, setPayload] = useState(user)
    const { app_color } = useDatabaseConstants()
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [successStatus, setSuccessStatus] = useState(false)
    const [successOpen, setSuccessOpen] = useState(false)
    const { darkMode } = useContext(DarkModeContext);

    const profile_card_title = {
        fontWeight: 500,
        fontSize: 15,
    }

    const button_right_style = {
        background: app_color,
        width: '50%',
        color: 'white',
        boxShadow: 'none',
        borderRadius: 7,
        fontWeight: 500,
        fontSize: 15,
        textTransform: 'capitalize',
        padding: '3% 0'
    }

    const disabled_btn = {
        background: '#9e004f8e'
    }

    const details = [
        {
            title: 'Privacy',
            obj: [
                {
                    field: 'Contact number',
                    value: user?.privacy?.contact_number,
                    type: 'boolean'
                },
                {
                    field: 'Email',
                    value: user?.privacy?.email,
                    type: 'boolean'
                },
                {
                    field: 'Address',
                    value: user?.privacy?.address,
                    type: 'boolean'
                },
                {
                    field: 'Profile picture',
                    value: user?.privacy?.profile_picture,
                    type: 'boolean'
                },
                {
                    field: 'DOB',
                    value: user?.privacy?.dob,
                    type: 'boolean'
                },
                {
                    field: 'Photos',
                    value: user?.privacy?.photos,
                    type: 'boolean'
                },
                {
                    field: 'Income',
                    value: user?.privacy?.income,
                    type: 'boolean',
                },
                {
                    field: 'Platinum Profiles',
                    display_field: 'Visible to Platinum Profiles',
                    value: user?.privacy?.platinum_profiles,
                    type: 'boolean',
                },
            ]
        },
    ]

    const handleSubmit = () => {
        setLoading(true)
        setTimeout(() => {
            handleSaveDetails()
        }, 1000);
    }

    const handleSaveDetails = async () => {
        const body = { 'privacy': payload?.privacy }
        const response = await post_data(`user/update-user/${user?._id}`, body)
        if (response?.status) {
            if (type === 'Admin') {
                setUser(response?.data)
                handleCreateActivity('User profile updated', user_data)
            }
            else {
                dispatch(login(response?.data));
            }
            setLoading(false)
            setSuccessStatus(true)
            setSuccessOpen(true)
        }
        else {

        }
    }

    const privacyDetails = () => {
        return details.map((item, i) => {

            const profile_details_grid = {
                border: darkMode ? '' : '2px solid #F1F1F2',
                borderRadius: 10,
                background: darkMode ? secondaryDarkColor : 'white',
                boxShadow: '3px 3px 4px 0 #00000008',
                padding: '2%',
                margin: i === 0 ? '1% 0 3% 0' : '3% 0',
                position: 'relative'
            }

            return (
                <>
                    <div style={profile_details_grid}>
                        <h3 style={{ color: app_color, fontWeight: 500, fontSize: 18, marginBottom: '2%' }}>{item.title}</h3>
                        <div style={{ display: "flex" }}>
                            {
                                item.obj.map((item2, i2) => {
                                    let last_item = item.obj.length - 1
                                    return (
                                        <>
                                            <div style={{
                                                borderRight: last_item === i2 ? '' : '1.5px dashed #BFBFBF',
                                                padding: i2 === 0 ? '0 2% 0 0' : '0 3%'
                                            }}>
                                                <p style={{ ...profile_card_title, fontSize: 14 }}>
                                                    {
                                                        item2?.display_field ?
                                                            <>{item2?.display_field}</>
                                                            :
                                                            <>{item2.field}</>
                                                    }
                                                </p>
                                                <div style={{ marginTop: '5%' }}>
                                                    <CustomToggle
                                                        obj="privacy"
                                                        field={getFieldName(item2.field)}
                                                        payload={payload}
                                                        setPayload={setPayload}
                                                        toggle_value={user?.privacy?.[getFieldName(item2.field)]}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </>
            )
        })
    }

    return (
        <>
            <Grid container spacing={0} >
                <Grid item md={12}>
                    {privacyDetails()}
                </Grid>
                <Grid item md={3} style={{
                    width: '60%',
                    display: 'flex',
                    gap: '4%',
                }}>
                    <Button
                        onClick={handleSubmit} disabled={loading} variant='contained'
                        style={loading ? { ...button_right_style, ...disabled_btn } : button_right_style}
                    >{loading ? 'Saving...' : 'Save'}
                    </Button>
                </Grid>
            </Grid>
            <SuccessModal
                status={successStatus}
                open={successOpen}
                setOpen={setSuccessOpen}
                title={successStatus ? `Privacy details updated successfully!` : 'Something went wrong...'}
            />
        </>
    )
}