import React, { useState, useContext, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { CircularProgress, Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DarkModeContext } from "../../../WebApp/UserDshboard/Components/DarkModeContext";
import SerialNo from "../../Admin/Components/SerialNo";
import { Button } from "antd";
import {
  button_left_style,
  handleCreateActivity,
} from "../../../Constants/globalConstants";
import SimpleModal from "../Components/NewCopanModal";
import CustomToggleWithoutProps from "../../UserDshboard/Components/Coupantoggle";
import { get_data, post_data } from "../../../Api";
import CoupanDeleteModal from "../../UserDshboard/Components/CoupanDeleteModal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import SubAdminPermissions from "../../../Constants/subAdminPermissions";
import EmptyPage from "../../UserDshboard/Components/EmptyPage";
import TableSkeleton from "../../UserDshboard/Components/skeleton/TableSkeleton"

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    justifyContent: "center",
  },
  tableContainer: {
    width: "100%",
    padding: "3%",
    borderRadius: 10,
    border: "1px solid #F1F1F2",
    boxShadow: "0px 3px 4px 0 rgba(0, 0, 0, 0.03)",
    background: "white",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tableHeading: {
    fontWeight: 600,
    fontSize: 17,
    color: "#3F4254",
  },
  tableTagline: {
    fontWeight: 500,
    color: "#A1A5B7",
    fontSize: 13,
  },
  tableHeaderTitle: {
    margin: 0,
    fontWeight: 600,
    fontSize: 14,
    color: "#3F4254",
  },
  tableRowFont: {
    margin: 0,
    fontWeight: 500,
    fontSize: 14,
    color: "#3F4254",
  },
  tableCell: {
    padding: "8px",
    textAlign: "left",
  },
});

const staticTableHeader = [
  "Serial No.",
  "Coupon",
  "Discount",
  "Active",
  "Actions",
];

export default function Coupan() {

  const { user_data } = useSelector((state) => state.user);
  const classes = useStyles();
  const { darkMode } = useContext(DarkModeContext);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const [couponData, setCouponData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState({});
  const [itemToDelete, setItemToDelete] = useState(null);
  const [open, setOpen] = useState(false);
  const [editingCoupon, setEditingCoupon] = useState(null);
  const { permissions, isSubAdmin } = SubAdminPermissions();

  const count = couponData.length;
  const totalPages = Math.ceil(count / itemsPerPage);
  const startEntry = (currentPage - 1) * itemsPerPage + 1;
  const endEntry = Math.min(currentPage * itemsPerPage, count);

  const [pageLoading, setPageLoading] = useState(true)

  const openModal = (item) => {
    setItemToDelete(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setItemToDelete(null);
  };

  const confirmDelete = () => {
    if (itemToDelete) {
      handleDelete(itemToDelete?._id);
      closeModal();
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await post_data(`coupon/coupon-delete/${id}`);
      if (response?.status) {
        handleCreateActivity("Coupon Deleted", user_data);
        // setCouponData((prevData) =>
        //   prevData.filter((coupon) => coupon.coupon_name !== coupon_name)
        // );
        await handleAllCoupan();
        toast.success("Coupon deleted successfully");
      }
    } catch (error) {
      console.error("Error deleting coupon:", error);
      toast.error("Error deleting coupon");
    }
  };

  const handleChange = async (id, item) => {
    setLoading((prevLoading) => ({ ...prevLoading, [id]: true }));
    try {
      setTimeout(async () => {
        let payload = { is_active: !item?.is_active };
        const response = await post_data(`coupon/coupon-update/${id}`, payload);
        if (response.status) {
          await handleAllCoupan();
          setLoading((prevLoading) => ({ ...prevLoading, [id]: false }));
          toast.success(item?.is_active === true ? "Coupon Deactivated Successfully" : "Coupon Activated Successfully");
          handleCreateActivity(item?.is_active === true ? "Coupon Deactivated" : "Coupon Activated", user_data);
        }
      }, 1000);
    } catch (error) {
      setLoading((prevLoading) => ({ ...prevLoading, [id]: false }));
      console.error("Error updating coupon:", error);
      toast.error("Error updating coupon");
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleAllCoupan = async () => {
    try {
      const response = await get_data("coupon/coupon-get");
      if (response?.status) {
        setCouponData(response?.data || []);
        setPageLoading(false)
      }
    } catch (e) {
      console.error(e);
      toast.error("Error fetching coupons");
    }
  };

  const handleOpen = (coupon = null) => {
    setEditingCoupon(coupon);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingCoupon(null);
  };

  const handleSaveCoupon = (newCoupon) => {
    setCouponData((prevCoupons) => {
      const index = prevCoupons.findIndex((c) => c._id === newCoupon._id);
      if (index !== -1) {
        // Update existing coupon
        const updatedCoupons = [...prevCoupons];
        updatedCoupons[index] = newCoupon;
        return updatedCoupons;
      } else {
        // Add new coupon
        return [...prevCoupons, newCoupon];
      }
    });
  };

  useEffect(() => {
    handleAllCoupan();
  }, [setCouponData, setItemToDelete, itemToDelete]);

  const displayAllActivities = () => {
    return (
      <table className={classes.table}>
        <thead>
          <tr>
            {staticTableHeader?.map((header, index) => (
              <th
                key={index}
                className={`${classes.tableCell} ${classes.tableHeaderTitle}`}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {couponData?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)?.map((item, i) => (
            <tr key={item?._id} style={{ borderTop: "1px dashed gainsboro" }}>
              <td className={`${classes.tableCell}`}>
                <SerialNo index={(currentPage - 1) * itemsPerPage + i} />
              </td>
              <td className={`${classes.tableCell}`}>
                <span className={classes.tableRowFont}>
                  {item?.coupon_name}
                </span>
              </td>
              <td className={`${classes.tableCell}`}>
                <span className={classes.tableRowFont}>{item?.discount}%</span>
              </td>
              <td className={`${classes.tableCell}`}>
                <span className={classes.tableRowFont}>

                  {
                    isSubAdmin ? (
                      permissions?.includes("Edit Coupon") ? (
                        <>
                          {
                            loading[item._id] ?
                              <>
                                <CircularProgress style={{ height: 25, width: 25 }} />
                              </>
                              :
                              <>
                                <CustomToggleWithoutProps
                                  is_active={item?.is_active}
                                  onChange={() => handleChange(item?._id, item)}
                                />
                              </>
                          }
                        </>
                      ) : (
                        <>No Permissions</>
                      )
                    ) : (
                      <>
                        {
                          loading[item._id] ?
                            <>
                              <CircularProgress style={{ height: 25, width: 25 }} />
                            </>
                            :
                            <>
                              <CustomToggleWithoutProps
                                is_active={item?.is_active}
                                onChange={() => handleChange(item?._id, item)}
                              />
                            </>
                        }
                      </>
                    )
                  }

                </span>
              </td>
              <td className={`${classes.tableCell}`}>
                {
                  isSubAdmin ? (
                    <>
                      <div className="flex">
                        {
                          permissions?.includes("Edit Coupon") && (
                            <img
                              src="/images/edit-img.svg"
                              alt="Edit"
                              style={{
                                width: 30,
                                height: 30,
                                cursor: "pointer",
                                marginRight: 10,
                              }}
                              onClick={() => handleOpen(item)}
                            />
                          )
                        }

                        {
                          permissions?.includes("Delete Coupon") && (
                            <img
                              src="/images/delete-img.svg"
                              alt="Delete"
                              style={{
                                width: 30,
                                height: 30,
                                cursor: "pointer",
                              }}
                              onClick={() => openModal(item)}
                            />
                          )
                        }

                        {
                          !permissions?.includes("Delete Coupon") && !permissions?.includes("Edit Coupon") && (
                            <>No Permissions</>
                          )
                        }

                      </div>
                    </>
                  ) :
                    (
                      <>
                        <div className="flex">
                          <img
                            src="/images/edit-img.svg"
                            alt="Edit"
                            style={{
                              width: 30,
                              height: 30,
                              cursor: "pointer",
                              marginRight: 10,
                            }}
                            onClick={() => handleOpen(item)}
                          />
                          <img
                            src="/images/delete-img.svg"
                            alt="Delete"
                            style={{
                              width: 30,
                              height: 30,
                              cursor: "pointer",
                            }}
                            onClick={() => openModal(item)}
                          />

                        </div>
                      </>
                    )
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };


  return (
    <>
      {
        pageLoading ?
          <>
            <TableSkeleton />
          </>
          :
          <>
            <div className={classes.wrapper}>
              <div className={classes.tableContainer}>
                <div className="flex justify-between">
                  <h1 className={classes.tableHeading}>Coupan</h1>
                  {
                    isSubAdmin ? (
                      permissions?.includes("New Coupon") && (
                        <Button
                          style={{
                            ...button_left_style,
                            padding: "1.5% 0",
                            width: "13%",
                          }}
                          onClick={() => handleOpen()}
                        >
                          New Coupon
                        </Button>
                      )
                    ) : (
                      <Button
                        style={{
                          ...button_left_style,
                          padding: "1.5% 0",
                          width: "13%",
                        }}
                        onClick={() => handleOpen()}
                      >
                        New Coupon
                      </Button>
                    )
                  }
                </div>
                <SimpleModal
                  isOpen={open}
                  onClose={handleClose}
                  onSave={handleSaveCoupon}
                  editingCoupon={editingCoupon}
                />
                {
                  couponData?.length === 0 ?
                    <>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <EmptyPage title={'No Data to Display...'} />
                      </div>
                    </>
                    :
                    <>
                      {displayAllActivities()}
                    </>
                }
                {couponData?.length > 0 && (
                  <div
                    style={{
                      borderTop: "1px dashed gainsboro",
                      paddingTop: "2%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      className={classes.tableTagline}
                      style={{
                        color: darkMode ? "gray" : "black",
                      }}
                    >{`Showing ${startEntry} to ${endEntry} of ${count} activities`}</Typography>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </div>
                )}
              </div>
              <CoupanDeleteModal
                open={isModalOpen}
                onClose={closeModal}
                onConfirm={confirmDelete}
                title={`Are you sure you want to delete the coupon "${itemToDelete?.coupon_name}"?`}
              />
            </div >
          </>
      }
    </>
  );
}
