import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LogoutModal from "../../UserDshboard/Components/LogoutModal";
import DashboardHeading from "../../UserDshboard/Components/DashboardHeading";
import { useSelector } from "react-redux";
import SideDrawer from "./SideDrawer";
import { FiPower } from "react-icons/fi";

export default function TopBar({ isSubAdmin }) {

    const navigate = useNavigate()
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [open, setOpen] = useState(false)
    const { user_data } = useSelector(state => state.user);

    const popupRefProfile = useRef(null);

    const top_section = {
        display: 'flex',
        alignItems: 'center',
        height: 65,
        padding: '1% 3%',
        background: 'white',
        boxShadow: '0 3px 4px 0 #00000008'
    };

    const profile_modal = {
        position: 'absolute',
        top: '10%',
        right: '3%',
        backgroundColor: '#fff',
        border: '1px solid #F1F1F2',
        boxShadow: '0px 8px 14px 0px #0F2A5114',
        borderRadius: 10,
        zIndex: 9999,
        width: 300,
        maxHeight: "calc(100vh - 100px)",
        overflowY: "auto"
    }

    const logout_div = {
        display: 'flex',
        gap: '5%',
        fontWeight: 600,
        color: 'red',
        fontSize: 16,
        cursor: 'pointer',
        padding: '5% 6%',
    }

    const user_info_div = {
        padding: '6%',
        display: 'flex',
        alignItems: 'center',
        gap: '4%'
    }

    const user_list_div = {
        padding: '2% 6%',
    }

    const user_name = {
        fontSize: 17,
        fontWeight: 500,
        color: '#212121',
    }

    const user_email = {
        fontSize: 15,
        fontWeight: 400,
        color: '#3F4254',
    }

    // const togglePopupProfile = () => {
    //     setShowProfileModal(!showProfileModal);
    // };

    const handleClickOutside = (event) => {
        if (popupRefProfile.current && !popupRefProfile.current.contains(event.target)) {
            setShowProfileModal(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const user_list_items = [
        {
            title: 'My Profile',
            link: '/admin/dashboard'
        },
        {
            title: 'Settings',
            link: '/admin/dashboard/settings'
        },
    ]

    const list_item = {
        margin: '3% 0',
        fontWeight: 500,
        color: '#7E8299',
        fontSize: 16,
        cursor: 'pointer'
    }

    const handleItemClick = (item) => {
        navigate(item?.link)
    }

    const handleLogout = (e) => {
        setOpen(true)
    }

    const profile_pic = { borderRadius: 8, height: '40px', width: '40px', cursor: 'pointer' }

    return (
        <>
            <LogoutModal
                open={open}
                setOpen={setOpen}
            />
            <div style={top_section}>
                <div style={{ width: '100%' }}>
                    <DashboardHeading title={isSubAdmin ? 'Sub Admin Dashboard' : 'Super Admin Dashboard'} />
                </div>
                <div style={{ width: '70%', display: "flex", justifyContent: "right", alignItems: "center" }}>
                    <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
                        <FiPower style={{ width: 20, height: 20, color: 'red', cursor: 'pointer' }} onClick={handleLogout} />
                        <SideDrawer />
                    </div>
                </div>
            </div>

            {showProfileModal && (
                <div style={profile_modal} className="notifications-parent-div"
                    ref={popupRefProfile} >
                    <div style={user_info_div}>
                        <img src="/images/user-avatar.png" style={profile_pic} />

                        <div style={{ width: '100%', alignItems: "center" }}>
                            <div style={{ display: "flex", gap: '5%' }}>
                                <p style={user_name}>{user_data?.first_name} {user_data?.last_name}</p>
                            </div>
                            <p style={user_email}>{user_data?.email}</p>
                        </div>
                    </div>
                    <hr style={{
                        borderTop: '1px dashed #A1A5B7'
                    }} />
                    <div style={user_list_div}>
                        {
                            user_list_items.map((item, i) => {
                                return (
                                    <>
                                        <p style={list_item} onClick={() => handleItemClick(item)}>{item.title}</p>
                                    </>
                                )
                            })
                        }
                    </div>
                    <hr style={{
                        borderTop: '1px dashed #A1A5B7'
                    }} />
                    <div style={logout_div} onClick={(e) => handleLogout(e)}>
                        <h3>Logout</h3>
                    </div>
                </div>
            )}
        </>
    );
}
