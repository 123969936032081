import * as React from 'react';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDatabaseConstants } from '../../../Constants/globalConstants';

export default function AdditionalServicesDropdwon({ item, current_path, type }) {

    const navigate = useNavigate()
    const { app_color } = useDatabaseConstants()

    const [menuOpen, setMenuOpen] = useState(false);
    const handleClick = () => {
        setMenuOpen(!menuOpen);
    };

    const listItems = [
        {
            title: 'Background Verification',
            link: '/dashboard/background-verification'
        },
        {
            title: 'Video Consultation',
            link: '/dashboard/video-consultation'
        }
    ]

    const handleItemClick = (list) => {
        navigate(list.link)
        // setMenuOpen(false)
    }

    const sub_item = {
        width: '100%',
        fontSize: 13,
        margin: '10% 0',
        padding: 0,
        color: type === 'Admin' ? 'black' : '#A1A5B7',
        fontWeight: 500,
    }

    let sub_items_current_path = window.location.pathname;

    console.log('sub_items_current_path', sub_items_current_path);

    const title_styles = {
        fontSize: 13, color: type === 'Admin' ? 'black' : '#A1A5B7', fontWeight: 500, padding: 0, margin: 0, width: 'fit-content'
    }

    const displayListItems = () => {
        return listItems?.map((list) => {
            return (
                <>
                    <div onClick={() => handleItemClick(list)} style={{
                        ...sub_item,
                        color: sub_items_current_path === list?.link ? 'white' : '#A1A5B7',
                        background: sub_items_current_path === list?.link ? app_color : '',
                        padding: '2% 6%',
                        borderRadius: 5,
                    }}>{list.title}</div >
                </>
            )
        })
    }

    return (
        <List
            sx={{ width: '100%', display: 'flex', gap: '1%', alignItems: 'left', flexDirection: 'column', margin: 0, padding: 0 }}
            component="nav"
        >
            <div style={{ display: 'flex', alignItems: 'center', gap: '5%' }} onClick={handleClick}>
                <img
                    src={current_path === item?.link ? `/images/${item?.active_icon}.svg` : `/images/${item?.icon}.svg`}
                    style={{ width: 25, height: 25 }}
                />
                <h3 style={title_styles}>Additional Services</h3>
                {menuOpen ? <ExpandLess style={{ color: type === 'Admin' ? 'black' : '#A1A5B7' }} /> : <ExpandMore style={{ color: type === 'Admin' ? 'black' : '#A1A5B7' }} />}
            </div>
            <Collapse in={menuOpen} timeout="auto" unmountOnExit style={{ paddingLeft: 0 }}>
                <List>
                    {displayListItems()}
                </List>
            </Collapse>
        </List>
    );
}