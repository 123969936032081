import React from 'react';
import { Button, Grid } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {

    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/');
    }

    const errorPageStyles = {
        section: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            textAlign: 'center',
            backgroundColor: '#fff',
        },
        container: {
            maxWidth: '600px',
            padding: '0 2%',
        },
        gridItem: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        heading: {
            fontSize: '6rem',
            margin: '0',
        },
        message: {
            fontSize: '1.5rem',
        },
        button: {
            background: '#9e0050',
            color: 'white',
            boxShadow: 'none',
            borderRadius: 7,
            border: 'none',
            fontWeight: 500,
            fontSize: 15,
            textTransform: 'capitalize',
            padding: '2% 5%'
        },
    }

    return (
        <div style={errorPageStyles?.section}>
            <Grid container spacing={0} style={errorPageStyles?.container}>
                <Grid item xs={12} style={errorPageStyles?.gridItem}>
                    <img src='/images/404.svg' style={{ width: 500, height: 500 }} />
                    <Button
                        onClick={handleBack}
                        variant='contained'
                        style={errorPageStyles?.button}
                    >
                        Back to Home
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

export default ErrorPage;
