import React, { useEffect, useState } from "react"
import { Grid, Button } from "@mui/material"
import { button_left_style, darkPrimaryColor, secondaryDarkColor, useDatabaseConstants } from "../../../Constants/globalConstants";
import Heading from "../../../Website/Components/Heading";
import { get_data } from "../../../Api";
import EditPlanModal from "../../../WebApp/Admin/Components/EditPlanModal"
import SuccessModal from "./SuccessModal";
import TableSkeleton from "./skeleton/TableSkeleton";
import { useNavigate } from "react-router-dom";
import SignupSignModal from "../../../Website/Components/SignupLoginModal"
import { useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { DarkModeContext } from "./DarkModeContext";

export default function PricingPlans({
    screen,
    fetchPlansPermissions,
    admin_type,
    is_purchased,
    allPlansPermisison,
    isSubAdmin,
    permissions
}) {

    const { darkMode } = React.useContext(DarkModeContext);


    const pricing_page = {
        width: '100%',
        margin: "2% 0",
        background: darkMode ? secondaryDarkColor : 'white',
        borderRadius: 10,
        border: darkMode ? 'none' : '1px solid #F1F1F2',
        boxShadow: '0px 3px 4px 0 #00000008'
    }

    const navigate = useNavigate()
    const { user_data } = useSelector(state => state.user);
    const { app_color } = useDatabaseConstants()
    const [allPlans, setAllPlans] = useState([])
    const [selectedPlan, setSelectedPlan] = useState('monthly');
    const [plan1Permissions, setPlan1Permissions] = useState([])
    const [plan2Permissions, setPlan2Permissions] = useState([])
    const [plan3Permissions, setPlan3Permissions] = useState([])
    const [open, setOpen] = useState(false)
    const [editPlan, setEditPlan] = useState('')
    const [successOpen, setSuccessOpen] = React.useState(false)
    const [loading, setLoading] = useState(true)
    const [successStatus, setSuccessStatus] = React.useState(false)
    const { isLoggedIn } = useSelector(state => state.user);
    const [modalOpen, setModalOpen] = useState(false)
    const theme = useTheme();

    const pricing_title = {
        fontWeight: 600,
        fontSize: 23
    }

    const plans_tenure = {
        margin: '2% 0',
        fontWeight: 500,
        fontSize: 16
    }

    const border_style = {
        borderRight: darkMode ? '1px solid #182130' : '1px solid #E6E9F5',
        borderBottom: darkMode ? '1px solid #182130' : '1px solid #E6E9F5'
    }

    const plan_cost_title = {
        fontWeight: 600,
        fontSize: 26,
        color: 'black',
        margin: '6% 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2%'
    }

    const button_styles = {
        boxShadow: 'none',
        color: 'white',
        padding: '4% 0',
        fontWeight: 600,
        fontSize: 14,
        textTransform: 'capitalize'
    }

    const fetchAllPlans = async () => {
        const response = await get_data('plan/get-all-plans')
        if (response?.status) {
            setAllPlans(response?.data)
            setLoading(false)
        }
        else {
            setAllPlans([])
        }
    }

    useEffect(() => {
        fetchAllPlans()
    }, [])

    useEffect(() => {
        setPlan1Permissions(allPlans?.[0]?.permissions)
        setPlan2Permissions(allPlans?.[1]?.permissions)
        setPlan3Permissions(allPlans?.[2]?.permissions)
    }, [allPlans])

    const handlePlanChange = (event) => {
        setSelectedPlan(event.target.value);
    }

    const handleClick = (item) => {
        if (is_purchased && user_data?.plan_id?.[0]?._id === item?._id) {
            return
        }

        let plan_price =
            selectedPlan === 'monthly' ? item?.monthly_discounted_price :
                selectedPlan === 'quaterly' ? item?.quaterly_discounted_price :
                    selectedPlan === 'semi_yearly' ? item?.semi_yearly_discounted_price :
                        item?.yearly_discounted_price

        if (admin_type === 'super-admin') {
            setOpen(true)
            setEditPlan(item)
        }
        else {
            if (isLoggedIn) {
                navigate('/checkout', { state: { amount: plan_price, id: item?._id, selectedPlan: selectedPlan } })
                window.scrollTo(0, 0)
            }
            else {
                setModalOpen(true)
            }
        }
    }

    const editItems = [
        {
            field: 'Name',
            type: 'text'
        },
        {
            field: 'Monthly Price',
            type: 'number'
        },
        {
            field: 'Monthly Discounted Price',
            type: 'number',
        },

        {
            field: 'Quaterly Price',
            type: 'number'
        },
        {
            field: 'Quaterly Discounted Price',
            type: 'number',
        },

        {
            field: 'Semi Yearly Price',
            type: 'number'
        },
        {
            field: 'Semi Yearly Discounted Price',
            type: 'number',
        },

        {
            field: 'Yearly Price',
            type: 'number'
        },
        {
            field: 'Yearly Discounted Price',
            type: 'number',
        },
        {
            field: 'Description',
            type: 'text'
        }
    ]

    const pricingInfo = () => {
        return (
            <>
                <div>
                    <div style={{ ...border_style, padding: '10% 7%', height: 250 }}>
                        <h3 style={pricing_title}>Pick Your Plan</h3>
                        <div style={plans_tenure} className="plans_tenure">
                            <input type='radio' name='plan' id='monthly' value='monthly' checked={selectedPlan === 'monthly'} onChange={handlePlanChange} />
                            <label htmlFor='monthly'>Monthly Plan</label>
                        </div>
                        <div style={plans_tenure} className="plans_tenure">
                            <input type='radio' name='plan' id='quaterly' value='quaterly' checked={selectedPlan === 'quaterly'} onChange={handlePlanChange} />
                            <label htmlFor='quaterly'>Quaterly Plan</label>
                        </div>

                        <div style={plans_tenure} className="plans_tenure">
                            <input type='radio' name='plan' id='semi_yearly' value='semi_yearly' checked={selectedPlan === 'semi_yearly'} onChange={handlePlanChange} />
                            <label htmlFor='semi_yearly'>Semi-Yearly Plan</label>
                        </div>
                        <div style={plans_tenure} className="plans_tenure">
                            <input type='radio' name='plan' id='yearly' value='yearly' checked={selectedPlan === 'yearly'} onChange={handlePlanChange} />
                            <label htmlFor='yearly'>Yearly Plan</label>
                        </div>
                    </div>
                    {
                        allPlansPermisison?.map((item, i) => {
                            return (
                                <div style={{
                                    ...border_style,
                                    padding: '6%',
                                    height: 70,
                                    display: "flex",
                                    alignItems: 'center',
                                    fontWeight: 500,
                                    fontSize: 15
                                }}>
                                    <p>
                                        {
                                            admin_type === 'super-admin' ?
                                                <>
                                                    {
                                                        item?.permission_name === 'Receive Interest' ?
                                                            <>Accept Interest / Receive Interest</>
                                                            :
                                                            <>{item?.permission_name}</>
                                                    }
                                                </>
                                                :
                                                <>
                                                    {
                                                        item?.permission_name === 'Receive Interest' ?
                                                            <>Accept Interest</>
                                                            :
                                                            <>{item?.permission_name}</>
                                                    }
                                                </>
                                        }
                                    </p>
                                    {
                                        admin_type === 'super-admin' && (
                                            <div style={{
                                                width: 70,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                background: item?.is_active ? '#E8FFF3' : '#FFF5F8',
                                                borderRadius: 5,
                                                padding: '1%',
                                                marginLeft: 'auto'
                                            }}>
                                                <p style={{
                                                    color: item?.is_active ? '#50CD89' : '#F1416C',
                                                    fontSize: 13
                                                }}>
                                                    {item?.is_active ? 'Active' : 'Deactive'}
                                                </p>
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </>
        )
    }


    const renderPlanAction = (plan_id, is_highest, plan_cost) => {
        if (admin_type === 'super-admin') {
            return 'Edit This Plan';
        } else if (is_purchased) {
            if (user_data?.plan_id?.[0]?._id === plan_id) {
                return 'Your Plan';
            } else if (is_highest) {
                return 'Upgrade';
            } else if (user_data?.plan_id?.[0]?.monthly_discounted_price > plan_cost || user_data?.plan_id?.[0]?.yearly_discounted_price > plan_cost) {
                return 'Downgrade';
            } else {
                return 'Upgrade';
            }
        } else {
            return 'Choose This Plan';
        }
    };

    const pricingPlansDetails = (is_highest, plan_id, plans_name, item, plan_para, plan_cost, plan_arr, type) => {
        const formated_price = new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(plan_cost);
        return (
            <>
                <div style={{ position: 'relative' }}>
                    {
                        is_purchased && user_data?.plan_id?.[0]?._id === plan_id ?
                            <>
                                <div style={{
                                    top: 0,
                                    left: '25%',
                                    position: "absolute",
                                    width: 160,
                                    padding: '1.5% 1%',
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    background: '#0069ff',
                                    textTransform: 'capitalize',
                                    borderRadius: '0 0 4px 4px',
                                }}>
                                    <p style={{
                                        color: 'white',
                                        fontSize: 12
                                    }}>
                                        Current Plan - {user_data?.billing?.plan_type}
                                    </p>
                                </div>
                            </>
                            :
                            <></>
                    }
                    <div style={{ ...border_style, padding: '10% 7%', height: 250 }}>
                        <center>
                            <h3 style={{ ...pricing_title, color: app_color }}>{plans_name}</h3>
                            <p style={{ color: '#B5AEBB' }}>{plan_para}</p>
                            <div style={plan_cost_title}>
                                <h3 style={{ color: darkMode ? 'white' : 'black' }}>{formated_price}</h3>
                                <p style={{ color: '#858BA0', fontSize: 14, fontWeight: 500 }}>
                                    {selectedPlan === 'monthly' ? '/Per month' : selectedPlan === 'quaterly' ? 'Per 3 months' : selectedPlan === 'semi_yearly' ? 'Per 6 months' : '/Yearly'}
                                </p>
                            </div>

                            {
                                !is_purchased || user_data?.plan_id?.[0]?._id !== plan_id ? (
                                    (isSubAdmin && permissions?.includes('Edit Plans')) || !isSubAdmin ? (
                                        <Button
                                            onClick={(e) => handleClick(item)}
                                            variant="contained"
                                            style={{
                                                ...button_styles,
                                                background: type === 'recommended' ? app_color : darkMode ? darkPrimaryColor : '#252430',
                                            }}
                                            fullWidth
                                        >
                                            {renderPlanAction(plan_id, is_highest, plan_cost)}
                                        </Button>
                                    ) : <></>
                                ) : <></>
                            }

                        </center>
                    </div>
                    {
                        plan_arr?.map((item, i) => {
                            return (
                                <div style={{
                                    ...border_style,
                                    padding: '6%',
                                    display: "flex",
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontWeight: 500,
                                    fontSize: 15,
                                    height: 70
                                }}>
                                    {
                                        item?.value === 'true' || item?.value === 'Yes' ?
                                            <>
                                                <img src="/images/green-check-icon.svg" style={{ width: 23 }} />
                                            </>
                                            :
                                            item?.value === 'false' || item?.value === 'No' ?
                                                <>
                                                    <img src="/images/wrong-icon.svg" style={{ width: 23 }} />
                                                </>
                                                :
                                                <>
                                                    <p className="about-para"
                                                        style={{ color: darkMode ? 'white' : app_color, textAlign: 'center', fontWeight: 600, fontSize: 15, textTransform: 'capitalize' }}>
                                                        {item?.value}
                                                    </p>
                                                </>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </>
        )
    }

    return (
        <>
            <div style={{ padding: screen === 'Dashboard' ? '' : '5%' }} className={screen === 'Dashboard' ? '' : "pricing-div"}>
                {
                    screen !== 'Dashboard' && (
                        <img src='/images/blur-div.svg' style={{ position: 'absolute', left: 0, bottom: 0, zIndex: -1 }} />
                    )
                }
                {
                    loading ?
                        <TableSkeleton />
                        :
                        <>
                            {
                                admin_type !== 'super-admin' && (
                                    <Grid item md={12} style={{ marginTop: is_purchased ? '2%' : '' }}>
                                        <center><Heading title={is_purchased ? '' : 'Choose a Plan'} /></center>
                                    </Grid>
                                )
                            }

                            <Grid container spacing={0} style={pricing_page}>
                                <Grid item md={3} style={{ width: '100%' }}>
                                    {pricingInfo()}
                                </Grid>
                                <Grid item md={3} style={{ width: '100%' }}>
                                    {selectedPlan === 'monthly' ?
                                        pricingPlansDetails(allPlans[0]?.is_highest, allPlans[0]?._id, allPlans[0]?.name, allPlans[0], allPlans[0]?.description, allPlans[0]?.monthly_discounted_price, plan1Permissions) :

                                        selectedPlan === 'quaterly' ?
                                            pricingPlansDetails(allPlans[0]?.is_highest, allPlans[0]?._id, allPlans[0]?.name, allPlans[0], allPlans[0]?.description, allPlans[0]?.quaterly_discounted_price, plan1Permissions) :

                                            selectedPlan === 'semi_yearly' ?
                                                pricingPlansDetails(allPlans[0]?.is_highest, allPlans[0]?._id, allPlans[0]?.name, allPlans[0], allPlans[0]?.description, allPlans[0]?.semi_yearly_discounted_price, plan1Permissions) :

                                                pricingPlansDetails(allPlans[0]?.is_highest, allPlans[0]?._id, allPlans[0]?.name, allPlans[0], allPlans[0]?.description, allPlans[0]?.yearly_discounted_price, plan1Permissions)
                                    }
                                </Grid>
                                <Grid item md={3} style={{ width: '100%', }}>
                                    {selectedPlan === 'monthly' ?
                                        pricingPlansDetails(allPlans[1]?.is_highest, allPlans[1]?._id, allPlans[1]?.name, allPlans[1], allPlans[1]?.description, allPlans[1]?.monthly_discounted_price, plan2Permissions, 'recommended') :

                                        selectedPlan === 'quaterly' ?
                                            pricingPlansDetails(allPlans[1]?.is_highest, allPlans[1]?._id, allPlans[1]?.name, allPlans[1], allPlans[1]?.description, allPlans[1]?.quaterly_discounted_price, plan2Permissions, 'recommended') :

                                            selectedPlan === 'semi_yearly' ?
                                                pricingPlansDetails(allPlans[1]?.is_highest, allPlans[1]?._id, allPlans[1]?.name, allPlans[1], allPlans[1]?.description, allPlans[1]?.semi_yearly_discounted_price, plan2Permissions, 'recommended') :


                                                pricingPlansDetails(allPlans[1]?.is_highest, allPlans[1]?._id, allPlans[1]?.name, allPlans[1], allPlans[1]?.description, allPlans[1]?.yearly_discounted_price, plan2Permissions, 'recommended')
                                    }
                                </Grid>
                                <Grid item md={3} style={{ width: '100%', }}>
                                    {selectedPlan === 'monthly' ?
                                        pricingPlansDetails(allPlans[2]?.is_highest, allPlans[2]?._id, allPlans[2]?.name, allPlans[2], allPlans[2]?.description, allPlans[2]?.monthly_discounted_price, plan3Permissions) :

                                        selectedPlan === 'quaterly' ?
                                            pricingPlansDetails(allPlans[2]?.is_highest, allPlans[2]?._id, allPlans[2]?.name, allPlans[2], allPlans[2]?.description, allPlans[2]?.quaterly_discounted_price, plan3Permissions) :

                                            selectedPlan === 'semi_yearly' ?
                                                pricingPlansDetails(allPlans[2]?.is_highest, allPlans[2]?._id, allPlans[2]?.name, allPlans[2], allPlans[2]?.description, allPlans[2]?.semi_yearly_discounted_price, plan3Permissions) :


                                                pricingPlansDetails(allPlans[2]?.is_highest, allPlans[2]?._id, allPlans[2]?.name, allPlans[2], allPlans[2]?.description, allPlans[2]?.yearly_discounted_price, plan3Permissions)
                                    }
                                </Grid>
                            </Grid>
                            <EditPlanModal
                                setSuccessOpen={setSuccessOpen}
                                setSuccessStatus={setSuccessStatus}
                                setOpen={setOpen}
                                open={open}
                                plan={editPlan}
                                editItems={editItems}
                                fetchAllPlans={fetchAllPlans}
                                fetchPlansPermissions={fetchPlansPermissions}
                                plansPermissions={allPlansPermisison}
                            />
                            <SuccessModal
                                status={successStatus}
                                open={successOpen}
                                setOpen={setSuccessOpen}
                                title={successStatus ? `Plan updated successfully` : 'Something went wrong...'}
                            />
                            <SignupSignModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
                        </>
                }

            </div>
        </>
    )
}