import React, { useEffect, useState } from "react";
import { Grid, Pagination, Typography } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading";
import {
  extractNumber,
  useDatabaseConstants,
} from "../../../Constants/globalConstants";
import UserProfileCard from "../Components/UserProfileCard";
import { get_data, post_data } from "../../../Api";
import UserProfileCardSkeleton from "../Components/skeleton/UserProfileCardSkeleton";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FilterBar from "../Components/FilterBar";
import DefaultModal from "../Components/DefaultModal";
import { login } from "../../../redux/slices/user-slice";
import { IoLockClosed } from "react-icons/io5";
import BackButton from ".././Components/BackButton";
import EmptyPage from "../Components/EmptyPage";

export default function FindMatch({
  permissions,
  profileLoading,
  setProfileLoading,
  setProfileName,
  setProfileLoadingType,
  profileLoadingType,
}) {

  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { user_data } = useSelector((state) => state.user);
  const [limitUsers, setLimitUsers] = useState(false);
  const [noPlan, setNoPlan] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const itemsPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [totalUsers, setTotalUsers] = useState(null);
  const [filterCurrentPage, setFilterCurrentPage] = useState(1);
  const [isFilter, setIsFilter] = useState(false);

  const { app_color } = useDatabaseConstants();

  const my_profile_page = {
    width: "100%",
    position: "relative",
  };

  const page_container = {
    width: "100%",
    margin: "2% 0",
  };


  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };


  const fetchAllUsers = async (pageNumber) => {
    setLoading(true);

    try {
      let gender =
        user_data?.basic_details?.gender === "Female" ? "Male" : "Female";
      const response = await get_data(
        `user/get-all-users/${gender}?page_number=${pageNumber}`
      );

      // let filteredUsers = response?.data?.allUsers.filter(
      //   (user) => user?.privacy?.platinum_profiles === false
      // );
      // console.log({ filteredUsers });

      if (response?.status) {
        if (user_data?.plan_id?.[0]?.name === "Platinum") {
          // setAllUsers(response?.data?.allUsers);
          const shuffledUsers = shuffleArray(response?.data?.allUsers);
          setAllUsers(shuffledUsers);

          setTotalUsers(response?.data?.totalUsers);
          setTotalPages(response?.data?.totalPages);
        } else if (user_data?.plan_id?.[0]?.name !== "Platinum") {
          let filteredUsers = response?.data?.allUsers?.filter(
            (user) => user?.privacy?.platinum_profiles === false
          );
          setAllUsers(filteredUsers);
          setTotalPages(response?.data?.totalPages);
        }
      }

    } catch (error) {
      // console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentPage && !isFilter) {
      fetchAllUsers(currentPage);
    }
  }, [currentPage, user_data, permissions, noPlan]);

  useEffect(() => {
    if (user_data?.plan_id?.length === 0) {
      setNoPlan(true);
    }

    if (
      (permissions?.hasOwnProperty("get_up_to_2x_more_matches") &&
        permissions?.get_up_to_2x_more_matches === "No") ||
      noPlan
    ) {
      setLimitUsers(true);
    }
  }, [user_data, permissions, noPlan]);

  const limited_users = 100;

  const createBrowsingHistory = async (item) => {
    await post_data("browsing-history/create-browsing-history", {
      user_id: user_data?._id,
      partner_id: item?._id,
    });
  };

  const navigateToProfile = (item) => {
    navigate(`/dashboard/profile/${item?.dil_sey_id}`, {
      state: { user_profile_details: item, screen: "Match" },
    });
  };

  const updateBrowsedProfiles = async (item) => {
    const payload = { profile_id: item?._id };
    const response = await post_data(
      `user/update-browsed-profiles/${user_data?._id}`,
      payload
    );
    if (response?.status) {
      dispatch(login(response?.data));
    }
  };

  const profileLoadingFunc = (item) => {
    setProfileLoading(true);
    const profile_name = `${item?.first_name || ""} ${item?.last_name || ""
      }`.trim();
    if (profile_name) {
      setProfileName(profile_name);
    }
  };

  const handleProfileClick = (item, i) => {
    if (limitUsers && i > limited_users) return;

    profileLoadingFunc(item);

    const proceedToProfile = async () => {
      await createBrowsingHistory(item);
      navigateToProfile(item);
      setProfileLoading(false);
    };

    setTimeout(async () => {
      if (noPlan) {
        await proceedToProfile();
      } else if (permissions?.browse_profiles) {
        if (permissions?.browse_profiles === "Unlimited") {
          await proceedToProfile();
        } else {
          const browsedProfiles = user_data?.browsed_profiles || [];
          const browseLimit = extractNumber(permissions?.browse_profiles);

          if (
            browsedProfiles?.length === browseLimit &&
            !browsedProfiles?.includes(item?._id)
          ) {
            setOpen(true);
            setProfileLoading(false);
          } else {
            await createBrowsingHistory(item);
            if (!browsedProfiles?.includes(item?._id)) {
              await updateBrowsedProfiles(item);
            }
            navigateToProfile(item);
            setProfileLoading(false);
          }
        }
      } else {
        await proceedToProfile();
      }
    }, 0);
  };

  const startEntry = (currentPage - 1) * itemsPerPage + 1;
  const endEntry = Math.min(currentPage * itemsPerPage, totalUsers);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const displayUsersCard = () => {
    const users_blur = limited_users;
    const startIndex = (currentPage - 1) * itemsPerPage;
    // allUsers?.slice(startIndex, startIndex + itemsPerPage)?.map
    return (
      <>
        <Grid container spacing={0}>
          {allUsers?.map((item, i) => {
            const overallIndex = startIndex + i;
            return (
              <>
                <Grid item md={4} style={{ position: "relative" }}>
                  {limitUsers && overallIndex > users_blur && (
                    <>
                      <div
                        style={{
                          position: "absolute",
                          zIndex: 99,
                          top: "35%",
                          left: "10%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          flexDirection: "column",
                        }}
                      >
                        <IoLockClosed
                          style={{
                            height: 50,
                            width: 50,
                          }}
                        />
                        <h3 style={{ margin: "auto", width: "80%" }}>
                          Upgrade your Plan to view more Matches!
                        </h3>
                      </div>
                    </>
                  )}
                  <div
                    className={
                      limitUsers && overallIndex > users_blur
                        ? "blur-details-div"
                        : ""
                    }
                  >
                    <UserProfileCard
                      permissions={permissions}
                      item={item}
                      i={i}
                      screen="Match"
                      handleProfileClick={handleProfileClick}
                      current_user_data={user_data}
                    />
                  </div>
                </Grid>
              </>
            );
          })}
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid container spacing={0} style={my_profile_page}>
        <Grid
          item
          md={12}
          style={{ display: "flex", alignItems: "center", gap: "1%" }}
        >
          <BackButton />
          <DashboardHeading title="Find a Match 💞" />
        </Grid>

        <div style={page_container}>
          {loading ? (
            <>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <UserProfileCardSkeleton />
                </Grid>
                <Grid item xs={4}>
                  <UserProfileCardSkeleton />
                </Grid>
                <Grid item xs={4}>
                  <UserProfileCardSkeleton />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <div style={{ margin: "0 0 2%" }}>
                <FilterBar
                  permissions={permissions}
                  setProfileLoading={setProfileLoading}
                  setProfileLoadingType={setProfileLoadingType}
                  profileLoadingType={profileLoadingType}
                  setAllUsers={setAllUsers}
                  fetchAllUsers={fetchAllUsers}
                  setTotalPages={setTotalPages}
                  currentPage={currentPage}
                  setTotalUsers={setTotalUsers}
                  isFilter={isFilter}
                  setIsFilter={setIsFilter}
                />
              </div>

              {allUsers?.length > 0 ? (
                <>{displayUsersCard()}</>
              ) : (
                <>
                  <EmptyPage title="No Matched Profiles..." />
                </>
              )}

              <div
                style={{
                  borderTop: "1px dashed gainsboro",
                  paddingTop: "2%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: 15,
                    color: "black",
                  }}
                >
                  {`Showing ${startEntry} to ${endEntry} of ${totalUsers} users`}
                </Typography>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                />
              </div>
            </>
          )}
        </div>
        <DefaultModal
          open={open}
          setOpen={setOpen}
          screen={"Match"}
          limit="exceed"
          title_left="You have exceed limit!"
          para_left="Please upgrade your Plan, if you want to view more profiles."
          title_right="You have exceed limit!"
          para_right="Please upgrade your Plan, if you want to view more profiles."
          image="modal-img-plans"
          button_right="Upgrade your Plan"
        />
      </Grid>
    </>
  );
}
