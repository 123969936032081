import { useMediaQuery } from '@mui/material';
import React from 'react';
import { useDatabaseConstants } from '../../Constants/globalConstants';

const HeroSection = () => {

    const isDesktop = useMediaQuery('(min-width: 768px)');
    const { app_color } = useDatabaseConstants()

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
            padding: isDesktop ? '0 7%' : '20px 1px',
        },
        leftSide: {
            flex: 1,
            padding: '20px',
            order: isDesktop ? 1 : 2
        },
        rightSide: {
            flex: 1,
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            order: isDesktop ? 2 : 1
        },
        title: {
            fontSize: 20,
            fontWeight: 500,
        },
        heading: {
            fontSize: isDesktop ? 50 : '32px',
            fontWeight: '600',
            color: app_color,
            marginBottom: '20px',
            marginTop: 0,
            lineHeight: 1
        },
        highlight: {
            color: app_color,
        },
        description: {
            fontSize: '16px',
            marginBottom: '20px',
        },
        button: {
            padding: isDesktop ? '3% 8%' : '10px 20px',
            fontSize: '16px',
            color: '#fff',
            backgroundColor: app_color,
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
        },
        image: {
            maxWidth: isDesktop ? '80%' : '100%',
            height: 'auto',
            borderRadius: '10px',
        }
    };

    return (
        <>
            <div className='about-bg'>

                <div style={{ padding: isDesktop ? '4% 7%' : '10% 4% 5%', position: 'relative' }}>
                    <h2 className="global-h2" style={{ fontWeight: 600 }}>
                        DilSey: Where Love Stories Bloom
                    </h2>
                    <p className="about-para" style={{ width: isDesktop ? '50%' : '100%' }}>
                        DilSey is more than just a matrimony platform; it's a community passionate about fostering genuine connections and lasting love.
                    </p>
                    <img src='/images/blur-div.svg' style={{ position: 'absolute', left: 0, bottom: 0, zIndex: -1 }} />
                </div>

                <div style={styles.container}>
                    <div style={styles.leftSide}>
                        <h2 style={styles.heading}>
                            Celebrating Love and Togetherness
                        </h2>
                        <p style={styles.description}>
                            At DilSey, we believe in the magic of connection and the power of love. Our platform is dedicated to helping you find that special someone who complements your life beautifully. Whether you're searching for a soulmate or a life partner, DilSey is where your love story begins.
                            <br />
                            <br />
                            Join our community and discover meaningful relationships built on trust, respect, and shared values. Embrace the journey of finding love with DilSey.
                        </p>
                    </div>
                    <div style={styles.rightSide}>
                        <img
                            // src="/images/nivishka-bg-image.jpg"
                            src="/images/about-img-2.svg"
                            alt="Home Maintenance"
                            style={styles.image}
                        />
                    </div>
                </div>
                <div style={{ ...styles.container, paddingBottom: '10%', order: isDesktop ? 2 : 1 }}>
                    <img src='/images/petal-6.png' style={{ position: 'absolute', left: 0, bottom: 0, width: 250 }} />
                    <img src='/images/blur-div.svg' style={{ position: 'absolute', left: 0, bottom: 0, zIndex: -1 }} />
                    <div style={styles.leftSide}>
                        <img
                            // src="/images/nivishka-bg-image.jpg"
                            src="/images/hero-img-v2.svg"
                            alt="Home Maintenance"
                            style={styles.image}
                        />
                    </div>
                    <div style={{ ...styles.rightSide, flexDirection: 'column', order: isDesktop ? 1 : 2 }}>
                        <h2 style={styles.heading}>
                            The Promise of a Lifetime
                        </h2>
                        <p style={styles.description}>
                            Marriage is not just a ceremony; it's a journey of shared dreams, mutual respect, and unconditional love. At DilSey, we are committed to making your path to matrimony as beautiful and seamless as possible.
                            <br />
                            <br />
                            With our extensive database of genuine profiles and our user-friendly interface, finding the right match has never been easier. Let DilSey be your trusted partner in this important journey of finding true companionship and building a lifetime of happiness.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}


export default HeroSection;
