import React, { useContext } from "react"
import { useState, useEffect } from "react"
import { Grid, Button } from "@mui/material"
import SideBar from "../Components/SideBar"
import TopBar from "../Components/TopBar"
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import MyProfile from "./MyProfile"
import CollapsedSideBar from "../Components/CollapsedSidebar"
import FindMatch from "./FindMatch"
import InterestSent from './InterestSent'
import InterestReceived from './InterestReceived'
import Billing from './Billing'
import AdditionalServices from './AdditionalServices'
import Chat from "./Chat"
import Settings from "./Settings"
import PermissionsFunction from "../../../Constants/permissionsFunction"
import BrowsingHistory from "./BrowsingHistory"
import ReviewModal from "../Components/ReviewModal"
import SuccessModal from "../Components/SuccessModal"
import Review from "./Review"
import ProfileLoading from "../Components/ProfileLoading"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import EmptyPage from "../Components/EmptyPage"
import ChatBot from "../Components/ChatBot"
import ShareYourStory from "./ShareYourStory"
import VideoConsultation from "./VideoConsultation"
import HelpCenter from "./HelpCenter"
import { DarkModeContext } from "../Components/DarkModeContext"
import { darkPrimaryColor } from "../../../Constants/globalConstants"

export default function Dashboard() {

    const [collapse, setCollapse] = useState(false)
    const { permissions } = PermissionsFunction()
    const [open, setOpen] = useState(false)
    const [successStatus, setSuccessStatus] = useState(false)
    const [successOpen, setSuccessOpen] = useState(false)
    const [profileLoading, setProfileLoading] = useState(false)
    const [profileName, setProfileName] = useState('')
    const theme = useTheme();
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'));
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const [profileLoadingType, setProfileLoadingType] = useState('')

    const { darkMode } = useContext(DarkModeContext);

    const content_section = {
        width: '100%',
        height: '90.8vh',
        padding: '2%',
        color: darkMode ? 'white' : 'black',
        background: darkMode ? darkPrimaryColor : '#F8F8F8',
        overflowY: 'auto',
    }

    const menu_items = [
        {
            title: 'My Profile',
            link: '/dashboard',
            icon: 'my-profile-icon',
            active_icon: 'my-profile-white-icon'
        },
        {
            title: 'Find a Match',
            link: '/dashboard/find-match',
            icon: 'users-icon',
            active_icon: 'users-white-icon'
        },
        {
            title: 'Interest Sent',
            link: '/dashboard/interest-sent',
            icon: 'heart-tick-icon',
            active_icon: 'heart-tick-white-icon'
        },
        {
            title: 'Interest Received',
            link: '/dashboard/interest-received',
            icon: 'heart-slash-icon',
            active_icon: 'heart-slash-white-icon'
        },
        {
            title: 'Chat',
            link: '/dashboard/chat',
            icon: 'chat-icon',
            active_icon: 'chat-white-icon'
        },
        {
            title: 'Billing',
            link: '/dashboard/billing',
            icon: 'billing-icon',
            active_icon: 'billing-white-icon'
        },
        {
            title: 'Background Verification',
            link: '/dashboard/background-verification',
            icon: 'bg-verification-icon',
            active_icon: 'bg-verification-color-icon'
        },
        {
            title: 'Video Consultation',
            link: '/dashboard/video-consultation',
            icon: 'video-icon',
            active_icon: 'video-color-icon'
        },
        {
            title: 'Reviews',
            icon: 'review-icon',
            link: '/dashboard/my-review',
            type: 'review',
            active_icon: 'review-active-icon'
        },
        {
            title: 'History',
            link: '/dashboard/browsing-history',
            icon: 'history-icon',
            active_icon: 'history-active-icon'
        },
        {
            title: 'Settings',
            link: '/dashboard/settings',
            icon: 'settings-icon-new',
            active_icon: 'settings-white-icon-new'
        },
        // {
        //     title: 'Share your Story',
        //     link: '/dashboard/share-your-story',
        //     icon: 'heart-tick-icon',
        //     active_icon: 'heart-tick-white-icon'
        // },
    ]

    return (
        <>
            {
                matches_md ?
                    <>
                        <EmptyPage title={'Please install our Mobile App'} />
                    </>
                    :
                    <>
                        <div style={{ position: 'relative' }}>
                            {
                                profileLoading && (
                                    <ProfileLoading profileName={profileName} profileLoadingType={profileLoadingType} />
                                )
                            }
                            <Grid container spacing={0}>
                                <Grid item md={collapse ? 0.5 : 1.7} style={{ position: 'sticky', top: 0, height: '100vh' }}>
                                    {
                                        collapse ?
                                            <>
                                                <CollapsedSideBar
                                                    open={open}
                                                    setOpen={setOpen}
                                                    setCollapse={setCollapse}
                                                    collapse={collapse}
                                                    menu_items={menu_items}
                                                />
                                            </>
                                            :
                                            <>
                                                <SideBar
                                                    open={open}
                                                    setOpen={setOpen}
                                                    setCollapse={setCollapse}
                                                    collapse={collapse}
                                                    menu_items={menu_items}
                                                />
                                            </>
                                    }
                                </Grid>
                                <Grid item md={collapse ? 11.5 : 10.3} style={{ overflowY: 'auto' }} className="dashboard_content_grid">
                                    <TopBar />
                                    <div style={content_section} className="content-section">
                                        <Routes>
                                            <Route element={<MyProfile permissions={permissions} />} path="/" />
                                            <Route element={<FindMatch permissions={permissions} setProfileLoadingType={setProfileLoadingType} profileLoadingType={profileLoadingType} setProfileLoading={setProfileLoading} profileLoading={profileLoading} setProfileName={setProfileName} />} path="/find-match" />
                                            <Route element={<InterestSent permissions={permissions} setProfileLoading={setProfileLoading} profileLoading={profileLoading} setProfileName={setProfileName} />} path="/interest-sent" />
                                            <Route element={<InterestReceived permissions={permissions} setProfileLoading={setProfileLoading} profileLoading={profileLoading} setProfileName={setProfileName} />} path="/interest-received" />
                                            <Route element={<Billing />} path="/billing" />
                                            <Route element={<MyProfile permissions={permissions} />} path="/profile/:dil_sey_id" />
                                            <Route element={<Chat permissions={permissions} />} path="/chat" />
                                            <Route element={<BrowsingHistory permissions={permissions} setProfileLoading={setProfileLoading} profileLoading={profileLoading} setProfileName={setProfileName} />} path="/browsing-history" />
                                            <Route element={<AdditionalServices />} path="/additional-services" />
                                            <Route element={<Settings />} path="/settings" type='user' />
                                            <Route element={<Review />} path="/my-review" />
                                            <Route element={<HelpCenter />} path="/help-center" />
                                            <Route element={<VideoConsultation setProfileLoading={setProfileLoading} profileLoading={profileLoading} setProfileName={setProfileName} />} path="/video-consultation" />
                                        </Routes>
                                    </div>
                                </Grid>
                            </Grid>
                            <ReviewModal open={open} setOpen={setOpen} successOpen={successOpen}
                                setSuccessOpen={setSuccessOpen} successStatus={successStatus}
                                setSuccessStatus={setSuccessStatus}
                            />

                            <SuccessModal
                                status={successStatus}
                                open={successOpen}
                                setOpen={setSuccessOpen}
                                title={
                                    successStatus ? `Thanks for giving us your Time!` : 'Something went wrong...'
                                }
                            />

                            {/* <ChatBot /> */}
                        </div>
                    </>
            }
        </>
    )
}