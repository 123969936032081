import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';
import { secondaryDarkColor } from '../../../../Constants/globalConstants';
import { DarkModeContext } from '../DarkModeContext';

export default function ReviewsSkeleton() {

    const { darkMode } = React.useContext(DarkModeContext);

    const SkeletonBlock = () => (
        <Grid item xs={3}>
            <Stack spacing={1}>
                <Skeleton variant="circular" width={50} height={50} style={{ background: darkMode ? secondaryDarkColor : '' }} />
                <Skeleton variant="rounded" width={'100%'} height={60} style={{ background: darkMode ? secondaryDarkColor : '' }} />
                <Skeleton variant="rounded" width={'100%'} height={60} style={{ background: darkMode ? secondaryDarkColor : '' }} />
            </Stack>
        </Grid>
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} style={{ marginBottom: '3%' }}>
                <Skeleton variant="rounded" width={'100%'} height={140} style={{ background: darkMode ? secondaryDarkColor : '' }} />
            </Grid>
            {Array.from({ length: 4 }).map((_, index) => (
                <SkeletonBlock key={index} style={{ background: darkMode ? secondaryDarkColor : '' }} />
            ))}
        </Grid>
    );
}
