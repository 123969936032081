import React, { useState } from 'react';
import { Button } from '@mui/material';
import { button_right_style, disabled_btn } from '../../../Constants/globalConstants';
import { post_data } from "../../../Api";
import toast, { Toaster } from 'react-hot-toast';
import OtpComponent from '../../../Website/Components/OtpComponent';

const SuperAdminLogin = () => {

    const [emailForm, setEmailForm] = useState(true)
    const [otpForm, setOtpForm] = useState(false)

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [errors, setErrors] = useState({
        email: '',
        password: '',
    });

    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const handleLogin = async () => {
        let is_valid = true;
        const newErrors = { ...errors };


        if (!formData?.email) {
            newErrors.email = 'Please enter your email !';
            is_valid = false;
        }


        if (!formData?.password) {
            newErrors.password = 'Please enter your password !';
            is_valid = false;
        }

        if (is_valid) {
            setLoading(true);
            const response = await post_data('super-admin/super-admin-login', formData);
            if (response?.status) {
                setLoading(false);
                setEmailForm(false)
                setOtpForm(true)
            } else {
                setLoading(false);
                toast.error('Something went wrong.');
            }
        } else {
            setErrors(newErrors);
        }
    };

    return (
        <div className="super-admin-container">
            <Toaster />
            <div className="sign-in-card">
                {
                    emailForm && (
                        <>
                            <div>
                                <center>
                                    <img src={`/images/dilsey-logo.svg`} style={{ width: '40%' }} alt="logo" />
                                </center>
                                <h1 style={{ fontWeight: 500, fontSize: 20, marginTop: '5%' }}>Welcome Back Admin<span role="img" aria-label="wave">✌️</span></h1>
                                <p className='about-para'>Enter your credentials to access your account.</p>
                                <div style={{ margin: '6% 0 4%' }}>
                                    <input

                                        className={`register-from-input ${errors?.email ? 'invalid-input' : ''}`}
                                        type="text"
                                        placeholder="Email Address"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                    {errors?.email && <p className="invalid-txt" style={{ display: "flex" }}>{errors?.email}</p>}
                                </div>
                                <div style={{ margin: '4% 0' }}>
                                    <input
                                        className={`register-from-input ${errors?.password ? 'invalid-input' : ''}`}
                                        type="password"
                                        placeholder="Password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleInputChange}
                                    />
                                    {errors?.password && <p className="invalid-txt" style={{ display: "flex" }}>{errors?.password}</p>}
                                </div>
                                <div>
                                    <Button variant='contained'
                                        style={{
                                            ...button_right_style,
                                            width: '30%',
                                            ...(loading ? disabled_btn : {})
                                        }}
                                        onClick={handleLogin} disabled={loading}
                                    >
                                        {loading ? 'Signing In...' : 'Sign In'}
                                    </Button>
                                </div>
                            </div>
                        </>
                    )
                }
                {
                    otpForm && (
                        <OtpComponent
                            formData={formData}
                            resendOtpFunc={handleLogin}
                            type={'super-admin-login'} />
                    )
                }
            </div>
        </div>
    );
}

export default SuperAdminLogin;
