import React, { useContext, useEffect, useState } from "react"
import { extractNumber, formatDate, getAvatar, featuredLimitDays, maskNumber, maskOther, useDatabaseConstants, button_right_style, disabled_btn, secondaryDarkColor, darkPrimaryColor } from "../../../Constants/globalConstants"
import SuccessModal from "./SuccessModal"
import { post_data, serverURLProfilePicture } from "../../../Api"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import FeaturedLabel from "./FeaturedLabel"
import ProfileLabel from "./ProfileLabel"
import InterestButtons from "./InterestButtons"
import InterestButtonsFunction from "../../../Constants/interestButtonsFunction"
import InterestConditionsModal from "./InterestConditionsModal"
import { Button } from "@mui/material"
import { DarkModeContext } from "./DarkModeContext"

export default function UserProfileCard({
    item,
    i,
    screen,
    permissions,
    current_user_data,
    handleProfileClick,
    type
}) {

    const { user_data } = useSelector(state => state.user);
    const [noPlan, setNoPlan] = useState(false)
    const { darkMode } = useContext(DarkModeContext);

    const navigate = useNavigate()
    const { app_color } = useDatabaseConstants()
    const [open, setOpen] = useState(false)
    const [successOpen, setSuccessOpen] = useState(false)
    const [btnClicked, setBtnClicked] = useState('')
    const [successStatus, setSuccessStatus] = useState(false)
    const [hasPermission, setHasPermission] = useState(false)
    const [isFeatured, setIsFeatured] = useState(false)
    const [featuredLimit, setFeaturedLimit] = useState('')

    const { interestStatus, sentInterestMatch, receivedInterestMatch } = InterestButtonsFunction({ screen, item })

    const profile_card = {
        boxShadow: '3px 3px 4px 0 #00000008',
        position: 'relative',
        border: darkMode ? '' : '2px solid #F1F1F2',
        padding: "5% 5% 7%",
        width: '95%',
        margin: '6% 2%',
        borderRadius: 10,
        cursor: 'pointer',
        background: darkMode ? secondaryDarkColor : 'white'
    }

    const profile_pic = {
        borderRadius: "50%",
        width: 120,
        height: 120,
        marginTop: "-15%",
        border: darkMode ? `7px solid ${secondaryDarkColor}` : "7px solid white",
        zIndex: 2,
    };

    const profile_card_title = {
        fontWeight: 500,
        fontSize: 14,
    };

    const profile_card_para = {
        fontWeight: 500,
        fontSize: 13,
        color: darkMode ? '#B2B2C1' : "#00000080",
    };

    const profile_label_div = {
        position: 'absolute',
        top: '2%',
        right: '2%'
    }

    const featured_div = {
        position: 'absolute',
        top: '2%',
        left: '2%'
    }

    const handleChatLink = async (e, item) => {
        let payload = { "sender_id": user_data?._id, "receiver_id": item?._id }
        const response = await post_data('chat/create-new-chat', payload)
        if (response?.status) {
            navigate('/dashboard/chat')
        }
    }

    const handleProfileCardClick = () => {
        handleProfileClick(item, i)
    }

    useEffect(() => {
        const has_permission = permissions?.hasOwnProperty('view_contact_details') ? (permissions?.view_contact_details === 'Yes' ? true : false) : true
        if (has_permission) {
            setHasPermission(true)
        }
    }, [permissions])

    let avatar = getAvatar(item)

    useEffect(() => {
        if (item?.plan_id?.length > 0) {
            setIsFeatured(true)
            const featured_value = item?.plan_id?.[0]?.permissions?.find(permission => permission?.permission_id?.permission_name === "Get featured")?.value;
            if (featured_value) {
                if (featured_value === 'Unlimited') {
                    setFeaturedLimit('Unlimited')
                }
                else {
                    setFeaturedLimit(extractNumber(featured_value))
                }
            }
        }
    }, [item])

    const renderFeaturedLabel = () => {

        const is_within_limit = featuredLimitDays(item, featuredLimit)

        return (
            <>
                {
                    is_within_limit && (
                        <div style={featured_div}>
                            <FeaturedLabel />
                        </div>
                    )
                }
            </>
        )
    }

    const openWhatsAppVideoCall = (phoneNumber) => {
        let url = `https://wa.me/${phoneNumber}`;
        window.open(url, '_blank');
    };

    const displayUserCard = () => {
        return (
            <>
                <div style={profile_card}>
                    <div onClick={handleProfileCardClick}>
                        {/* {matchScore()} */}
                        <div style={profile_label_div}>
                            <ProfileLabel width={100} user={item} />
                        </div>
                        {
                            isFeatured ?
                                <>
                                    {
                                        featuredLimit === 'Unlimited' ?
                                            <>
                                                <div style={featured_div}>
                                                    <FeaturedLabel />
                                                </div>
                                            </>
                                            :
                                            <>
                                                {
                                                    renderFeaturedLabel()
                                                }
                                            </>
                                    }
                                </>
                                :
                                <></>
                        }
                        <div>
                            <center>
                                {
                                    screen !== 'Match' && screen !== 'Sent' && screen !== 'Received' ?
                                        <>
                                            {
                                                item?.profile_picture ?
                                                    <>
                                                        <img src={`${serverURLProfilePicture}/${item?.profile_picture}`}
                                                            className={noPlan ? "blur-photos" : ""}
                                                            style={profile_pic} />
                                                    </>
                                                    :
                                                    <>
                                                        <img src={`/images/${avatar}`} style={profile_pic} />
                                                    </>
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                item?.privacy?.profile_picture ?
                                                    <>
                                                        <img src={`/images/${avatar}`} style={profile_pic} />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            item?.profile_picture ?
                                                                <>
                                                                    <img src={`${serverURLProfilePicture}/${item?.profile_picture}`}
                                                                        style={profile_pic}
                                                                        className={noPlan ? "blur-photos" : ""}
                                                                    />
                                                                </>
                                                                :
                                                                <>
                                                                    <img src={`/images/${avatar}`} style={profile_pic} />
                                                                </>
                                                        }
                                                    </>
                                            }
                                        </>
                                }

                            </center>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', margin: '1% 0' }}>
                            <h3 style={{ fontWeight: 600, fontSize: 20 }}>
                                {
                                    noPlan ?
                                        <>
                                            {maskOther(item?.first_name, 2)} {maskOther(item?.last_name, 2)}
                                        </>
                                        :
                                        <>
                                            {item?.first_name} {item?.last_name}
                                        </>
                                }
                            </h3>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                padding: "3% 0",
                                height: "60px",
                            }}
                        >
                            <div style={{ width: "50%", borderRight: "0.6px dashed #BFBFBF" }}>
                                <p style={profile_card_title}>DilSey ID</p>
                                <p style={profile_card_para}>
                                    {item?.dil_sey_id ? item?.dil_sey_id.toUpperCase() : ''}
                                </p>
                            </div>
                            <div style={{ width: "50%", paddingLeft: "6%" }}>
                                <p style={profile_card_title}>Mobile</p>
                                {
                                    item?.privacy?.contact_number ?
                                        <>
                                            <p style={profile_card_para}>****Privacy</p>
                                        </>
                                        :
                                        <>
                                            {
                                                noPlan ?
                                                    <>
                                                        <p style={profile_card_para}>{maskNumber(item?.contact_number)}</p>
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            hasPermission ?
                                                                <><p style={profile_card_para}>{item?.contact_number}</p></>
                                                                :
                                                                <>
                                                                    <p style={profile_card_para}>{maskNumber(item?.contact_number)}</p>
                                                                </>
                                                        }
                                                    </>
                                            }
                                        </>
                                }
                            </div>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                padding: "3% 0",
                                height: "60px",
                            }}
                        >
                            <div style={{ width: "50%", borderRight: "0.6px dashed #BFBFBF" }}>
                                <p style={profile_card_title}>DOB</p>
                                <p style={profile_card_para}>
                                    {
                                        item?.privacy?.dob ?
                                            <>
                                                ****Privacy
                                            </>
                                            :
                                            <>
                                                {formatDate(item?.basic_details?.dob) || 'N/A'}
                                            </>
                                    }
                                </p>
                            </div>
                            <div style={{ width: "50%", paddingLeft: "6%" }}>
                                <p style={profile_card_title}>Education</p>
                                {
                                    noPlan ?
                                        <>
                                            <p style={profile_card_para}>
                                                {item?.education_and_careers?.qualifications.length > 0 && Array.isArray(item?.education_and_careers?.qualifications) ? maskOther(item?.education_and_careers?.qualifications.join(', '), 2) : 'N/A'}
                                            </p>
                                        </>
                                        :
                                        <>
                                            <p style={profile_card_para}>
                                                {item?.education_and_careers?.qualifications.length > 0 && Array.isArray(item?.education_and_careers?.qualifications) ? item?.education_and_careers?.qualifications.join(', ') : 'N/A'}
                                            </p>
                                        </>
                                }
                            </div>

                        </div>

                        <div
                            style={{
                                display: "flex",
                                padding: "3% 0",
                                height: "60px",
                            }}
                        >
                            <div style={{ width: "50%", borderRight: "0.6px dashed #BFBFBF" }}>
                                <p style={profile_card_title}>Religion </p>
                                <p style={profile_card_para}>{item?.basic_details?.religion ? item?.basic_details?.religion : 'N/A'}</p>
                            </div>
                            <div style={{ width: "50%", paddingLeft: "6%" }}>
                                <p style={profile_card_title}>Occupation</p>
                                {
                                    noPlan ?
                                        <>
                                            <p style={profile_card_para}>
                                                {item?.education_and_careers?.occupation.length > 0 && Array.isArray(item?.education_and_careers?.occupation) ? maskOther(item?.education_and_careers?.occupation.join(', '), 2) : 'N/A'}
                                            </p>
                                        </>
                                        :
                                        <>
                                            <p style={profile_card_para}>
                                                {item?.education_and_careers?.occupation.length > 0 && Array.isArray(item?.education_and_careers?.occupation) ? item?.education_and_careers?.occupation.join(', ') : 'N/A'}
                                            </p>
                                        </>
                                }
                            </div>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                padding: "3% 0",
                                height: "60px",
                            }}
                        >
                            <div style={{ width: "50%", borderRight: "0.6px dashed #BFBFBF" }}>
                                <p style={profile_card_title}>Location</p>
                                <p style={profile_card_para}>{item?.contact_details?.city ? item?.contact_details?.city : 'N/A'}</p>
                            </div>
                            <div style={{ width: "50%", paddingLeft: "6%" }}>
                                <p style={profile_card_title}>Marital Status</p>
                                <p style={profile_card_para}>{item?.basic_details?.marital_status || 'N/A'}</p>
                            </div>
                        </div>
                    </div>

                    {
                        type === 'videoCall' ?
                            <>
                                <Button
                                    onClick={() => openWhatsAppVideoCall(`91${item?.contact_number}`)}
                                    disabled={item?.privacy?.contact_number || (hasPermission === false) || noPlan}
                                    variant="contained" style={item?.privacy?.contact_number || (hasPermission === false) || noPlan ? { ...disabled_btn, width: '100%', marginTop: '4%', color: 'white' } : { ...button_right_style, width: '100%', marginTop: '4%' }}>
                                    {
                                        noPlan ?
                                            <>
                                                Please Purchase a Plan
                                            </>
                                            :
                                            <>
                                                {
                                                    hasPermission ?
                                                        <>
                                                            {
                                                                item?.privacy?.contact_number ?
                                                                    <>
                                                                        ****Privacy
                                                                    </>
                                                                    :
                                                                    <>Video Call</>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            Upgrade to Gold or Premium Plan
                                                        </>
                                                }
                                            </>
                                    }
                                </Button>
                            </>
                            :
                            <>
                                {/* Interest Buttons */}
                                <InterestButtons
                                    screen={screen}
                                    interestStatus={interestStatus}
                                    sentInterestMatch={sentInterestMatch}
                                    receivedInterestMatch={receivedInterestMatch}
                                    setBtnClicked={setBtnClicked}
                                    setOpen={setOpen}
                                    item={item}
                                    noPlan={noPlan}
                                    open={open}
                                    permissions={permissions}
                                    user_data={user_data}
                                />
                            </>
                    }

                    {
                        (screen === 'Interest Received' && interestStatus === 'pending') || (screen === 'Match' && sentInterestMatch === 'pending') ?
                            <>
                                <p className="about-para"
                                    style={{ color: app_color, textAlign: 'center', fontWeight: 500, fontSize: 14, marginTop: '3%', cursor: 'pointer' }} onClick={(e) => handleChatLink(e, item)}>
                                    or Chat Instead
                                </p>
                            </>
                            :
                            <></>
                    }
                </div>
            </>
        )
    }

    useEffect(() => {
        if (user_data?.plan_id?.length === 0) {
            setNoPlan(true)
        }
    }, [user_data])

    return (
        <>
            {displayUserCard()}

            <InterestConditionsModal
                noPlan={noPlan}
                receivedInterestMatch={receivedInterestMatch}
                permissions={permissions}
                user_data={user_data}
                open={open}
                setOpen={setOpen}
                setSuccessOpen={setSuccessOpen}
                setSuccessStatus={setSuccessStatus}
                item={item}
                successStatus={successStatus}
                btnClicked={btnClicked}
                sentInterestMatch={sentInterestMatch}
                screen={screen}
                interestStatus={interestStatus}
            />

            <SuccessModal
                open={successOpen}
                setOpen={setSuccessOpen}
                status={successStatus}
                title={
                    screen === 'Match' && sentInterestMatch === 'Send Interest' ? 'Interest Sent Successfully' :
                        screen === 'Match' && sentInterestMatch === 'pending' && btnClicked === 'left' ? 'Interest declined Successfully' :
                            screen === 'Match' && sentInterestMatch === 'pending' && btnClicked === 'right' ? 'Interest accepted Successfully' :

                                screen === 'Interest Received' && btnClicked === 'left' ? 'Interest declined Successfully' :
                                    screen === 'Interest Received' && btnClicked === 'right' ? 'Interest accepted Successfully' : ''
                }
            />
        </>
    )
}