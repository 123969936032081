import React from "react"
import { useState, useEffect } from "react"
import { Grid, Button, TextField, InputAdornment } from "@mui/material"
import { useDatabaseConstants } from '../../../Constants/globalConstants'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useNavigate } from "react-router-dom";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AdditionalServicesDropdwon from "../../UserDshboard/Components/AdditionalServicesDropdown";

export default function SideBar({
    menu_items,
    isSubAdmin,
    type
}) {

    const navigate = useNavigate()

    const user_dashboard_sidebar = {
        borderRight: '2px solid gainsboro',
        background: 'white',
        width: '100%',
        height: '100%',
        position: 'sticky',
        top: 0
    }

    const logo_section = {
        padding: '8% 10%',
        display: "flex",
        alignItems: 'center',
    }

    const sidebar_items_div = {
        padding: '4% 0'
    }

    const handleListItem = (item, i) => {
        if (item.link) {
            navigate(item.link);
            window.scrollTo(0, 0);
        }
    }

    let current_path = window.location.pathname;

    const filtered_menu_items = isSubAdmin
        ? menu_items?.filter(item => item?.title !== 'Sub Admins' && item?.title !== 'Permissions' && item?.title !== 'Activities')
        : menu_items;

    const display_sidebar_items = () => {
        return (
            <List sx={{ width: '100%', maxWidth: 360 }} component="nav">
                {filtered_menu_items?.map((item, i) => {
                    return (
                        <div>
                            <ListItemButton
                                key={i}
                                onClick={() => handleListItem(item, i)}
                                style={{
                                    margin: '0',
                                    // borderRadius: 8,
                                    padding: '4% 6%',
                                    background: item?.type !== 'dropdown' ? current_path === item.link ? '#9E0050' : 'transparent' : ''
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        gap: '8%',
                                        width: '100%',
                                        alignItems: item?.type !== 'dropdown' ? "center" : 'start'
                                    }}
                                >
                                    {
                                        item?.type === 'dropdown' ?
                                            <><AdditionalServicesDropdwon type={type} item={item} current_path={current_path} /></>
                                            :
                                            <>
                                                <img
                                                    src={current_path === item.link ? `/images/${item?.active_icon}.svg` : `/images/${item.icon}.svg`}
                                                    style={{ width: 23, height: 23 }}
                                                />
                                                <p style={{ color: current_path === item.link ? 'white' : 'black', fontWeight: 500, fontSize: 13 }}>
                                                    {item?.type !== 'dropdown' ? item.title : ''}
                                                </p>
                                            </>
                                    }
                                </div>
                            </ListItemButton>
                        </div>
                    );
                })
                }
            </List >
        )
    }

    return (
        <>
            <div style={user_dashboard_sidebar} className="sidebar-div">
                <div style={logo_section}>
                    <img src={`/images/dilsey-logo.svg`} style={{ width: '70%', cursor: 'pointer' }} />
                </div>
                <hr style={{ opacity: '20%', borderColor: 'black' }} />

                <div style={sidebar_items_div}>
                    {display_sidebar_items()}
                </div>
            </div>
        </>
    )
}