import React, { useContext } from "react"
import { darkPrimaryColor, secondaryDarkColor, useDatabaseConstants } from '../../../Constants/globalConstants'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { useNavigate } from "react-router-dom";
import AdditionalServicesDropdwon from "./AdditionalServicesDropdown";
import Badge from '@mui/material/Badge';
import { DarkModeContext } from "./DarkModeContext";

export default function SideBar({
    setCollapse,
    menu_items
}) {

    const navigate = useNavigate()
    const { app_color, user_dashboard_color } = useDatabaseConstants();
    const { darkMode, setDarkMode } = useContext(DarkModeContext);

    const user_dashboard_sidebar = {
        background: darkMode ? darkPrimaryColor : '#32012F',
        borderRight: darkMode ? `2px solid ${secondaryDarkColor}` : '',
        width: '100%',
        height: '100%',
        position: 'sticky',
        top: 0
    }

    const logo_section = {
        padding: '8% 10%',
        display: "flex",
        alignItems: 'center',
    }

    const sidebar_items_div = {
        padding: '4% 0'
    }

    const handleListItem = (item, i) => {
        if (item?.link) {
            navigate(item.link);
            window.scrollTo(0, 0);
        }
    }

    const handleCollapse = () => {
        setCollapse(true)
    }

    let current_path = window.location.pathname;

    const display_sidebar_items = () => {
        return (
            <List sx={{ width: '100%', maxWidth: 360 }} component="nav">
                {menu_items.map((item, i) => {
                    return (
                        <div>
                            <ListItemButton
                                key={i}
                                onClick={() => handleListItem(item, i)}
                                style={{
                                    margin: '3% 0',
                                    // borderRadius: 8,
                                    padding: '4% 6%',
                                    background: item?.type !== 'dropdown' ? current_path === item.link ? app_color : 'transparent' : ''
                                }}
                            >
                                <div style={{
                                    display: "flex",
                                    gap: '8%',
                                    width: '100%',
                                    alignItems: item?.type !== 'dropdown' ? "center" : 'center'
                                }}>
                                    {
                                        item?.type === 'dropdown' ?
                                            <><AdditionalServicesDropdwon item={item} current_path={current_path} /></>
                                            :
                                            <>
                                                {
                                                    item?.title === 'Chat' ?
                                                        <>
                                                            <img
                                                                src={current_path === item?.link ? `/images/${item?.active_icon}.svg` : `/images/${item?.icon}.svg`}
                                                                style={{ width: 23, height: 23 }}
                                                            />
                                                            <p style={{ color: current_path === item?.link ? 'white' : '#A1A5B7', fontWeight: 500, fontSize: 14 }}>
                                                                {item?.type !== 'dropdown' ? item?.title : ''}
                                                            </p>
                                                        </>
                                                        :
                                                        <>
                                                            <img
                                                                src={current_path === item?.link ? `/images/${item?.active_icon}.svg` : `/images/${item?.icon}.svg`}
                                                                style={{ width: 23, height: 23 }}
                                                            />
                                                            <p style={{ color: current_path === item?.link ? 'white' : '#A1A5B7', fontWeight: 500, fontSize: 13 }}>
                                                                {item?.type !== 'dropdown' ? item?.title : ''}
                                                            </p>
                                                        </>
                                                }
                                            </>
                                    }
                                </div>
                            </ListItemButton>
                        </div>
                    );
                })}
            </List>
        )
    }

    return (
        <>
            <div style={user_dashboard_sidebar} className="sidebar-div">
                <div style={logo_section}>
                    <img src={`/images/dilsey-logo-white.svg`} style={{ width: '70%', cursor: 'pointer' }} />
                    <img src="/images/collapse-icon.svg" onClick={handleCollapse} style={{ width: 25, height: 25, marginLeft: 'auto', cursor: 'pointer' }} />
                </div>
                <hr style={{ opacity: '30%', borderColor: 'black' }} />
                <div style={sidebar_items_div}>
                    {display_sidebar_items()}
                </div>
            </div>
        </>
    )
}