import Faq from "../Components/Faq";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

export default function FaqPage() {
    return (
        <>
            <Header />
            <Faq />
            <Footer />
        </>
    )
}