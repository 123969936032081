import React from 'react';
import { formatDate, formatPrice } from '../../../Constants/globalConstants';

const InvoicePdf = () => {

    const styles = {
        invoice: {
            padding: '4%',
            height: 620,
            borderRadius: 5,
            boxShadow: '0px 0px 4px 4px #00000008',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '2px solid #000',
            paddingBottom: '10px',
            marginBottom: '5%'
        },
        brand: {
            textAlign: 'left',
        },
        info: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '10px',
            paddingBottom: '10px',
        },
        infoSection: {
            textAlign: 'left',
        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
        },
        th: {
            border: '1px solid #ddd',
            padding: '8px',
        },
        td: {
            border: '1px solid #ddd',
            padding: '8px',
        },
        footer: {
            marginTop: '20px',
            borderTop: '2px solid #000',
            marginTop: '5%',
            paddingTop: '10px',
            display: 'flex',
            justifyContent: 'space-between',
        },
        terms: {
            textAlign: 'left',
        },
        payment: {
            textAlign: 'left',
        },
        summary: {
            marginTop: '20px',
            textAlign: 'right',
        },
        text: {
            fontSize: 14
        }
    };

    const details = [
        {
            invoice_to: 'Dwyane Clark',
            invoice_to_address: '24 Dummy Street Area, Location, Lorem Ipsum, 570xx59x',
            item_description: 'Platinum Plan',
            item_price: 19999,
            qty: 1,
            total: 19999,
            tax: 0,
            grand_total: 19999,
            invoice_number: '#5',
            invoice_date: new Date()
        }
    ]

    return (
        <div style={{
            padding: '2% 27%'
        }}>
            <div id="invoice" style={styles.invoice}>
                <div style={styles.header}>
                    <div style={styles.brand}>
                        <h2 style={{ fontWeight: 600 }}>DilSey</h2>
                        <p style={{ fontSize: 11, opacity: '70%' }}>Rishtey Jodo DilSey</p>
                    </div>
                    <h1 style={{ fontWeight: 600 }}>INVOICE</h1>
                </div>
                <div style={{ ...styles.info, ...styles.text }}>
                    <div style={styles.infoSection}>
                        <p><strong>Invoice to:</strong></p>
                        <p>{details[0]?.invoice_to}</p>
                        <p>{details[0]?.invoice_to_address}</p>
                    </div>
                    <div style={styles.infoSection}>
                        <p><strong>Invoice: </strong> {details[0]?.invoice_number}</p>
                        <p><strong>Date: </strong> {formatDate(details[0]?.invoice_date)}</p>
                    </div>
                </div>
                <table style={{ ...styles.table, ...styles.text, marginTop: '3%' }}>
                    <thead style={{ textAlign: 'left' }}>
                        <tr>
                            <th>SL.</th>
                            <th>Item Description</th>
                            <th>Price</th>
                            <th>Qty.</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1.</td>
                            <td>{details[0]?.item_description}</td>
                            <td>{formatPrice(details[0]?.item_price)}</td>
                            <td>{details[0]?.qty}</td>
                            <td>{formatPrice(details[0]?.total)}</td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ ...styles.footer, ...styles.text }}>
                    <div style={styles.terms}>
                        <p>Thank you for your business</p>
                    </div>
                    <div style={{ ...styles.summary, ...styles.text }}>
                        <p>Sub Total: {formatPrice(details[0]?.total)}</p>
                        <p>Tax: {details[0]?.tax}</p>
                        <p><strong>Total: {formatPrice(details[0]?.grand_total)}</strong></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoicePdf;
