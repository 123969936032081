import React, { useState } from "react";
import { Switch } from "@headlessui/react";
import { post_data } from "../../../Api";
import { useDispatch } from "react-redux";
import { login } from "../../../redux/slices/user-slice";

export default function CustomDeactivateToggle({ user_data = [], type }) {
  const dispatch = useDispatch();

  const handleToggleChange = async () => {
    try {
      if (type && type === 'specially') {
        let payload = { specially_abled: !user_data?.specially_abled };
        const response = await post_data(
          `user/update-user/${user_data?._id}`,
          payload
        );

        if (response?.status) {
          dispatch(login(response?.data));
        }
      }
      else {
        let payload = { is_active: !user_data?.is_active };
        const response = await post_data(
          `user/update-user/${user_data?._id}`,
          payload
        );

        if (response?.status) {
          dispatch(login(response?.data));
        }
      }
    } catch (error) {
      // console.error("Error updating user:", error);
    }
  };

  let checked = type === 'specially' ? user_data?.specially_abled : !user_data?.is_active

  return (
    <Switch
      checked={checked} // Reflect initial state
      onChange={handleToggleChange}
      className={` relative inline-flex h-6 w-11 items-center rounded-full ${checked ? "bg-pink-700" : "bg-gray-200"
        }`} // Background color based on state
    >
      <span
        className={`${checked
          ? "translate-x-6 bg-white"
          : "translate-x-1 bg-white"
          } inline-block h-4 w-4 transform rounded-full transition`} // Toggle handle styles
      />
    </Switch>
  );
}
