import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal, { modalClasses } from '@mui/material/Modal';
import { useState } from 'react';
import { Grid, colors } from '@mui/material';
import { secondaryDarkColor, useDatabaseConstants } from '../../../Constants/globalConstants';
import { useNavigate } from 'react-router-dom';
import { post_data } from '../../../Api';
import { logout } from '../../../redux/slices/user-slice';
import { useDispatch } from 'react-redux';
import { DarkModeContext } from './DarkModeContext';

export default function LogoutModal({
    open,
    setOpen
}) {

    const handleClose = () => setOpen(false);
    const { app_color } = useDatabaseConstants()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { darkMode } = React.useContext(DarkModeContext);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 550,
        bgcolor: darkMode ? secondaryDarkColor : 'white',
        boxShadow: 24,
        borderRadius: 4,
        padding: '3%'
    };

    const modal_title = {
        marginTop: '4%',
        textAlign: 'center',
        width: '80%',
        fontSize: 21,
        fontWeight: 500,
        color: app_color
    }

    const button_right_style = {
        background: app_color,
        width: '50%',
        color: 'white',
        boxShadow: 'none',
        borderRadius: 7,
        fontWeight: 500,
        fontSize: 15,
        textTransform: 'capitalize',
        padding: '3% 0'
    }

    const button_left_style = {
        background: 'white',
        width: '50%',
        color: app_color,
        boxShadow: 'none',
        borderRadius: 7,
        padding: '3% 0',
        fontWeight: 500,
        fontSize: 15,
        textTransform: 'capitalize',
        border: `2px solid ${app_color}`
    }

    const disabled_btn = {
        background: '#9e004f8e'
    }

    const handleLogout = (e) => {
        setLoading(true)
        setTimeout(() => {
            logoutUser()
        }, 1000);
    }

    const logoutUser = async () => {
        localStorage.removeItem('authToken')
        dispatch(logout());
        handleClose()
        navigate('/')
        setLoading(false)
        // const response = await post_data('/user-logout')
        // if (response?.status){
        //     localStorage.removeItem('authToken')
        //     dispatch(logout());
        //     navigate('/')
        //     handleClose()
        // }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <div onClick={handleClose} style={{
                        position: 'absolute',
                        right: '3%',
                        top: '3%',
                        cursor: 'pointer',
                    }}
                    >
                        <img src='/images/modal-close-icon.svg' style={{ width: '100%', height: '100%' }} />
                    </div>
                    <Grid container spacing={0}>
                        {
                            !darkMode && (
                                <Grid item md={12}>
                                    <center><img src={'/images/modal-img-send.svg'} style={{ width: '70%', height: '70%' }} /></center>
                                </Grid>
                            )
                        }

                        <Grid item md={12} style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                            <h2 style={{ ...modal_title, color: darkMode ? 'white' : app_color }}>Are you sure, you want to Logout ?</h2>
                        </Grid>
                    </Grid>

                    <Grid item md={12} style={{
                        width: '70%',
                        display: 'flex',
                        gap: '4%',
                        margin: '6% auto 0 auto',
                        // marginTop: '8%'
                    }}>
                        <Button onClick={handleClose} variant='contained' style={button_left_style}>
                            Cancel
                        </Button>

                        <Button onClick={(e) => handleLogout(e)} disabled={loading} variant='contained'
                            style={loading ? { ...button_right_style, ...disabled_btn } : button_right_style}
                        >{loading ? 'Logging out...' : 'Yes'}
                        </Button>

                    </Grid>

                </Box>
            </Modal>
        </div>
    );
}
