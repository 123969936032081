import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { button_right_style, useDatabaseConstants, disabled_btn } from '../../../Constants/globalConstants';
import { Button, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { post_data } from '../../../Api';
import { login } from '../../../redux/slices/user-slice';

export default function SideDrawer() {

    const dispatch = useDispatch()
    const { app_color } = useDatabaseConstants()
    const [state, setState] = React.useState({ right: false })
    const [selectedItemIndex, setSelectedItemIndex] = useState('')
    const [anchorEl, setAnchorEl] = useState(null)
    const { user_data } = useSelector(state => state.user);
    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false)
    const open = Boolean(anchorEl)

    const [formData, setFormData] = useState({
        first_name: user_data?.first_name,
        last_name: user_data?.last_name,
        // email: user_data?.email,
    })

    const toggleDrawer = (anchor, open) => (event) => {
        if (event?.type === 'keydown' && (event?.key === 'Tab' || event?.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    useEffect(() => {
        setSelectedItemIndex(0)
    }, [])

    const handleEnter = (event) => {
        if (event.key == 'Enter') {
            handleUpdateAccount()
        }
    }

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setInvalid({ ...invalid, [e.target.name]: false })
    }

    const [invalid, setInvalid] = useState({
        first_name: false,
        last_name: false,
        email: false,
    });

    const handleUpdateAccount = (e) => {
        e?.preventDefault()

        let is_false = 0;
        let obj = {
            first_name: false,
            last_name: false,
            // email: false
        }

        // if (formData?.email === '') {
        //     obj.email = true;
        //     is_false = 1;
        // }

        if (formData?.first_name === '') {
            obj.first_name = true;
            is_false = 1;
        }

        if (formData?.last_name === '') {
            obj.last_name = true;
            is_false = 1;
        }

        if (is_false) {
            setInvalid(obj);
            return "";
        }

        setLoading(true)

        setTimeout(async () => {
            let response
            if (user_data?.is_sub_admin) {
                response = await post_data(`sub-admin/update-sub-admin/${user_data?._id}`, formData)
            }
            else {
                response = await post_data(`super-admin/update-super-admin/${user_data?._id}`, formData)
            }
            if (response?.status) {
                dispatch(login(response?.data));
                setLoading(false)
                toggleDrawer('right', false)(e)
            }
            else {
                setLoading(false)
                toggleDrawer('right', false)(e)
            }
        }, 1000);
    }

    const editAdmin = () => {
        return (
            <>
                <div style={{ width: '100%' }}>
                    <Grid container spacing={1} style={{ width: '100%' }}>

                        <Grid item xs={6} style={{ padding: 0, marginTop: '8%' }}>
                            <input
                                onKeyPress={(event) => handleEnter(event)}
                                placeholder='First name'
                                value={formData?.first_name}
                                type='text'
                                style={{ height: 42, paddingLeft: '6%' }}
                                className={`register-from-input ${invalid?.first_name ? 'invalid-input' : ''}`}
                                name={'first_name'}
                                onChange={handleInputChange}
                            />
                            {
                                invalid?.first_name && <p className="invalid-txt" style={{ display: "flex" }}>
                                    Enter first name
                                </p>
                            }
                        </Grid>
                        <Grid item xs={6} style={{ padding: 0, marginTop: '8%' }}>
                            <input
                                onKeyPress={(event) => handleEnter(event)}
                                placeholder='Last name'
                                value={formData?.last_name}
                                type='text'
                                style={{ height: 42, paddingLeft: '6%' }}
                                className={`register-from-input ${invalid?.last_name ? 'invalid-input' : ''}`}
                                name={'last_name'}
                                onChange={handleInputChange}
                            />
                            {
                                invalid?.last_name && <p className="invalid-txt" style={{ display: "flex" }}>
                                    Enter last name
                                </p>
                            }
                        </Grid>

                        <Grid item xs={12} variant='contained' style={{ padding: 0, marginTop: '8%' }}>
                            <Button
                                disabled={loading}
                                onClick={handleUpdateAccount}
                                fullWidth
                                style={loading ? { ...button_right_style, ...disabled_btn } : button_right_style}
                            >
                                Update
                            </Button>
                        </Grid>
                    </Grid>

                </div>
            </>
        )
    }

    const profileSec = () => {
        return (
            <>
                <div
                    style={{ width: '100%', height: '100%' }} >
                    <Grid container spacing={1} style={{ padding: '10%', display: 'flex', justifyContent: 'center' }}>
                        <h2 style={{ margin: 0, fontWeight: 600, fontSize: 25 }}>My Profile</h2>
                        <Grid item xs={12} style={{ marginTop: '8%' }}>
                            <center>
                                <img className='profileImg' src='/images/user-avatar.png' style={{ width: 80, height: 80, borderRadius: '50%', marginBottom: '3%' }} />
                                <h2 style={{ margin: 0, fontWeight: 600, fontSize: 20 }}>{user_data?.first_name} {user_data?.last_name}</h2>
                                <p style={{ padding: 0, fontWeight: 600, color: app_color, fontSize: 14 }}>{user_data?.email}</p>
                                <div style={{ marginTop: '10%', width: '100%' }}>
                                    {editAdmin()}
                                </div>
                            </center>
                        </Grid>
                    </Grid>
                </div>
            </>
        )
    }

    const list = (anchor) => (
        <Box
            style={{ width: 350, position: 'relative' }}
            role="presentation"
            onKeyDown={(event) => {
                if (event.key === 'Escape') {
                    toggleDrawer(anchor, false)(event);
                }
            }}
        >
            <CloseIcon onClick={toggleDrawer('right', false)} style={{ position: 'absolute', right: '2%', top: '1%', cursor: 'pointer', opacity: '70%', zIndex: 99 }} />
            {profileSec()}
        </Box>
    );


    return (
        <div>
            <React.Fragment key={'right'}>
                <img onClick={toggleDrawer('right', true)} src='/images/user-avatar.png' style={{ width: 40, height: 40, borderRadius: '50%', marginRight: '6%', cursor: 'pointer' }} />
                <Drawer
                    anchor={'right'}
                    open={state['right']}
                    onClose={toggleDrawer('right', false)}
                >
                    {list('right')}
                </Drawer>
            </React.Fragment>
        </div>
    );
}