import React, { useEffect, useState } from "react"
import { Button, Grid } from "@mui/material"
import DashboardHeading from "../Components/DashboardHeading"
import { useSelector } from "react-redux"
import { get_data } from "../../../Api"
import AppTotalReviews from "../Components/AppTotalReviews"
import AppAllReviews from "../Components/AppAllReviews"
import { button_right_style, disabled_btn } from "../../../Constants/globalConstants"
import ReviewModal from "../Components/ReviewModal"
import SuccessModal from "../Components/SuccessModal"
import ReviewsSkeleton from "../Components/skeleton/ReviewsSkeleton"
import BackButton from "../Components/BackButton"
import EmptyPage from "../Components/EmptyPage"

export default function Review() {

    const { user_data } = useSelector(state => state.user);
    const [review, setReview] = useState([])
    const [allAppReviews, setAllAppReviews] = useState([])
    const [reviewCount, setReviewCount] = useState([])
    const [loading, setLoading] = useState(true)
    const [btnLoading, setBtnLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [successOpen, setSuccessOpen] = useState(false)
    const [successStatus, setSuccessStatus] = useState(false)
    const [formData, setFormData] = useState({})
    const [modalType, setModalType] = useState('')
    const [sureModal, setSureModal] = useState(false)

    const review_page = {
        width: '100%',
    }

    const page_container = {
        width: "100%",
        margin: "2% 0",
        background: 'transparent'
    };

    const fetchUserReview = async () => {
        const response = await get_data(`review/get-review-by-user-id/${user_data?._id}`)
        if (response?.status) {
            setReview(response?.data)
        }
    }

    const fetchReviewCount = async () => {
        const response = await get_data(`review/get-user-count-by-rating`)
        if (response?.status) {
            setReviewCount(response?.data)
        }
    }

    const fetchAllReviews = async () => {
        const response = await get_data('review/get-all-reviews')
        if (response?.status) {
            const modifyData = reorderData(response?.data)
            if (modifyData) {
                setAllAppReviews(modifyData)
            }
            setLoading(false)
        }
    }

    const reorderData = (data) => {
        const index = data?.findIndex(item => item?.user_id._id === user_data?._id);
        if (index !== -1 && index !== 0) {
            const [matchedItem] = data?.splice(index, 1);
            data?.unshift(matchedItem);
        }
        return data;
    }

    useEffect(() => {
        fetchUserReview()
        fetchAllReviews()
    }, [user_data])

    useEffect(() => {
        fetchReviewCount()
    }, [])

    return (
        <>
            <Grid container spacing={0} style={{ ...review_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <BackButton />
                <DashboardHeading title='Reviews ⭐' />

                {
                    !review?.length > 0 && !loading && (
                        <div style={{ display: "flex", marginLeft: 'auto', width: '13%', gap: '3%' }}>
                            <Button
                                onClick={() => {
                                    setOpen(true)
                                    setModalType('Create')
                                }}
                                variant='contained'
                                disabled={btnLoading}
                                style={{
                                    ...button_right_style,
                                    ...(btnLoading && disabled_btn),
                                    marginLeft: 'auto',
                                    width: '100%',
                                    padding: '6% 0',
                                    marginTop: 0
                                }}
                            >
                                Write a Review
                            </Button>
                        </div>
                    )
                }

                <div style={page_container}>
                    {
                        loading ?
                            <>
                                <ReviewsSkeleton />
                            </>
                            :
                            <>
                                {
                                    allAppReviews?.length === 0 ?
                                        <>
                                            <EmptyPage title={'No Reviews Yet...'} />
                                        </>
                                        :
                                        <>
                                            <AppTotalReviews allReviews={allAppReviews} reviewCount={reviewCount} />
                                            <AppAllReviews
                                                setSuccessOpen={setSuccessOpen}
                                                modalType={modalType}
                                                setSureModal={setSureModal}
                                                sureModal={sureModal}
                                                setModalType={setModalType}
                                                formData={formData}
                                                fetchUserReview={fetchUserReview}
                                                setFormData={setFormData}
                                                open={open} setOpen={setOpen}
                                                allReviews={allAppReviews}
                                                setSuccessStatus={setSuccessStatus}
                                                fetchAllReviews={fetchAllReviews}
                                            />
                                        </>
                                }
                            </>
                    }
                </div>

                <ReviewModal
                    setSureModal={setSureModal}
                    modalType={modalType}
                    setModalType={setModalType}
                    formData={formData}
                    setFormData={setFormData}
                    fetchUserReview={fetchUserReview}
                    fetchAllReviews={fetchAllReviews}
                    setSuccessStatus={setSuccessStatus}
                    setSuccessOpen={setSuccessOpen}
                    successStatus={successStatus}
                    successOpen={successOpen}
                    open={open} setOpen={setOpen}
                />

                <SuccessModal
                    status={successStatus}
                    open={successOpen}
                    setOpen={setSuccessOpen}
                    title={
                        successStatus && modalType === 'Create' ? `Thanks for giving us your Time!` :
                            successStatus && modalType === 'Edit' ? 'Review updated successfully!' :
                                successStatus && modalType === 'Delete' ? 'Review deleted successfully!' :
                                    'Something went wrong...'
                    }
                />

            </Grid>
        </>
    )
}