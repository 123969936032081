import React, { useEffect, useState } from "react"
import { Button, Grid, Pagination } from "@mui/material"
import DashboardHeading from "../Components/DashboardHeading"
import { useSelector } from "react-redux"
import { get_data, post_data } from "../../../Api"
import UserProfileCard from "../Components/UserProfileCard"
import { useNavigate } from "react-router-dom"
import EmptyPage from "../Components/EmptyPage"
import UserProfileCardSkeleton from "../Components/skeleton/UserProfileCardSkeleton"
import { button_right_style } from "../../../Constants/globalConstants"
import SuccessModal from "../Components/SuccessModal"
import AreYouSureModal from "../Components/AreYouSureModal"
import BackButton from "../Components/BackButton"
import { Typography } from "@material-ui/core"

export default function BrowsingHistory({ permissions, setProfileLoading, profileLoading, setProfileName }) {

    const { user_data } = useSelector(state => state?.user);
    const navigate = useNavigate()
    const [browsedProfiles, setBrowsedProfiles] = useState([])
    const [btnLoading, setBtnLoading] = useState(false)
    const [loading, setLoading] = useState(true)
    const [successOpen, setSuccessOpen] = React.useState(false)
    const [successStatus, setSuccessStatus] = React.useState(false)
    const [open, setOpen] = useState(false)
    const [modalType, setModalType] = useState('Delete')
    const itemsPerPage = 15;
    const [totalPages, setTotalPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const history_page = {
        width: '100%',
    }

    const page_container = {
        width: "100%",
        margin: "2% 0",
        background: 'transparent'
    }

    const fetchBrowsedProfiles = async (pageNumber) => {
        const response = await get_data(`browsing-history/get-browsing-history-by-user-id/${user_data?._id}?pageNumber=${pageNumber}`)
        if (response?.status) {
            setLoading(false)
            setTotalPages(response?.data?.totalPages);
            setBrowsedProfiles(response?.data?.browsingHistory)
        }
    }

    useEffect(() => {
        if (currentPage) {
            fetchBrowsedProfiles(currentPage);
        }
    }, [user_data, currentPage])

    const profileLoadingFunc = (item) => {
        setProfileLoading(true);
        const profile_name = `${item?.first_name || ''} ${item?.last_name || ''}`.trim();
        if (profile_name) {
            setProfileName(profile_name);
        }
    }

    const handleProfileClick = (item) => {
        profileLoadingFunc(item)
        setTimeout(() => {
            setProfileLoading(false)
            navigate(`/dashboard/profile/${item?.dil_sey_id}`, { state: { user_profile_details: item, screen: 'Match' } });
        }, 1000);
    };

    const displayUsersCard = () => {
        return (
            <>
                <Grid container spacing={0}>
                    {
                        browsedProfiles?.map((item, i) => {
                            return (
                                <>
                                    <Grid item md={4}>
                                        <UserProfileCard permissions={permissions} item={item?.partner_id} i={i} screen='Match' handleProfileClick={handleProfileClick} current_user_data={user_data} />
                                    </Grid>
                                </>
                            )
                        })
                    }
                </Grid>
            </>
        )
    }

    const handleDelete = () => {
        setOpen(true)
    }

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, browsedProfiles?.length);

    const count = browsedProfiles?.length
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleConfirmFunc = async () => {
        const response = await post_data(`browsing-history/delete-browsing-history-by-user-id/${user_data?._id}`)
        if (response?.status) {
            setBtnLoading(false)
            setSuccessStatus(true)
            fetchBrowsedProfiles()
            setOpen(false)
            setSuccessOpen(true)
        }
        else {
            setBtnLoading(false)
            setSuccessStatus(false)
            setSuccessOpen(true)
        }
    }

    return (
        <>
            <Grid container spacing={0} style={{ ...history_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <BackButton />
                <DashboardHeading title='Your Browsing History ⌛' />

                <div style={{ display: "flex", marginLeft: 'auto', width: '13%', gap: '3%' }}>
                    <Button
                        onClick={handleDelete}
                        variant='contained'
                        style={{
                            ...button_right_style,
                            marginLeft: 'auto',
                            width: '100%',
                            padding: '6% 0',
                            marginTop: 0
                        }}
                    >
                        Clear History
                    </Button>
                </div>

                <div style={page_container}>
                    {
                        loading ?
                            <>
                                <Grid container spacing={3}>
                                    <Grid item xs={4} style={{ marginTop: '1%' }}>
                                        <UserProfileCardSkeleton />
                                    </Grid>
                                    <Grid item xs={4} style={{ marginTop: '1%' }}>
                                        <UserProfileCardSkeleton />
                                    </Grid>
                                    <Grid item xs={4} style={{ marginTop: '1%' }}>
                                        <UserProfileCardSkeleton />
                                    </Grid>
                                </Grid>
                            </>
                            :
                            <>
                                {
                                    browsedProfiles?.length === 0 ?
                                        <>
                                            <EmptyPage title='Your Browsing history is empty...' />
                                        </>
                                        :
                                        <>
                                            {displayUsersCard()}

                                            <div
                                                style={{
                                                    borderTop: '1px dashed gainsboro',
                                                    paddingTop: '2%',
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        fontWeight: 500,
                                                        fontSize: 15,
                                                        color: 'black'
                                                    }}
                                                >
                                                    {`Showing ${count} Users`}</Typography>
                                                <Pagination
                                                    count={totalPages}
                                                    page={currentPage}
                                                    onChange={handlePageChange}
                                                    color="primary"
                                                />
                                            </div>
                                        </>
                                }
                            </>
                    }
                </div>
                <SuccessModal
                    status={successStatus}
                    open={successOpen}
                    setOpen={setSuccessOpen}
                    title={successStatus ? `History deleted successfully` : 'Something went wrong...'}
                />
                <AreYouSureModal
                    setLoading={setBtnLoading}
                    loading={btnLoading}
                    confirmFunc={handleConfirmFunc}
                    open={open}
                    setOpen={setOpen}
                    title={modalType === 'Delete' ? 'Are you Sure, you want to clear History ?' : 'Are you sure, you want to login to Dashboard ?'}
                    image={modalType === 'Delete' ? '/images/modal-img-delete.svg' : '/images/modal-img-login.svg'}
                    successOpen={successOpen}
                    setSuccessOpen={setSuccessOpen}
                />
            </Grid>
        </>
    )
}