import { Button } from "@mui/material"
import CheckoutAccordions from "../Components/CheckoutAccordians"
import Header from "../Components/Header"
import Footer from "../Components/Footer"
import { useDatabaseConstants } from "../../Constants/globalConstants"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import BottomBar from "../Components/BottomBar"

export default function PaymentSuccess() {

    const location = useLocation()
    let status = location?.state?.status
    let amount = location?.state?.amount
    let type = location?.state?.type
    let payableAmount = location?.state?.payableAmount
    let savedAmount = location?.state?.savedAmount
    const { user_data } = useSelector(state => state.user);

    const { app_color } = useDatabaseConstants()
    const navigate = useNavigate()

    const success_div = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        margin: '0 auto',
        padding: '6% 2%',
    }

    const success_box = {
        width: '35%',
        margin: 'auto',
        border: '1px solid #E4E6FF',
        padding: '2%',
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 10,
        alignItems: 'center',
        flexDirection: 'column',
    }

    const handleClick = () => {
        navigate('/contact')
        window.scrollTo(0, 0)
    }

    const handleDashboard = () => {
        navigate('/dashboard', { state: { user_profile_details: user_data } })
        window.scrollTo(0, 0)
    }

    let image = status === 'success' ? 'success-image' : 'failure-image'
    let title = status === 'success' ? 'Thanks a lot for putting up this order!' : 'Payment failed, something went wrong!'

    return (
        <>
            <Header />
            <div style={success_div} className="">
                <div style={success_box}>
                    <img src={`/images/${image}.svg`} style={{ width: 100, height: 100 }} />
                    <h3 className="global-h3" style={{ margin: '2% 0', fontSize: 20 }}>{title}</h3>
                    <p className="about-para" style={{ textAlign: 'center', fontSize: 13 }}>
                        Your Mailto order ASK123456 has successfully been placed. You'll fin all the details about your order below, and we'll send you a shipping confrimation email as soon as your order ships. In the meantime, you can share Mailto and earn store credit.
                    </p>
                    <p className="about-para" style={{ textAlign: 'center', fontSize: 13, marginTop: '5%' }}>
                        Questions? Suggestions? insightful showe thoughts?
                    </p>
                    <p className="about-para" style={{ color: app_color, fontWeight: 500, textDecoration: 'underline', cursor: "pointer" }} onClick={handleClick}>
                        Shoot us an email.
                    </p>
                    {
                        type !== 'verification' && (
                            <div style={{ width: '100%', marginTop: '5%' }}>
                                <CheckoutAccordions screen='success' amount={amount} payableAmount={payableAmount} savedCost={savedAmount} />
                            </div>
                        )
                    }
                    <Link to={'/dashboard'} target="_blank">
                        <Button
                            // onClick={handleDashboard}
                            fullWidth
                            variant='contained' className='header-btn'
                            style={{
                                fontSize: 13,
                                marginTop: '5%',
                                fontWeight: 600
                            }}>
                            Go to Dashboard
                        </Button>
                    </Link>
                </div>
            </div>
            <Footer />
            <BottomBar />
        </>
    )
}