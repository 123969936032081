import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import TableSkeleton from './TableSkeleton';
import { DarkModeContext } from '../DarkModeContext';
import { secondaryDarkColor } from '../../../../Constants/globalConstants';

export default function BillingSkeleton() {

    const { darkMode } = React.useContext(DarkModeContext);

    return (
        <>
            <Stack spacing={0} style={{ display: 'flex', flexDirection: 'row', gap: '2%' }}>
                <Skeleton variant="rounded" width={'50%'} height={200} style={{ background: darkMode ? secondaryDarkColor : '' }} />
                <Skeleton variant="rounded" width={'50%'} height={200} style={{ background: darkMode ? secondaryDarkColor : '' }} />
            </Stack>
            <div style={{ marginTop: '3%' }}><TableSkeleton /></div>
        </>
    );
}
