import React, { useState, useContext, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DarkModeContext } from "../../../WebApp/UserDshboard/Components/DarkModeContext";
import SerialNo from "../../Admin/Components/SerialNo";
import { get_data } from "../../../Api";
import { formatDate, formatDateTime } from "../../../Constants/globalConstants";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    justifyContent: "center",
  },
  tableContainer: {
    width: "100%",
    padding: "3%",
    borderRadius: 10,
    border: "1px solid #F1F1F2",
    boxShadow: "0px 3px 4px 0 rgba(0, 0, 0, 0.03)",
    background: "white",
  },

  table: {
    width: "100%", // Changed from "auto" to "100%"
    borderCollapse: "collapse",
  },
  tableHeading: {
    fontWeight: 600,
    fontSize: 17,
    color: "#3F4254",
  },
  tableTagline: {
    fontWeight: 500,
    color: "#A1A5B7",
    fontSize: 13,
  },
  tableHeaderTitle: {
    margin: 0,
    fontWeight: 600,
    fontSize: 14,
    color: "#3F4254",
  },
  tableRowFont: {
    margin: 0,
    fontWeight: 500,
    fontSize: 14,
    color: "#3F4254",
  },
  tableCell: {
    padding: "8px",
    textAlign: "left",
  },
  serialNoCell: {
    width: "80px",
  },
  activityCell: {
    width: "200px",
  },
  activityByCell: {
    width: "150px",
  },
  dateCell: {
    width: "100px",
  },
});

const staticTableHeader = ["Serial No.", "Activity", "Activity By", "Date"];

const staticData = [
  {
    serialNo: 1,
    activity: "Created a new document",
    activityBy: "John Doe",
    date: "2024-07-01",
  },
  {
    serialNo: 2,
    activity: "Updated profile settings",
    activityBy: "Jane Smith",
    date: "2024-07-02",
  },
  {
    serialNo: 3,
    activity: "Deleted a post",
    activityBy: "Alice Johnson",
    date: "2024-07-03",
  },
  {
    serialNo: 4,
    activity: "Commented on a thread",
    activityBy: "Chris Lee",
    date: "2024-07-04",
  },
  {
    serialNo: 5,
    activity: "Uploaded a file",
    activityBy: "Michael Brown",
    date: "2024-07-05",
  },
];

export default function Activity() {

  const classes = useStyles();
  const { darkMode } = useContext(DarkModeContext);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  const [tableData, setTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [totalActivitiesCount, setTotalActivitiesCount] = useState(null);


  const handleActivityData = async (pageNumber) => {
    try {
      const response = await get_data(`activities/get-all-activity?pageNumber=${pageNumber}`);
      setTableData(response?.data?.activities);
      setTotalPages(response?.data?.totalPages);
      setTotalActivitiesCount(response?.data?.totalActivities)
    } catch (e) {
      // console.error(e);
    }
  };

  useEffect(() => {
    if (currentPage) {
      handleActivityData(currentPage);
    }
  }, [currentPage]);


  const count = tableData?.length;
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const displayAllActivities = () => {
    return (
      <table className={classes.table}>
        <thead>
          <tr>
            {staticTableHeader.map((header, index) => (
              <th
                key={index}
                className={`${classes.tableCell} ${classes.tableHeaderTitle}`}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData?.map((item, i) => (
            <tr key={i} style={{ borderTop: "1px dashed gainsboro" }}>
              <td className={`${classes.tableCell} ${classes.serialNoCell}`}>
                <SerialNo index={i} />
              </td>
              <td className={`${classes.tableCell} ${classes.activityCell}`}>
                <span className={classes.tableRowFont}>
                  {item?.activity_name}
                </span>
              </td>
              <td className={`${classes.tableCell} ${classes.activityByCell}`}>
                <span className={classes.tableRowFont}>
                  {item?.activity_by?.first_name + ' '}
                  {item?.activity_by?.last_name}
                </span>
              </td>
              <td className={`${classes.tableCell} ${classes.dateCell}`}>
                <span className={classes.tableRowFont}>{formatDateTime(item.createdAt)}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.tableContainer}>
        <h1 className={classes.tableHeading}>Activity</h1>
        {displayAllActivities()}
        {tableData?.length > 0 && (
          <div
            style={{
              borderTop: "1px dashed gainsboro",
              paddingTop: "2%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              className={classes.tableTagline}
              style={{
                color: darkMode ? "gray" : "black",
              }}
            >{`Showing ${count} activities`}</Typography>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </div>
        )}
      </div>
    </div>
  );
}
