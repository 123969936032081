// import React from "react"
// import { Button, Grid } from "@mui/material"
// import Heading from "./Heading";
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';

// export default function Process() {

//     const theme = useTheme();
//     const matches_sm = useMediaQuery(theme.breakpoints.down('sm'));
//     const matches_md = useMediaQuery(theme.breakpoints.down('md'));

//     const rightImgSection = () => {

//         return (
//             <>
//                 <div style={{ display: "flex", flexDirection: 'column' }}>
//                     <Grid container spacing={1} className="process-div">
//                         <Grid item md={6} className="process-border-dark mobile-bottom-div">
//                             <h3 className="process-div-title">
//                                 1. Fill Basic Details
//                             </h3>
//                             <p className="process-div-para">
//                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
//                             </p>
//                         </Grid>
//                         <Grid item md={6} className="mobile-top-div">
//                             <center>
//                                 <img src={`/images/vector-basic.svg`} className="process-images" />
//                             </center>
//                         </Grid>
//                     </Grid>

//                     <Grid container spacing={1} className="process-div">
//                         <Grid item md={6} className="process-border-gray">
//                             <center><img src={`/images/vector-phone.svg`} className="process-2-image" /></center>
//                         </Grid>
//                         <Grid item md={6} style={{ paddingLeft: '4%' }}>
//                             <h3 className="process-div-title">
//                                 2. Verify Phone
//                             </h3>
//                             <p className="process-div-para">
//                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
//                             </p>
//                         </Grid>
//                     </Grid>

//                     <Grid container spacing={1} className="process-div">
//                         <Grid item md={6} className="process-border-gray mobile-bottom-div">
//                             <h3 className="process-div-title">
//                                 3. Onboard to Dashboard
//                             </h3>
//                             <p className="process-div-para">
//                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
//                             </p>
//                         </Grid>
//                         <Grid item md={6} className="mobile-top-div">
//                             <center>
//                                 <img src={`/images/vector-onboard.svg`} className="process-images" />
//                             </center>
//                         </Grid>
//                     </Grid>
//                 </div>
//             </>
//         )
//     }

//     return (
//         <>
//             <div className="process-section">
//                 <center><Heading title="Find your Special Someone" /></center>
//                 <div>
//                     {rightImgSection()}
//                 </div>
//             </div>
//         </>
//     )
// }


import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import Heading from './Heading';

const Process = () => {

    const processArr = [
        {
            title: "Create Your Profile",
            para: "Sign up and fill out your profile with detailed information about your interests, lifestyle, and partner preferences. The more detailed your profile, the better our matching algorithm can find compatible matches for you.",
        },
        {
            title: "Discover Matches",
            para: "Our advanced matching system analyzes your preferences and suggests profiles that align with your criteria. Browse through these matches, read their profiles, and express interest in the ones that catch your eye.",
        },
        {
            title: "Connect and Communicate",
            para: "Start meaningful conversations with your matches using our secure messaging system. Get to know each other, share your stories, and build a connection that could lead to a beautiful relationship.",
        },
        {
            title: "Connect and Communicate",
            para: "Start meaningful conversations with your matches using our secure messaging system. Get to know each other, share your stories, and build a connection that could lead to a beautiful relationship.",
        },
    ];

    return (
        <Box
            sx={{
                padding: (theme) => theme.spacing(12, 0),
                position: 'relative'
            }}
        >
            <img
                src="/images/flower-v2.png"
                style={{
                    position: 'absolute',
                    width: 200,
                    right: 0,
                    top: '3%',
                }}
            />
            <img src='/images/blur-div.svg' style={{ position: 'absolute', left: 0, bottom: 0, zIndex: -1 }} />
            <img src='/images/petal-6.png' style={{ position: 'absolute', left: 0, bottom: 0, zIndex: -1 }} />
            <img src='/images/blur-div.svg' style={{ position: 'absolute', right: 0, top: '3%', transform: 'rotate(180deg)', zIndex: -1 }} />
            <Container>
                <Grid container spacing={2}>
                    <Grid item md={5}>
                        <Heading title={'How DilSey Works'} />

                        <Typography
                            sx={{
                                fontSize: '1rem',
                                marginTop: '3%',
                                marginBottom: (theme) => theme.spacing(4),
                            }}
                        >
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                        </Typography>
                        <img
                            src="/images/process.svg"
                            alt="How It Works"
                            style={{
                                width: '80%',
                                marginBottom: (theme) => theme.spacing(4),
                            }}
                        />
                    </Grid>
                    <Grid item md={7} style={{ position: 'relative' }}>
                        <Grid container spacing={2}>
                            {
                                processArr?.map((item) => {
                                    return (
                                        <Grid item md={6}>
                                            <Box
                                                sx={{
                                                    textAlign: 'center',
                                                    padding: (theme) => theme.spacing(2),
                                                    borderRadius: '8px',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        fontSize: '2rem',
                                                        marginBottom: (theme) => theme.spacing(2),
                                                        color: '#B33771',
                                                    }}
                                                >
                                                    <img src='/images/star-img.svg' style={{ marginBottom: '5%' }} />
                                                </div>
                                                <Typography
                                                    sx={{
                                                        fontSize: '1.25rem',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {item?.title}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: '1rem',
                                                        marginTop: (theme) => theme.spacing(1),
                                                    }}
                                                >
                                                    {item?.para}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Process;
