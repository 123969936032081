import React from "react"
import CircularProgress from '@mui/material/CircularProgress';

export default function ProfileLoading({ type, profileName, profileLoadingType }) {

    return (
        <>
            <div style={{
                position: 'absolute',
                background: 'rgba(0, 0, 0, 0.7)',
                display: 'flex',
                width: '100%',
                gap: '1%',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                zIndex: 99
            }}>
                {
                    type === 'delete' || profileLoadingType === 'filter' || profileLoadingType === 'search' ? (
                        <h2 className="global-h2" style={{ color: 'white', fontSize: 20, fontWeight: 500 }}>
                            Loading...
                        </h2>
                    ) : (
                        <h2 className="global-h2" style={{ color: 'white', fontSize: 20, fontWeight: 500 }}>
                            Loading {profileName}'s Profile...
                        </h2>
                    )
                }
                <CircularProgress style={{ color: 'white' }} />
            </div>
        </>
    )
}