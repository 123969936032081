import React, { useEffect, useState } from "react"
import ProgressBar from "@ramonak/react-progress-bar";
import Rating from '@mui/material/Rating';
import { DarkModeContext } from "./DarkModeContext";
import { secondaryDarkColor } from "../../../Constants/globalConstants";

export default function AppTotalReviews({
    allReviews,
    reviewCount
}) {

    const { darkMode } = React.useContext(DarkModeContext);
    const totalRating = allReviews?.reduce((acc, review) => acc + review?.rating, 0);
    const averageRating = totalRating / allReviews?.length;

    const totalReviews = [
        {
            title: 'Total Reviews',
            value: allReviews?.length?.toFixed(1),
            para: 'Total Reviews we got'
        },
        {
            title: 'Average Ratings',
            value: (averageRating / 2).toFixed(1) || 0,
            para: 'Total Average ratings of DilSey'
        },
        {
            type: 'ratings'
        },
    ]

    const main_div_styles = {
        display: 'flex',
        justifyContent: 'left',
        textAlign: 'center',
        gap: '4%',
        alignItems: 'center'
    }

    const ratings = [
        {
            rating_num: 5,
            value: reviewCount[8]?.count + reviewCount[9]?.count
        },
        {
            rating_num: 4,
            value: reviewCount[6]?.count + reviewCount[7]?.count
        },
        {
            rating_num: 3,
            value: reviewCount[4]?.count + reviewCount[5]?.count
        },
        {
            rating_num: 2,
            value: reviewCount[2]?.count + reviewCount[3]?.count
        },
        {
            rating_num: 1,
            value: reviewCount[0]?.count + reviewCount[1]?.count
        },
    ]

    const ratingsComponent = () => {
        return (
            <>
                <div style={{
                    display: 'flex', flexDirection: 'column',
                    textAlign: 'left', justifyContent: 'left'
                }}>
                    {
                        ratings?.map((item, i) => {
                            return (
                                <div style={{
                                    width: '100%',
                                    display: 'flex', gap: '4%', margin: '5px 0',
                                    textAlign: 'left', justifyContent: 'left', alignItems: 'center'
                                }}>
                                    <p className='about-para' style={{ fontSize: 14, fontWeight: 600 }}>{item?.rating_num}</p>
                                    <div>
                                        <Rating value={item?.rating_num} readOnly />
                                    </div>
                                    <p className='about-para' style={{ fontSize: 14, fontWeight: 600, color: darkMode ? 'white' : 'black' }}>{item?.value}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </>
        )
    }

    const totalReviewsDiv = () => {
        return (
            <>
                <div style={main_div_styles}>
                    {
                        totalReviews?.map((item, i) => {
                            let last_item = i === totalReviews?.length - 1
                            return (
                                <>
                                    {
                                        item?.type === 'ratings' ?
                                            <>
                                                <div
                                                    style={{ width: '45%' }}
                                                >
                                                    {ratingsComponent()}
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div style={{
                                                    width: '25%',
                                                    display: 'flex', flexDirection: 'column',
                                                    textAlign: 'left', justifyContent: 'left',
                                                    borderRight: last_item ? '' : '1px solid #E1E3EA', paddingRight: '5%'
                                                }}>
                                                    <p className='about-para' style={{ fontSize: 16, fontWeight: 500, color: darkMode ? 'white' : 'black' }}>{item?.title}</p>
                                                    <div style={{ display: 'flex', gap: '3%', alignItems: 'center' }}>
                                                        <h3 className='global-h3' style={{ fontSize: 27, color: darkMode ? 'white' : 'black', fontWeight: 600, margin: '2% 0' }}>{item?.value}</h3>
                                                        {
                                                            item?.title === 'Average Ratings' && (
                                                                <Rating name="half-rating-read" defaultValue={averageRating / 2} precision={0.5} readOnly />
                                                            )
                                                        }
                                                    </div>
                                                    <p className='about-para' style={{ fontSize: 14, fontWeight: 500 }}>{item?.para}</p>
                                                </div>
                                            </>
                                    }
                                </>
                            )
                        })
                    }
                </div>
            </>
        )
    }

    return (
        <>
            <div style={{ padding: '3%', color: darkMode ? 'white' : 'black', background: darkMode ? secondaryDarkColor : 'white', borderRadius: 10, boxShadow: '3px 3px 4px 0 #00000008', border: darkMode ? 'none' : '2px solid #F1F1F2', }}>
                {totalReviewsDiv()}
            </div>
        </>
    )
}