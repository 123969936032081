import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal, { modalClasses } from '@mui/material/Modal';
import { useState } from 'react';
import { Grid, colors } from '@mui/material';
import { button_left_style, button_right_style, getFieldName, useDatabaseConstants, disabled_btn } from '../../../Constants/globalConstants';
import OtpComponent from '../../../Website/Components/OtpComponent';
import { post_data } from '../../../Api';
import toast, { Toaster } from 'react-hot-toast';

export default function AdminOtpModal({
    open,
    setOpen,
    successStatus,
    setSuccessStatus,
    successOpen,
    setSuccessOpen,
    formData,
    handleSubmit2
}) {

    const { app_color } = useDatabaseConstants()
    const [loading, setLoading] = useState(false)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'white',
        boxShadow: 24,
        borderRadius: 4,
        padding: '2%'
    };

    const handleClose = () => {
        setLoading(false)
        setOpen(false)
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Toaster />
                    <div onClick={handleClose} style={{
                        position: 'absolute',
                        right: '3%',
                        top: '3%',
                        cursor: 'pointer',
                    }}
                    >
                        <img src='/images/modal-close-icon.svg' style={{ width: '100%', height: '100%' }} />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <OtpComponent
                            setOpen={setOpen}
                            successStatus={successStatus}
                            setSuccessStatus={setSuccessStatus}
                            successOpen={successOpen}
                            setSuccessOpen={setSuccessOpen}
                            formData={formData}
                            resendOtpFunc={handleSubmit2}
                            type={'change-super-admin-email'}
                        />
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
