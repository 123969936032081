import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, List, ListItem, ListItemText, Grid } from '@mui/material';
import { extractNumber, secondaryDarkColor, useDatabaseConstants } from '../../../Constants/globalConstants';
import { useDispatch, useSelector } from 'react-redux';
import { get_data, post_data } from '../../../Api';
import UserProfileCard from '../Components/UserProfileCard';
import PermissionsFunction from '../../../Constants/permissionsFunction';
import { useNavigate } from 'react-router-dom';
import { login } from '../../../redux/slices/user-slice';
import UserProfileCardSkeleton from "../../UserDshboard/Components/skeleton/UserProfileCardSkeleton"
import { DarkModeContext } from '../Components/DarkModeContext';

const VideoConsultation = ({
    setProfileLoading,
    setProfileName
}) => {

    const { app_color } = useDatabaseConstants()
    const { isLoggedIn, user_data } = useSelector((state) => state.user);
    const [loading, setLoading] = useState(true)
    const [allUsers, setAllUsers] = useState([])
    const { permissions } = PermissionsFunction()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [noPlan, setNoPlan] = useState(false)
    const { darkMode } = React.useContext(DarkModeContext);

    useEffect(() => {
        if (user_data?.plan_id?.length === 0) {
            setNoPlan(true)
        }
    }, [user_data])

    const fetchVideoCallUsers = async () => {
        const response = await get_data(`user/get-all-interest/${user_data?._id}`)
        if (response?.status) {
            setAllUsers(response?.data?.interest_sent.concat(response?.data?.interest_received))
            setLoading(false)
            // setTotalPages(response?.data?.totalPages);
        }
    }

    useEffect(() => {
        fetchVideoCallUsers()
    }, [user_data])

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px 20px',
            borderRadius: '10px',
            background: darkMode ? secondaryDarkColor : 'white',
            border: darkMode ? 'none' : '1px solid #E0E0E0',
            maxWidth: '80%',
            margin: 'auto',
        },
        image: {
            width: 400,
            marginBottom: '20px',
        },
        content: {
            width: '65%',
        },
        heading: {
            fontWeight: 700,
            fontSize: 25,
            marginBottom: '10px',
        },
        subHeading: {
            marginBottom: '10px',
            color: 'grey',
        },
        list: {
            listStyle: 'none',
            padding: 0,
            marginLeft: '-4%'
        },
        listItem: {
            display: 'flex',
            alignItems: 'center',
        },
        listItemIcon: {
            color: 'green',
        },
        button: {
            backgroundColor: app_color,
            color: 'white',
            textTransform: 'none',
            padding: '13px 20px',
            borderRadius: '5px',
            width: '100%',
            marginTop: '4%'
        },
    };


    const createBrowsingHistory = async (item) => {
        await post_data('browsing-history/create-browsing-history', { user_id: user_data?._id, partner_id: item?._id });
    }

    const navigateToProfile = (item) => {
        navigate(`/dashboard/profile/${item?.dil_sey_id}`, { state: { user_profile_details: item, screen: 'Received' } });
    }

    const updateBrowsedProfiles = async (item) => {
        const payload = { profile_id: item?._id };
        const response = await post_data(`user/update-browsed-profiles/${user_data?._id}`, payload);
        if (response?.status) {
            dispatch(login(response?.data));
        }
    }

    const profileLoadingFunc = (item) => {
        setProfileLoading(true);
        const profile_name = `${item?.first_name || ''} ${item?.last_name || ''}`.trim();
        if (profile_name) {
            setProfileName(profile_name);
        }
    }

    const handleProfileClick = (item, i) => {

        profileLoadingFunc(item);

        const proceedToProfile = async () => {
            await createBrowsingHistory(item);
            navigateToProfile(item);
            setProfileLoading(false);
        }

        setTimeout(async () => {
            if (noPlan) {
                await proceedToProfile();
            } else if (permissions?.browse_profiles) {
                if (permissions?.browse_profiles === 'Unlimited') {
                    await proceedToProfile();
                } else {
                    const browsedProfiles = user_data?.browsed_profiles || [];
                    const browseLimit = extractNumber(permissions?.browse_profiles);

                    if (browsedProfiles?.length === browseLimit && !browsedProfiles?.includes(item?._id)) {
                        setProfileLoading(false);
                    } else {
                        await createBrowsingHistory(item);
                        if (!browsedProfiles?.includes(item?._id)) {
                            await updateBrowsedProfiles(item);
                        }
                        navigateToProfile(item);
                        setProfileLoading(false);
                    }
                }
            } else {
                await proceedToProfile();
            }
        }, 1000);
    }

    const displayUsersCard = () => {
        return (
            <>
                <Grid container spacing={0}>
                    {
                        allUsers?.map((item, i) => {
                            return (
                                <>
                                    <Grid item md={4}
                                        style={{ position: 'relative' }}
                                    >
                                        <UserProfileCard
                                            type='videoCall'
                                            permissions={permissions} item={item?.user_id} i={i} screen='Match'
                                            handleProfileClick={handleProfileClick}
                                            current_user_data={user_data}
                                        />
                                    </Grid>
                                </>
                            )
                        })
                    }
                </Grid>
            </>
        )
    }

    return (
        <>
            <Box sx={styles.container}>
                <Box> <img src="/images/video-call-image.svg" alt="Video Consultation" style={styles.image} /></Box>
                <Box sx={styles.content}>
                    <Typography variant="h5" sx={{ ...styles.heading, fontWeight: 600 }}>Video Consultation</Typography>
                    <Typography variant="body1" sx={styles.subHeading}>Video calling with users</Typography>
                    <Typography variant="h6" sx={styles.heading}>FREE</Typography>
                    <List style={styles.list}>
                        {['One on one video Calling with users', 'Know each other', 'No restriction on video call duration', 'Unlock access to advanced search', 'View contact details'].map((text, index) => (
                            <ListItem key={index} sx={styles.listItem}>
                                <img src='/images/green-check-icon.svg' style={{ width: 23, marginRight: '2%' }} />
                                <ListItemText primary={text} />
                            </ListItem>
                        ))}
                    </List>
                    {/* <Button style={styles.button}>Start Video Calling</Button> */}
                </Box>
            </Box>

            <div style={{ marginTop: '5%' }}>
                {
                    loading ?
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <UserProfileCardSkeleton />
                                </Grid>
                                <Grid item xs={4}>
                                    <UserProfileCardSkeleton />
                                </Grid>
                                <Grid item xs={4}>
                                    <UserProfileCardSkeleton />
                                </Grid>
                            </Grid>
                        </>
                        :
                        <>
                            {displayUsersCard()}
                        </>
                }
            </div>
        </>
    );
};

export default VideoConsultation;