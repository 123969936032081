import { calculateSimilarityScore, useDatabaseConstants } from "../../../Constants/globalConstants";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useSelector } from "react-redux";

export default function CompatiblityScore({
    item
}) {

    const { app_color } = useDatabaseConstants()

    const { isLoggedIn, user_data } = useSelector(state => state.user);
    const compatiblity_score = Math.floor(calculateSimilarityScore(user_data, item));

    return (
        <>
            <CircularProgressbar
                styles={buildStyles({
                    rotation: 0.25,
                    strokeLinecap: 'butt',
                    textSize: '16px',
                    pathTransitionDuration: 0.5,
                    pathColor: compatiblity_score < 100 ? app_color : 'green',
                    textColor: compatiblity_score < 100 ? app_color : 'green',
                    trailColor: '#d6d6d6',
                    backgroundColor: '#3e98c7',
                })}
                value={compatiblity_score} text={`${compatiblity_score}%`} />
        </>
    )
}