import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { post_data } from '../../../Api';
import { formatPrice } from '../../../Constants/globalConstants';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export default function LineChartComponent({
  padding,
  width,
  height,
  totalSales,
  setTotalSales,
}) {
  const defaultRange = [moment().subtract(1, 'month').toDate(), moment().toDate()];
  const [chartLabels, setChartLabels] = useState([]);
  const [chartValue, setChartValue] = useState([]);
  const [dateRange, setDateRange] = useState(defaultRange);
  const [totalMaleSales, setTotalMaleSales] = useState(0);
  const [totalFemaleSales, setTotalFemaleSales] = useState(0);
  const [startDate, endDate] = dateRange;

  const chartStyle = {
    width: '100%',
    height: '100%',
    border: '1px solid #F1F1F2',
    borderRadius: 14,
    background: 'white',
    padding: padding,
    position: 'relative',
    boxShadow: '0 3px 4px 0 #00000008',
  };

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  };

  const titleStyle = {
    fontWeight: 600,
    fontSize: 20,
  };

  const rangePickerStyle = {
    display: 'flex',
    gap: '4%',
    justifyContent: 'center',
    textAlign: 'left',
    width: 250,
    height: 40,
    borderRadius: 10,
    fontWeight: 500,
    fontSize: 14,
    padding: '10px 12px',
    background: '#F1F1F2',
    color: '#7E8299',
    border: 'none',
    cursor: 'pointer'
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 14,
            color: '#7E8299',
            weight: 500,
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 14,
            color: '#7E8299',
            weight: 500,
          },
        },
      },
    },
  };

  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: 'Sales',
        fill: true,
        data: chartValue,
        borderColor: '#F1416C',
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
          gradient.addColorStop(0, '#ffbdce');
          gradient.addColorStop(1, 'rgba(255, 255, 255, 0.096)');
          return gradient;
        },
        tension: 0.5,
        pointRadius: (context) => {
          const index = context.dataIndex;
          const value = context.dataset.data[index];
          const prevValue = context.dataset.data[index - 1];
          const nextValue = context.dataset.data[index + 1];

          if ((prevValue !== undefined && value > prevValue) || (nextValue !== undefined && value < nextValue)) {
            return 5;
          }

          return 5;
        },
        pointHoverRadius: 5,
      },
    ],
  };

  useEffect(() => {
    if (startDate && endDate) {
      fetchData();
    }
  }, [dateRange]);

  const fetchData = async () => {
    try {
      const fromDate = moment(startDate).toISOString();
      const toDate = moment(endDate).toISOString();

      const response = await post_data('billing/get-billing-data-by-date', {
        from: fromDate,
        to: toDate,
      });

      if (response?.data) {
        const fetchedLabels = response?.data?.map((entry) => entry?.date);
        const fetchedValues = response?.data?.map((entry) => entry?.totalPayment);

        const getTotalValue = (data) => {
          return data?.reduce((accumulator, currentValue) => {
            return accumulator + currentValue?.totalPayment;
          }, 0);
        };

        const getTotalMaleSales = (data) => {
          return data?.reduce((accumulator, currentValue) => {
            return accumulator + currentValue?.totalMale;
          }, 0);
        };

        const getTotalFemaleSales = (data) => {
          return data?.reduce((accumulator, currentValue) => {
            return accumulator + currentValue?.totalFemale;
          }, 0);
        };

        const total_sales = getTotalValue(response?.data);
        const total_male_sales = getTotalMaleSales(response?.data);
        const total_female_sales = getTotalFemaleSales(response?.data);

        setTotalSales(total_sales || 0);
        setTotalMaleSales(total_male_sales || 0);
        setTotalFemaleSales(total_female_sales || 0);

        setChartLabels(fetchedLabels || []);
        setChartValue(fetchedValues || []);
      } else {
        setChartLabels([]);
        setChartValue([]);
        setTotalSales(0);
        setTotalMaleSales(0);
        setTotalFemaleSales(0);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setChartLabels([]);
      setChartValue([]);
      setTotalSales(0);
      setTotalMaleSales(0);
      setTotalFemaleSales(0);
    }
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
    }
  };

  const disableFutureDates = (current) => {
    return current && current > moment().endOf('day');
  };

  const salesStats = [
    {
      value: totalSales,
      title: 'Total Sales',
    },
    {
      value: totalMaleSales,
      title: 'Total Sales from Male',
    },
    {
      value: totalFemaleSales,
      title: 'Total Sales from Female',
    },
  ];

  const stats_main_div_styles = {
    display: 'flex',
    justifyContent: 'left',
    textAlign: 'center',
    gap: '4%',
    alignItems: 'center',
  };

  return (
    <div style={chartStyle}>
      <div style={headerStyle}>
        <div style={titleStyle}>Monthly Sales</div>
        <div style={rangePickerStyle}>
          <DatePicker
            selected={startDate}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            dateFormat="dd-MM-yyyy"
            maxDate={new Date()}
            customInput={
              <button>
                {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
              </button>
            }
          />
          <img src='/images/calendor-icon-2.svg' />
        </div>
      </div>

      <div style={stats_main_div_styles}>
        {salesStats?.map((item, i) => {
          let last_item = i === salesStats?.length - 1;
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
                justifyContent: 'left',
                borderRight: last_item ? '' : '1px dashed #E1E3EA',
                paddingRight: '8%',
              }}
              key={i}
            >
              <h3 className="global-h3" style={{ fontSize: 20, color: '#3F4254', fontWeight: 600 }}>
                {formatPrice(item?.value)}
              </h3>
              <p className="about-para" style={{ fontSize: 14, fontWeight: 500 }}>
                {item?.title}
              </p>
            </div>
          );
        })}
      </div>

      {chartLabels.length === 0 || chartValue.length === 0 ? (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: 20,
          color: '#7E8299',
        }}>
          No data to display
        </div>
      ) : (
        <Line
          options={options}
          data={data}
          height={height}
          width={width}
          style={{
            fontSize: 18,
          }}
        />
      )}
    </div>
  );
}
