import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import {
  gender_options,
  getFieldName,
  religion_options,
  state_options,
  useDatabaseConstants,
} from "../../Constants/globalConstants";
import { Dropdown } from "primereact/dropdown";
import OrderSummary from "./OrderSummary";
import useRazorpay from "react-razorpay";
import CheckoutAccordions from "./CheckoutAccordians";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { post_data } from "../../Api";
import { login } from "../../redux/slices/user-slice";
import DiscountCoupon from "./DiscountCoupan";

export default function CheckoutForm({
  registerFormFields,
  paymentFormFields,
  amount,
  plan_id,
  selectedPlan,
}) {
  const steps = ["Welcome", "Payment", "Completed"];

  const { isLoggedIn, user_data } = useSelector((state) => state.user);
  const [Razorpay] = useRazorpay();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const { app_color } = useDatabaseConstants();
  const [formData, setFormData] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [payableAmount, setPayableAmount] = React.useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    setFormData({
      ...formData,
      first_name: user_data?.first_name,
      last_name: user_data?.last_name,
      email: user_data?.email,
      city: user_data?.contact_details?.city,
      state: user_data?.contact_details?.state,
      contact_number: user_data?.contact_number,
      address: user_data?.contact_details?.address,
      name_on_card: `${user_data?.first_name} ${user_data?.last_name}`,
    });
  }, [user_data]);

  const optionsMap = {
    state: state_options,
    gender: gender_options,
    religion: religion_options,
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const validateRegisterFields = () => {
    let newErrors = {};
    registerFormFields.forEach((field) => {
      let fieldName = getFieldName(field?.field);
      if (!formData[fieldName]) {
        newErrors[fieldName] = `${field?.field} is required`;
      } else if (field.type === "number" && isNaN(formData[fieldName])) {
        newErrors[fieldName] = `${field?.field} must be a number`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validatePaymentFields = () => {
    let newErrors = {};
    paymentFormFields.forEach((field) => {
      let fieldName = getFieldName(field?.field);
      if (!formData[fieldName]) {
        newErrors[fieldName] = `${field?.field} is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  //   const handleNext = () => {
  //     let newSkipped = skipped;
  //     if (isStepSkipped(activeStep)) {
  //         newSkipped = new Set(newSkipped.values());
  //         newSkipped.delete(activeStep);
  //     }
  // }

  const handleNext = () => {
    if (activeStep === 0) {
      if (validateRegisterFields()) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 1) {
      if (validatePaymentFields()) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const multi_stepper_div = {
    width: "100%",
    padding: "5% 23%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    // background: 'pink'
  };

  const input_style = {
    margin: "3% 0",
  };

  const multi_stepper_styles = {
    width: "60%",
    margin: "auto",
  };

  const step_styles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const step_title = {
    marginTop: "2%",
    fontWeight: 500,
  };

  const register_text_fields = registerFormFields?.filter(
    (subItem) => subItem?.type === "text"
  );
  const register_select_fields = registerFormFields?.filter(
    (subItem) => subItem?.type === "select"
  );
  const register_number_fields = registerFormFields?.filter(
    (subItem) => subItem?.type === "number"
  );

  const payment_text_fields = paymentFormFields?.filter(
    (subItem) => subItem?.type === "text"
  );
  const payment_number_fields = paymentFormFields?.filter(
    (subItem) => subItem?.type === "number"
  );

  //   const handleInputChange = (e, field, i, type) => {
  //     if (type === "number") {
  //       if (!/^\d*$/.test(e.target.value)) {
  //         return;
  //       }

  //       if (parseInt(e.target.value) < 0) {
  //         return;
  //       }
  //       setFormData({ ...formData, [e.target.name]: e.target.value });
  //     } else {
  //       setFormData({ ...formData, [e.target.name]: e.target.value });
  //     }
  //   };

  const handleInputChange = (e, field, i, type) => {
    const fieldName = getFieldName(field?.field);
    const value = e.target.value;

    if (type === "number") {
      if (!/^\d*$/.test(value)) {
        return;
      }
    }

    let modified_value = e.target.value?.name;

    const newFormData = {
      ...formData,
      [e.target.name]: type === "select" ? modified_value : value,
    };

    setFormData(newFormData);

    const newErrors = { ...errors };
    delete newErrors[fieldName];
    setErrors(newErrors);
  };

  const textFields = () => {
    return register_text_fields.map((field, i) => {
      let field_name = getFieldName(field?.field);
      return (
        <>
          <Grid item md={6} style={{ margin: "1% 0" }}>
            <div style={input_style}>
              <label className="input-label color-label">{field?.field}</label>
              <input
                value={formData?.[field_name]}
                style={{ height: 42 }}
                className={`register-from-input ${errors[field_name] ? "invalid-input" : ""
                  }`}
                // className="register-from-input"
                type="text"
                placeholder={field?.field}
                name={getFieldName(field?.field)}
                onChange={(e) => handleInputChange(e, field, i, "text")}
              />
            </div>
            {errors[field_name] && (
              <div className="invalid-txt">{errors[field_name]}</div>
            )}
          </Grid>
        </>
      );
    });
  };

  const paymentTextFields = () => {
    return payment_text_fields.map((field, i) => {
      let field_name = getFieldName(field?.field);
      return (
        <>
          <Grid item md={12} style={{ margin: "1% 0" }}>
            <div style={input_style}>
              <label className="input-label color-label">{field?.field}</label>
              <input
                value={formData?.[field_name]}
                style={{ height: 42 }}
                className={`register-from-input ${errors[field_name] ? "invalid-input" : ""
                  }`}
                // className="register-from-input"
                type="text"
                placeholder={field?.field}
                name={getFieldName(field?.field)}
                onChange={(e) => handleInputChange(e, field, i, "text")}
              />
            </div>
            {errors[field_name] && (
              <div className="invalid-txt">{errors[field_name]}</div>
            )}
          </Grid>
        </>
      );
    });
  };

  const numberFields = () => {
    return register_number_fields.map((field, i) => {
      let field_name = getFieldName(field?.field);
      return (
        <Grid item md={6} style={{ margin: "1% 0" }} key={field_name}>
          <div style={input_style}>
            <label className="input-label color-label">{field?.field}</label>
            <input
              value={formData?.[field_name] || ""}
              placeholder={field?.field}
              style={{ height: 42 }}
              className={`register-from-input ${errors[field_name] ? "invalid-input" : ""
                }`}
              type="text"
              pattern="[0-9]*"
              maxLength={10}
              name={field_name}
              onChange={(e) => handleInputChange(e, field, i, "number")}
            />
          </div>
          {errors[field_name] && (
            <div className="invalid-txt">{errors[field_name]}</div>
          )}
        </Grid>
      );
    });
  };

  const paymentNumberFields = () => {
    return payment_number_fields.map((field, i) => {
      let field_name = getFieldName(field?.field);
      return (
        <>
          <Grid
            item
            md={field?.title === "card" ? 12 : 6}
            style={{ margin: "1% 0" }}
          >
            <div style={input_style}>
              <label className="input-label color-label">{field?.field}</label>
              <input
                value={formData?.[field_name]}
                style={{ height: 42 }}
                placeholder={field?.field}
                className={`register-from-input ${errors[field_name] ? "invalid-input" : ""
                  }`}
                // className="register-from-input"
                type="text"
                pattern="[0-9]*"
                maxLength={10}
                name={getFieldName(field?.field)}
                onChange={(e) => handleInputChange(e, field, i, "number")}
              />
            </div>
            {errors[field_name] && (
              <div className="invalid-txt">{errors[field_name]}</div>
            )}
          </Grid>
        </>
      );
    });
  };

  const selectFields = () => {
    return register_select_fields.map((field, i) => {
      let field_name = getFieldName(field?.field);
      let field_value = {
        name: formData?.[field_name],
        code: formData?.[field_name],
      };

      return (
        <>
          <Grid item md={6} style={{ margin: "1% 0" }}>
            <div style={input_style}>
              <label className="input-label color-label">{field?.field}</label>
              <Dropdown
                style={{ width: "95%" }}
                value={field_value}
                onChange={(e) => handleInputChange(e, field, i, "select")}
                options={optionsMap[getFieldName(field?.field)]}
                optionLabel="name"
                name={getFieldName(field?.field)}
                placeholder={field?.field}
                className="global-select"
              />
            </div>
            {errors[field_name] && (
              <div className="invalid-txt">{errors[field_name]}</div>
            )}
          </Grid>
        </>
      );
    });
  };

  const registerForm = () => {
    return (
      <>
        <Grid container spacing={0} style={{ marginTop: "5%" }}>
          <Grid
            item
            md={6}
            style={{ borderRight: "2px dashed gainsboro", padding: "2%" }}
          >
            <Grid container spacing={0}>
              {textFields()}
              {selectFields()}
              {numberFields()}
              <Button
                fullWidth
                onClick={handleNext}
                variant="contained"
                className="header-btn"
                style={{
                  fontSize: 13,
                  marginTop: "5%",
                  fontWeight: 600,
                }}
              >
                NEXT
              </Button>
            </Grid>
          </Grid>

          <Grid item md={6} style={{ padding: "2%" }}>
            <OrderSummary amount={amount} setPayableAmount={setPayableAmount} />
            <DiscountCoupon />
          </Grid>
        </Grid>
      </>
    );
  };

  const paymentForm = () => {
    return (
      <>
        <Grid container spacing={0} style={{ marginTop: "5%" }}>
          <Grid
            item
            md={6}
            style={{ borderRight: "2px dashed #F2F2F2", padding: "2%" }}
          >
            <h3 style={{ fontWeight: 500, fontSize: 18 }}>Payment</h3>
            <Grid container spacing={0}>
              {paymentTextFields()}
              {paymentNumberFields()}
              <Button
                fullWidth
                onClick={handleNext}
                variant="contained"
                className="header-btn"
                style={{
                  fontSize: 13,
                  marginTop: "5%",
                  fontWeight: 600,
                }}
              >
                NEXT
              </Button>
            </Grid>
          </Grid>

          <Grid item md={6} style={{ padding: "2%" }}>
            <OrderSummary amount={amount} setPayableAmount={setPayableAmount} />
          </Grid>
        </Grid>
      </>
    );
  };

  const formated_price = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(payableAmount);

  const checkoutLastStep = () => {
    return (
      <>
        <Grid container spacing={0} style={{ marginTop: "5%" }}>
          <Grid item md={12} style={{ padding: "2%" }}>
            <div
              style={{
                margin: "auto",
                width: "60%",
                // border: "1px solid #E4E6FF",
                // padding: "4%",
              }}
            >
              <CheckoutAccordions
                amount={amount}
                setPayableAmount={setPayableAmount}
                step="last"
                payableAmount={payableAmount}
              />
              <Button
                onClick={handlePayment}
                fullWidth
                variant="contained"
                className="header-btn"
                style={{
                  fontSize: 13,
                  marginTop: "5%",
                  fontWeight: 600,
                }}
              >
                {formated_price}
              </Button>
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  const handlePayment = () => {
    handleRzrpPayment();
  };

  const handleRzrpPayment = async (params) => {
    const rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
  };

  const options = {
    key: "rzp_test_GQ6XaPC6gMPNwH",
    // amount: payableAmount * 100,
    amount: payableAmount * 10,
    currency: "INR",
    name: "DilSey",
    description: "Matrimony",
    image: "/images/dilsey-logo.svg",

    handler: async function (response) {
      // create billing
      let payload = {
        user_id: user_data?._id,
        plan_id: plan_id || "",
        payment_amount: parseInt(payableAmount),
        plan_type: selectedPlan || "",
      };
      const result = await post_data("billing/create-billing", payload);
      if (result?.status) {
        navigate("/payment-successfull", {
          state: {
            status: "success",
            amount: amount,
            payableAmount,
            payableAmount,
            savedAmount: parseInt(amount) - parseInt(payableAmount),
          },
        });
        window.scrollTo(0, 0);
        dispatch(login(result?.data));

      } else {
        navigate("/payment-successfull", {
          state: {
            status: "failure",
            amount: amount,
            payableAmount,
            payableAmount,
            savedAmount: parseInt(amount) - parseInt(payableAmount),
          },
        });
        window.scrollTo(0, 0);
      }
    },
    prefill: {
      name: `${user_data?.first_name} ${user_data?.last_name}`,
      email: user_data?.email,
      contact: user_data?.contact_number,
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#9E0050",
    },
  };

  return (
    <div>
      <Box style={multi_stepper_div}>
        <h2 className="global-h2" style={{ fontSize: 25, textAlign: "center" }}>
          Where should we sent the order?
        </h2>
        <p
          className="about-para"
          style={{ width: "50%", margin: "1% auto 3%", textAlign: "center" }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore.
        </p>
        <Stepper activeStep={activeStep} style={multi_stepper_styles}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <div style={step_styles}>
                  <StepLabel {...labelProps}></StepLabel>
                  <p
                    style={{
                      ...step_title,
                      color: activeStep === index ? app_color : "gray",
                    }}
                  >
                    {label}
                  </p>
                </div>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you're finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {activeStep !== 0 && (
              <div
                onClick={handleBack}
                style={{
                  width: 30,
                  height: 30,
                  background: "#f2f2f2",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src="/images/arrow.svg"
                  style={{
                    opacity: "50%",
                    cursor: "pointer",
                  }}
                />
              </div>
            )}
            {activeStep === 0 && <> {registerForm()}</>}
            {activeStep === 1 && <>{checkoutLastStep()}</>}
            {/* {activeStep === 2 && <>{checkoutLastStep()}</>} */}
          </React.Fragment>
        )}
      </Box>
    </div>
  );
}
