import React, { useState } from "react";
import { FormControl, Select, MenuItem, Grid } from "@mui/material";

function Dropdown({ label, options, onOpen, placeholder, onChange }) {
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  const handleOpen = () => {
    if (onOpen) {
      onOpen();
    }
  };

  return (
    <Grid item xs={12} md={6} style={{ padding: "12px" }}>
      <Grid container xs={12} style={{ marginBottom: "8px" }}>
        <label
          style={{
            display: "block",
            marginBottom: "8px",
            fontSize: "14px",
            fontWeight: 500,
            color: "#333",
          }}
        >
          {label}
        </label>
        <FormControl fullWidth>
          <Select
            displayEmpty
            value={selectedValue}
            onChange={handleChange}
            onOpen={handleOpen}
            renderValue={(selected) => {
              if (!selected) {
                return <p style={{ color: "gray" }}>{placeholder}</p>;
              }
              const selectedOption = options.find(
                (option) => option.value === selected
              );
              return selectedOption ? selectedOption.label : "";
            }}
            style={{
              backgroundColor: "#f5f5f5",
              width: "200px",
              height: "40px",
              borderRadius: "10px",
              outline: "none",
              border: "none",
              boxShadow: "none",
              "&:focus": {
                outline: "none",
                border: "none",
              },
              "& .MuiSelect-select": {
                borderRadius: "10px",
                outline: "none",
              },
            }}
          >
            <MenuItem disabled value="">
              <em>{placeholder}</em>
            </MenuItem>
            {options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                style={{ fontSize: "14px" }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default Dropdown;