import React, { useMemo, useRef } from "react";
import { useState, useEffect } from "react";
import { Grid, Button } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading";
import ChatContacts from "../Components/ChatContacts";
import ChatScreen from "../Components/ChatScreen";
import io from "socket.io-client";
import { useSelector } from "react-redux";
import { get_data, post_data, serverURL } from "../../../Api";
import { socketServerUrl } from "../../../lib/axios-request";
import EmptyPage from "../Components/EmptyPage";
import ChatSkeleton from "../Components/skeleton/ChatSkeleton";
import { DarkModeContext } from "../Components/DarkModeContext";
import { useLocation } from "react-router-dom";

// let socket;

export default function Chat() {
  const scroll = useRef(null);
  // const socket = useMemo(() => io(server, { withCredentials: true }), []);
  const socket = useMemo(
    () =>
      io(socketServerUrl, {
        path: "/api/socket",
      }),
    []
  );

  const location = useLocation()
  const selected_user = location?.state?.selectedUser

  const { darkMode } = React.useContext(DarkModeContext);
  const [selectedUser, setSelectedUser] = useState(selected_user || null);
  const [allChats, setAllChats] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const { isLoggedIn, user_data } = useSelector((state) => state.user);
  const [contactsList, setContactsList] = useState([]);
  const [allMessages, setAllMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [iAmTyping, setIAmTyping] = useState(false);
  const [userTyping, setUserTyping] = useState(false);
  const typingTimeout = useRef(null);
  const selectedUserRef = useRef(selectedUser);
  const [noPlan, setNoPlan] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (user_data?.plan_id?.length === 0) {
      setNoPlan(true);
    }
  }, [user_data]);

  const chat_page = {
    width: "100%",
    height: 570,
  };

  const page_container = {
    width: "100%",
    height: "100%",
  };

  useEffect(() => {
    // if (user_data?._id) {
    //   socket = io(socketServerUrl, {
    //     path: "/api/socket",
    //   });
    socket.emit("new-user-add", user_data?._id);
    socket.on("get-users", (users) => {
      setOnlineUsers(users);
    });
    // }
  }, [socket, user_data?._id]);

  const getAllChats = async () => {
    const current_data = await get_data(
      `chat/get-chat-by-id/${user_data?._id}`
    );
    if (current_data?.status) {
      setAllChats(current_data.data);
    } else {
      // console.log("error: ", current_data?.message);
      setAllChats([]);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (user_data?._id) {
      getAllChats();
    }
  }, [user_data]);


  useEffect(() => {
    if (allChats.length > 0) {
      const currentList = allChats?.map((item) => {
        let currentItem = item?.members[0];
        return {
          name: `${currentItem?.first_name} ${currentItem?.last_name}`,
          profile_picture: currentItem?.profile_picture,
          messages: currentItem?.last_msg,
          last_msg: currentItem?.messages,
          privacy: currentItem?.privacy,
          is_online: onlineUsers?.find(
            (item) => item?.userId === currentItem?._id
          )
            ? 1
            : 0,
          user_id: currentItem?._id,
          chat_id: item?._id,
          count: item.count,
          last_message: item.last_message,
        };
      });
      setContactsList(currentList);
      setSelectedUser(selected_user || null);
    }
  }, [allChats, onlineUsers]);

  const getAllMessages = async () => {
    const current_messages = await get_data(
      `chat-message/get-messages-by-chat-id/${selectedUser?.chat_id}`
    );
    if (current_messages?.status) {
      setAllMessages(current_messages.data);
    } else {
      // console.log("error: ", current_data?.message);
      setAllMessages([]);
    }
  };

  useEffect(() => {
    if (selectedUser?.chat_id) {
      getAllMessages();
    }
  }, [selectedUser]);

  const handleMessage = async (event) => {
    if (
      (currentMessage !== "" && event.key === "Enter") ||
      (event.type === "click" && currentMessage !== "")
    ) {
      let data = {
        chatId: selectedUser?.chat_id,
        senderId: user_data?._id,
        message: currentMessage,
      };
      socket.emit("send-message", {
        ...data,
        createdAt: new Date(),
        receiverId: selectedUser?.user_id,
      });
      // sendMessage(data);
      const message_response = await post_data(
        `chat-message/create-chat-message`,
        data
      );

      if (message_response.status) {
        setAllMessages((prev) => [
          ...prev,
          {
            _id: message_response.data._id,
            senderId: message_response.data.senderId,
            message: message_response.data.message,
            attachments: message_response.data.attachments,
            createdAt: message_response.data.createdAt,
          },
        ]);
        setCurrentMessage("");
      } else {
        // console.log("error happened");
      }
    }
  };

  useEffect(() => {
    selectedUserRef.current = selectedUser;
  }, [selectedUser]);

  useEffect(() => {
    const handleMessageReceive = (data) => {
      alert('yes message recive')
      console.log('data revice', data);
      if (data.chatId === selectedUserRef.current?.chat_id) {
        setAllMessages((prev) => [
          ...prev,
          {
            _id: allMessages.length + 1,
            senderId: data.senderId,
            message: data.message,
            attachments: data.attachments,
            createdAt: data.createdAt,
          },
        ]);
      } else {
        setContactsList((prevContacts) => {
          const currentIndex = prevContacts.findIndex(
            (contact) => contact.chat_id === data.chatId
          );

          if (currentIndex === -1) {
            return prevContacts;
          }

          const updatedContacts = [...prevContacts];
          const updatedCurrentContact = {
            ...updatedContacts[currentIndex],
            count: updatedContacts[currentIndex].count + 1,
            last_message:
              data.attachments.length === 0
                ? data.message
                : `${data.attachments.length} New Messages`,
          };

          const filteredArray = updatedContacts.filter(
            (obj) => obj.chat_id !== updatedCurrentContact.chat_id
          );

          return [updatedCurrentContact, ...filteredArray];
        });
      }
    };

    socket.on("recieve-message", handleMessageReceive);

    // Clean up the event listener on component unmount
    return () => {
      socket.off("recieve-message", handleMessageReceive);
    };
  }, [socket, allMessages.length]);

  const messageOnChange = (e) => {
    setCurrentMessage(e.target.value);

    if (!iAmTyping) {
      socket.emit("start-typing", {
        receiverId: selectedUser?.user_id,
        typing: true,
      });
      setIAmTyping(true);
    }

    if (typingTimeout.current) clearTimeout(typingTimeout.current);

    typingTimeout.current = setTimeout(() => {
      socket.emit("stop-typing", {
        receiverId: selectedUser?.user_id,
        typing: false,
      });
      setIAmTyping(false);
    }, [2000]);
  };

  useEffect(() => {
    socket.on("start-typing", (data) => {
      setUserTyping(true);
    });
    socket.on("stop-typing", (data) => {
      setUserTyping(false);
    });
  }, []);

  useEffect(() => {
    scroll.current?.scrollIntoView({ behavior: "smooth" });
  }, [allMessages]);


  const handleFileChange = async (event) => {
    try {
      if (!selectedUser?.chat_id) {
        return;
      }
      const files = Array.from(event.target.files);
      const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
      const validFiles = files.filter((file) =>
        validImageTypes.includes(file.type)
      );
      if (validFiles.length !== files.length) {
        // toast.error("Some files are not valid images.");
        return;
      }

      const formData = new FormData();
      validFiles.forEach((file) => {
        formData.append("image", file);
      });

      formData.append("chatId", selectedUser?.chat_id);
      formData.append("senderId", user_data?._id);
      formData.append("receiverId", selectedUser?.user_id);
      formData.append("message", "");

      const message_response = await post_data(
        `chat-message/create-chat-attachment`,
        formData
      );

      if (message_response.status) {
        socket.emit("send-message", {
          chatId: selectedUser?.chat_id,
          senderId: message_response.data.senderId,
          message: message_response.data.message,
          attachments: message_response.data.attachments,
          createdAt: message_response.data.createdAt,
          receiverId: selectedUser?.user_id,
        });
        setAllMessages((prev) => [
          ...prev,
          {
            _id: message_response.data._id,
            senderId: message_response.data.senderId,
            message: message_response.data.message,
            attachments: message_response.data.attachments,
            createdAt: message_response.data.createdAt,
          },
        ]);
      }
    } catch (error) {
      // setLoading(false);
    }
  };

  return (
    <>
      <Grid container spacing={0} style={chat_page}>
        {/* <DashboardHeading title='Chat 🗨️' /> */}

        <div style={page_container}>
          {loading ? (
            <>
              <ChatSkeleton />
            </>
          ) : (
            <>
              {contactsList?.length === 0 ? (
                <>
                  <EmptyPage title="No contacts in your Chat list!" />
                </>
              ) : (
                <>
                  <Grid container spacing={1} style={{ height: "100%" }}>
                    <Grid item md={3} style={{ height: "100%" }}>
                      <ChatContacts
                        noPlan={noPlan}
                        setSelectedUser={setSelectedUser}
                        selectedUser={selectedUser}
                        contactsList={contactsList}
                      />
                    </Grid>

                    <Grid
                      item
                      md={9}
                      style={{ height: "100%" }}
                      className="content-section"
                    >
                      <ChatScreen
                        noPlan={noPlan}
                        selectedUser={selectedUser}
                        allMessages={allMessages}
                        setCurrentMessage={setCurrentMessage}
                        onMessageChange={messageOnChange}
                        userTyping={userTyping}
                        currentMessage={currentMessage}
                        handleMessage={handleMessage}
                        scroll={scroll}
                        fileInputRef={fileInputRef}
                        handleFileChange={handleFileChange}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </div>
      </Grid>
    </>
  );
}