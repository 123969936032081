import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal, { modalClasses } from '@mui/material/Modal';
import { useState } from 'react';
import { Grid } from '@mui/material';
import { IoMdClose } from "react-icons/io";
import { button_left_style, button_right_style, disabled_btn, edit_div, secondaryDarkColor } from '../../../Constants/globalConstants';
import { FaCheck } from "react-icons/fa6";
import { DarkModeContext } from './DarkModeContext';

export default function PhotosGuidelines({
    open,
    setOpen,
    setOpenDropzone,
    openDropzone,
    setEditPhotos,
    setShowDisplayPhotos,
    type,
    setProfilePicture,
    setBtnStatus

}) {

    const { darkMode } = React.useContext(DarkModeContext);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        maxHeight: '95%',
        overflowY: 'auto',
        bgcolor: darkMode ? secondaryDarkColor : 'background.paper',
        color: darkMode ? 'white' : 'black',
        boxShadow: 24,
        borderRadius: 4,
        padding: '2%',
    };

    const handleClose = () => {
        setOpen(false)
    };

    const modal_title = {
        textAlign: 'left',
        width: '80%',
        fontSize: 23,
        fontWeight: 600,
    }

    const handleClick = () => {
        setOpen(false)
        setOpenDropzone(!openDropzone)
        setEditPhotos(false)
        setShowDisplayPhotos(true)
    }

    const do_photos = [
        'Use a clear face photo with your face fully visible and well-lit.',
        'Choose a simple, neutral background that doesn’t distract from your face.',
        'Maintain a natural expression, such as a natural smile or a neutral look.',
        'Upload high-resolution images to avoid blurriness.',
        'Use minimal or no filters to ensure the photo represents your true appearance.',
        'Ensure proper lighting with natural or soft light; avoid harsh shadows or overly bright lights.',
        'Include a solo photo with just yourself, avoiding group photos.'
    ];

    const do_not_photos = [
        'Avoid using group photos where multiple people are present.',
        'Do not use backgrounds that are too bright or too dark, as they can overshadow your image.',
        'Refrain from using photos with distracting props that divert attention from you.',
        'Avoid heavily edited or filtered photos that alter your true appearance.',
        'Do not use outdated photos; ensure the image is recent and accurately represents you.',
        'Avoid photos with excessive makeup or heavy editing that misrepresents your appearance.',
        'Refrain from using photos with inappropriate or unprofessional content, including suggestive or casual images.'
    ];


    const handleImage = (event) => {
        setProfilePicture({ bytes: event.target.files[0], filename: URL.createObjectURL(event.target.files[0]) })
        setBtnStatus(true)
        setOpen(false)
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <div onClick={handleClose} style={{
                        position: 'absolute',
                        right: '3%',
                        top: '3%',
                        cursor: 'pointer',
                    }}
                    >
                        <img src='/images/modal-close-icon.svg' style={{ width: '100%', height: '100%' }} />
                    </div>

                    <Grid container spacing={0}>

                        <Grid item md={12} style={{ marginBottom: '5%', display: 'flex', justifyContent: 'left' }}>
                            <h2 style={modal_title}>Guideliness of uploading Photos</h2>
                        </Grid>

                        <div style={{
                            marginBottom: '3%',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '3%',
                            justifyContent: 'center',
                            width: '100%'
                        }}>
                            <div style={{
                                position: 'relative'
                            }}>
                                <img src="/images/bad-profile-pic.jpg" style={{ width: 110, height: 110, borderRadius: '50%' }} />
                                <IoMdClose style={{ position: 'absolute', top: '20%', left: '20%', color: 'red', height: 70, width: 70 }} />
                            </div>
                            <div style={{
                                position: 'relative'
                            }}>
                                <img src="/images/good-profile-pic.jpg" style={{ width: 110, height: 110, borderRadius: '50%' }} />
                                <FaCheck style={{ position: 'absolute', top: '20%', left: '20%', color: '#00ff2f', height: 70, width: 70 }} />
                            </div>
                        </div>

                        <h2 style={{ ...modal_title, fontSize: 19 }}>Do's of uploading Photos</h2>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'left',
                            width: '100%'
                        }}>
                            {
                                do_photos?.map((item, i) => {
                                    return (
                                        <>
                                            <div style={{ display: 'flex', gap: '2%', width: '100%', margin: '5px 0' }}>
                                                <img src="/images/verified-icon.svg" style={{ width: 20, height: 20 }} />
                                                <p style={{ width: '100%', opacity: '60%', color: darkMode ? 'white' : 'black' }} className='about-para'>
                                                    {item}
                                                </p>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>

                        <h2 style={{ ...modal_title, fontSize: 19, marginTop: '5%' }}>Dont's of uploading Photos</h2>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'left',
                            width: '100%'
                        }}>
                            {
                                do_not_photos?.map((item, i) => {
                                    return (
                                        <>
                                            <div style={{ display: 'flex', gap: '2%', width: '100%', margin: '5px 0' }}>
                                                <img src="/images/wrong-icon.svg" style={{ width: 20, height: 20 }} />
                                                <p style={{ width: '100%', opacity: '60%', color: darkMode ? 'white' : 'black' }} className='about-para'>
                                                    {item}
                                                </p>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>


                    </Grid>

                    <Grid item md={12} style={{
                        width: '50%',
                        display: 'flex',
                        gap: '4%',
                        justifyContent: 'center',
                        margin: '3% auto 0 auto',
                    }}>

                        {
                            type === 'profile-pic' ? (
                                <Button
                                    onChange={handleImage}
                                    component="label" variant="outlined"
                                    style={button_right_style} fullWidth>
                                    I understood
                                    <input hidden type="file" accept="images/*" />
                                </Button>
                            ) : (
                                <Button
                                    onClick={handleClick}
                                    variant='contained'
                                    style={button_right_style}
                                >
                                    I understood
                                </Button>
                            )
                        }
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
