import React from "react";
import { useState, useEffect } from "react";
import { Grid, Button } from "@mui/material";
import SideBar from "../Components/SideBar";
import TopBar from "../Components/TopBar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import DashboardComponent from "../Components/DashboardComponent";
import Sales from "./Sales";
import Plans from "./Plans";
import Users from "./Users";
import MatchMaking from "./MatchMaking";
import SubAdmins from "./SubAdmins";
import AdditionalServices from "./AdditionalServices";
import Permissions from "./Permissions";
import Activity from "./Activity";
import SubAdminPermissions from "../../../Constants/subAdminPermissions";
import PermissionDenied from "../Components/PermissionDenied";
import Settings from "../../UserDshboard/Pages/Settings";
import ProfileLoading from "../../UserDshboard/Components/ProfileLoading";
import SupportChat from "./SupportChat";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import EmptyPage from "../../UserDshboard/Components/EmptyPage";
import Coupan from "./Coupan";
import UserEditPage from "./UserEditPage";
import VerificationSalesTable from "../Components/VerificationSalesTable";
import VerificationPlanSales from "./VerificationPlanSales";
import { MdDashboard } from "react-icons/md";

export default function AdminDashboard() {

  const { permissions, isSubAdmin } = SubAdminPermissions();
  const [profileLoading, setProfileLoading] = useState(false);
  const theme = useTheme();
  const matches_md = useMediaQuery(theme.breakpoints.down("md"));

  const content_section = {
    width: "100%",
    height: "90.8vh",
    padding: "2%",
    background: "#F8F8F8",
    overflowY: "auto",
  };

  const menu_items = [
    {
      title: "Dashboard",
      link: "/admin/dashboard",
      icon: "dashboard-icon",
      active_icon: "dashboard-white-icon",
    },
    {
      title: "Sales",
      link: "/admin/dashboard/sales",
      icon: "sales-icon",
      active_icon: "sales-white-icon",
    },
    {
      title: "Verification Plan Sales",
      link: "/admin/dashboard/verification-plan-sales",
      icon: "sales-icon",
      active_icon: "sales-white-icon",
    },
    {
      title: "Users",
      link: "/admin/dashboard/users",
      icon: "users-icon",
      active_icon: "users-white-icon",
    },
    {
      title: "Sub Admins",
      link: "/admin/dashboard/sub-admins",
      icon: "sub-admin-icon-2",
      active_icon: "sub-admin-white-icon",
    },
    {
      title: "Permissions",
      link: "/admin/dashboard/permissions",
      icon: "lock-icon",
      active_icon: "lock-white-icon",
    },
    {
      title: "Plans",
      link: "/admin/dashboard/plans",
      icon: "billing-icon",
      active_icon: "billing-white-icon",
    },
    {
      title: "Background Verification",
      link: "/admin/dashboard/background-verification",
      icon: "bg-verification-icon",
      active_icon: "bg-verification-color-icon",
    },
    {
      title: "Coupons",
      link: "/admin/dashboard/coupons",
      icon: "coupon-icon",
      active_icon: "coupon-active-icon",
    },
    {
      title: "Activities",
      link: "/admin/dashboard/activities",
      icon: "activity-icon",
      active_icon: "activity-active-icon",
    },
    {
      title: "Settings",
      link: "/admin/dashboard/settings",
      icon: "settings-icon-new",
      active_icon: "settings-white-icon-new",
    },
  ];

  return (
    <>
      {matches_md ? (
        <>
          <EmptyPage
            title={"This Web app is not made for small screen devices."}
          />
        </>
      ) : (
        <>
          <Grid container spacing={0} style={{ position: "relative" }}>
            {profileLoading && <ProfileLoading type="delete" />}
            <Grid
              item
              md={1.7}
              style={{ position: "sticky", top: 0, height: "100vh" }}
            >
              <SideBar
                type="Admin"
                menu_items={menu_items}
                isSubAdmin={isSubAdmin}
              />
            </Grid>
            <Grid
              item
              md={10.3}
              style={{ overflowY: "auto" }}
              className="dashboard_content_grid"
            >
              <TopBar isSubAdmin={isSubAdmin} />
              <div style={content_section} className="content-section">
                <Routes>
                  {isSubAdmin ? (
                    permissions?.includes("View Users") ||
                      permissions?.includes("View Sales") ? (
                      <Route
                        element={
                          <DashboardComponent
                            profileLoading={profileLoading}
                            setProfileLoading={setProfileLoading}
                            isSubAdmin={isSubAdmin}
                            permissions={permissions}
                          />
                        }
                        path="/"
                      />
                    ) : (
                      <Route
                        element={
                          <PermissionDenied
                            title={
                              "You don't have Permission to view this Page"
                            }
                          />
                        }
                        path="/"
                      />
                    )
                  ) : (
                    <Route
                      element={
                        <DashboardComponent
                          profileLoading={profileLoading}
                          setProfileLoading={setProfileLoading}
                          isSubAdmin={isSubAdmin}
                          permissions={permissions}
                        />
                      }
                      path="/"
                    />
                  )}

                  {isSubAdmin ? (
                    permissions?.includes("View Sales") ? (
                      <Route element={<Sales />} path="/sales" />
                    ) : (
                      <Route
                        element={
                          <PermissionDenied
                            title={
                              "You don't have Permission to view this Page"
                            }
                          />
                        }
                        path="/sales"
                      />
                    )
                  ) : (
                    <Route element={<Sales />} path="/sales" />
                  )}


                  {isSubAdmin ? (
                    permissions?.includes("Edit Users") ? (
                      <Route element={<UserEditPage />} path="/edit-user" />
                    ) : (
                      <Route
                        element={
                          <PermissionDenied
                            title={
                              "You don't have Permission to view this Page"
                            }
                          />
                        }
                        path="/sales"
                      />
                    )
                  ) : (
                    <Route element={<UserEditPage />} path="/edit-user" />
                  )}


                  {isSubAdmin ? (
                    permissions?.includes("New Coupon") ||
                      permissions?.includes("View Coupons") ||
                      permissions?.includes("Edit Coupon") ||
                      permissions?.includes("Delete Coupon") ? (
                      <Route path="/coupons" element={<Coupan />} />
                    ) : (
                      <Route
                        element={
                          <PermissionDenied
                            title={
                              "You don't have Permission to view this Page"
                            }
                          />
                        }
                        path="/coupons"
                      />
                    )
                  ) : (
                    <Route path="/coupons" element={<Coupan />} />
                  )}


                  {isSubAdmin ? (
                    permissions?.includes("View Users") ||
                      permissions?.includes("Edit Users") ||
                      permissions?.includes("Delete Users") ? (
                      <Route
                        element={
                          <Users
                            setProfileLoading={setProfileLoading}
                            isSubAdmin={isSubAdmin}
                            permissions={permissions}
                          />
                        }
                        path="/users"
                      />
                    ) : (
                      <Route
                        element={
                          <PermissionDenied
                            title={
                              "You don't have Permission to view this Page"
                            }
                          />
                        }
                        path="/users"
                      />
                    )
                  ) : (
                    <Route
                      element={
                        <Users
                          setProfileLoading={setProfileLoading}
                          isSubAdmin={isSubAdmin}
                          permissions={permissions}
                        />
                      }
                      path="/users"
                    />
                  )}

                  {!isSubAdmin && (
                    <>
                      <Route element={<SubAdmins setProfileLoading={setProfileLoading} />} path="/sub-admins" />
                      <Route element={<Permissions setProfileLoading={setProfileLoading} />} path="/permissions" />
                      <Route path="/activities" element={<Activity />} />
                    </>
                  )}

                  {isSubAdmin ? (
                    permissions?.includes("View Plans") ||
                      permissions?.includes("Edit Plans") ? (
                      <Route
                        element={
                          <Plans
                            isSubAdmin={isSubAdmin}
                            permissions={permissions}
                          />
                        }
                        path="/plans"
                      />
                    ) : (
                      <Route
                        element={
                          <PermissionDenied
                            title={
                              "You don't have Permission to view this Page"
                            }
                          />
                        }
                        path="/plans"
                      />
                    )
                  ) : (
                    <Route element={<Plans />} path="/plans" />
                  )}


                  <Route element={<Settings type="admin" />} path="/settings" />


                  {isSubAdmin ? (
                    permissions?.includes("Verification Sales") ? (
                      <Route element={<VerificationPlanSales />} path="/verification-plan-sales" />
                    ) : (
                      <Route
                        element={
                          <PermissionDenied
                            title={
                              "You don't have Permission to view this Page"
                            }
                          />
                        }
                        path="/verification-plan-sales"
                      />
                    )
                  ) : (
                    <Route element={<VerificationPlanSales />} path="/verification-plan-sales" />
                  )}

                </Routes>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
