import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal, { modalClasses } from "@mui/material/Modal";
import { useState } from "react";
import { Grid, colors } from "@mui/material";
import {
  button_left_style,
  button_right_style,
  useDatabaseConstants,
  disabled_btn,
} from "../../../Constants/globalConstants";
import DefaultMultiSelect from "../../UserDshboard/Components/DefaultMultiSelect";
import { get_data, post_data } from "../../../Api";
import CustomToggle from "../../UserDshboard/Components/CustomToggle";
import toast, { Toaster } from "react-hot-toast";

export default function SubAdminModal({
  fetchAllSubAdmin,
  open,
  setOpen,
  title,
  setSuccessOpen,
  setSuccessStatus,
  subAdminItem,
  setSubAdminItem,
  setModalType,
  modalType,
}) {
  const [loading, setLoading] = useState("");
  const { app_color } = useDatabaseConstants();
  const [permissions, setPermissions] = useState([]);
  const [passwodForm, setPasswordForm] = useState(false);
  const [editForm, setEditForm] = useState(true);
  const [passwordError, setPasswordError] = useState(false);
  // const [permissionsInvalid, setPermissionsInvalid] = useState(false)
  const [defaultMultiSelectValues, setDefaultMultiSelectValues] = useState([]);

  React.useEffect(() => {
    const defaultValues = permissions?.filter((permission) =>
      subAdminItem?.permissions?.includes(permission?.code)
    );
    setDefaultMultiSelectValues(defaultValues);
  }, [subAdminItem, permissions]);

  const fetchAllPermissions = async () => {
    const response = await get_data(
      "sub-admin-permission/get-all-sub-admin-permissions"
    );
    if (response?.status) {
      const modifiedPermisions = transformData(
        response?.data?.filter((item) => item?.is_active)
      );
      if (modifiedPermisions) {
        setPermissions(modifiedPermisions);
      }
    }
  };

  React.useEffect(() => {
    fetchAllPermissions();
  }, []);

  const transformData = (data) => {
    return data?.map((item) => {
      return {
        name: item?.permission_name,
        code: item?._id,
      };
    });
  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    permissions: [],
    is_active: "",
  });

  React.useEffect(() => {
    if (modalType !== "Edit") {
      setDefaultMultiSelectValues([]);
    }
    setFormData({
      first_name: modalType === "Edit" ? subAdminItem?.first_name : "",
      last_name: modalType === "Edit" ? subAdminItem?.last_name : "",
      email: modalType === "Edit" ? subAdminItem?.email : "",
      password: modalType === "Edit" ? subAdminItem?.password : "",
      permissions: modalType === "Edit" ? subAdminItem?.permissions : [],
      is_active: modalType === "Edit" ? subAdminItem?.is_active : true,
      _id: subAdminItem?._id || "",
    });
  }, [subAdminItem, modalType]);

  const [invalid, setInvalid] = useState({
    first_name: false,
    email: false,
    password: false,
    // permissions: false
  });

  const handleClose = () => {
    setPasswordForm(false);
    setEditForm(true);
    setOpen(false);
    setModalType("");
    setFormData({ ...formData, password: "" });
    setInvalid({
      first_name: false,
      email: false,
      password: false,
      permissions: false,
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    overflowY: "auto",
    borderRadius: 4,
    padding: "1%",
  };

  const modal_title = {
    marginTop: "4%",
    textAlign: "center",
    width: "80%",
    fontSize: 24,
    fontWeight: 500,
    color: app_color,
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setInvalid({ ...invalid, [name]: false });
    setPasswordError(false);
  };

  // React.useEffect(() => {
  //     if (formData?.permissions?.admin?.length > 0) {
  //         setPermissionsInvalid(false)
  //     }
  // }, [formData])

  const handleSubmit = (e) => {
    e?.preventDefault();

    let is_false = 0;
    let obj = {
      first_name: false,
      email: false,
      password: false,
      permissions: false,
    };

    if (formData?.first_name === "") {
      obj.first_name = true;
      is_false = 1;
    }

    if (modalType !== "Edit" && formData?.password === "") {
      obj.password = true;
      is_false = 1;
    }

    // if (formData?.permissions?.admin?.length < 1) {
    //     setPermissionsInvalid(true);
    // }

    if (formData?.email === "") {
      obj.email = true;
      is_false = 1;
    }

    if (is_false) {
      setInvalid(obj);
      return "";
    }

    setLoading(true);

    setTimeout(async () => {
      let payload = { ...formData, permissions: formData?.permissions?.admin };
      let response;
      if (modalType === "Edit") {
        delete payload?.password;
        response = await post_data(
          `sub-admin/update-sub-admin/${formData?._id}`,
          payload
        );
      } else {
        delete payload?._id;
        response = await post_data("sub-admin/create-sub-admin", payload);
      }
      if (response?.status) {
        handleClose();
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          permissions: [],
        });
        fetchAllSubAdmin();
        setLoading(false);
        setOpen(false);
        setSuccessOpen(true);
        setSuccessStatus(true);
        setModalType('Edit')
      } else {
        handleClose();
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          permissions: [],
        });
        setOpen(false);
        setLoading(false);
        setSuccessOpen(true);
        setSuccessStatus(false);
      }
    }, 1000);
  };

  const handleChangePassword = (e) => {
    e?.preventDefault();

    if (formData?.password === "") {
      setPasswordError(true);
      return "";
    }

    setLoading(true);

    setTimeout(async () => {
      let payload = { password: formData?.password, email: formData?.email };
      const response = await post_data(
        "sub-admin/password-change-by-admin",
        payload
      );
      if (response?.status) {
        handleClose();
        fetchAllSubAdmin();
        setLoading(false);
        setOpen(false);
        setSuccessOpen(true);
        setSuccessStatus(true);
      } else {
        handleClose();
        setLoading(false);
        setOpen(false);
        setSuccessOpen(true);
        setSuccessStatus(false);
      }
    }, 1000);
  };

  const handleToggleChange = (e) => {
    setFormData({ ...formData, is_active: !formData?.is_active });
  };

  const handlePasswordClick = () => {
    setEditForm(false);
    setPasswordForm(true);
  };

  const createForm = () => {
    return (
      <>
        <div className="register-form">
          <Grid container spacing={2}>
            <Grid item md={6} style={{ width: "100%" }}>
              <label className="input-label color-label">First Name*</label>
              <input
                style={{ height: 42 }}
                onKeyPress={(event) => handleEnter(event)}
                className={`register-from-input ${
                  invalid?.first_name ? "invalid-input" : ""
                }`}
                placeholder="Enter First Name"
                type="text"
                name="first_name"
                value={formData?.first_name}
                onChange={handleInputChange}
              />
              {invalid?.first_name && (
                <p className="invalid-txt">Please enter First name</p>
              )}
            </Grid>
            <Grid item md={6} style={{ width: "100%" }}>
              <label className="input-label color-label">Last Name</label>
              <input
                style={{ height: 42 }}
                onKeyPress={(event) => handleEnter(event)}
                className="register-from-input"
                placeholder="Enter Last Name"
                type="text"
                name="last_name"
                value={formData?.last_name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item md={6} style={{ width: "100%" }}>
              <label className="input-label color-label">Email*</label>
              <input
                style={{ height: 42 }}
                onKeyPress={(event) => handleEnter(event)}
                className={`register-from-input ${
                  invalid?.email ? "invalid-input" : ""
                }`}
                placeholder="Enter Email"
                type="text"
                name="email"
                value={formData?.email}
                onChange={handleInputChange}
              />
              {invalid?.email && (
                <p className="invalid-txt">Please enter Email</p>
              )}
            </Grid>
            {modalType !== "Edit" && (
              <>
                <Grid item md={6} style={{ width: "100%" }}>
                  <label className="input-label color-label">Password*</label>
                  <input
                    style={{ height: 42 }}
                    className={`register-from-input ${
                      invalid?.password ? "invalid-input" : ""
                    }`}
                    type="password"
                    placeholder="Enter Password"
                    name="password"
                    value={formData?.password}
                    onChange={handleInputChange}
                  />
                  {invalid?.password && (
                    <p className="invalid-txt">Please enter Password</p>
                  )}
                </Grid>
              </>
            )}
            <Grid item md={6} style={{ width: "100%" }}>
              <label className="input-label color-label">Permisions*</label>
              <div className="multi-select">
                <DefaultMultiSelect
                  permissions={permissions}
                  formData={formData}
                  setFormData={setFormData}
                  title={"permissions"}
                  type={"admin"}
                  defaultMultiSelectValues={defaultMultiSelectValues}
                  setDefaultMultiSelectValues={setDefaultMultiSelectValues}
                />
              </div>
              {/* {
                                permissionsInvalid && (
                                    <p className="invalid-txt">Please Select Permissions</p>
                                )
                            } */}
            </Grid>
            <Grid
              item
              md={3}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: modalType === "Edit" ? "row" : "column",
                gap: modalType === "Edit" ? "10%" : "",
                alignItems: "start",
              }}
            >
              <label className="input-label color-label">Active</label>
              <CustomToggle
                field={"active"}
                payload={formData}
                setPayload={setFormData}
                handleInputChange={handleToggleChange}
                toggle_value={formData?.is_active}
                screen="permissions"
              />
            </Grid>
          </Grid>

          <Grid
            item
            md={12}
            style={{
              width: "60%",
              display: "flex",
              gap: "4%",
              margin: "3% auto 0 auto",
            }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              style={button_left_style}
            >
              Close
            </Button>

            <Button
              onClick={handleSubmit}
              disabled={loading}
              variant="contained"
              style={
                loading
                  ? { ...button_right_style, ...disabled_btn }
                  : button_right_style
              }
            >
              {loading ? "Saving..." : "Save"}
            </Button>
          </Grid>
        </div>
      </>
    );
  };

  const handleEnter = (event) => {
    if (event.key == "Enter") {
      if (modalType === "Edit") {
        handleSubmit();
      } else {
        handleChangePassword();
      }
    }
  };

  // console.log('modalType', modalType);

  const changePassword = () => {
    return (
      <>
        <Grid container spacing={2} justifyContent={"center"} margin={"3% 0"}>
          <Grid item md={8} style={{ width: "100%" }}>
            <label className="input-label color-label">New Password*</label>
            <input
              onKeyPress={(event) => handleEnter(event)}
              style={{ height: 42 }}
              className={`register-from-input ${
                passwordError ? "invalid-input" : ""
              }`}
              type="password"
              placeholder="Enter Password"
              name="password"
              value={formData?.password}
              onChange={handleInputChange}
            />
            {passwordError && (
              <p className="invalid-txt">Please enter Password</p>
            )}
          </Grid>
          <Grid
            item
            md={6}
            style={{
              width: "60%",
              display: "flex",
              gap: "4%",
              margin: "3% auto 0 auto",
            }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              style={button_left_style}
            >
              Close
            </Button>

            <Button
              onClick={handleChangePassword}
              disabled={loading}
              variant="contained"
              style={
                loading
                  ? { ...button_right_style, ...disabled_btn }
                  : button_right_style
              }
            >
              {loading ? "Saving..." : "Save"}
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Toaster />
          <div
            onClick={handleClose}
            style={{
              position: "absolute",
              right: "3%",
              top: "3%",
              cursor: "pointer",
            }}
          >
            <img
              src="/images/modal-close-icon.svg"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <Grid container spacing={0}>
            <Grid
              item
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h2 style={modal_title}>
                {modalType === "Edit" ? "Edit" : "Create"} Sub Admin
              </h2>
            </Grid>

            {editForm && (
              <>
                {createForm()}
                {modalType === "Edit" && (
                  <>
                    <Grid
                      item
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p
                        className="about-para"
                        style={{
                          padding: 0,
                          margin: 0,
                          textAlign: "center",
                          color: app_color,
                          textAlign: "center",
                          fontWeight: 500,
                          fontSize: 14,
                          cursor: "pointer",
                        }}
                        onClick={handlePasswordClick}
                      >
                        Change Password
                      </p>
                    </Grid>
                  </>
                )}
              </>
            )}
            {passwodForm && <>{changePassword()}</>}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
