import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { Button, Grid } from "@mui/material";
import SuccessModal from "./SuccessModal";
import { useDispatch, useSelector } from "react-redux";
import { post_data, serverURLPhotos } from "../../../Api";
import {
  button_right_style,
  useDatabaseConstants,
  disabled_btn,
  button_left_style,
  handleCreateActivity
} from "../../../Constants/globalConstants";
import { login } from "../../../redux/slices/user-slice";
import EmptyPage from "../Components/EmptyPage";
import ProfilePictureModal from "./ProfilePictureModal";
import AreYouSureModal from "./AreYouSureModal";

export default function UserPhotos({
  user,
  screen,
  setOpenDropzone,
  openDropzone,
  editPhotos,
  showDisplayPhotos,
  setShowDisplayPhotos,
  setEditPhotos,
  permissions,
  type,
  setUser
}) {

  const dispatch = useDispatch();
  const [selectedImages, setSelectedImages] = useState([]);
  const [successStatus, setSuccessStatus] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const { app_color } = useDatabaseConstants();
  const { isLoggedIn, user_data } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [photoModal, setPhotoModal] = useState(false)
  const [selectedPhoto, setSelectedPhoto] = useState('')
  // const [formData, setFormData] = useState(user_data)
  const [formData, setFormData] = useState(user)
  const [btnStatus, setBtnStatus] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [sureModal, setSureModal] = useState(false)
  const [noPlan, setNoPlan] = useState(false)
  const [deletedPhotos, setDeletedPhotos] = useState([])

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    acceptedFiles?.forEach((file) => {
      setSelectedImages((prevState) => [...prevState, file]);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const handleRemoveImage = (image, index) => {
    const updatedImages = [...selectedImages];
    updatedImages?.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const handleRemoveImageExist = (image, index) => {
    setFormData((prevFormData) => {
      const galleryArray = prevFormData?.photos || [];
      const updatedGallery = [...galleryArray];
      const deletedImagesArray = [...deletedPhotos];
      const deletedImage = updatedGallery?.[index];
      updatedGallery.splice(index, 1);

      setBtnStatus(true);
      setSelectedImages(updatedGallery);

      setDeletedPhotos([...deletedImagesArray, deletedImage]);

      return {
        ...prevFormData,
        photos: updatedGallery
      };
    });
  };

  useEffect(() => {
    if (type !== 'Admin') {
      if (user_data?.plan_id?.length === 0) {
        setNoPlan(true)
      }
    }
  }, [user_data])

  const editPhotosFunc = () => {
    return (
      <div style={{ ...user_album_div, marginTop: '3%' }}>
        {formData?.photos?.map((item, i) => {
          return (
            <>
              <div style={{ marginBottom: '2%', cursor: "pointer", position: 'relative' }}>
                <div
                  style={{
                    position: "absolute",
                    top: '5%',
                    left: '5%',
                    background: app_color,
                    borderRadius: 5,
                  }}
                >
                  <ClearOutlinedIcon
                    style={{
                      color: "white",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRemoveImageExist(item, i)}
                  />
                </div>
                <img src={`${serverURLPhotos}/${item}`} style={user_photo} />
              </div>
            </>
          );
        })}
      </div>
    );
  };

  const addPhotos = () => {
    return (
      <>
        <div>
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop file(s) here ...</p>
            ) : (
              <p>Drag and drop file(s) here, or click to select files</p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              gap: 0,
              marginTop: "3%",
              flexWrap: "wrap",
            }}
          >
            {selectedImages?.length > 0 &&
              selectedImages?.map((image, index) => {
                // Add a check for image type and existence
                if (image instanceof File || image instanceof Blob) {
                  return (
                    <div key={index} style={{ position: "relative", padding: "2%" }}>
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          background: app_color,
                          borderRadius: 5,
                        }}
                      >
                        <ClearOutlinedIcon
                          style={{
                            color: "white",
                            cursor: "pointer",
                          }}
                          onClick={() => handleRemoveImage(image, index)}
                        />
                      </div>
                      <img
                        src={`${URL.createObjectURL(image)}`}
                        style={{
                          width: 180,
                          height: 180,
                          margin: "3%",
                          borderRadius: 7,
                        }}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}


          </div>
        </div>
        <Grid
          item
          md={3}
          style={{
            width: "60%",
            display: "flex",
            gap: "4%",
            margin: 0,
          }}
        >
          <Button
            onClick={handleSubmit}
            disabled={loading}
            variant="contained"
            style={
              loading
                ? { ...button_right_style, ...disabled_btn }
                : button_right_style
            }
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        </Grid>
      </>
    );
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    setLoading(true);
    let formData = new FormData();
    selectedImages?.map((file, i) => {
      formData?.append("photos", file);
    });
    setTimeout(() => {
      handleUploadPhotos(formData);
    }, 1000);
  };

  const handleUploadPhotos = async (formData) => {
    let response = await post_data(
      `user/update-photos/${user?._id}`,
      formData
    );
    if (response?.status) {
      setSelectedImages([])
      setOpenDropzone(false)
      if (type === 'Admin') {
        setUser(response?.data)
        handleCreateActivity('User photos updated', user_data)
      }
      else {
        dispatch(login(response?.data));
      }
      setLoading(false);
      setSuccessStatus(true);
      setSuccessOpen(true);
    } else {
      setSelectedImages([])
      setOpenDropzone(false)
      setLoading(false);
      setSuccessStatus(false);
      setSuccessOpen(false);
    }
  };

  const user_album_div = {
    display: "flex",
    gap: "2%",
    padding: "1%",
    flexWrap: "wrap",
  };

  const user_photo = {
    height: 200,
    width: 200,
    borderRadius: 10,
  };

  const handlePhotoClick = (item) => {
    if ((screen === 'Match' || screen === 'Sent' || screen === 'Interest Sent' || screen === 'Interest Received' || screen === 'Received') && noPlan) {
    }
    else {
      setPhotoModal(true)
      setSelectedPhoto(`${serverURLPhotos}/${item}`)
    }
  }

  const displayPhotos = () => {
    return (
      <div style={user_album_div}>
        {user?.photos?.map((item, i) => {
          return (
            <>
              <div style={{ marginBottom: '2%', cursor: "pointer" }} onClick={() => handlePhotoClick(item)}>
                <img src={`${serverURLPhotos}/${item}`} style={user_photo}
                  className={(screen === 'Match' || screen === 'Sent' || screen === 'Interest Sent' || screen === 'Interest Received' || screen === 'Received') && noPlan ? "blur-photos" : ""}
                />
              </div>
            </>
          );
        })}
      </div>
    );
  };

  const handleResetPicture = () => {
    setFormData({ ...formData, photos: user?.photos })
    setShowDisplayPhotos(true)
    setEditPhotos(false)
    setBtnStatus(false)
  }

  const handleUpdatePhotos = () => {
    setSureModal(true)
  }

  const handleConfirm = async () => {
    let payload = { photos: deletedPhotos }
    const response = await post_data(`user/delete-user-photos/${user?._id}`, payload)
    if (response?.status) {
      if (type === 'Admin') {
        setUser(response?.data)
        handleCreateActivity('User photos deleted', user_data)
      }
      else {
        dispatch(login(response?.data));
      }
      setSureModal(false)
      setSuccessStatus(true);
      setSuccessOpen(true);
      setLoading2(false)
      setEditPhotos(false)
      setShowDisplayPhotos(true)
    }
    else {
      setLoading2(false)
      setSureModal(false)
      setShowDisplayPhotos(true)
      setEditPhotos(false)
      setSuccessStatus(false);
      setSuccessOpen(true);
    }
  }

  return (
    <>
      <Grid container spacing={0}>
        {screen !== "Match" &&
          screen !== "Sent" &&
          screen !== "Received" &&
          screen !== "Interest Sent" &&
          screen !== "Interest Received" &&
          openDropzone && (
            <Grid item md={12} style={{ marginTop: "2%" }}>
              {addPhotos()}
            </Grid>
          )}

        <Grid item md={12}>
          {user?.photos?.length === 0 ? (
            <>
              <EmptyPage title="No Photos uploaded yet..." />
            </>
          ) : (
            <>
              {
                showDisplayPhotos && (
                  <div style={{ marginTop: "3%" }}>{displayPhotos()}</div>
                )
              }
            </>
          )}
        </Grid>
      </Grid>

      {
        editPhotos && (
          <>
            <Grid item md={12}>
              {editPhotosFunc()}
            </Grid>
            {
              btnStatus && (
                <Grid item md={3} style={{
                  width: '60%',
                  display: 'flex',
                  gap: '4%',
                  margin: '3% auto 0 auto',
                }}>
                  <Button variant='contained' style={button_left_style} onClick={handleResetPicture}>
                    Reset
                  </Button>

                  <Button
                    onClick={handleUpdatePhotos}
                    variant='contained'
                    style={button_right_style}
                  >
                    Save
                  </Button>
                </Grid>
              )
            }
          </>
        )
      }

      <SuccessModal
        status={successStatus}
        open={successOpen}
        setOpen={setSuccessOpen}
        title={
          successStatus
            ? `Photos Updated successfully!`
            : "Something went wrong..."
        }
      />

      <AreYouSureModal
        setLoading={setLoading2}
        loading={loading2}
        confirmFunc={handleConfirm}
        open={sureModal}
        setOpen={setSureModal}
        title='Are you Sure, you want to Delete ?'
        image='/images/modal-img-delete.svg'
        successOpen={successOpen}
        setSuccessOpen={setSuccessOpen}
      />

      <ProfilePictureModal open={photoModal} setOpen={setPhotoModal}
        image={selectedPhoto} />
    </>
  );
}
