import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal, { modalClasses } from '@mui/material/Modal';
import { useState } from 'react';
import { Grid, colors } from '@mui/material';
import { useDatabaseConstants, button_left_style, button_right_style, disabled_btn, secondaryDarkColor } from '../../../Constants/globalConstants';
import { post_data } from '../../../Api';
import { useSelector } from 'react-redux';
import { MdDeleteOutline } from "react-icons/md";
import { DarkModeContext } from './DarkModeContext';

export default function ReviewModal({
    open,
    setOpen,
    successOpen,
    setSuccessOpen,
    successStatus,
    fetchAllReviews,
    fetchUserReview,
    setSuccessStatus,
    formData,
    setFormData,
    modalType,
    setModalType,
    setSureModal
}) {

    const handleClose = () => setOpen(false);
    const { app_color } = useDatabaseConstants()
    const [selectedRating, setSelectedRating] = useState('')
    const [selectedHeard, setSelectedHeard] = useState('')
    const [loading, setLoading] = useState(false)
    const { user_data } = useSelector(state => state.user);
    const { darkMode } = React.useContext(DarkModeContext);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 650,
        bgcolor: darkMode ? secondaryDarkColor : 'white',
        boxShadow: 24,
        borderRadius: 4,
        padding: '3%'
    };

    const modal_title = {
        textAlign: 'left',
        width: '80%',
        fontSize: 24,
        fontWeight: 500,
        color: app_color
    }

    const rating_div = {
        display: 'flex',
        gap: '2.5%',
        flexWrap: 'wrap',
        justifyContent: 'left',
        marginTop: '3%',
        width: '100%'
    }

    const rating_arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    const heard_arr = [
        'Social Media',
        'Google',
        'YouTube',
        'Word of Mouth'
    ]

    const rating_box = {
        height: 40,
        width: 40,
        background: '#EEE9E3',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: 40
    }

    const heard_box = {
        height: 40,
        padding: '1% 4%',
        textAlign: 'center',
        background: '#EEE9E3',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: 20
    }

    const rating_txt = {
        fontSize: 15,
        fontWeight: 500
    }

    const handleRatingClick = (index) => {
        setSelectedRating(index)
        setFormData({ ...formData, rating: index + 1 })
    }

    const handleHeardClick = (item, index) => {
        setSelectedHeard(index)
        setFormData({ ...formData, heard: item })
    }

    const handleInputChange = (e) => {
        const { name, value } = e?.target;
        setFormData({ ...formData, [name]: value });
    }

    const modal_title_2 = { fontSize: 18, color: 'black' }

    const rating = () => {
        return (
            <>
                <dv style={rating_div}>
                    {
                        rating_arr?.map((item, i) => {
                            return (
                                <div style={{ width: 40, height: 40 }} onClick={() => handleRatingClick(i)}>
                                    <div style={{
                                        ...rating_box, background: selectedRating === i ? app_color : '#EEE9E3',
                                        color: selectedRating === i ? 'white' : app_color
                                    }}
                                    >
                                        <p style={rating_txt}>{item}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </dv >
            </>
        )
    }

    const heard = () => {
        return (
            <>
                <dv style={rating_div}>
                    {
                        heard_arr?.map((item, i) => {
                            return (
                                <div style={{
                                    ...heard_box, background: selectedHeard === i ? app_color : '#EEE9E3',
                                    color: selectedHeard === i ? 'white' : app_color
                                }}
                                    onClick={() => handleHeardClick(item, i)}
                                >
                                    <p style={rating_txt}>{item}</p>
                                </div>
                            )
                        })
                    }
                </dv>
            </>
        )
    }

    const handleSubmitReview = () => {
        setLoading(true)
        setTimeout(async () => {
            let payload = { ...formData, user_id: user_data?._id }
            let response

            if (modalType === 'Edit') {
                response = await post_data(`review/update-review/${formData?._id}`, formData)
            }
            else {
                delete payload?._id
                response = await post_data('review/create-review', payload)
            }

            if (response?.status) {
                setOpen(false)
                setLoading(false)
                fetchAllReviews()
                fetchUserReview()
                setSuccessOpen(true)
                setSuccessStatus(true)
            }
            else {
                setLoading(false)
                setOpen(false)
                setSuccessOpen(true)
                setSuccessStatus(false)
            }
        }, 1000);
    }

    React.useEffect(() => {
        setSelectedHeard('')
        setSelectedRating('')
    }, [open])

    const handleLeftBtn = () => {
        if (modalType == 'Edit') {
            setOpen(false)
            setSureModal(true)
        }
        else {
            handleClose()
        }
        setModalType('Delete')
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <div onClick={handleClose} style={{
                        position: 'absolute',
                        right: '3%',
                        top: '3%',
                        cursor: 'pointer',
                    }}
                    >
                        <img src='/images/modal-close-icon.svg' style={{ width: '100%', height: '100%' }} />
                    </div>
                    <Grid container spacing={0}>

                        <Grid item md={12} style={{
                            display: 'flex',
                            justifyContent: 'left',
                            flexDirection: 'column',
                        }}>
                            <h2 style={modal_title}>
                                {modalType === 'Edit' ? 'Edit your' : 'Write a'} Review
                            </h2>
                            <p className='about-para' style={{ textAlign: 'left' }}>We value your options, take a time to evaluate us.</p>

                            <h2 style={{ ...modal_title, ...modal_title_2, marginTop: '5%', color: darkMode ? 'white' : 'black' }}>
                                How satisfied were you when using our playform.
                            </h2>
                            {rating()}

                            <h2 style={{ ...modal_title, ...modal_title_2, marginTop: '8%', color: darkMode ? 'white' : 'black' }}>
                                What are you thinking about us?
                            </h2>

                            <textarea
                                style={{ height: 100, marginTop: '2%' }}
                                className='from-textarea'
                                placeholder="Enter your message here..."
                                type="text"
                                name="review_text"
                                value={formData?.review_text}
                                onChange={handleInputChange}
                            />

                            <h2 style={{ ...modal_title, ...modal_title_2, marginTop: '8%', color: darkMode ? 'white' : 'black' }}>
                                How did you heard about us?
                            </h2>
                            {heard()}

                        </Grid>

                        <Grid item md={7} style={{
                            width: '60%',
                            display: 'flex',
                            gap: '4%',
                            margin: '3% auto 0 auto',
                            marginTop: '8%'
                        }}>
                            <Button startIcon={modalType === 'Edit' ? <MdDeleteOutline /> : ''}
                                onClick={handleLeftBtn} variant='contained'
                                style={modalType === 'Edit' ? { ...button_left_style, color: '#DC3545', borderColor: '#DC3545' } : button_left_style}
                            >
                                {modalType === 'Edit' ? 'Delete' : 'Close'}
                            </Button>

                            <Button
                                onClick={handleSubmitReview}
                                disabled={loading} variant='contained'
                                style={loading ? { ...button_right_style, ...disabled_btn } : button_right_style}
                            >
                                {loading ? 'Saving...' : 'Save'}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
