import React from "react";

const DiscountCoupon = ({ code = "DS10" }) => {
  return (
    <div className="max-w-sm mx-auto my-4 p-6 bg-white rounded-lg border ">
      <p className="text-lg font-semibold text-[#9E0050] mb-2">
        Special Offer!
      </p>
      <p className="text-gray-600">
        Use coupon code{" "}
        <span className="font-bold text-white bg-[#9E0050] px-2 py-1 rounded">
          {code}
        </span>
      </p>
      <p className="text-gray-600 mt-2">
        at checkout for an instant{" "}
        <span className="font-bold">10% Discount</span>
      </p>
    </div>
  );
};

export default DiscountCoupon;
