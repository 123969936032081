import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';
import { DarkModeContext } from '../DarkModeContext';
import { secondaryDarkColor } from '../../../../Constants/globalConstants';

export default function SettingsSkeleton() {

    const { darkMode } = React.useContext(DarkModeContext);

    const SkeletonBlock = () => (
        <Grid item xs={4}>
            <Stack spacing={1}>
                <Skeleton variant="rounded" width={'100%'} height={200} style={{ background: darkMode ? secondaryDarkColor : '' }} />
            </Stack>
        </Grid>
    );

    return (
        <Grid container spacing={3}>
            {Array.from({ length: 3 }).map((_, index) => (
                <SkeletonBlock key={index} style={{ background: darkMode ? secondaryDarkColor : '' }} />
            ))}
        </Grid>
    );
}
