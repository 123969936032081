// import React from "react";
// import { Button, Grid } from "@mui/material";
// import Heading from "./Heading";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
// import { useDatabaseConstants } from "../../Constants/globalConstants";

// export default function CompanyFeatures() {

//   const theme = useTheme();
//   const matches_sm = useMediaQuery(theme.breakpoints.down("sm"));
//   const matches_md = useMediaQuery(theme.breakpoints.down("md"));
//   const { app_color, app_second_color } = useDatabaseConstants();

//   const features = [
//     {
//       icon: "settings-icon",
//       title: "Access to the Filtered member",
//       para: "Lorem ipsum dolor sit amet consectetur. Eu faucibus purus facilisi neque eget laoreet sit in in. Nibh integer varius.",
//     },
//     {
//       icon: "heart-icon",
//       title: "Creating Bonds",
//       para: "Lorem ipsum dolor sit amet consectetur. Eu faucibus purus facilisi neque eget laoreet sit in in. Nibh integer varius.",
//     },
//     {
//       icon: "man-icon",
//       title: "Relationship Manager",
//       para: "Lorem ipsum dolor sit amet consectetur. Eu faucibus purus facilisi neque eget laoreet sit in in. Nibh integer varius.",
//     },
//     {
//       icon: "heart-2-icon",
//       title: "Complimentary grooming",
//       para: "Lorem ipsum dolor sit amet consectetur. Eu faucibus purus facilisi neque eget laoreet sit in in. Nibh integer varius.",
//     },
//   ];

//   const featuresSection = () => {
//     return (
//       <div className="flex justify-between items-center flex-wrap gap-5">
//         {features.map((item, i) => (
//           <div
//             key={i}
//             style={{
//               width: matches_sm ? '100%' : matches_md ? '45%' : '23%',
//               padding: matches_sm ? '6%' : matches_md ? '3%' : "2%",
//               height: matches_sm ? 240 : 270,
//               borderRadius: 15,
//               boxShadow: "0 4px 22px 0 #6F6F6F33",
//               // boxShadow: "3px 3px 0 0 black",
//               // border: '2px solid black',
//               background: "white",
//             }}
//           >
//             <img
//               src={`/images/${item.icon}.svg`}
//               style={{ width: 55, height: 55 }}
//             />
//             <h3 className="features-div-title">{item.title}</h3>
//             <p className="features-div-para">{item.para}</p>
//           </div>
//         ))}
//       </div>
//     );
//   };

//   return (
//     <>
//       <div className="features-section">
//         <center>
//           <Heading title="Why 'DilSey'" />
//         </center>

//         <div
//           style={{ position: "relative", padding: matches_md ? '2% 3%' : "2% 6%", marginTop: matches_md ? '6%' : "2%" }}
//         >
//           {featuresSection()}
//         </div>
//       </div>
//     </>
//   );
// }


import React from 'react';
import { Box, Container, Grid, Typography, Card, CardContent, useMediaQuery } from '@mui/material';
import { useDatabaseConstants } from "../../Constants/globalConstants";
import Heading from './Heading';

const CompanyFeatures = () => {

  const isDesktop = useMediaQuery('(min-width: 768px)');

  const features = [
    {
      title: "Personalized Matchmaking",
      para: "Our algorithm and matchmakers craft ideal pairings, perfectly tailored to your preferences and lifestyle.",
    },
    {
      title: "Verified & Genuine Profiles",
      para: "We verify each profile for authenticity, ensuring genuine connections and prioritizing your safety.",
    },
    {
      title: "User-Friendly Interface",
      para: "Our platform offers easy navigation, making your matchmaking experience smooth and enjoyable.",
    },
    {
      title: "Comprehensive Support",
      para: "Our support team assists you at every step, from profile setup to finding meaningful connections.",
    },
  ];

  const { app_color } = useDatabaseConstants()

  return (
    <Box
      className='whyUsBg'
      sx={{
        padding: (theme) => theme.spacing(8, 0),
      }}
    >
      <Container>
        <Heading title={'Why DilSey'} />
        <Grid container spacing={4} style={{ marginTop: 1 }}>
          <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: isDesktop ? 'left' : 'center' }}>
            <img
              src="/images/why-us-img.svg"
              alt="Why Choose Us"
              style={{
                width: '80%',
                borderRadius: '16px',
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              {features?.map((item, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Card
                    sx={{
                      textAlign: 'center',
                      padding: (theme) => theme.spacing(1),
                      backgroundColor: index === 0 ? app_color : '#FFFFFF',
                      boxShadow: 'none',
                      borderRadius: 2,
                      height: 280,
                      border: '1px solid gainsboro',
                      color: index === 0 ? '#FFFFFF' : '#000000',
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          fontSize: '2rem',
                          marginBottom: (theme) => theme.spacing(2),
                        }}
                      >
                        <img src='/images/heart-img.svg' />
                      </div>
                      <Typography
                        sx={{
                          fontSize: '1.25rem',
                          fontWeight: '600',
                          textAlign: 'left'
                        }}
                      >
                        {item?.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '1rem',
                          textAlign: 'left'
                        }}
                      >
                        {item?.para}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CompanyFeatures;
