import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/user-slice";
import subAdminReducer from "./slices/sub-admin-slice";
import coupanSlice from "./slices/coupan-slice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    sub_admin: subAdminReducer,
    coupanSlice: coupanSlice,
  },
});
