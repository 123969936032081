import HeroSection from "../Components/HeroSection";
import CompanyFeatures from "../Components/CompanyFeatures";
import Process from "../Components/Process";
import Testimonials from "../Components/Testimonials";
import AppComponent from "../Components/AppComponent";
import Tab from "../Components/Tab";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import MatchingFeatures from "../Components/MatchingFeatures";
import AboutCompanySection from "../Components/AboutCompanySection";
import { useEffect, useState } from "react";
import AppModal from "../Components/AppModal";
import Cta from "../Components/Cta";
import BottomBar from "../Components/BottomBar";
import Faq from "../Components/Faq";

export default function Home() {

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setOpen(true)
    }, 10000);
  }, [])

  return (
    <>
      <Header />
      <HeroSection />
      <CompanyFeatures />
      <Process />
      <MatchingFeatures />
      {/* <Testimonials /> */}
      <AppComponent screen={'home'} />
      <Faq />
      <Footer />
      <BottomBar />
      {/* <AppModal open={open} setOpen={setOpen} /> */}
    </>
  );
}
