import React from "react";
import { darkPrimaryColor, secondaryDarkColor } from "../../../Constants/globalConstants";
import { DarkModeContext } from "../../UserDshboard/Components/DarkModeContext";

export default function SerialNo({ index }) {

    const { darkMode } = React.useContext(DarkModeContext);

    return (
        <>
            <div style={{
                width: 30, height: 30, display: "flex",
                justifyContent: 'center', alignItems: 'center',
                background: darkMode ? darkPrimaryColor : '#F1F1F2', borderRadius: 7, padding: '1%'
            }}>
                <p style={{ fontSize: 14 }}>{index + 1}</p>
            </div>
        </>
    )
}