import React from "react";
import { Switch } from "@headlessui/react";

export default function CustomDeactivateToggle({ is_active = true, onChange }) {
  return (
    <Switch
      checked={is_active}
      onChange={onChange}
      className={`relative inline-flex h-6 w-11 items-center rounded-full ${
        is_active ? "bg-pink-700" : "bg-gray-200"
      }`}
    >
      <span
        className={`${
          is_active ? "translate-x-6" : "translate-x-1"
        } inline-block h-4 w-4 transform rounded-full transition bg-white`}
      />
    </Switch>
  );
}
