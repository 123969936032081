import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import {
  button_right_style,
  useDatabaseConstants,
} from "../../../Constants/globalConstants";
import { saveAs } from "file-saver"; // Ensure file-saver is installed
import { post_data } from "../../../Api"; // Ensure this is the correct import for your API function

const ExportModal = ({ isOpen, onRequestClose }) => {
  const { app_color } = useDatabaseConstants();
  const [selectedOption, setSelectedOption] = useState(1); // Default value should be numeric
  const [data, setData] = useState([]);

  const fetchData = async (timeRange) => {
    try {
      const response = await post_data("user/get-account-plan-controller", { days: timeRange });
      setData(response?.data?.filteredUsers); // Store the fetched data in the state
    } catch (err) {
      // console.error(err);
    }
  };

  useEffect(() => {
    fetchData(selectedOption);
  }, [selectedOption]); // Fetch data when selectedOption changes

  const convertToCSV = (data) => {
    if (data.length === 0) return "";

    const fields = ["first_name", "last_name", "contact_number", "createdAt", "basic_details.gender", "contact_details.city"];
    const header = fields.join(",");

    const getFieldValue = (row, field) => {
      return field.split('.').reduce((acc, part) => acc && acc[part], row);
    };

    const rows = data
      .map((row) => fields.map((field) => getFieldValue(row, field)).join(","))
      .join("\n");

    return `${header}\n${rows}`;
  };


  const handleExport = () => {
    const csvData = convertToCSV(data);
    if (csvData) {
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, "exported_data.csv");
    }
    onRequestClose();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={isOpen}
      onClose={onRequestClose}
      aria-labelledby="export-modal-title"
    >
      <Box sx={style}>
        <Typography id="export-modal-title" variant="h6" component="h2">
          Export Data
        </Typography>
        <Select
          value={selectedOption}
          onChange={(e) => setSelectedOption(Number(e.target.value))}
          fullWidth
          sx={{ mt: 2, mb: 2 }}
        >
          <MenuItem value="">Select a time range</MenuItem>
          <MenuItem value={1}>Today</MenuItem>
          <MenuItem value={7}>Last 7 days</MenuItem>
          <MenuItem value={30}>Last 30 days</MenuItem>
          <MenuItem value={0}>All Time</MenuItem>
        </Select>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button onClick={onRequestClose} sx={{ mr: 2, color: app_color }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleExport}
            style={{ ...button_right_style, width: "40%" }}
          >
            Download
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ExportModal;
