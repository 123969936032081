import React from "react"
import InvoicesTable from "./InvoicesTable"
import { userInvoicesTableHeader } from "../../../Constants/globalConstants"

export default function BillingInvoice({
    invoices,
    setInvoices,
    setCurrentPage,
    currentPage,
    itemsPerPage
}) {

    return (
        <>
            <div>
                <InvoicesTable
                    // data={invoices}
                    data={invoices?.slice(
                        (currentPage - 1) * itemsPerPage,
                        currentPage * itemsPerPage
                    )}
                    count={invoices?.length}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    setData={setInvoices}
                    type='Users'
                    tableHeader={userInvoicesTableHeader}
                />
            </div>
        </>
    )
}