import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { DarkModeContext } from '../DarkModeContext';
import { secondaryDarkColor } from '../../../../Constants/globalConstants';

export default function StatsCardSkeleton() {

    const { darkMode } = React.useContext(DarkModeContext);

    return (
        <Stack spacing={1}>
            <Skeleton variant="rounded" width={'100%'} height={90} style={{ background: darkMode ? secondaryDarkColor : '' }} />
        </Stack>
    );
}
