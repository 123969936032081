import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import { formatDate, useDatabaseConstants, edit_div, secondaryDarkColor } from '../../../Constants/globalConstants';
import { post_data, serverURLProfilePicture } from '../../../Api';
import Rating from '@mui/material/Rating';
import { useSelector } from 'react-redux';
import AreYouSureModal from './AreYouSureModal';
import { DarkModeContext } from './DarkModeContext';

function AppAllReviews({
    allReviews,
    open,
    modalType,
    setModalType,
    setOpen,
    formData,
    setFormData,
    setSuccessOpen,
    successOpen,
    setSureModal,
    sureModal,
    fetchAllReviews,
    setSuccessStatus,
    fetchUserReview
}) {

    const { user_data } = useSelector(state => state.user);
    const [loading, setLoading] = useState(false)
    const { darkMode } = React.useContext(DarkModeContext);

    const handleEdit = (item) => {
        setModalType('Edit')
        setFormData({
            rating: item?.rating,
            review_text: item?.review_text,
            heard: item?.heard,
            _id: item?._id
        })
        setOpen(true)
    }

    const handleConfirmFunc = () => {
        setTimeout(async () => {
            let response = await post_data(`review/delete-review/${formData?._id}`)
            if (response?.status) {
                setLoading(false)
                setSureModal(false)
                fetchAllReviews()
                setSuccessOpen(true)
                setSuccessStatus(true)
                fetchUserReview()
                setFormData({
                    rating: '',
                    review_text: '',
                    heard: '',
                })
            }
            else {
                setFormData({
                    rating: '',
                    review_text: '',
                    heard: '',
                })
                setSureModal(false)
                setLoading(false)
                setOpen(false)
                setSuccessOpen(true)
                setSuccessStatus(false)
            }
        }, 1000);
    }

    const Review = ({ item, name, spend, totalReviews, date, rating, reviewText, avatarUrl, user_id, user_data, gender }) => {
        let profile_pic = gender === 'Male' ? 'user-avatar-male' : 'user-avatar-female'
        return (
            <>
                <div className="review" style={{ background: darkMode ? secondaryDarkColor : 'white' }}>
                    {
                        user_id === user_data?._id && (
                            <div style={{ ...edit_div, right: '4%', top: '4%' }}>
                                <img src="/images/edit-icon.svg" style={{ width: '80%', height: '80%' }}
                                    onClick={() => handleEdit(item)}
                                />
                            </div>
                        )
                    }
                    <div className="review-header">
                        <img src={avatarUrl ? `${serverURLProfilePicture}/${avatarUrl}` : `/images/${profile_pic}.jpg`} alt={`${name}'s avatar`} className="avatar" />
                        <div className="review-info">
                            <h2>{name}</h2>
                        </div>
                    </div>
                    <div className="review-body">
                        <div className="rating">
                            <Rating name="half-rating-read" defaultValue={rating} precision={0.5} readOnly />
                            <span className="date">{date}</span>
                        </div>

                        <p className="review-text">{reviewText}</p>
                    </div>
                </div>
            </>
        );
    };


    return (
        <>
            <Grid container spacing={2} className="all-reviews-section" style={{ marginTop: '3%' }}>
                {
                    allReviews?.slice(0, 20)?.map((item, i) => {
                        return (
                            <Grid item md={4}>
                                <div>
                                    <Review
                                        item={item}
                                        name={item?.user_id?.first_name + ' ' + item?.user_id?.last_name}
                                        date={formatDate(item?.review_date)}
                                        rating={item?.rating / 2}
                                        reviewText={item?.review_text}
                                        avatarUrl={item?.user_id?.profile_picture}
                                        user_id={item?.user_id?._id}
                                        user_data={user_data}
                                        gender={item?.user_id?.basic_details?.gender}
                                    />
                                </div>
                            </Grid>
                        )
                    })
                }

                <AreYouSureModal
                    setLoading={setLoading}
                    loading={loading}
                    confirmFunc={handleConfirmFunc}
                    open={sureModal}
                    setOpen={setSureModal}
                    title={'Are you Sure, you want to Delete ?'}
                    image={'/images/modal-img-delete.svg'}
                    successOpen={successOpen}
                    setSuccessOpen={setSuccessOpen}
                />
            </Grid>
            <center><p style={{ marginTop: '3%', opacity: '80%' }}>And many Reviews...</p></center>
        </>
    );
}

export default AppAllReviews;
