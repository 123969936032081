import React from 'react';
import { Box, Container, Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Heading from './Heading';

const Faq = () => {

    const faqs = [
        {
            title: "Is DilSey safe to use?",
            para: "Yes, DilSey prioritizes user safety. We have implemented security measures to protect your information, and our team monitors activity to ensure a positive experience for everyone.",
        },
        {
            title: "How does DilSey ensure profile quality?",
            para: "We have a system that allows the users to verify their profiles using Govt Id like Aadhar, Pan etc and thus getting a Verified badge, this ensure the genuinity of a profile.",
        },
        {
            title: "What makes DilSey different from other matrimony apps?",
            para: "DilSey goes beyond simple swiping. We focus on creating genuine connections based on shared values, interests, and lifestyle preferences. We also offer personalized support and a safe space for meaningful conversation.",
        },
        {
            title: "Is DilSey for everyone?",
            para: "DilSey welcomes individuals of all backgrounds and cultural preferences seeking long-term love. Our diverse community allows you to find a compatible match who shares your values.",
        },

        {
            title: "How does DilSey handle user data?",
            para: "DilSey follows strict data privacy regulations to ensure that user data is handled securely and responsibly.",
        },
        {
            title: "What features does DilSey offer for free?",
            para: "DilSey offers some free features including profile creation, browsing matches, and chat communication with users.",
        },
        {
            title: "How can I contact DilSey support?",
            para: "You can contact DilSey support via email at info@dilsey.in.",
        },
        {
            title: "Does DilSey offer any premium features?",
            para: "Yes, DilSey offers premium features such as advanced matchmaking algorithms, virtual AI assistant support.",
        },
    ];

    const leftFaqs = faqs.slice(0, 4);
    const rightFaqs = faqs.slice(4);

    return (
        <Box
            sx={{
                padding: (theme) => theme.spacing(14, 0),
                textAlign: 'center',
                position: 'relative',
            }}
        >
            <img src='/images/blur-div.svg' style={{ position: 'absolute', left: 0, bottom: 0, zIndex: -1 }} />

            <Container>
                <Heading title={'Frequenlty Asked Questions'} />
                <Grid container spacing={2} style={{ marginTop: '2%' }}>
                    <Grid item xs={12} md={6}>
                        {leftFaqs?.map((item, index) => (
                            <Accordion
                                key={index}
                                sx={{
                                    marginBottom: (theme) => theme.spacing(3),
                                    background: 'transparent',
                                    boxShadow: 'none'
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index + 1}a-content`}
                                    id={`panel${index + 1}a-header`}
                                >
                                    <Typography style={{ color: 'black', fontWeight: 600, textAlign: 'left' }}>{item?.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography style={{ textAlign: 'left' }}>{item?.para}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {rightFaqs?.map((item, index) => (
                            <Accordion
                                key={index + 4} // Ensure unique key for each FAQ
                                sx={{
                                    marginBottom: (theme) => theme.spacing(3),
                                    background: 'transparent',
                                    boxShadow: 'none'
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index + 5}a-content`}
                                    id={`panel${index + 5}a-header`}
                                >
                                    <Typography style={{ color: 'black', fontWeight: 600, textAlign: 'left' }}>{item?.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography style={{ textAlign: 'left' }}>{item?.para}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>
                </Grid>
            </Container>
            <img
                src="/images/petal-1.svg"
                alt="Right decorative element"
                style={{
                    position: 'absolute',
                    right: 0,
                    top: '20%',
                    opacity: '30%',
                    width: 'auto',
                    height: '60%',
                    zIndex: -1,
                }}
            />
        </Box>
    );
};

export default Faq;
