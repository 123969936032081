import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { button_right_style, disabled_btn } from '../../Constants/globalConstants';
import OtpComponent from './OtpComponent';
import { post_data } from '../../Api';
import { useSelector } from 'react-redux';

export default function VerifyEmailModal({
    open,
    setOpen,
    setSuccessOpen,
    setSuccessStatus
}) {

    const { user_data } = useSelector(state => state.user);
    const [otpBox, setOtpBox] = React.useState(false)
    const [verifyModal, setVerifyModal] = React.useState(true)
    const [email, setEmail] = React.useState(user_data?.email)
    const [loading, setLoading] = React.useState(false)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 550,
        bgcolor: 'white',
        boxShadow: 24,
        border: '1px solid white',
        borderRadius: 7,
        padding: '4%',
    };

    const app_modal_styles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }

    const handleClose = () => setOpen(false);

    const handleBtnClick = () => {
        setLoading(true)
        setTimeout(async () => {
            const response = await post_data('user/send-verification-email', { email: email })
            if (response?.status) {
                setLoading(false)
                setVerifyModal(false)
                setOtpBox(true)
            }
            else {
                setOpen(false)
                setLoading(false)
                setSuccessStatus(false)
                setSuccessOpen(true)
            }
        }, 1000);
    }

    const verifyModalFunc = () => {
        return (
            <>
                <div style={app_modal_styles}>
                    <img src='/images/modal-img-verify.svg' style={{ width: 320, height: 320 }} />
                    <h2 className='global-h2' style={{ textAlign: 'center', fontSize: 33 }}>Verify your Email</h2>
                    <p className='about-para' style={{ textAlign: 'center', margin: '2% auto' }}>
                        Verify your email, so that others can see that your profile has verified email.
                    </p>
                    <Button
                        disabled={loading}
                        onClick={handleBtnClick}
                        variant='contained'
                        style={{
                            ...button_right_style,
                            width: '38%',
                            marginTop: '3%',
                            ...(loading && disabled_btn)
                        }}
                    >
                        {loading ? 'Sending OTP...' : 'Verify'}
                    </Button>

                </div >
            </>
        )
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <div onClick={handleClose} style={{
                        position: 'absolute',
                        right: '3%',
                        top: '3%',
                        cursor: 'pointer',
                    }}
                    >
                        <img src='/images/modal-close-icon.svg' style={{ width: '100%', height: '100%' }} />
                    </div>
                    {
                        verifyModal && (
                            <>
                                {verifyModalFunc()}
                            </>
                        )
                    }
                    {
                        otpBox && (
                            <>
                                <OtpComponent
                                    setOtpBox={setOtpBox}
                                    setVerifyModal={setVerifyModal}
                                    setOpen={setOpen}
                                    resendOtpFunc={handleBtnClick}
                                    email={email}
                                    type={'email-verify'}
                                />
                            </>
                        )
                    }
                </Box>
            </Modal>
        </div>
    );
}
