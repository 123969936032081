import React from "react"
import { IoWarningOutline } from "react-icons/io5";

export default function ProfileLabel({ width, user }) {

    let is_verified = user?.is_pan_verified && user?.is_dl_verified && user?.is_aadhar_verified

    const profile_label = () => {
        return (
            <>
                <div style={{
                    width: is_verified ? width : width + 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: is_verified ? '#50CD8933' : '#fff5d9',
                    borderRadius: 8,
                    padding: '4%',
                    gap: '3%'
                }}>
                    {
                        is_verified ?
                            <img src="/images/verified-check-v2.svg" style={{ width: 18, height: 18 }} />
                            :
                            <IoWarningOutline
                                style={{
                                    width: 18,
                                    height: 18,
                                    color: '#ffc71c',
                                    cursor: "pointer",
                                }}
                            />
                    }
                    <p style={{
                        color: is_verified ? '#50CD89' : '#ffc71c',
                        fontSize: 14
                    }}>
                        {is_verified ? 'Verified' : 'Unverified'}
                    </p>
                </div>
            </>
        )
    }


    return (
        <>
            <div>{profile_label()}</div>
        </>
    )
}