import React from "react"
import { useState, useEffect } from "react"
import { Grid, Button, Pagination, Typography } from "@mui/material"
import DashboardHeading from "../Components/DashboardHeading"
import UserProfileCard from "../Components/UserProfileCard"
import { extractNumber, getAcceptedStatus, getDeclinedStatus, getFilteredInterestStats, getPendingStatus, useDatabaseConstants } from "../../../Constants/globalConstants"
import UserStatsCard from "../Components/UserStatsCard";
import { get_data, post_data } from "../../../Api"
import UserProfileCardSkeleton from "../Components/skeleton/UserProfileCardSkeleton"
import StatsCardSkeleton from "../Components/skeleton/StatsCardSkeleton"
import { useNavigate } from "react-router-dom"
import EmptyPage from "../Components/EmptyPage"
import { useDispatch, useSelector } from "react-redux"
import { login } from "../../../redux/slices/user-slice"
import DefaultModal from "../Components/DefaultModal"
import BackButton from "../Components/BackButton"
import { DarkModeContext } from "../Components/DarkModeContext"

export default function InterestSent({ permissions, profileLoading, setProfileLoading, setProfileName }) {

  const interest_sent_page = {
    width: '100%',
  }

  const navigate = useNavigate()
  const { app_color } = useDatabaseConstants()
  const [loading, setLoading] = useState(true)
  const [allUsers, setAllUsers] = useState([])
  const { isLoggedIn, user_data } = useSelector(state => state.user);
  const [pendingStatus, setPendingStatus] = useState([])
  const [acceptedStatus, setAcceptedStatus] = useState([])
  const [declinedStatus, setDeclinedStatus] = useState([])
  const [selectedCard, setSelectedCard] = useState('')
  const [selectedStats, setSelectedStats] = useState('')
  const [filteredUsers, setFilteredUsers] = useState([])
  const [open, setOpen] = useState(false)
  const [noPlan, setNoPlan] = useState(false)
  const dispatch = useDispatch()
  const itemsPerPage = 15;
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState('');

  const page_container = {
    width: "100%",
    margin: "2% 0",
  };

  useEffect(() => {
    const fetchAllUsers = async (pageNumber) => {
      const response = await get_data(`user/get-all-interest-sent/${user_data?._id}?page_number=${pageNumber}`)
      if (response?.status) {
        setAllUsers(response?.data?.user)
        getPendingStatus(response?.data?.user, setPendingStatus)
        getAcceptedStatus(response?.data?.user, setAcceptedStatus)
        getDeclinedStatus(response?.data?.user, setDeclinedStatus)
        setLoading(false)
        setTotalPages(response?.data?.totalPages);
        setTotalUsers(response?.data?.totalUsers)
      }
    }
    // fetchAllUsers();
    if (currentPage) {
      fetchAllUsers(currentPage);
    }
  }, [user_data, currentPage]);

  useEffect(() => {
    setFilteredUsers(allUsers);
  }, [allUsers]);

  useEffect(() => {
    if (user_data?.plan_id?.length === 0) {
      setNoPlan(true)
    }
  }, [user_data])

  const startEntry = (currentPage - 1) * itemsPerPage + 1;
  const endEntry = Math.min(currentPage * itemsPerPage, filteredUsers?.length);

  const count = allUsers?.length;
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  const createBrowsingHistory = async (item) => {
    await post_data('browsing-history/create-browsing-history', { user_id: user_data?._id, partner_id: item?._id });
  }

  const navigateToProfile = (item) => {
    navigate(`/dashboard/profile/${item?.dil_sey_id}`, { state: { user_profile_details: item, screen: 'Interest Sent' } });
  }

  const updateBrowsedProfiles = async (item) => {
    const payload = { profile_id: item?._id };
    const response = await post_data(`user/update-browsed-profiles/${user_data?._id}`, payload);
    if (response?.status) {
      dispatch(login(response?.data));
    }
  }

  const profileLoadingFunc = (item) => {
    setProfileLoading(true);
    const profile_name = `${item?.first_name || ''} ${item?.last_name || ''}`.trim();
    if (profile_name) {
      setProfileName(profile_name);
    }
  }

  const handleProfileClick = (item, i) => {

    profileLoadingFunc(item);

    const proceedToProfile = async () => {
      await createBrowsingHistory(item);
      navigateToProfile(item);
      setProfileLoading(false);
    }

    setTimeout(async () => {
      if (noPlan) {
        await proceedToProfile();
      } else if (permissions?.browse_profiles) {
        if (permissions?.browse_profiles === 'Unlimited') {
          await proceedToProfile();
        } else {
          const browsedProfiles = user_data?.browsed_profiles || [];
          const browseLimit = extractNumber(permissions?.browse_profiles);

          if (browsedProfiles?.length === browseLimit && !browsedProfiles?.includes(item?._id)) {
            setOpen(true);
            setProfileLoading(false);
          } else {
            await createBrowsingHistory(item);
            if (!browsedProfiles?.includes(item?._id)) {
              await updateBrowsedProfiles(item);
            }
            navigateToProfile(item);
            setProfileLoading(false);
          }
        }
      } else {
        await proceedToProfile();
      }
    }, 0);
  }

  useEffect(() => {
    if (selectedStats === 'Interest Declined') {
      const filteredInterestSent = getFilteredInterestStats(allUsers, 'declined');
      setFilteredUsers(filteredInterestSent);
    }

    if (selectedStats === 'Interest Sent') {
      setFilteredUsers(allUsers);
    }

    if (selectedStats === 'Interest Accepted') {
      const filteredInterestSent = getFilteredInterestStats(allUsers, 'accepted');
      setFilteredUsers(filteredInterestSent);
    }

    if (selectedStats === 'Interest Pending') {
      const filteredInterestSent = getFilteredInterestStats(allUsers, 'pending');
      setFilteredUsers(filteredInterestSent);
    }
  }, [selectedStats]);

  const displayUsersCard = () => {
    return (
      <>
        {
          filteredUsers?.length === 0 ?
            <>
              <EmptyPage title='No results to display...' />
            </>
            :
            <>
              <Grid container spacing={0}>
                {/* ?.slice(     (currentPage - 1) * itemsPerPage, currentPage * itemsPerPage   )? */}
                {
                  filteredUsers?.map((item, i) => {
                    return (
                      <>
                        <Grid item md={4}>
                          <UserProfileCard
                            permissions={permissions}
                            item={item?.user_id}
                            i={i}
                            screen='Interest Sent'
                            // screen='Sent'
                            handleProfileClick={handleProfileClick}
                            current_user_data={user_data} />
                        </Grid>
                      </>
                    )
                  })
                }
              </Grid>
            </>
        }
      </>
    )
  }

  const interest_sent_stats_items = [
    {
      title: 'Interest Sent',
      number: allUsers?.length
    },
    {
      title: 'Interest Declined',
      number: declinedStatus?.length
    },
    {
      title: 'Interest Accepted',
      number: acceptedStatus?.length
    },
    {
      title: 'Interest Pending',
      number: pendingStatus?.length
    }
  ]

  const interestSentStats = () => {
    return (
      <>
        <Grid container spacing={3} style={{ width: '100%' }}>
          {
            interest_sent_stats_items?.map((item, i) => {
              return (
                <>
                  <Grid item md={3} style={{}}>
                    <UserStatsCard
                      setSelectedStats={setSelectedStats} selectedStats={selectedStats}
                      setSelectedCard={setSelectedCard} selectedCard={selectedCard}
                      title='Interest Sent' item={item} index={i} />
                  </Grid>
                </>
              )
            })
          }
        </Grid>
      </>
    )
  }

  return (
    <>
      <Grid container spacing={0} style={interest_sent_page}>

        <Grid item md={12} style={{ display: "flex", alignItems: 'center', gap: '1%' }}>
          <BackButton />
          <DashboardHeading title='Interest Sent ✅' />
        </Grid>

        <div style={page_container}>
          {
            loading ?
              <>
                <Grid container spacing={3}>

                  <Grid item xs={3}>
                    <StatsCardSkeleton />
                  </Grid>
                  <Grid item xs={3}>
                    <StatsCardSkeleton />
                  </Grid>
                  <Grid item xs={3}>
                    <StatsCardSkeleton />
                  </Grid>
                  <Grid item xs={3}>
                    <StatsCardSkeleton />
                  </Grid>

                  <hr style={{ opacity: '95%', width: '100%', marginTop: '3%' }} />

                  <Grid item xs={4} style={{ marginTop: '1%' }}>
                    <UserProfileCardSkeleton />
                  </Grid>
                  <Grid item xs={4} style={{ marginTop: '1%' }}>
                    <UserProfileCardSkeleton />
                  </Grid>
                  <Grid item xs={4} style={{ marginTop: '1%' }}>
                    <UserProfileCardSkeleton />
                  </Grid>

                </Grid>
              </>
              :
              <>
                {
                  allUsers?.length === 0 ?
                    <>
                      <EmptyPage title='No Interest Sent Yet...' />
                    </>
                    :
                    <>
                      <div style={{ margin: '0 0 3% 0', width: '100%' }}>
                        {interestSentStats()}
                      </div>
                      {displayUsersCard()}
                      <div
                        style={{
                          borderTop: '1px dashed gainsboro',
                          paddingTop: '2%',
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          style={{
                            fontWeight: 500,
                            fontSize: 15,
                            color: 'black'
                          }}
                        >{`Showing ${count} Users`}</Typography>
                        <Pagination
                          count={totalPages}
                          page={currentPage}
                          onChange={handlePageChange}
                          color="primary"
                        />
                      </div>
                      <DefaultModal
                        open={open}
                        setOpen={setOpen}
                        screen={'Match'}
                        limit='exceed'

                        title_left='You have exceed limit!'

                        para_left='Please upgrade your Plan, if you want to view more profiles.'

                        title_right='You have exceed limit!'

                        para_right='Please upgrade your Plan, if you want to view more profiles.'

                        image='modal-img-plans'

                        button_right='Upgrade your Plan'
                      />
                    </>
                }
              </>
          }
        </div>

      </Grid>
    </>
  )
}