import React from "react"
import { Grid, Button } from "@mui/material"
import DashboardHeading from "../Components/DashboardHeading"

export default function AdditionalServices() {

    const services_page = {
        width: '100%',
    }

    return (
        <>
            <Grid container spacing={0} style={services_page}>

                <DashboardHeading title='Additional Services ✨' />

                <Grid item md={2}>
                </Grid>
                <Grid item md={10}>
                </Grid>

            </Grid>
        </>
    )
}