import React from "react";
import { useState, useEffect } from "react";
import { Grid, Button, Pagination, Typography } from "@mui/material";
import { verificationSalesTableHeader } from "../../../Constants/globalConstants";
import { get_data, post_data } from "../../../Api";
import TableSkeleton from "../../UserDshboard/Components/skeleton/TableSkeleton";
import VerificationSalesTable from "../Components/VerificationSalesTable";

export default function VerificationPlanSales() {

    const [tableLoading, setTableLoading] = useState(true);
    const itemsPerPage = 15;
    const [currentPage, setCurrentPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [totalPages, setTotalPages] = useState(null);

    const [sales, setSales] = useState([])
    const [totalSalesCount, setTotalSalesCount] = useState([])

    const fetchAllVerificationSales = async (pageNumber) => {
        const response = await get_data(`verification-plans/get-all-verification-plans?pageNumber=${pageNumber}`)
        if (response?.status) {
            setTableLoading(false);
            setTotalSalesCount(response?.data?.totalSales)
            setTotalPages(response?.data?.totalPages);
            setSales(response?.data?.verificationPlanSales)
        }
        else {
            setTableLoading(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            fetchAllVerificationSales(currentPage);
        }
    }, [currentPage]);

    const permissions_page = {
        width: "100%",
    };

    const page_container = {
        width: "100%",
        margin: "2% 0",
        background: "transparent",
    };

    const handleClick = () => {
        setOpen(true)
    }

    return (
        <>
            <Grid container spacing={0} style={permissions_page}>
                <div style={page_container}>
                    {tableLoading ? (
                        <>
                            <TableSkeleton />
                        </>
                    ) : (
                        <>
                            <VerificationSalesTable
                                title="Verification Plan Sales"
                                data={sales}
                                count={sales?.length}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                setData={setSales}
                                tableHeader={verificationSalesTableHeader}
                                totalPages={totalPages}
                            />
                        </>
                    )}
                </div>
            </Grid>
        </>
    );
}
