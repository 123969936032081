import React from "react"

export default function Heading({
    title
}) {

    return (
        <>
            <h2
                className="global-h2" style={{ fontWeight: 600 }}>{title}
            </h2>
        </>
    )
}