import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import { button_right_style, useDatabaseConstants, disabled_btn } from '../../../Constants/globalConstants';
import { post_data } from '../../../Api';
import toast, { Toaster } from 'react-hot-toast';

const SubAdminForgetPassword = () => {

    const { app_color } = useDatabaseConstants()
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [emailForm, setEmailForm] = useState(true)
    const [emailSent, setEmailSent] = useState(false)

    const [invalid, setInvalid] = useState({
        email: false,
    });

    const handleEmailChange = (e) => {
        const { name, value } = e.target;
        setEmail(e?.target?.value)
        setInvalid({ ...invalid, [name]: false })
    };

    const handleSubmit = (e) => {

        e?.preventDefault()

        let is_false = 0;
        let obj = {
            email: false,
        }

        if (email === '') {
            obj.email = true;
            is_false = 1;
        }

        if (is_false) {
            setInvalid(obj);
            return "";
        }

        setLoading(true)
        setTimeout(async () => {
            const response = await post_data('sub-admin/send-reset-password-email', { email: email })
            if (response?.status) {
                setLoading(false)
                setEmailForm(false)
                setEmailSent(true)
            }
            else {
                toast.error('Something went wrong.')
                setLoading(false)
            }
        }, 1000);
    }

    const handleReEnterEmail = () => {
        setEmailSent(false)
        setEmailForm(true)
    }

    const handleEnter = (event) => {
        if (event.key == 'Enter') {
            handleSubmit()
        }
    }

    return (
        <>
            <div className="container-new modal">
                <Toaster />
                <div className="fp-card">
                    <img src="/images/reset-password.svg" style={{ width: 280, height: 280 }} />
                    {
                        emailForm && (
                            <>
                                <div>
                                    <h3 className='global-h3' style={{ marginBottom: '4%', fontSize: 25, color: 'black' }}>Forgot your password?</h3>
                                    <p className='about-para'>Enter your Email and we'll help you reset your password.</p>
                                    <div style={{ textAlign: 'left', marginTop: '5%', width: '100%' }}>
                                        <input
                                            onKeyPress={(event) => handleEnter(event)}
                                            placeholder='Enter your email'
                                            value={email}
                                            type='text'
                                            style={{ height: 42, paddingLeft: '3%' }}
                                            className={`register-from-input ${invalid?.email ? 'invalid-input' : ''}`}
                                            name={'email'}
                                            onChange={handleEmailChange}
                                        />
                                        {
                                            invalid?.email && <p className="invalid-txt" style={{ display: "flex" }}>
                                                Enter your email
                                            </p>
                                        }
                                    </div>
                                    <Button
                                        onClick={handleSubmit}
                                        disabled={loading}
                                        style={{
                                            ...button_right_style,
                                            width: '40%',
                                            margin: 'auto',
                                            marginTop: '6%',
                                            ...(loading ? disabled_btn : {})
                                        }}
                                    >
                                        {loading ? 'Sending Link...' : 'Continue'}
                                    </Button>

                                </div>
                            </>
                        )
                    }
                    {
                        emailSent && (
                            <>
                                <div style={{
                                    width: '70%',
                                    margin: 'auto'
                                }}>
                                    <h3>A verification link has been sent to your email address.</h3>
                                    <p
                                        onClick={handleReEnterEmail}
                                        style={{ color: app_color, textDecoration: 'underline', cursor: 'pointer', marginTop: '3%' }}>
                                        Re-enter email
                                    </p>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </>
    );
};

export default SubAdminForgetPassword;
