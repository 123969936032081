import { Button, Grid } from "@material-ui/core";
import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  formatDate,
  secondaryDarkColor,
  useDatabaseConstants,
} from "../../../Constants/globalConstants";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import BillingInvoice from "./BillingInvoiceTable";
import { get_data, post_data } from "../../../Api";
import { useNavigate } from "react-router-dom";
import BillingSkeleton from "./skeleton/BillingSkeleton";
import { DarkModeContext } from "./DarkModeContext";

export default function Subscription() {

  const { user_data } = useSelector((state) => state.user);
  const { app_color } = useDatabaseConstants();
  const [planType, setPlanType] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [expirydate, setExpiryDate] = useState(user_data?.billing?.expiry_date);

  const itemsPerPage = 5;
  const { darkMode } = React.useContext(DarkModeContext);

  const fetchInvoices = async () => {
    const response = await get_data(
      `billing/get-all-billings-by-user-id/${user_data?._id}`
    );
    if (response?.status) {
      setInvoices(response?.data);
      setLoading(false);
    } else {
      setInvoices([]);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  useEffect(() => {
    setPlanType(user_data?.billing?.plan_type);
  }, [user_data]);

  const current_plan_div = {
    borderRadius: 10,
    color: darkMode ? "white" : "black",
    boxShadow: "3px 3px 4px 0 #00000008",
    border: darkMode ? "none" : "2px solid #F1F1F2",
    background: darkMode ? secondaryDarkColor : "white",
    height: "100%",
  };

  const planItems = [
    {
      title: "Plan name",
      value: user_data?.plan_id?.[0]?.name,
    },
    {
      title: "Billing Cycle",
      value: user_data?.billing?.plan_type,
    },
    {
      title: "Plan cost",
      value: invoices?.[0]?.payment_amount,
      type: "currency",
    },
  ];

  const plan_items_div = {
    display: "flex",
    gap: "4%",
    justifyContent: "left",
    padding: "3%",
  };

  const plan_item = {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    width: "100%",
  };

  const payment_details_div = {
    display: "flex",
    gap: "3%",
    padding: "3%",
    border: "1px solid #F1F1F2",
    borderRadius: 5,
  };

  const handleBtnClick = (btn) => {
    navigate("/pricing");
  };

  const upgradeDiv = (title, btn) => {
    let is_highest = invoices?.[0]?.plan_id?.is_highest;
    return (
      <>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            borderBottom: darkMode ? "2px solid gray" : "2px solid #F1F1F2",
            padding: "3%",
          }}
        >
          <h2
            className="global-h2"
            style={{
              color: darkMode ? "white" : "black",
              fontSize: 18,
              margin: 0,
            }}
          >
            {title}
          </h2>
          {btn === "Renew" ? (
            <>
              <div
                onClick={() => handleBtnClick(btn)}
                style={{
                  background: btn === "Change" ? "white" : app_color,
                  color: btn === "Change" ? app_color : "white",
                  boxShadow: "none",
                  marginLeft: "auto",
                  fontSize: 13,
                  padding: "2% 4%",
                  border: btn === "Change" ? "2px solid" + app_color : "",
                  borderRadius: 7,
                  fontWeight: 600,
                  cursor: "pointer",
                }}
              >
                {btn}
              </div>
            </>
          ) : (
            <>
              {!is_highest && (
                <div
                  onClick={() => handleBtnClick(btn)}
                  style={{
                    background: btn === "Change" ? "white" : app_color,
                    color: btn === "Change" ? app_color : "white",
                    boxShadow: "none",
                    marginLeft: "auto",
                    fontSize: 13,
                    padding: "2% 4%",
                    border: btn === "Change" ? "2px solid" + app_color : "",
                    borderRadius: 7,
                    fontWeight: 600,
                    cursor: "pointer",
                  }}
                >
                  {btn}
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  };

  let lastinvoice = invoices?.length - 1;
  const expirationDate = new Date(invoices?.[lastinvoice]?.expiry_date); // (YYYY-MM-DD)

  const expiryProgressBar = (expirationDate) => {
    const today = new Date();
    const timeRemaining = expirationDate - today;
    let totalDuration;
    if (planType === "monthly") {
      totalDuration =
        expirationDate -
        new Date(
          expirationDate?.getFullYear(),
          expirationDate?.getMonth() - 1,
          expirationDate?.getDate()
        );
    }
    else if (planType === "quaterly") {
      totalDuration =
        expirationDate -
        new Date(
          expirationDate?.getFullYear(),
          expirationDate?.getMonth() - 3,
          expirationDate?.getDate()
        );
    }
    else if (planType === "semi_yearly") {
      totalDuration =
        expirationDate -
        new Date(
          expirationDate?.getFullYear(),
          expirationDate?.getMonth() - 6,
          expirationDate?.getDate()
        );
    }
    else {
      totalDuration =
        expirationDate -
        new Date(
          expirationDate?.getFullYear() - 1,
          expirationDate?.getMonth(),
          expirationDate?.getDate()
        );
    }
    const percentage = ((totalDuration - timeRemaining) / totalDuration) * 100;
    return (
      <div style={{ padding: "3%" }}>
        <p
          className="about-para"
          style={{ fontSize: 13, textTransform: "uppercase" }}
        >
          Expiry Date
          <b> {formatDate(invoices?.[0]?.expiry_date)}</b>
        </p>
        <LinearProgress
          variant="determinate"
          value={percentage}
          style={{ borderRadius: 5, height: 23, marginTop: "1%" }}
        />
      </div>
    );
  };

  const paymentDetails = () => {
    return (
      <div style={payment_details_div}>
        <div>
          <img src="/images/razorpay-logo.svg" style={{ width: 150 }} />
        </div>
        <div style={plan_item}>
          <h3 className="global-h3" style={{ color: "black", fontSize: 18 }}>
            Secure payment with RazorPay
          </h3>
        </div>
      </div>
    );
  };

  const isPlanExpiringSoon = () => {
    if (!expirydate) {
      return false; // Don't show the grid if expiry date is unavailable
    }

    const expiryDateObject = new Date(expirydate);
    const today = new Date();

    const differenceInMs = expiryDateObject.getTime() - today.getTime();

    const daysRemaining = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));

    return daysRemaining <= 7;
  };

  const sendPlanExpirationMail = async () => {
    const isPlanExpire = isPlanExpiringSoon()
    if (isPlanExpire) {
      let payload = { user_data: user_data, expiry_date: formatDate(user_data?.billing?.expiry_date) }
      const response = await post_data('user/send-plan-expire-reminder-mail', payload)
      if (response?.status) {
        // console.log('Mail sent');
      }
    }
  }

  useEffect(() => {
    sendPlanExpirationMail()
  }, [])

  const subscriptionSummary = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <div style={current_plan_div}>
              {upgradeDiv("Current Plan Summary", "Upgrade")}
              <div style={plan_items_div}>
                {planItems?.map((item, i) => {
                  let formated_price;
                  if (item?.type === "currency") {
                    formated_price = new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format(item?.value || 0);
                  }
                  return (
                    <div style={plan_item}>
                      <p
                        className="about-para"
                        style={{ fontSize: 13, textTransform: "uppercase" }}
                      >
                        {item?.title}
                      </p>
                      <h3
                        style={{
                          margin: "2%",
                          fontSize: 17,
                          fontWeight: 500,
                          textTransform: "capitalize",
                        }}
                      >
                        {item?.type === "currency" ? (
                          <>{formated_price}</>
                        ) : (
                          <>{item?.value}</>
                        )}
                      </h3>
                    </div>
                  );
                })}
              </div>
              {expiryProgressBar(expirationDate)}
            </div>
          </Grid>
          {isPlanExpiringSoon() && ( // Only render the grid if the condition is met
            <Grid item md={6}>
              <div style={current_plan_div}>
                {upgradeDiv("Plan Expiring in 7 days", "Renew")}
                <div style={{ padding: "4%" }}>{paymentDetails()}</div>
              </div>
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  return (
    <>
      <div>
        {loading ? (
          <>
            <BillingSkeleton />
          </>
        ) : (
          <>
            {subscriptionSummary()}
            <div style={{ marginTop: "4%" }}>
              <BillingInvoice
                count={invoices?.length}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                invoices={invoices}
                setInvoices={setInvoices}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
