import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { DarkModeContext } from "../../WebApp/UserDshboard/Components/DarkModeContext"
import { secondaryDarkColor } from '../../Constants/globalConstants';

export default function CompleteProfileModal({
    open,
    setOpen
}) {

    const { darkMode } = React.useContext(DarkModeContext);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 550,
        bgcolor: darkMode ? secondaryDarkColor : 'white',
        boxShadow: 24,
        border: '1px solid white',
        borderRadius: 7,
        padding: '4%',
    };

    const app_modal_styles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }

    const image_styles = {
        width: 170
    }

    const handleClose = () => setOpen(false);

    const appPromotion = () => {
        return (
            <>
                <div style={app_modal_styles}>
                    {
                        !darkMode && (
                            <img src='/images/modal-img-profile.svg' style={{ width: 320, height: 320 }} />
                        )
                    }

                    <h2 className='global-h2' style={{ textAlign: 'center', fontSize: 33, color: darkMode ? 'white' : 'black' }}>Complete Your Profile!</h2>
                    <p className='about-para' style={{ textAlign: 'center', margin: '2% auto', color: darkMode ? 'gray' : 'gray' }}>
                        Complete your profile by providing all the details to get the best and perfect match that you are looking for.
                    </p>
                </div>
            </>
        )
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <div onClick={handleClose} style={{
                        position: 'absolute',
                        right: '3%',
                        top: '3%',
                        cursor: 'pointer',
                    }}
                    >
                        <img src='/images/modal-close-icon.svg' style={{ width: '100%', height: '100%' }} />
                    </div>
                    {appPromotion()}
                </Box>
            </Modal>
        </div>
    );
}
