import { Box, Button, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { formatPrice, secondaryDarkColor, useDatabaseConstants } from "../../../Constants/globalConstants";
import { DarkModeContext } from "./DarkModeContext";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CustomProgressBar from "./CustomProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import VerifyEmailModal from "../../../Website/Components/VerifyEmailModal";
import SuccessModal from "./SuccessModal";
import useRazorpay from "react-razorpay";
import VerifyAadharModal from "./VerifyAadharModal";
import { post_data } from "../../../Api";
import { login } from "../../../redux/slices/user-slice";

export default function Verification({
    setActiveTab
}) {

    const { app_color } = useDatabaseConstants();
    const { user_data } = useSelector(state => state.user);
    const { darkMode } = React.useContext(DarkModeContext);
    const [open, setOpen] = useState(false)
    const [openAadhar, setOpenAadhar] = useState(false)
    const [successStatus, setSuccessStatus] = useState(false)
    const [successOpen, setSuccessOpen] = useState(false)
    const [title, setTitle] = useState('')
    const [Razorpay] = useRazorpay();
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const grid_box = {
        padding: "2%",
        borderRadius: 10,
        marginRight: "2%",
        background: darkMode ? secondaryDarkColor : "white",
        border: darkMode ? "none" : "2px solid #F1F1F2",
        boxShadow: "3px 3px 4px 0 #00000008",
        color: darkMode ? "white" : "black",
    };

    const listItems = [
        {
            title: 'Pan Card',
            value: user_data?.is_pan_verified
        },
        {
            title: 'Driving License',
            value: user_data?.is_dl_verified
        },
        {
            title: 'Aadhar',
            value: user_data?.is_aadhar_verified,
        },
        {
            title: 'Selfie',
            value: user_data?.user_selfie === '' ? false : true,
        },
    ];

    // Calculate verification percentage
    const verifiedCount = listItems?.filter(item => item?.value === true).length;
    const verificationPercentage = verifiedCount * 20;

    const handleVerify = (item) => {
        if (item?.title === 'Aadhar') {
            setOpenAadhar(true)
            setTitle('Aadhar')
        }
        if (item?.title === 'Pan Card') {
            setOpenAadhar(true)
            setTitle('Pan Card')
        }
        if (item?.title === 'Driving License') {
            setOpenAadhar(true)
            setTitle('Driving License')
        }
    }

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '20px',
            borderRadius: '10px',
            background: darkMode ? secondaryDarkColor : 'white',
            border: darkMode ? 'none' : '1px solid #E0E0E0',
            maxWidth: '80%',
            margin: 'auto',
        },
        image: {
            width: 400,
            marginBottom: '20px',
        },
        content: {
            width: '65%',
        },
        heading: {
            fontWeight: 700,
            fontSize: 25,
            marginBottom: '10px',
        },
        subHeading: {
            marginBottom: '10px',
            color: 'grey',
        },
        list: {
            listStyle: 'none',
            padding: 0,
            marginLeft: '-4%'
        },
        listItem: {
            display: 'flex',
            alignItems: 'center',
        },
        listItemIcon: {
            color: 'green',
        },
        button: {
            backgroundColor: app_color,
            color: 'white',
            textTransform: 'none',
            padding: '13px 20px',
            borderRadius: '5px',
            width: '100%',
            marginTop: '4%'
        },
    };

    const handlePayment = () => {
        handleRzrpPayment();
    };

    const handleRzrpPayment = async (params) => {
        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.payment_id);
        });
        rzp1.open();
    };

    const options = {
        key: "rzp_test_GQ6XaPC6gMPNwH",
        amount: 2999 * 100,
        currency: "INR",
        name: "DilSey",
        description: "Matrimony",
        image: "/images/dilsey-logo.svg",

        handler: async function (response) {
            let payload = {
                user_id: user_data?._id,
                amount: 2999,
            };
            const result = await post_data("verification-plans/create-verification-plan", payload);
            if (result?.status) {
                dispatch(login(result?.data));
                navigate("/payment-successfull", {
                    state: {
                        status: "success",
                        amount: 2999,
                        type: 'verification'
                    },
                });
            } else {
                navigate("/payment-successfull", {
                    state: {
                        status: "failure",
                    },
                });
            }
        },
        prefill: {
            name: `${user_data?.first_name} ${user_data?.last_name}`,
            email: user_data?.email,
            contact: user_data?.contact_number,
        },
        notes: {
            address: "Razorpay Corporate Office",
        },
        theme: {
            color: "#9E0050",
        },
    };

    return (
        <>
            {
                !user_data?.verification_plan ? (
                    <Box sx={styles.container}>
                        <Box> <img src="/images/aadhar.svg" alt="Video Consultation" style={styles.image} /></Box>
                        <Box sx={styles.content}>
                            <Typography variant="h5" sx={{ ...styles.heading, fontWeight: 600 }}>Profile Verification</Typography>
                            <Typography variant="body1" sx={styles.subHeading}>Get a verified badge by verifying your profile using your Identity</Typography>
                            <Typography variant="h6" sx={styles.heading}>{formatPrice(2999)}</Typography>
                            <List style={styles.list}>
                                {['One on one video Calling with users', 'Know each other', 'No restriction on video call duration', 'Unlock access to advanced search', 'View contact details'].map((text, index) => (
                                    <ListItem key={index} sx={styles.listItem}>
                                        <img src='/images/green-check-icon.svg' style={{ width: 23, marginRight: '2%' }} />
                                        <ListItemText primary={text} />
                                    </ListItem>
                                ))}
                            </List>
                            <Button onClick={handlePayment} style={styles.button}>Get Verified</Button>
                        </Box>
                    </Box>
                ) : (
                    <Grid container spacing={0}>
                        <Grid item md={4} style={grid_box}>
                            <h3
                                style={{
                                    color: darkMode ? "white" : "black",
                                    fontSize: 20,
                                }}
                                className="global-h3"
                            >
                                Verification Percentage
                            </h3>
                            <p
                                style={{ fontWeight: 500, color: darkMode ? 'white' : '#333', fontSize: 13, cursor: "pointer", marginBottom: '7%' }}>
                                Verify 100% to get a 'Verified Badge'
                            </p>

                            <div style={{ width: 140, height: 135, margin: 'auto' }}>
                                <CustomProgressBar completionPercentage={verificationPercentage} />
                            </div>
                        </Grid>

                        <Grid item md={4} style={grid_box}>
                            <h3
                                style={{
                                    color: darkMode ? "white" : "black",
                                    fontSize: 20,
                                    marginBottom: "7%",
                                }}
                                className="global-h3"
                            >
                                Profile Verification
                            </h3>

                            {
                                listItems?.map((item, i) => (
                                    <div key={i} style={{ display: 'flex', gap: '2%', width: '100%', margin: '10px 0' }}>
                                        <p style={{ width: '60%', opacity: '80%', color: darkMode ? 'white' : 'black', fontSize: 16, fontWeight: 500 }} className='about-para'>
                                            {item.title}
                                        </p>
                                        {
                                            item.value === true ? (
                                                <img src={"/images/green-check-icon.svg"} style={{ display: "flex", marginLeft: 'auto', width: 20, height: 20 }} alt="Verified" />
                                            ) : (
                                                <p
                                                    onClick={() => handleVerify(item)}
                                                    style={{ width: '40%', textAlign: 'right', fontWeight: 500, color: darkMode ? 'white' : app_color, textDecoration: 'underline', fontSize: 14, cursor: item?.title !== 'Selfie' ? "pointer" : '' }}>
                                                    {item?.title === 'Profile Picture' ? 'Upload' : item?.title === 'Selfie' ? 'Verify at Mobile app' : 'Verify'}
                                                </p>
                                            )
                                        }
                                    </div>
                                ))
                            }
                        </Grid>
                    </Grid>
                )
            }


            <VerifyEmailModal
                setSuccessOpen={setSuccessOpen}
                setSuccessStatus={setSuccessStatus}
                successStatus={successStatus}
                open={open}
                setOpen={setOpen}
            />
            <VerifyAadharModal open={openAadhar} setOpen={setOpenAadhar} title={title} />
            <SuccessModal
                status={successStatus}
                open={successOpen}
                setOpen={setSuccessOpen}
                title={'Something went wrong!'}
            />
        </>
    );
}