import { useState, useEffect } from "react";
import { post_data } from "../Api";

const app_color = '#9e0050'
export const darkPrimaryColor = '#000c1f'
export const secondaryDarkColor = '#202B3E'


export const handleCreateActivity = async (activityName, user_data) => {
    if (user_data?.is_sub_admin) {
        let payload = { 'activity_name': activityName, 'activity_by': user_data?._id }
        const response = await post_data('activities/create-activity', payload)
    }
}

export function useDatabaseConstants() {

    const [app_color, set_app_color] = useState('#9e0050');
    const [app_second_color, set_app_second_color] = useState('#EEE9E3');
    const [user_dashboard_color, set_user_dashboard_color] = useState('#eee9e3');
    const [app_name, set_app_name] = useState('DilSey');

    return { app_color, app_second_color, app_name, user_dashboard_color };
}

export const sortArrAscending = (arr, key) => {
    const result = arr.sort((a, b) => {
        const valA = a[key]?.trim().toLowerCase();
        const valB = b[key]?.trim().toLowerCase();

        if (valA === 'other' && valB === 'none') return -1;
        if (valA === 'none' && valB === 'other') return 1;
        if (valA === 'other') return -1;
        if (valA === 'none') return -1;
        if (valB === 'other') return 1;
        if (valB === 'none') return 1;

        return (valA < valB) ? -1 : (valA > valB) ? 1 : 0;
    });
    return result;
}

export const getFieldName = (fieldName) => {
    return fieldName ? fieldName.toLowerCase().replace(/\s+/g, '_') : '';
}

export const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;
    const outputDate = day + '-' + month + '-' + year;
    return outputDate;
}

export const getPendingStatus = (usersArray, setState) => {
    const result = usersArray?.filter(user => user?.status === "pending");
    if (result) {
        setState(result)
    }
}

export const getAcceptedStatus = (usersArray, setState) => {
    const result = usersArray?.filter(user => user?.status === "accepted");
    if (result) {
        setState(result)
    }
}

export const getDeclinedStatus = (usersArray, setState) => {
    const result = usersArray?.filter(user => user?.status === "declined");
    if (result) {
        setState(result)
    }
}

export const getFilteredInterestStats = (arr, status) => {
    return arr?.filter(interest => interest?.status === status);
}

export const button_left_style = {
    background: 'white',
    width: '50%',
    color: app_color,
    boxShadow: 'none',
    borderRadius: 7,
    padding: '3% 0',
    fontWeight: 500,
    fontSize: 15,
    textTransform: 'capitalize',
    border: `2px solid ${app_color}`
}

export const button_right_style = {
    background: app_color,
    width: '50%',
    color: 'white',
    boxShadow: 'none',
    borderRadius: 7,
    border: 'none',
    fontWeight: 500,
    fontSize: 15,
    textTransform: 'capitalize',
    padding: '3% 0'
}

// Function to calculate profile completion percentage
export const calculateProfileCompletion = (user, profile_completion_details) => {
    const weightageConst = {
        'Profile picture': 0.2,
        'First name': 0.1,
        'Last name': 0.1,
        'Email': 0.1,
        'Contact number': 0.2,
        'Qualifications': 0.1,
        'Occupation': 0.2
    };

    let totalWeightage = 0;
    let filledWeightage = 0;

    profile_completion_details?.forEach(detail => {
        const value = detail.value;
        const weightage = weightageConst[detail.field];

        totalWeightage += weightage;

        // if (value !== undefined && value !== null && value !== '' && value.length > 0) {
        //     filledWeightage += weightage;
        // }

        if (user && user.hasOwnProperty('education_and_careers') && user?.education_and_careers?.hasOwnProperty('qualifications') && value !== undefined && value !== null && value !== '' && value.length > 0) {
            filledWeightage += weightage;
        }
    });

    const completionPercentage = (filledWeightage / totalWeightage) * 100;
    return completionPercentage.toFixed(2); // Round to 2 decimal places
}

export const disabled_btn = {
    background: '#9e004f8e',
    borderRadius: 7,
    padding: '3% 0'
}

export const gender_options = [
    { name: 'Male', code: 'Male' },
    { name: 'Female', code: 'Female' }
];

export const marital_status_options = [
    { name: 'Never Married', code: 'Never Married' },
    { name: 'Divorced', code: 'Divorced' },
    { name: 'Widow', code: 'Widow' },
    { name: 'Widower', code: 'Widower' },
    { name: 'Separated', code: 'Separated' },
    // { name: 'Annulled', code: 'Annulled' },
    // { name: 'Civil Union', code: 'Civil Union' },
    // { name: 'Domestic Partnership', code: 'Domestic Partnership' }
];


export const occupation_options = [

    { name: 'None', code: 'None' },
    { name: 'Other', code: 'Other' },

    // Information Technology
    { name: 'Software Engineer', code: 'Software Engineer' },
    { name: 'Data Scientist', code: 'Data Scientist' },
    { name: 'Web Developer', code: 'Web Developer' },
    { name: 'Database Administrator', code: 'Database Administrator' },
    { name: 'Network Engineer', code: 'Network Engineer' },
    { name: 'Cybersecurity Analyst', code: 'Cybersecurity Analyst' },
    { name: 'Systems Analyst', code: 'Systems Analyst' },
    { name: 'IT Manager', code: 'IT Manager' },

    // Design
    { name: 'Graphic Designer', code: 'Graphic Designer' },
    { name: 'UI/UX Designer', code: 'UI/UX Designer' },
    { name: 'Interior Designer', code: 'Interior Designer' },
    { name: 'Fashion Designer', code: 'Fashion Designer' },
    { name: 'Industrial Designer', code: 'Industrial Designer' },
    { name: 'Art Director', code: 'Art Director' },

    // Marketing
    { name: 'Marketing Manager', code: 'Marketing Manager' },
    { name: 'Digital Marketing Specialist', code: 'Digital Marketing Specialist' },
    { name: 'Brand Manager', code: 'Brand Manager' },
    { name: 'Market Research Analyst', code: 'Market Research Analyst' },
    { name: 'Public Relations Specialist', code: 'Public Relations Specialist' },
    { name: 'Content Marketing Manager', code: 'Content Marketing Manager' },

    // Finance
    { name: 'Financial Analyst', code: 'Financial Analyst' },
    { name: 'Accountant', code: 'Accountant' },
    { name: 'Investment Banker', code: 'Investment Banker' },
    { name: 'Financial Advisor', code: 'Financial Advisor' },
    { name: 'Actuary', code: 'Actuary' },
    { name: 'Insurance Underwriter', code: 'Insurance Underwriter' },

    // Education
    { name: 'Teacher', code: 'Teacher' },
    { name: 'Professor', code: 'Professor' },
    { name: 'School Counselor', code: 'School Counselor' },
    { name: 'Principal', code: 'Principal' },
    { name: 'Curriculum Developer', code: 'Curriculum Developer' },
    { name: 'Special Education Teacher', code: 'Special Education Teacher' },

    // Healthcare
    { name: 'Nurse', code: 'Nurse' },
    { name: 'Physician', code: 'Physician' },
    { name: 'Pharmacist', code: 'Pharmacist' },
    { name: 'Dentist', code: 'Dentist' },
    { name: 'Physical Therapist', code: 'Physical Therapist' },
    { name: 'Medical Technologist', code: 'Medical Technologist' },

    // Culinary
    { name: 'Chef', code: 'Chef' },
    { name: 'Sous Chef', code: 'Sous Chef' },
    { name: 'Pastry Chef', code: 'Pastry Chef' },
    { name: 'Caterer', code: 'Caterer' },
    { name: 'Food Scientist', code: 'Food Scientist' },
    { name: 'Restaurant Manager', code: 'Restaurant Manager' },

    // Construction
    { name: 'Civil Engineer', code: 'Civil Engineer' },
    { name: 'Architect', code: 'Architect' },
    { name: 'Construction Manager', code: 'Construction Manager' },
    { name: 'Electrician', code: 'Electrician' },
    { name: 'Plumber', code: 'Plumber' },
    { name: 'Carpenter', code: 'Carpenter' }
    // Add more occupations as needed
];

export const religion_options = [
    { name: "Muslim", code: "Muslim" },
    { name: "Hindu", code: "Hindu" },
    { name: "Christian", code: "Christian" },
    { name: "Sikh", code: "Sikh" },
    { name: "Jain", code: "Jain" },
    { name: "Buddhist", code: "Buddhist" },
    { name: 'Other', code: 'Other' },
];

export const qualifications_options = [
    { name: 'None', code: 'None' },
    { name: 'Other', code: 'Other' },
    { name: 'B.Tech', code: 'B.Tech' },
    { name: 'B.C.A', code: 'B.C.A' },
    { name: 'B.A.', code: 'B.A.' },
    { name: 'B.S.', code: 'B.S.' },
    { name: 'M.Tech', code: 'M.Tech' },
    { name: 'M.A.', code: 'M.A.' },
    { name: 'M.S.', code: 'M.S.' },
    { name: 'Ph.D.', code: 'Ph.D.' },
    { name: 'BBA', code: 'BBA' },
    { name: 'MBA', code: 'MBA' },
    { name: 'LLB', code: 'LLB' },
    { name: 'JD', code: 'JD' },
    { name: 'MD', code: 'MD' },
    { name: 'DDS', code: 'DDS' },
    { name: 'DVM', code: 'DVM' },
    { name: 'D.Pharm', code: 'D.Pharm' },
    { name: 'Pharm.D.', code: 'Pharm.D.' },
    { name: 'BSN', code: 'BSN' },
    { name: 'MSN', code: 'MSN' },
    { name: 'RN', code: 'RN' },
    { name: 'B.Ed', code: 'B.Ed' },
    { name: 'M.Ed', code: 'M.Ed' },
    { name: 'CFA', code: 'CFA' },
    { name: 'CA', code: 'CA' },
    { name: 'CPA', code: 'CPA' },
    { name: 'CFP', code: 'CFP' },
    { name: 'CMA', code: 'CMA' },
    { name: 'CISA', code: 'CISA' },
    { name: 'CISSP', code: 'CISSP' },
    { name: 'PMP', code: 'PMP' },
    { name: 'CEH', code: 'CEH' },
    { name: 'CCNA', code: 'CCNA' },
    { name: 'CCNP', code: 'CCNP' },
    { name: 'CCIE', code: 'CCIE' },
    { name: 'RHCE', code: 'RHCE' },
    { name: 'MCSE', code: 'MCSE' },
    { name: 'AWS Certification', code: 'AWS Certification' },
    { name: 'Google Cloud Certification', code: 'Google Cloud Certification' },
    { name: 'Azure Certification', code: 'Azure Certification' }
];

export const hobbies_options = [
    { name: 'None', code: 'None' },
    { name: 'Other', code: 'Other' },
    { name: 'Cricket', code: 'Cricket' },
    { name: 'Travelling', code: 'Travelling' },
    { name: 'Reading', code: 'Reading' },
    { name: 'Painting', code: 'Painting' },
    { name: 'Cooking', code: 'Cooking' },
    { name: 'Photography', code: 'Photography' },
    { name: 'Hiking', code: 'Hiking' },
    { name: 'Gardening', code: 'Gardening' },
    { name: 'Writing', code: 'Writing' },
    { name: 'Dancing', code: 'Dancing' },
    { name: 'Singing', code: 'Singing' },
    { name: 'Yoga', code: 'Yoga' },
    { name: 'Fishing', code: 'Fishing' },
    { name: 'Cycling', code: 'Cycling' },
    { name: 'Running', code: 'Running' },
    { name: 'Swimming', code: 'Swimming' },
    { name: 'Knitting', code: 'Knitting' },
    { name: 'Woodworking', code: 'Woodworking' },
    { name: 'Model Building', code: 'Model Building' }
];

export const state_options = [
    { name: 'Andhra Pradesh', code: 'Andhra Pradesh' },
    { name: 'Arunachal Pradesh', code: 'Arunachal Pradesh' },
    { name: 'Assam', code: 'Assam' },
    { name: 'Bihar', code: 'Bihar' },
    { name: 'Chhattisgarh', code: 'Chhattisgarh' },
    { name: 'Goa', code: 'Goa' },
    { name: 'Gujarat', code: 'Gujarat' },
    { name: 'Haryana', code: 'Haryana' },
    { name: 'Himachal Pradesh', code: 'Himachal Pradesh' },
    { name: 'Jharkhand', code: 'Jharkhand' },
    { name: 'Karnataka', code: 'Karnataka' },
    { name: 'Kerala', code: 'Kerala' },
    { name: 'Madhya Pradesh', code: 'Madhya Pradesh' },
    { name: 'Maharashtra', code: 'Maharashtra' },
    { name: 'Manipur', code: 'Manipur' },
    { name: 'Meghalaya', code: 'Meghalaya' },
    { name: 'Mizoram', code: 'Mizoram' },
    { name: 'Nagaland', code: 'Nagaland' },
    { name: 'Odisha', code: 'Odisha' },
    { name: 'Punjab', code: 'Punjab' },
    { name: 'Rajasthan', code: 'Rajasthan' },
    { name: 'Sikkim', code: 'Sikkim' },
    { name: 'Tamil Nadu', code: 'Tamil Nadu' },
    { name: 'Telangana', code: 'Telangana' },
    { name: 'Tripura', code: 'Tripura' },
    { name: 'Uttar Pradesh', code: 'Uttar Pradesh' },
    { name: 'Uttarakhand', code: 'Uttarakhand' },
    { name: 'West Bengal', code: 'West Bengal' }
];

export const age_range_options_male = [
    { name: '21 - 25 Years', code: '21 - 25 Years' },
    { name: '26 - 30 Years', code: '26 - 30 Years' },
    { name: '31 - 35 Years', code: '31 - 35 Years' },
    { name: '36 - 40 Years', code: '36 - 40 Years' },
    { name: '46 - 50 Years', code: '46 - 50 Years' },
    { name: 'above 51 Years', code: 'above 51 Years' },
];

export const age_range_options_female = [
    { name: '18 - 25 Years', code: '18 - 25 Years' },
    { name: '26 - 30 Years', code: '26 - 30 Years' },
    { name: '31 - 35 Years', code: '31 - 35 Years' },
    { name: '36 - 40 Years', code: '36 - 40 Years' },
    { name: '46 - 50 Years', code: '46 - 50 Years' },
    { name: 'above 51 Years', code: 'above 51 Years' },
];

export const annual_income_options = [
    { name: 'None', code: 'None' },
    { name: '1 LPA-5 LPA', code: '1 LPA-5 LPA' },
    { name: '6 LPA-10 LPA', code: '6 LPA-10 LPA' },
    { name: '11 LPA-15 LPA', code: '11 LPA-15 LPA' },
    { name: '16 LPA-20 LPA', code: '16 LPA-20 LPA' },
    { name: '21 LPA-25 LPA', code: '21 LPA-25 LPA' },
    { name: '26 LPA-30 LPA', code: '26 LPA-30 LPA' },
    { name: '31 LPA-35 LPA', code: '31 LPA-35 LPA' },
    { name: '36 LPA-40 LPA', code: '36 LPA-40 LPA' },
    { name: '41 LPA-45 LPA', code: '41 LPA-45 LPA' },
    { name: '46 LPA-50 LPA', code: '46 LPA-50 LPA' },
    { name: '>50 LPA', code: '>50 LPA' },
];

export const country_options = [
    { name: 'Afghanistan', code: 'Afghanistan' },
    { name: 'Albania', code: 'Albania' },
    { name: 'Algeria', code: 'Algeria' },
    { name: 'Andorra', code: 'Andorra' },
    { name: 'Angola', code: 'Angola' },
    { name: 'Antigua and Barbuda', code: 'Antigua and Barbuda' },
    { name: 'Argentina', code: 'Argentina' },
    { name: 'Armenia', code: 'Armenia' },
    { name: 'Australia', code: 'Australia' },
    { name: 'Austria', code: 'Austria' },
    { name: 'Azerbaijan', code: 'Azerbaijan' },
    { name: 'Bahamas', code: 'Bahamas' },
    { name: 'Bahrain', code: 'Bahrain' },
    { name: 'Bangladesh', code: 'Bangladesh' },
    { name: 'Barbados', code: 'Barbados' },
    { name: 'Belarus', code: 'Belarus' },
    { name: 'Belgium', code: 'Belgium' },
    { name: 'Belize', code: 'Belize' },
    { name: 'Benin', code: 'Benin' },
    { name: 'Bhutan', code: 'Bhutan' },
    { name: 'Bolivia', code: 'Bolivia' },
    { name: 'Bosnia and Herzegovina', code: 'Bosnia and Herzegovina' },
    { name: 'Botswana', code: 'Botswana' },
    { name: 'Brazil', code: 'Brazil' },
    { name: 'Brunei', code: 'Brunei' },
    { name: 'Bulgaria', code: 'Bulgaria' },
    { name: 'Burkina Faso', code: 'Burkina Faso' },
    { name: 'Burundi', code: 'Burundi' },
    { name: 'Cabo Verde', code: 'Cabo Verde' },
    { name: 'Cambodia', code: 'Cambodia' },
    { name: 'Cameroon', code: 'Cameroon' },
    { name: 'Canada', code: 'Canada' },
    { name: 'Central African Republic', code: 'Central African Republic' },
    { name: 'Chad', code: 'Chad' },
    { name: 'Chile', code: 'Chile' },
    { name: 'China', code: 'China' },
    { name: 'Colombia', code: 'Colombia' },
    { name: 'Comoros', code: 'Comoros' },
    { name: 'Congo (Congo-Brazzaville)', code: 'Congo (Congo-Brazzaville)' },
    { name: 'Costa Rica', code: 'Costa Rica' },
    { name: 'Croatia', code: 'Croatia' },
    { name: 'Cuba', code: 'Cuba' },
    { name: 'Cyprus', code: 'Cyprus' },
    { name: 'Czechia (Czech Republic)', code: 'Czechia (Czech Republic)' },
    { name: 'Democratic Republic of the Congo', code: 'Democratic Republic of the Congo' },
    { name: 'Denmark', code: 'Denmark' },
    { name: 'Djibouti', code: 'Djibouti' },
    { name: 'Dominica', code: 'Dominica' },
    { name: 'Dominican Republic', code: 'Dominican Republic' },
    { name: 'East Timor (Timor-Leste)', code: 'East Timor (Timor-Leste)' },
    { name: 'Ecuador', code: 'Ecuador' },
    { name: 'Egypt', code: 'Egypt' },
    { name: 'El Salvador', code: 'El Salvador' },
    { name: 'Equatorial Guinea', code: 'Equatorial Guinea' },
    { name: 'Eritrea', code: 'Eritrea' },
    { name: 'Estonia', code: 'Estonia' },
    { name: 'Eswatini (fmr. "Swaziland")', code: 'Eswatini (fmr. "Swaziland")' },
    { name: 'Ethiopia', code: 'Ethiopia' },
    { name: 'Fiji', code: 'Fiji' },
    { name: 'Finland', code: 'Finland' },
    { name: 'France', code: 'France' },
    { name: 'Gabon', code: 'Gabon' },
    { name: 'Gambia', code: 'Gambia' },
    { name: 'Georgia', code: 'Georgia' },
    { name: 'Germany', code: 'Germany' },
    { name: 'Ghana', code: 'Ghana' },
    { name: 'Greece', code: 'Greece' },
    { name: 'Grenada', code: 'Grenada' },
    { name: 'Guatemala', code: 'Guatemala' },
    { name: 'Guinea', code: 'Guinea' },
    { name: 'Guinea-Bissau', code: 'Guinea-Bissau' },
    { name: 'Guyana', code: 'Guyana' },
    { name: 'Haiti', code: 'Haiti' },
    { name: 'Honduras', code: 'Honduras' },
    { name: 'Hungary', code: 'Hungary' },
    { name: 'Iceland', code: 'Iceland' },
    { name: 'India', code: 'India' },
    { name: 'Indonesia', code: 'Indonesia' },
    { name: 'Iran', code: 'Iran' },
    { name: 'Iraq', code: 'Iraq' },
    { name: 'Ireland', code: 'Ireland' },
    { name: 'Israel', code: 'Israel' },
    { name: 'Italy', code: 'Italy' },
    { name: 'Ivory Coast', code: 'Ivory Coast' },
    { name: 'Jamaica', code: 'Jamaica' },
    { name: 'Japan', code: 'Japan' },
    { name: 'Jordan', code: 'Jordan' },
    { name: 'Kazakhstan', code: 'Kazakhstan' },
    { name: 'Kenya', code: 'Kenya' },
    { name: 'Kiribati', code: 'Kiribati' },
    { name: 'Kosovo', code: 'Kosovo' },
    { name: 'Kuwait', code: 'Kuwait' },
    { name: 'Kyrgyzstan', code: 'Kyrgyzstan' },
    { name: 'Laos', code: 'Laos' },
    { name: 'Latvia', code: 'Latvia' },
    { name: 'Lebanon', code: 'Lebanon' },
    { name: 'Lesotho', code: 'Lesotho' },
    { name: 'Liberia', code: 'Liberia' },
    { name: 'Libya', code: 'Libya' },
    { name: 'Liechtenstein', code: 'Liechtenstein' },
    { name: 'Lithuania', code: 'Lithuania' },
    { name: 'Luxembourg', code: 'Luxembourg' },
    { name: 'Madagascar', code: 'Madagascar' },
    { name: 'Malawi', code: 'Malawi' },
    { name: 'Malaysia', code: 'Malaysia' },
    { name: 'Maldives', code: 'Maldives' },
    { name: 'Mali', code: 'Mali' },
    { name: 'Malta', code: 'Malta' },
    { name: 'Marshall Islands', code: 'Marshall Islands' },
    { name: 'Mauritania', code: 'Mauritania' },
    { name: 'Mauritius', code: 'Mauritius' },
    { name: 'Mexico', code: 'Mexico' },
    { name: 'Micronesia', code: 'Micronesia' },
    { name: 'Moldova', code: 'Moldova' },
    { name: 'Monaco', code: 'Monaco' },
    { name: 'Mongolia', code: 'Mongolia' },
    { name: 'Montenegro', code: 'Montenegro' },
    { name: 'Morocco', code: 'Morocco' },
    { name: 'Mozambique', code: 'Mozambique' },
    { name: 'Myanmar (formerly Burma)', code: 'Myanmar (formerly Burma)' },
    { name: 'Namibia', code: 'Namibia' },
    { name: 'Nauru', code: 'Nauru' },
    { name: 'Nepal', code: 'Nepal' },
    { name: 'Netherlands', code: 'Netherlands' },
    { name: 'New Zealand', code: 'New Zealand' },
    { name: 'Nicaragua', code: 'Nicaragua' },
    { name: 'Niger', code: 'Niger' },
    { name: 'Nigeria', code: 'Nigeria' },
    { name: 'North Korea', code: 'North Korea' },
    { name: 'North Macedonia', code: 'North Macedonia' },
    { name: 'Norway', code: 'Norway' },
    { name: 'Oman', code: 'Oman' },
    { name: 'Pakistan', code: 'Pakistan' },
    { name: 'Palau', code: 'Palau' },
    { name: 'Palestine State', code: 'Palestine State' },
    { name: 'Panama', code: 'Panama' },
    { name: 'Papua New Guinea', code: 'Papua New Guinea' },
    { name: 'Paraguay', code: 'Paraguay' },
    { name: 'Peru', code: 'Peru' },
    { name: 'Philippines', code: 'Philippines' },
    { name: 'Poland', code: 'Poland' },
    { name: 'Portugal', code: 'Portugal' },
    { name: 'Qatar', code: 'Qatar' },
    { name: 'Romania', code: 'Romania' },
    { name: 'Russia', code: 'Russia' },
    { name: 'Rwanda', code: 'Rwanda' },
    { name: 'Saint Kitts and Nevis', code: 'Saint Kitts and Nevis' },
    { name: 'Saint Lucia', code: 'Saint Lucia' },
    { name: 'Saint Vincent and the Grenadines', code: 'Saint Vincent and the Grenadines' },
    { name: 'Samoa', code: 'Samoa' },
    { name: 'San Marino', code: 'San Marino' },
    { name: 'Sao Tome and Principe', code: 'Sao Tome and Principe' },
    { name: 'Saudi Arabia', code: 'Saudi Arabia' },
    { name: 'Senegal', code: 'Senegal' },
    { name: 'Serbia', code: 'Serbia' },
    { name: 'Seychelles', code: 'Seychelles' },
    { name: 'Sierra Leone', code: 'Sierra Leone' },
    { name: 'Singapore', code: 'Singapore' },
    { name: 'Slovakia', code: 'Slovakia' },
    { name: 'Slovenia', code: 'Slovenia' },
    { name: 'Solomon Islands', code: 'Solomon Islands' },
    { name: 'Somalia', code: 'Somalia' },
    { name: 'South Africa', code: 'South Africa' },
    { name: 'South Korea', code: 'South Korea' },
    { name: 'South Sudan', code: 'South Sudan' },
    { name: 'Spain', code: 'Spain' },
    { name: 'Sri Lanka', code: 'Sri Lanka' },
    { name: 'Sudan', code: 'Sudan' },
    { name: 'Suriname', code: 'Suriname' },
    { name: 'Sweden', code: 'Sweden' },
    { name: 'Switzerland', code: 'Switzerland' },
    { name: 'Syria', code: 'Syria' },
    { name: 'Taiwan', code: 'Taiwan' },
    { name: 'Tajikistan', code: 'Tajikistan' },
    { name: 'Tanzania', code: 'Tanzania' },
    { name: 'Thailand', code: 'Thailand' },
    { name: 'Togo', code: 'Togo' },
    { name: 'Tonga', code: 'Tonga' },
    { name: 'Trinidad and Tobago', code: 'Trinidad and Tobago' },
    { name: 'Tunisia', code: 'Tunisia' },
    { name: 'Turkey', code: 'Turkey' },
    { name: 'Turkmenistan', code: 'Turkmenistan' },
    { name: 'Tuvalu', code: 'Tuvalu' },
    { name: 'Uganda', code: 'Uganda' },
    { name: 'Ukraine', code: 'Ukraine' },
    { name: 'United Arab Emirates', code: 'United Arab Emirates' },
    { name: 'United Kingdom', code: 'United Kingdom' },
    { name: 'United States', code: 'United States' },
    { name: 'Uruguay', code: 'Uruguay' },
    { name: 'Uzbekistan', code: 'Uzbekistan' },
    { name: 'Vanuatu', code: 'Vanuatu' },
    { name: 'Vatican City', code: 'Vatican City' },
    { name: 'Venezuela', code: 'Venezuela' },
    { name: 'Vietnam', code: 'Vietnam' },
    { name: 'Yemen', code: 'Yemen' },
    { name: 'Zambia', code: 'Zambia' },
    { name: 'Zimbabwe', code: 'Zimbabwe' }
];

export const languages_options = [
    { name: "Abkhaz", code: "Abkhaz" },
    { name: "Afar", code: "Afar" },
    { name: "Afrikaans", code: "Afrikaans" },
    { name: "Akan", code: "Akan" },
    { name: "Albanian", code: "Albanian" },
    { name: "Amharic", code: "Amharic" },
    { name: "Arabic", code: "Arabic" },
    { name: "Aragonese", code: "Aragonese" },
    { name: "Armenian", code: "Armenian" },
    { name: "Assamese", code: "Assamese" },
    { name: "Avaric", code: "Avaric" },
    { name: "Avestan", code: "Avestan" },
    { name: "Aymara", code: "Aymara" },
    { name: "Azerbaijani", code: "Azerbaijani" },
    { name: "Bambara", code: "Bambara" },
    { name: "Bashkir", code: "Bashkir" },
    { name: "Basque", code: "Basque" },
    { name: "Belarusian", code: "Belarusian" },
    { name: "Bengali", code: "Bengali" },
    { name: "Bihari", code: "Bihari" },
    { name: "Bislama", code: "Bislama" },
    { name: "Bosnian", code: "Bosnian" },
    { name: "Breton", code: "Breton" },
    { name: "Bulgarian", code: "Bulgarian" },
    { name: "Burmese", code: "Burmese" },
    { name: "Catalan", code: "Catalan" },
    { name: "Chamorro", code: "Chamorro" },
    { name: "Chechen", code: "Chechen" },
    { name: "Chichewa", code: "Chichewa" },
    { name: "Chinese", code: "Chinese" },
    { name: "Chuvash", code: "Chuvash" },
    { name: "Cornish", code: "Cornish" },
    { name: "Corsican", code: "Corsican" },
    { name: "Cree", code: "Cree" },
    { name: "Croatian", code: "Croatian" },
    { name: "Czech", code: "Czech" },
    { name: "Danish", code: "Danish" },
    { name: "Divehi", code: "Divehi" },
    { name: "Dutch", code: "Dutch" },
    { name: "Dzongkha", code: "Dzongkha" },
    { name: "English", code: "English" },
    { name: "Esperanto", code: "Esperanto" },
    { name: "Estonian", code: "Estonian" },
    { name: "Ewe", code: "Ewe" },
    { name: "Faroese", code: "Faroese" },
    { name: "Fijian", code: "Fijian" },
    { name: "Finnish", code: "Finnish" },
    { name: "French", code: "French" },
    { name: "Fula", code: "Fula" },
    { name: "Galician", code: "Galician" },
    { name: "Georgian", code: "Georgian" },
    { name: "German", code: "German" },
    { name: "Greek", code: "Greek" },
    { name: "Guaraní", code: "Guaraní" },
    { name: "Gujarati", code: "Gujarati" },
    { name: "Haitian", code: "Haitian" },
    { name: "Hausa", code: "Hausa" },
    { name: "Hebrew", code: "Hebrew" },
    { name: "Herero", code: "Herero" },
    { name: "Hindi", code: "Hindi" },
    { name: "Hiri Motu", code: "Hiri Motu" },
    { name: "Hungarian", code: "Hungarian" },
    { name: "Interlingua", code: "Interlingua" },
    { name: "Indonesian", code: "Indonesian" },
    { name: "Interlingue", code: "Interlingue" },
    { name: "Irish", code: "Irish" },
    { name: "Igbo", code: "Igbo" },
    { name: "Inupiaq", code: "Inupiaq" },
    { name: "Ido", code: "Ido" },
    { name: "Icelandic", code: "Icelandic" },
    { name: "Italian", code: "Italian" },
    { name: "Inuktitut", code: "Inuktitut" },
    { name: "Japanese", code: "Japanese" },
    { name: "Javanese", code: "Javanese" },
    { name: "Kalaallisut", code: "Kalaallisut" },
    { name: "Kannada", code: "Kannada" },
    { name: "Kanuri", code: "Kanuri" },
    { name: "Kashmiri", code: "Kashmiri" },
    { name: "Kazakh", code: "Kazakh" },
    { name: "Khmer", code: "Khmer" },
    { name: "Kikuyu", code: "Kikuyu" },
    { name: "Kinyarwanda", code: "Kinyarwanda" },
    { name: "Kirundi", code: "Kirundi" },
    { name: "Kyrgyz", code: "Kyrgyz" },
    { name: "Komi", code: "Komi" },
    { name: "Kongo", code: "Kongo" },
    { name: "Korean", code: "Korean" },
    { name: "Kurdish", code: "Kurdish" },
    { name: "Kwanyama", code: "Kwanyama" },
    { name: "Latin", code: "Latin" },
    { name: "Luxembourgish", code: "Luxembourgish" },
    { name: "Luganda", code: "Luganda" },
    { name: "Limburgish", code: "Limburgish" },
    { name: "Lingala", code: "Lingala" },
    { name: "Lao", code: "Lao" },
    { name: "Lithuanian", code: "Lithuanian" },
    { name: "Luba-Katanga", code: "Luba-Katanga" },
    { name: "Latvian", code: "Latvian" },
    { name: "Manx", code: "Manx" },
    { name: "Macedonian", code: "Macedonian" },
    { name: "Malagasy", code: "Malagasy" },
    { name: "Malay", code: "Malay" },
    { name: "Malayalam", code: "Malayalam" },
    { name: "Maltese", code: "Maltese" },
    { name: "Maori", code: "Maori" },
    { name: "Marathi", code: "Marathi" },
    { name: "Marshallese", code: "Marshallese" },
    { name: "Mongolian", code: "Mongolian" },
    { name: "Nauru", code: "Nauru" },
    { name: "Navajo", code: "Navajo" },
    { name: "Norwegian Bokmål", code: "Norwegian Bokmål" },
    { name: "North Ndebele", code: "North Ndebele" },
    { name: "Nepali", code: "Nepali" },
    { name: "Ndonga", code: "Ndonga" },
    { name: "Norwegian Nynorsk", code: "Norwegian Nynorsk" },
    { name: "Norwegian", code: "Norwegian" },
    { name: "Nuosu", code: "Nuosu" },
    { name: "South Ndebele", code: "South Ndebele" },
    { name: "Occitan", code: "Occitan" },
    { name: "Ojibwe", code: "Ojibwe" },
    { name: "Oromo", code: "Oromo" },
    { name: "Oriya", code: "Oriya" },
    { name: "Ossetian", code: "Ossetian" },
    { name: "Panjabi", code: "Panjabi" },
    { name: "Pali", code: "Pali" },
    { name: "Persian", code: "Persian" },
    { name: "Polish", code: "Polish" },
    { name: "Pashto", code: "Pashto" },
    { name: "Portuguese", code: "Portuguese" },
    { name: "Quechua", code: "Quechua" },
    { name: "Romansh", code: "Romansh" },
    { name: "Russian", code: "Russian" },
    { name: "Sanskrit", code: "Sanskrit" },
    { name: "Sardinian", code: "Sardinian" },
    { name: "Sindhi", code: "Sindhi" },
    { name: "Northern Sami", code: "Northern Sami" },
    { name: "Samoan", code: "Samoan" },
    { name: "Sango", code: "Sango" },
    { name: "Serbian", code: "Serbian" },
    { name: "Gaelic", code: "Gaelic" },
    { name: "Shona", code: "Shona" },
    { name: "Sinhalese", code: "Sinhalese" },
    { name: "Slovak", code: "Slovak" },
    { name: "Slovene", code: "Slovene" },
    { name: "Somali", code: "Somali" },
    { name: "Southern Sotho", code: "Southern Sotho" },
    { name: "Spanish", code: "Spanish" },
    { name: "Sundanese", code: "Sundanese" },
    { name: "Swahili", code: "Swahili" },
    { name: "Swati", code: "Swati" },
    { name: "Swedish", code: "Swedish" },
    { name: "Tamil", code: "Tamil" },
    { name: "Telugu", code: "Telugu" },
    { name: "Tajik", code: "Tajik" },
    { name: "Thai", code: "Thai" },
    { name: "Tigrinya", code: "Tigrinya" },
    { name: "Tibetan", code: "Tibetan" },
    { name: "Turkmen", code: "Turkmen" },
    { name: "Tagalog", code: "Tagalog" },
    { name: "Tswana", code: "Tswana" },
    { name: "Tonga", code: "Tonga" },
    { name: "Turkish", code: "Turkish" },
    { name: "Tsonga", code: "Tsonga" },
    { name: "Tatar", code: "Tatar" },
    { name: "Twi", code: "Twi" },
    { name: "Tahitian", code: "Tahitian" },
    { name: "Uighur", code: "Uighur" },
    { name: "Ukrainian", code: "Ukrainian" },
    { name: "Urdu", code: "Urdu" },
    { name: "Uzbek", code: "Uzbek" },
    { name: "Venda", code: "Venda" },
    { name: "Vietnamese", code: "Vietnamese" },
    { name: "Volapük", code: "Volapük" },
    { name: "Walloon", code: "Walloon" },
    { name: "Welsh", code: "Welsh" },
    { name: "Wolof", code: "Wolof" },
    { name: "Western Frisian", code: "Western Frisian" },
    { name: "Xhosa", code: "Xhosa" },
    { name: "Yiddish", code: "Yiddish" },
    { name: "Yoruba", code: "Yoruba" },
    { name: "Zhuang", code: "Zhuang" },
    { name: "Zulu", code: "Zulu" }
];

export const food_options = [
    { name: 'Veg', code: 'Veg' },
    { name: 'Non-Veg', code: 'Non-Veg' },
]

export const boolean_options = [
    { name: 'Yes', code: 'Yes' },
    { name: 'No', code: 'No' },
]

const jaccardSimilarity = (set1, set2) => {
    const intersection = new Set([...set1].filter(x => set2.has(x)));
    const union = new Set([...set1, ...set2]);
    return union.size === 0 ? 0 : intersection.size / union.size;
}


// export const calculateSimilarityScore = (loggedInUser, otherUser) => {
//     let score = 0;
//     const maxScore = 100; // Assuming the maximum score is 100
//     let totalWeight = 0;

//     // Define weights for each preference criteria
//     const weights = {
//         qualifications: 20,
//         age_range: 20,
//         religion: 20,
//         occupation: 20,
//         income: 10,
//         location: 10
//     };

//     // Utility function to check age range
//     function isAgeInRange(dob, ageRange) {
//         const currentDate = new Date();
//         const birthDate = new Date(dob);
//         const age = currentDate?.getFullYear() - birthDate?.getFullYear();
//         const [minAge, maxAge] = ageRange?.split(' - ')?.map(age => parseInt(age));
//         return age >= minAge && age <= maxAge;
//     }

//     // 1. Check qualifications
//     if (loggedInUser?.my_preferences?.qualifications?.includes(otherUser?.education_and_careers?.qualifications?.[0])) {
//         score += weights?.qualifications;
//     } else {
//         // console.log(`Qualification mismatch: Expected ${loggedInUser?.my_preferences?.qualifications}, got ${otherUser?.education_and_careers?.qualifications?.[0]}`);
//     }
//     totalWeight += weights?.qualifications;

//     // 2. Check age range
//     if (isAgeInRange(otherUser?.basic_details?.dob, loggedInUser?.my_preferences?.age_range?.[0])) {
//         score += weights?.age_range;
//     } else {
//         // console.log(`Age range mismatch: Expected ${loggedInUser?.my_preferences?.age_range}, got ${otherUser?.basic_details?.dob}`);
//     }
//     totalWeight += weights?.age_range;

//     // 3. Check religion
//     if (loggedInUser?.my_preferences?.religion === otherUser?.basic_details?.religion) {
//         score += weights?.religion;
//     } else {
//         // console.log(`Religion mismatch: Expected ${loggedInUser?.my_preferences?.religion}, got ${otherUser?.basic_details?.religion}`);
//     }
//     totalWeight += weights?.religion;

//     // 4. Check occupation
//     if (loggedInUser?.my_preferences?.occupation?.includes(otherUser?.education_and_careers?.occupation?.[0])) {
//         score += weights?.occupation;
//     } else {
//         // console.log(`Occupation mismatch: Expected ${loggedInUser?.my_preferences?.occupation}, got ${otherUser?.education_and_careers?.occupation}`);
//     }
//     totalWeight += weights?.occupation;

//     // 5. Check income
//     const otherUserIncome = otherUser?.education_and_careers?.annual_income;
//     if (otherUserIncome === loggedInUser?.my_preferences?.income) {
//         score += weights?.income;
//     } else {
//         // console.log(`Income mismatch: Expected ${loggedInUser?.my_preferences?.income}, got ${otherUser?.education_and_careers?.annual_income}`);
//     }
//     totalWeight += weights?.income;

//     // 6. Check location
//     const otherUserLocation = otherUser?.contact_details?.city;
//     if (loggedInUser?.my_preferences?.location?.includes(otherUserLocation)) {
//         score += weights?.location;
//     } else {
//         // console.log(`Location mismatch: Expected ${loggedInUser?.my_preferences?.location}, got ${otherUser?.contact_details?.city}`);
//     }
//     totalWeight += weights?.location;

//     // Calculate the final compatibility score as a percentage
//     const compatibilityScore = (score / totalWeight) * maxScore;

//     return compatibilityScore;
// }


export const calculateSimilarityScore = (loggedInUser, otherUser) => {
    let score = 0;
    const maxScore = 100; // Assuming the maximum score is 100
    let totalWeight = 0;

    // Define weights for each preference criteria
    const weights = {
        qualifications: 20,
        age_range: 20,
        religion: 20,
        occupation: 20,
        income: 10,
        location: 10
    };

    // Utility function to check age range
    function isAgeInRange(dob, ageRange) {
        if (!dob || !ageRange) return false;
        const currentDate = new Date();
        const birthDate = new Date(dob);
        const age = currentDate.getFullYear() - birthDate.getFullYear();
        const [minAge, maxAge] = ageRange.split(' - ').map(age => parseInt(age));
        return age >= minAge && age <= maxAge;
    }

    // 1. Check qualifications
    const loggedInQualifications = loggedInUser?.my_preferences?.qualifications || [];
    const otherQualifications = otherUser?.education_and_careers?.qualifications || [];
    if (loggedInQualifications.some(qual => otherQualifications.includes(qual))) {
        score += weights.qualifications;
    } else {
        console.log(`Qualification mismatch: Expected ${loggedInQualifications}, got ${otherQualifications}`);
    }
    totalWeight += weights.qualifications;

    // 2. Check age range
    const loggedInAgeRange = loggedInUser?.my_preferences?.age_range?.[0] || "";
    const otherUserDOB = otherUser?.basic_details?.dob || "";
    if (isAgeInRange(otherUserDOB, loggedInAgeRange)) {
        score += weights.age_range;
    } else {
        console.log(`Age range mismatch: Expected ${loggedInAgeRange}, got ${otherUserDOB}`);
    }
    totalWeight += weights.age_range;

    // 3. Check religion
    const loggedInReligion = loggedInUser?.my_preferences?.religion || "";
    const otherUserReligion = otherUser?.basic_details?.religion || "";
    if (loggedInReligion === otherUserReligion) {
        score += weights.religion;
    } else {
        console.log(`Religion mismatch: Expected ${loggedInReligion}, got ${otherUserReligion}`);
    }
    totalWeight += weights.religion;

    // 4. Check occupation
    const loggedInOccupation = loggedInUser?.my_preferences?.occupation || [];
    const otherOccupation = otherUser?.education_and_careers?.occupation || [];
    if (loggedInOccupation.some(occ => otherOccupation.includes(occ))) {
        score += weights.occupation;
    } else {
        console.log(`Occupation mismatch: Expected ${loggedInOccupation}, got ${otherOccupation}`);
    }
    totalWeight += weights.occupation;

    // 5. Check income
    const loggedInIncome = loggedInUser?.my_preferences?.income || "";
    const otherUserIncome = otherUser?.education_and_careers?.annual_income || "";
    if (loggedInIncome === otherUserIncome) {
        score += weights.income;
    } else {
        console.log(`Income mismatch: Expected ${loggedInIncome}, got ${otherUserIncome}`);
    }
    totalWeight += weights.income;

    // 6. Check location
    const loggedInLocation = loggedInUser?.my_preferences?.location || [];
    const otherUserLocation = otherUser?.contact_details?.city || "";
    if (loggedInLocation.includes(otherUserLocation)) {
        score += weights.location;
    } else {
        console.log(`Location mismatch: Expected ${loggedInLocation}, got ${otherUserLocation}`);
    }
    totalWeight += weights.location;

    // Calculate the final compatibility score as a percentage
    const compatibilityScore = (score / totalWeight) * maxScore;

    return compatibilityScore;
}


export const userTableHeader = [
    { title: 'Serial no.', grid: 1 },
    { title: 'User name', grid: 2 },
    { title: 'Mobile no.', grid: 2 },
    { title: 'Date', grid: 2 },
    { title: 'Gender', grid: 1 },
    { title: 'Plan', grid: 2 },
    { title: 'Active', grid: 1 },
    { title: 'Actions', grid: 1 }
]

export const permissionsTableHeader = [
    { title: 'Serial no.', grid: 1 },
    { title: 'Permission name', grid: 6 },
    { title: 'Assigned To', grid: 3 },
    { title: 'Status', grid: 1 },
    { title: 'Actions', grid: 1 }
]

export const verificationSalesTableHeader = [
    { title: 'Serial no.', grid: 1 },
    { title: 'User', grid: 5 },
    { title: 'Amount', grid: 2 },
    { title: 'Billing Date', grid: 2 },
    { title: 'Receipt', grid: 2 }
]

export const subAdminsTableHeader = [
    { title: 'Serial no.', grid: 1 },
    { title: 'Name', grid: 2 },
    { title: 'Email', grid: 2 },
    { title: 'Date', grid: 2 },
    { title: 'Permissions', grid: 3 },
    { title: 'Status', grid: 1 },
    { title: 'Actions', grid: 1 }
]

export const userInvoicesTableHeader = [
    { title: 'Serial no.', grid: 1 },
    { title: 'Plan', grid: 2 },
    { title: 'Billing Date', grid: 3 },
    { title: 'Expiry', grid: 2 },
    { title: 'Amount', grid: 2 },
    { title: 'Receipt', grid: 2 },
]

export const adminInvoicesTableHeader = [
    { title: 'Serial no.', grid: 1 },
    { title: 'User', grid: 2 },
    { title: 'Plan', grid: 1 },
    { title: 'Billing Date', grid: 2 },
    { title: 'Expiry', grid: 2 },
    { title: 'Amount', grid: 2 },
    { title: 'Receipt', grid: 2 }
]

export const browseProfilesOptions = [
    { name: '5 Profiles', code: '5 Profiles' },
    { name: '10 Profiles', code: '10 Profiles' },
    { name: '15 Profiles', code: '15 Profiles' },
    { name: '20 Profiles', code: '20 Profiles' },
    { name: 'Unlimited', code: 'Unlimited' }
]

export const sendInterestOptions = [
    { name: '5 Profiles', code: '5 Profiles' },
    { name: '10 Profiles', code: '10 Profiles' },
    { name: '15 Profiles', code: '15 Profiles' },
    { name: '20 Profiles', code: '20 Profiles' },
    { name: 'Unlimited', code: 'Unlimited' }
]

export const receiveInterestOptions = [
    { name: '5 Profiles', code: '5 Profiles' },
    { name: '10 Profiles', code: '10 Profiles' },
    { name: '15 Profiles', code: '15 Profiles' },
    { name: '20 Profiles', code: '20 Profiles' },
    { name: 'Unlimited', code: 'Unlimited' }
]

export const featuredOptions = [
    { name: '5 Days', code: '5 Days' },
    { name: '10 Days', code: '10 Days' },
    { name: '15 Days', code: '15 Days' },
    { name: '20 Days', code: '20 Days' },
    { name: 'Unlimited', code: 'Unlimited' }
]

export const videoOptions = [
    { name: '5 Mins', code: '5 Mins' },
    { name: '30 Mins', code: '30 Mins' },
    { name: '50 Mins', code: '50 Mins' },
    { name: 'Unlimited', code: 'Unlimited' }
]

export const maskNumber = (number) => {
    let numStr = number?.toString();
    let lastThree = numStr?.slice(-3);
    let masked = '*'.repeat(numStr?.length - 3) + lastThree;
    return masked;
}

export const maskEmail = (email) => {
    let [local, domain] = email.split('@');
    let maskedLocal = '*'.repeat(local.length);
    return maskedLocal + '@' + domain;
}

export const maskOther = (input, num) => {
    if (input?.length < 0) {
        return "Input is too short";
    }
    const firstTwoLetters = input?.substring(0, num);
    const maskedString = firstTwoLetters + "*****";
    return maskedString;
}

export const checkEmptyProfileFields = (data) => {

    const elementsToCheck = [
        'basic_details',
        'contact_details',
        'education_and_careers',
        'life_style_and_hobbies',
        'family_details',
        'my_preferences',
        'profile_picture'
    ];

    for (const element of elementsToCheck) {
        if (data?.[element]) {
            for (const key in data?.[element]) {
                if (element === 'contact_details' && (key === 'parent_address' || key === 'alternate_email_id')) {
                    continue;
                }
                if (element === 'my_preferences' && key === 'education') {
                    continue;
                }

                const value = data?.[element]?.[key];
                // console.log(`Checking element: ${element}, key: ${key}, value: ${value}`);

                if (Array.isArray(value)) {
                    if (value?.length === 0) {
                        // console.log(`Empty array found at element: ${element}, key: ${key}`);
                        return true;
                    }
                } else if (
                    value === '' ||
                    value === 0 ||
                    value === null ||
                    value === undefined
                ) {
                    // console.log(`Empty field found at element: ${element}, key: ${key}`);
                    return true;
                }
            }
        } else {
            // console.log(`Missing section: ${element}`);
            return true; // Entire section is missing
        }
    }

    return false;
}


export const extractNumber = (str) => {
    const match = str?.match(/\d+/);
    return match ? parseInt(match[0], 10) : NaN;
}


export const formatPrice = (value) => {
    const formatted_price = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(value || 0);
    return formatted_price
}

export const avatar_male = 'user-avatar-male.jpg'
export const avatar_female = 'user-avatar-female.jpg'

export const getAvatar = (user) => {
    let avatar = user?.basic_details?.gender === 'Male' ? avatar_male : user?.basic_details?.gender === 'Female' ? avatar_female : 'user-avatar.png'
    if (avatar) {
        return avatar
    }
}

export const edit_div = {
    position: 'absolute',
    right: '2%',
    bottom: '6%',
    background: app_color,
    borderRadius: '50%',
    height: '30px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const formatDateTime = (date) => {
    let dateObject = new Date(date) || ''
    let day = dateObject?.getDate();
    let month = months[dateObject?.getMonth()];
    let year = dateObject?.getFullYear();
    let hours = dateObject?.getHours();
    let minutes = dateObject?.getMinutes();
    let formattedTime = hours >= 12 ? 'PM' : 'AM';
    let formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    let formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedDateTime = `${day} ${month} ${year} at ${formattedHours}:${formattedMinutes} ${formattedTime}`;
    return formattedDateTime
}

export const featuredLimitDays = (item, featuredLimit) => {

    const purchased_date = new Date(item?.billing?.createdAt);
    const current_date = new Date();
    const end_date = new Date(purchased_date);

    end_date.setDate(purchased_date.getDate() + featuredLimit);
    end_date.setHours(23, 59, 59, 999);

    const isWithinLimit = current_date <= end_date;

    // console.log('Purchased Date:', purchased_date);
    // console.log('End Date:', item?.first_name, item?.billing?.plan_type, end_date);
    // console.log('Is Within Limit:', isWithinLimit);

    return isWithinLimit;
};

export const indian_cities = [
    { name: 'Mumbai', code: 'Mumbai' },
    { name: 'Delhi', code: 'Delhi' },
    { name: 'Bangalore', code: 'Bangalore' },
    { name: 'Kolkata', code: 'Kolkata' },
    { name: 'Chennai', code: 'Chennai' },
    { name: 'Hyderabad', code: 'Hyderabad' },
    { name: 'Ahmedabad', code: 'Ahmedabad' },
    { name: 'Pune', code: 'Pune' },
    { name: 'Surat', code: 'Surat' },
    { name: 'Jaipur', code: 'Jaipur' },
    { name: 'Lucknow', code: 'Lucknow' },
    { name: 'Kanpur', code: 'Kanpur' },
    { name: 'Nagpur', code: 'Nagpur' },
    { name: 'Visakhapatnam', code: 'Visakhapatnam' },
    { name: 'Indore', code: 'Indore' },
    { name: 'Thane', code: 'Thane' },
    { name: 'Bhopal', code: 'Bhopal' },
    { name: 'Patna', code: 'Patna' },
    { name: 'Vadodara', code: 'Vadodara' },
    { name: 'Ghaziabad', code: 'Ghaziabad' },
    { name: 'Ludhiana', code: 'Ludhiana' },
    { name: 'Coimbatore', code: 'Coimbatore' },
    { name: 'Agra', code: 'Agra' },
    { name: 'Madurai', code: 'Madurai' },
    { name: 'Nashik', code: 'Nashik' },
    { name: 'Vijayawada', code: 'Vijayawada' },
    { name: 'Faridabad', code: 'Faridabad' },
    { name: 'Meerut', code: 'Meerut' },
    { name: 'Rajkot', code: 'Rajkot' },
    { name: 'Kalyan-Dombivli', code: 'Kalyan-Dombivli' },
    { name: 'Vasai-Virar', code: 'Vasai-Virar' },
    { name: 'Varanasi', code: 'Varanasi' },
    { name: 'Srinagar', code: 'Srinagar' },
    { name: 'Aurangabad', code: 'Aurangabad' },
    { name: 'Dhanbad', code: 'Dhanbad' },
    { name: 'Amritsar', code: 'Amritsar' },
    { name: 'Navi Mumbai', code: 'Navi Mumbai' },
    { name: 'Allahabad', code: 'Allahabad' },
    { name: 'Ranchi', code: 'Ranchi' },
    { name: 'Howrah', code: 'Howrah' },
    { name: 'Jabalpur', code: 'Jabalpur' },
    { name: 'Gwalior', code: 'Gwalior' },
    { name: 'Jodhpur', code: 'Jodhpur' },
    { name: 'Raipur', code: 'Raipur' },
    { name: 'Kota', code: 'Kota' },
    { name: 'Guwahati', code: 'Guwahati' },
    { name: 'Chandigarh', code: 'Chandigarh' },
    { name: 'Thiruvananthapuram', code: 'Thiruvananthapuram' },
    { name: 'Solapur', code: 'Solapur' },
    { name: 'Hubballi-Dharwad', code: 'Hubballi-Dharwad' },
    { name: 'Tiruchirappalli', code: 'Tiruchirappalli' },
    { name: 'Bareilly', code: 'Bareilly' },
    { name: 'Moradabad', code: 'Moradabad' },
    { name: 'Mysore', code: 'Mysore' },
    { name: 'Tiruppur', code: 'Tiruppur' },
    { name: 'Gurgaon', code: 'Gurgaon' },
    { name: 'Aligarh', code: 'Aligarh' },
    { name: 'Jalandhar', code: 'Jalandhar' },
    { name: 'Bhubaneswar', code: 'Bhubaneswar' },
    { name: 'Salem', code: 'Salem' },
    { name: 'Warangal', code: 'Warangal' },
    { name: 'Guntur', code: 'Guntur' },
    { name: 'Bhiwandi', code: 'Bhiwandi' },
    { name: 'Saharanpur', code: 'Saharanpur' },
    { name: 'Gorakhpur', code: 'Gorakhpur' },
    { name: 'Bikaner', code: 'Bikaner' },
    { name: 'Amravati', code: 'Amravati' },
    { name: 'Noida', code: 'Noida' },
    { name: 'Jamshedpur', code: 'Jamshedpur' },
    { name: 'Bhilai', code: 'Bhilai' },
    { name: 'Cuttack', code: 'Cuttack' },
    { name: 'Firozabad', code: 'Firozabad' },
    { name: 'Kochi', code: 'Kochi' },
    { name: 'Nellore', code: 'Nellore' },
    { name: 'Bhavnagar', code: 'Bhavnagar' },
    { name: 'Dehradun', code: 'Dehradun' },
    { name: 'Durgapur', code: 'Durgapur' },
    { name: 'Asansol', code: 'Asansol' },
    { name: 'Rourkela', code: 'Rourkela' },
    { name: 'Nanded', code: 'Nanded' },
    { name: 'Kolhapur', code: 'Kolhapur' },
    { name: 'Ajmer', code: 'Ajmer' },
    { name: 'Akola', code: 'Akola' },
    { name: 'Gulbarga', code: 'Gulbarga' },
    { name: 'Jamnagar', code: 'Jamnagar' },
    { name: 'Ujjain', code: 'Ujjain' },
    { name: 'Loni', code: 'Loni' },
    { name: 'Siliguri', code: 'Siliguri' },
    { name: 'Jhansi', code: 'Jhansi' },
    { name: 'Ulhasnagar', code: 'Ulhasnagar' },
    { name: 'Jammu', code: 'Jammu' },
    { name: 'Sangli-Miraj & Kupwad', code: 'Sangli-Miraj & Kupwad' },
    { name: 'Mangalore', code: 'Mangalore' },
    { name: 'Erode', code: 'Erode' },
    { name: 'Belgaum', code: 'Belgaum' },
    { name: 'Ambattur', code: 'Ambattur' },
    { name: 'Tirunelveli', code: 'Tirunelveli' },
    { name: 'Malegaon', code: 'Malegaon' },
    { name: 'Gaya', code: 'Gaya' },
    { name: 'Jalgaon', code: 'Jalgaon' },
    { name: 'Udaipur', code: 'Udaipur' },
    { name: 'Maheshtala', code: 'Maheshtala' },
    { name: 'Davanagere', code: 'Davanagere' },
    { name: 'Kozhikode', code: 'Kozhikode' },
    { name: 'Kurnool', code: 'Kurnool' },
    { name: 'Rajpur Sonarpur', code: 'Rajpur Sonarpur' },
    { name: 'Rajahmundry', code: 'Rajahmundry' },
    { name: 'Bokaro', code: 'Bokaro' },
    { name: 'South Dumdum', code: 'South Dumdum' },
    { name: 'Bellary', code: 'Bellary' },
    { name: 'Patiala', code: 'Patiala' },
    { name: 'Gopalpur', code: 'Gopalpur' },
    { name: 'Agartala', code: 'Agartala' },
    { name: 'Bhagalpur', code: 'Bhagalpur' },
    { name: 'Muzaffarnagar', code: 'Muzaffarnagar' },
    { name: 'Bhatpara', code: 'Bhatpara' },
    { name: 'Panihati', code: 'Panihati' },
    { name: 'Latur', code: 'Latur' },
    { name: 'Dhule', code: 'Dhule' },
    { name: 'Tirupati', code: 'Tirupati' },
    { name: 'Rohtak', code: 'Rohtak' },
    { name: 'Korba', code: 'Korba' },
    { name: 'Bhilwara', code: 'Bhilwara' },
    { name: 'Berhampur', code: 'Berhampur' },
    { name: 'Muzaffarpur', code: 'Muzaffarpur' },
    { name: 'Ahmednagar', code: 'Ahmednagar' },
    { name: 'Mathura', code: 'Mathura' },
    { name: 'Kollam', code: 'Kollam' },
    { name: 'Avadi', code: 'Avadi' },
    { name: 'Kadapa', code: 'Kadapa' },
    { name: 'Anantapur', code: 'Anantapur' },
    { name: 'Kamarhati', code: 'Kamarhati' },
    { name: 'Bilaspur', code: 'Bilaspur' },
    { name: 'Shahjahanpur', code: 'Shahjahanpur' },
    { name: 'Satara', code: 'Satara' },
    { name: 'Bijapur', code: 'Bijapur' },
    { name: 'Rampur', code: 'Rampur' },
    { name: 'Shivamogga', code: 'Shivamogga' },
    { name: 'Chandrapur', code: 'Chandrapur' },
    { name: 'Junagadh', code: 'Junagadh' },
    { name: 'Thrissur', code: 'Thrissur' },
    { name: 'Alwar', code: 'Alwar' },
    { name: 'Bardhaman', code: 'Bardhaman' },
    { name: 'Kulti', code: 'Kulti' },
    { name: 'Kakinada', code: 'Kakinada' },
    { name: 'Nizamabad', code: 'Nizamabad' },
    { name: 'Parbhani', code: 'Parbhani' },
    { name: 'Tumkur', code: 'Tumkur' },
    { name: 'Khammam', code: 'Khammam' },
    { name: 'Ozhukarai', code: 'Ozhukarai' },
    { name: 'Bihar Sharif', code: 'Bihar Sharif' },
    { name: 'Panipat', code: 'Panipat' },
    { name: 'Darbhanga', code: 'Darbhanga' },
    { name: 'Bally', code: 'Bally' },
    { name: 'Aizawl', code: 'Aizawl' },
    { name: 'Dewas', code: 'Dewas' },
    { name: 'Ichalkaranji', code: 'Ichalkaranji' },
    { name: 'Karnal', code: 'Karnal' },
    { name: 'Bathinda', code: 'Bathinda' },
    { name: 'Jalna', code: 'Jalna' },
    { name: 'Eluru', code: 'Eluru' },
    { name: 'Kirari Suleman Nagar', code: 'Kirari Suleman Nagar' },
    { name: 'Barasat', code: 'Barasat' },
    { name: 'Purnia', code: 'Purnia' },
    { name: 'Satna', code: 'Satna' },
    { name: 'Mau', code: 'Mau' },
    { name: 'Sonipat', code: 'Sonipat' },
    { name: 'Farrukhabad', code: 'Farrukhabad' },
    { name: 'Sagar', code: 'Sagar' },
    { name: 'Rourkela', code: 'Rourkela' },
    { name: 'Durg', code: 'Durg' },
    { name: 'Imphal', code: 'Imphal' },
    { name: 'Ratlam', code: 'Ratlam' },
    { name: 'Hapur', code: 'Hapur' },
    { name: 'Arrah', code: 'Arrah' },
    { name: 'Anantapur', code: 'Anantapur' },
    { name: 'Karimnagar', code: 'Karimnagar' },
    { name: 'Etawah', code: 'Etawah' },
    { name: 'Ambarnath', code: 'Ambarnath' },
    { name: 'North Dumdum', code: 'North Dumdum' },
    { name: 'Bharatpur', code: 'Bharatpur' },
    { name: 'Begusarai', code: 'Begusarai' },
    { name: 'New Delhi', code: 'New Delhi' },
    { name: 'Gandhidham', code: 'Gandhidham' },
    { name: 'Baranagar', code: 'Baranagar' },
    { name: 'Tiruvottiyur', code: 'Tiruvottiyur' },
    { name: 'Pondicherry', code: 'Pondicherry' },
    { name: 'Sikar', code: 'Sikar' },
    { name: 'Thoothukudi', code: 'Thoothukudi' },
    { name: 'Rewa', code: 'Rewa' },
    { name: 'Mirzapur', code: 'Mirzapur' },
    { name: 'Raichur', code: 'Raichur' },
    { name: 'Pali', code: 'Pali' },
    { name: 'Ramagundam', code: 'Ramagundam' },
    { name: 'Silchar', code: 'Silchar' },
    { name: 'Haridwar', code: 'Haridwar' },
    { name: 'Vijayanagaram', code: 'Vijayanagaram' },
    { name: 'Tenali', code: 'Tenali' },
    { name: 'Nagercoil', code: 'Nagercoil' },
    { name: 'Sri Ganganagar', code: 'Sri Ganganagar' },
    { name: 'Karawal Nagar', code: 'Karawal Nagar' },
    { name: 'Mango', code: 'Mango' },
    { name: 'Thanjavur', code: 'Thanjavur' },
    { name: 'Bulandshahr', code: 'Bulandshahr' },
    { name: 'Uluberia', code: 'Uluberia' },
    { name: 'Katihar', code: 'Katihar' },
    { name: 'Sambhal', code: 'Sambhal' },
    { name: 'Singrauli', code: 'Singrauli' },
    { name: 'Nadiad', code: 'Nadiad' },
    { name: 'Secunderabad', code: 'Secunderabad' },
    { name: 'Naihati', code: 'Naihati' },
    { name: 'Yamunanagar', code: 'Yamunanagar' },
    { name: 'Bidar', code: 'Bidar' },
    { name: 'Pallavaram', code: 'Pallavaram' },
    { name: 'Bidhan Nagar', code: 'Bidhan Nagar' },
    { name: 'Munger', code: 'Munger' },
    { name: 'Panchkula', code: 'Panchkula' },
    { name: 'Burhanpur', code: 'Burhanpur' },
    { name: 'Raurkela Industrial Township', code: 'Raurkela Industrial Township' },
    { name: 'Kharagpur', code: 'Kharagpur' },
    { name: 'Dindigul', code: 'Dindigul' },
    { name: 'Gandhinagar', code: 'Gandhinagar' },
    { name: 'Hospet', code: 'Hospet' },
    { name: 'Nangloi Jat', code: 'Nangloi Jat' },
    { name: 'Malda', code: 'Malda' },
    { name: 'Ongole', code: 'Ongole' },
    { name: 'Deoghar', code: 'Deoghar' },
    { name: 'Chapra', code: 'Chapra' },
    { name: 'Haldia', code: 'Haldia' },
    { name: 'Khandwa', code: 'Khandwa' },
    { name: 'Nandyal', code: 'Nandyal' },
    { name: 'Morena', code: 'Morena' },
    { name: 'Amroha', code: 'Amroha' },
    { name: 'Anand', code: 'Anand' },
    { name: 'Bhind', code: 'Bhind' },
    { name: 'Bhalswa Jahangir Pur', code: 'Bhalswa Jahangir Pur' },
    { name: 'Madhyamgram', code: 'Madhyamgram' },
    { name: 'Bhiwani', code: 'Bhiwani' },
    { name: 'Berhampore', code: 'Berhampore' }
]