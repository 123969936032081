import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useDatabaseConstants } from "../../Constants/globalConstants";

export default function OrderSummary({
    step,
    amount,
    screen,
    discountedAmount,
    savedAmount
}) {

    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'));
    const { app_color } = useDatabaseConstants();


    const GridItem = ({ label, value }) => {
        const formated_price = new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(value || 0);
        return (
            <Grid container spacing={0}>
                <Grid item sm={6} style={{ width: '50%', display: 'flex', justifyContent: 'left' }}>
                    <p style={{ fontSize: 15, margin: '5% 0' }}>{label}</p>
                </Grid>
                <Grid item sm={6} style={{ width: '50%', display: 'flex', justifyContent: 'right' }}>
                    <p style={{ fontSize: 15, margin: '5% 0' }}>{formated_price || '-'}</p>
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            <div style={{
                background: 'white', borderRadius: 10,
                width: '100%', border: step !== 'last' && screen !== 'success' ? '1px solid #ededed' : '', padding: step !== 'last' && screen !== 'success' ? '5%' : '', borderRadius: 10
            }}>
                {
                    step !== 'last' && screen !== 'success' && (
                        <h3 style={{ fontWeight: 600, fontSize: matches_md ? 20 : 20, margin: 0, marginBottom: '4%' }}>Order Summary</h3>
                    )
                }
                <GridItem label="Original Price" value={amount} />
                <GridItem label="Amount Payable" value={discountedAmount || amount} />
                <GridItem label="Saved" value={savedAmount} />
                <hr style={{ borderColor: '#ededed' }} />
                <GridItem label="Total" value={discountedAmount || amount} />
            </div>
        </>
    );
}
