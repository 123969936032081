import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal, { modalClasses } from "@mui/material/Modal";
import { Grid, colors } from "@mui/material";
import { secondaryDarkColor, useDatabaseConstants } from "../../../Constants/globalConstants";
import { Link, useNavigate } from "react-router-dom";
import { DarkModeContext } from "./DarkModeContext";

export default function LimitExceedModal({
    open,
    setOpen,
}) {

    const handleClose = () => setOpen(false);
    const { app_color } = useDatabaseConstants();
    const navigate = useNavigate();
    const { darkMode } = React.useContext(DarkModeContext);


    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 550,
        bgcolor: darkMode ? secondaryDarkColor : "white",
        boxShadow: 24,
        borderRadius: 4,
        padding: "3%",
    };

    const modal_title = {
        fontSize: 25,
        fontWeight: 400,
        color: app_color,
        textAlign: "center",
    };

    const modal_para = {
        fontWeight: 500,
        textAlign: "center",
        fontSize: 14,
        color: "#00000080",
    };

    const button_right_style = {
        background: app_color,
        width: "50%",
        color: "white",
        boxShadow: "none",
        borderRadius: 7,
        fontWeight: 500,
        fontSize: 15,
        textTransform: "capitalize",
        padding: "3% 0",
    };

    const button_left_style = {
        background: "white",
        width: "50%",
        color: app_color,
        boxShadow: "none",
        borderRadius: 7,
        padding: "3% 0",
        fontWeight: 500,
        fontSize: 15,
        textTransform: "capitalize",
        border: `2px solid ${app_color}`,
    };

    const disabled_btn = {
        background: "#9e004f8e",
    };

    const handleRightBtnClick = () => {
        navigate('/pricing')
        window.scrollTo(0, 0)
    };

    const handleLeftBtnClick = async () => {
        handleClose();
    };


    return (
        <div>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <div
                        onClick={handleClose}
                        style={{
                            position: "absolute",
                            right: "3%",
                            top: "3%",
                            cursor: "pointer",
                        }}
                    >
                        <img
                            src="/images/modal-close-icon.svg"
                            style={{ width: "100%", height: "100%" }}
                        />
                    </div>
                    <Grid container spacing={0}>

                        {
                            !darkMode && (
                                <Grid item md={12}>
                                    <center>
                                        <img
                                            src={`/images/${'modal-img-plans'}.svg`}
                                            style={{ width: "70%", height: "70%" }}
                                        />
                                    </center>
                                </Grid>
                            )
                        }

                        <Grid
                            item
                            md={12}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <h2 style={{ ...modal_title, color: darkMode ? 'white' : app_color }}>
                                You have exceed limit!
                            </h2>
                            <p style={{ ...modal_para, color: darkMode ? 'gray' : '#00000080' }}>
                                Please upgrade your Plan, if you want to accept/decline more interests from people.
                            </p>
                        </Grid>

                        <Grid
                            item
                            md={9}
                            style={{
                                display: "flex",
                                gap: "4%",
                                margin: "6% auto 0 auto",
                            }}
                        >
                            <Button
                                onClick={handleRightBtnClick}
                                variant="contained"
                                style={{ ...button_right_style, width: "100%" }}
                            >
                                Upgrade Your Plan
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
