import axios from "axios";

export const serverURL = "https://api.dilsey.in";
// export const serverURL = "http://localhost:8000";

// export const socketServerUrl = "http://localhost:8000";
export const socketServerUrl = "https://api.dilsey.in";

const axiosRequest = axios.create({
  baseURL: serverURL,
  // withCredentials: true,
});

axiosRequest.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`; // Set Authorization header if token exists
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosRequest;
