import React from "react";
import { Button, Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function AppComponent({ screen }) {

  const theme = useTheme();
  const matches_md = useMediaQuery(theme.breakpoints.down("md"));

  const rightImgSection = () => {
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Grid container spacing={0}>
            <Grid item md={6} style={{ display: "flex", flexDirection: "column", justifyContent: "center", position: 'relative' }}>
              <h2 className="happiness-div-title" style={{ color: 'black' }}>
                Love On-the-Go - Get the DilSey App
              </h2>
              <p className="happiness-div-para" style={{ color: 'black' }}>
                Download the DilSey app and find your perfect match, right at your fingertips.
              </p>
              <div
                className="happiness-images"
                style={{ display: "flex", justifyContent: "left" }}
              >
                <img
                  src={`/images/google-play.svg`}
                  style={{ marginRight: "10px" }}
                />
                <img src={`/images/app-store.svg`} />
              </div>
            </Grid>
            <Grid item md={6} style={{ display: "flex", justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
              <img src="/images/app-promo-image.svg" style={{ width: 250, position: "absolute", top: '-30%' }} />
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="app-section" style={{ background: '#f7e4ed' }}>
        <div>{rightImgSection()}</div>
      </div>
    </>
  );
}



// import React from 'react';
// import { Box, Container, Grid, Typography } from '@mui/material';
// import Heading from './Heading';

// const AppComponent = () => {
//   return (
//     <Box
//       sx={{
//         padding: (theme) => theme.spacing(12, 0),
//         textAlign: 'center',
//         position: 'relative',
//       }}
//     >
//       <Container>
//         <Heading title={'Download our app'} />
//         <Grid container spacing={1} justifyContent="center" style={{ marginTop: '1px' }}>
//           <Grid item>
//             <img
//               src="/images/google-play.svg"
//               alt="Get it on Google Play"
//               style={{
//                 width: '150px',
//                 margin: (theme) => theme.spacing(1),
//               }}
//             />
//           </Grid>
//           <Grid item>
//             <img
//               src="/images/app-store.svg"
//               alt="Download on the App Store"
//               style={{
//                 width: '150px',
//                 margin: (theme) => theme.spacing(1),
//               }}
//             />
//           </Grid>
//         </Grid>
//       </Container>
//       <img
//         src="/images/petal-2.svg"
//         alt="Flower decoration"
//         style={{
//           position: 'absolute',
//           left: 0,
//           top: '50%',
//           transform: 'translateY(-50%)',
//           width: 'auto',
//           height: '100%',
//           zIndex: -1,
//         }}
//       />
//       <img
//         src="/images/petal-3.svg"
//         alt="Flower decoration"
//         style={{
//           position: 'absolute',
//           right: 0,
//           top: '50%',
//           transform: 'translateY(-50%)',
//           width: 'auto',
//           height: '100%',
//           zIndex: -1,
//         }}
//       />
//     </Box>
//   );
// };

// export default AppComponent;
