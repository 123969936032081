import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading";
import PricingPlans from "../Components/PricingPlans";
import Subscription from "../Components/Subscription";
import { useSelector } from "react-redux";
import { get_data } from "../../../Api";
import BackButton from "../Components/BackButton";
import { DarkModeContext } from "../Components/DarkModeContext";

export default function Billing() {
  const { user_data } = useSelector((state) => state.user);
  const [isPlan, setIsPlan] = useState(false);
  const [maxPlan, setMaxPlan] = useState({});
  const [allPlansPermisison, setAllPlansPermissions] = useState([]);

  const billing_page = {
    width: "100%",
  };

  const page_container = {
    width: "100%",
    margin: "2% 0",
    background: "transparent",
  };

  useEffect(() => {
    if (user_data?.plan_id?.[0]?._id) {
      setIsPlan(true);
    }
  }, [user_data]);

  const fetchPlansPermissions = async () => {
    const response = await get_data("permission/get-all-permission");
    if (response?.status) {
      setAllPlansPermissions(
        response?.data?.filter?.((item) => item?.is_active)
      );
    }
  };

  useEffect(() => {
    fetchPlansPermissions();
  }, []);

  return (
    <>
      <Grid
        container
        spacing={0}
        style={{
          ...billing_page,
          display: "flex",
          alignItems: "center",
          gap: "1%",
        }}
      >
        <BackButton />
        <DashboardHeading title="Billing and Subscription 💳" />

        <div style={page_container}>
          {isPlan ? (
            <>
              <Subscription />
              <PricingPlans
                is_purchased={true}
                allPlansPermisison={allPlansPermisison}
                fetchPlansPermissions={fetchPlansPermissions}
                setAllPlansPermissions={setAllPlansPermissions}
                screen="Dashboard"
              />
            </>
          ) : (
            <>
              <PricingPlans
                allPlansPermisison={allPlansPermisison}
                fetchPlansPermissions={fetchPlansPermissions}
                setAllPlansPermissions={setAllPlansPermissions}
                screen="Dashboard"
              />
            </>
          )}
        </div>
      </Grid>
    </>
  );
}
