import React, { useContext } from "react";
import { useState, useEffect } from "react";
import {
  age_range_options_female,
  age_range_options_male,
  annual_income_options,
  religion_options,
  occupation_options,
  languages_options,
  getFieldName,
  state_options,
  useDatabaseConstants,
  secondaryDarkColor,
  indian_cities,
} from "../../../Constants/globalConstants";
import {
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import DefaultMultiSelect from "./DefaultMultiSelect";
import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { CiLock } from "react-icons/ci";
import { get_data, post_data } from "../../../Api";
import { DarkModeContext } from "./DarkModeContext";
import { BiHandicap } from "react-icons/bi";
import "react-tooltip/dist/react-tooltip.css";

export default function FilterBar({
  permissions,
  setProfileLoadingType,
  setProfileLoading,
  setAllUsers,
  profileLoadingType,
  fetchAllUsers,
  setTotalPages,
  currentPage,
  setTotalUsers,
  isFilter,
  setIsFilter
}) {
  const { darkMode } = useContext(DarkModeContext);
  const [formData, setFormData] = useState({});
  const { user_data } = useSelector((state) => state.user);
  const [noPlan, setNoPlan] = useState(false);
  const { app_color } = useDatabaseConstants();
  const [searchValue, setSearchValue] = useState("");
  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const itemsPerPage = 15;

  const options = {
    income: annual_income_options,
    age_range:
      user_data?.basic_details?.gender === "Male"
        ? age_range_options_female
        : age_range_options_male,
    religion: religion_options,
    languages: languages_options,
    // state: state_options,
    city: indian_cities,
    occupation: occupation_options,
  };

  const filterOptions = [
    {
      field: "Age Range",
      value: options?.age_range,
      has_permission: true,
      type: "multi_select",
    },
    {
      field: "Income",
      value: options?.income,
      has_permission:
        noPlan === true
          ? false
          : permissions?.hasOwnProperty("unlock_access_to_advanced_search")
            ? permissions?.unlock_access_to_advanced_search === "Yes"
              ? true
              : false
            : true,
      type: "select",
    },
    {
      field: "Religion",
      value: options?.religion,
      has_permission: true,
      type: "select",
    },
    {
      field: "City",
      value: options?.city,
      has_permission:
        noPlan === true
          ? false
          : permissions?.hasOwnProperty("unlock_access_to_advanced_search")
            ? permissions?.unlock_access_to_advanced_search === "Yes"
              ? true
              : false
            : true,
      type: "multi_select",
    },
    {
      field: "Languages",
      value: options?.languages,
      has_permission:
        noPlan === true
          ? false
          : permissions?.hasOwnProperty("unlock_access_to_advanced_search")
            ? permissions?.unlock_access_to_advanced_search === "Yes"
              ? true
              : false
            : true,
      type: "multi_select",
    },
    {
      field: "Occupation",
      value: options?.occupation,
      has_permission:
        noPlan === true
          ? false
          : permissions?.hasOwnProperty("unlock_access_to_advanced_search")
            ? permissions?.unlock_access_to_advanced_search === "Yes"
              ? true
              : false
            : true,
      type: "multi_select",
    },
  ];

  const input_style = {
    margin: "3% 0",
    position: "relative",
  };

  const select_fields = filterOptions?.filter(
    (item) => item?.type === "select"
  );
  const multi_select = filterOptions?.filter(
    (item) => item?.type === "multi_select"
  );

  const handleInputChange = (e, field, i, type) => {
    setFormData({ ...formData, [e.target.name]: e?.target?.value });
    setProfileLoadingType("filter");
  };

  useEffect(() => {
    if (profileLoadingType === "filter") {
      setProfileLoading(true);
      const timeoutId = setTimeout(async () => {
        let payload = {
          gender:
            user_data?.basic_details?.gender === "Male" ? "Female" : "Male",
          age_range: formData?.age_range?.age_range,
          languages: formData?.languages?.languages,
          occupation: formData?.occupation?.occupation,
          city: formData?.city?.city,
          religion: formData?.religion?.name,
          income: formData?.income?.name,
        };
        const response = await post_data(`user/get-users-by-filters?pageNumber=${currentPage}`, payload);
        if (response?.status) {
          setIsFilter(true);
          setAllUsers(response?.data?.users);
          setTotalPages(response?.data?.totalPages);
          setTotalUsers(response?.data?.totalUsers);
        }
        setProfileLoading(false);
        setProfileLoadingType("");
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [formData, profileLoadingType]);

  const lock_styles = {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    zIndex: 99,
  };

  const multiSelect = () => {
    return multi_select?.map((field, i) => {
      const fieldOptions =
        field && typeof field.value === "string" ? field.value.split(",") : [];
      return (
        <>
          <Grid item md={6} style={{ margin: "1% 0" }}>
            <div
              style={{
                ...input_style,
                opacity:
                  noPlan && !field?.has_permission
                    ? "50%"
                    : field?.has_permission
                      ? "100%"
                      : "50%",
              }}
            >
              {field?.has_permission === false && (
                <>
                  <div style={lock_styles}>
                    <CiLock
                      style={{ width: 30, height: 30, opacity: "100%" }}
                    />
                  </div>
                </>
              )}
              <label className="input-label color-label">{field?.field}</label>
              <div className="multi-select">
                <DefaultMultiSelect
                  setProfileLoadingType={setProfileLoadingType}
                  setProfileLoading={setProfileLoading}
                  setAllUsers={setAllUsers}
                  disabled={
                    noPlan && !field?.has_permission
                      ? true
                      : field?.has_permission
                        ? false
                        : true
                  }
                  options={fieldOptions}
                  formData={formData}
                  currentPage={currentPage}
                  setIsFilter={setIsFilter}
                  setFormData={setFormData}
                  setSearchValue={setSearchValue}
                  setIsMultiSelect={setIsMultiSelect}
                  searchValue={searchValue}
                  title={field?.field}
                  type={getFieldName(field?.field)}
                />
              </div>
            </div>
          </Grid>
        </>
      );
    });
  };

  const selectFields = () => {
    return select_fields.map((field, i) => {
      let field_name = getFieldName(field?.field);
      return (
        <>
          <Grid item md={6} style={{ margin: "1% 0" }}>
            <div
              style={{
                ...input_style,
                opacity:
                  noPlan && !field?.has_permission
                    ? "50%"
                    : field?.has_permission
                      ? "100%"
                      : "50%",
              }}
            >
              {noPlan && !field?.has_permission ? (
                <>
                  <div style={lock_styles}>
                    <CiLock
                      style={{ width: 30, height: 30, opacity: "100%" }}
                    />
                  </div>
                </>
              ) : field?.has_permission ? (
                <></>
              ) : (
                <>
                  <div style={lock_styles}>
                    <CiLock
                      style={{ width: 30, height: 30, opacity: "100%" }}
                    />
                  </div>
                </>
              )}
              <label className="input-label color-label">{field?.field}</label>
              <Dropdown
                disabled={
                  noPlan && !field?.has_permission
                    ? true
                    : field?.has_permission
                      ? false
                      : true
                }
                style={{
                  width: "95%",
                  background: darkMode ? secondaryDarkColor : "white",
                  border: darkMode ? "none" : "1px solid gainsboro",
                }}
                value={formData?.[field_name]}
                onChange={(e) => handleInputChange(e, field, i, "select")}
                options={options[getFieldName(field?.field)]}
                optionLabel="name"
                name={getFieldName(field?.field)}
                placeholder={field?.field}
                className="global-select"
              />
            </div>
          </Grid>
        </>
      );
    });
  };

  useEffect(() => {
    if (user_data?.plan_id?.length === 0) {
      setNoPlan(true);
    }
  }, [user_data]);

  const handleEnter = (event) => {
    if (event.key == "Enter") {
      handleSearchSubmit();
    }
  };

  const handleSearchChange = (e) => {
    setProfileLoadingType("search");
    setSearchValue(e?.target?.value);
  };


  const handleSearchSubmit = async () => {
    setProfileLoading(true);
    if (searchValue !== '') {
      setTimeout(async () => {

        if (((permissions?.hasOwnProperty("unlock_access_to_advanced_search") && permissions?.unlock_access_to_advanced_search === 'No') || noPlan)) {
          let gender = user_data?.basic_details?.gender === "Male" ? "Female" : "Male";
          const response = await get_data(
            `user/search-limited-users/${searchValue}?gender=${gender}`
          );
          if (response?.status) {
            setIsFilter(true);
            setProfileLoading(false);
            setAllUsers(response?.data?.users);
            setTotalUsers(response?.data?.totalUsers);
            setTotalPages(response?.data?.totalPages);
          } else {
            setAllUsers([]);
            setProfileLoading(false);
          }
        }
        else {
          let gender = user_data?.basic_details?.gender === "Male" ? "Female" : "Male";
          const response = await get_data(
            `user/search-users/${searchValue}?gender=${gender}&pageNumber=${currentPage}`
          );
          if (response?.status) {
            setIsFilter(true);
            setProfileLoading(false);
            setAllUsers(response?.data?.users);
            setTotalUsers(response?.data?.totalUsers);
            setTotalPages(response?.data?.totalPages);
          } else {
            setAllUsers([]);
            setProfileLoading(false);
          }
        }

      }, 200);
    }
    else {
      fetchAllUsers(1)
      setProfileLoading(false);
    }
  };


  useEffect(() => {
    if (searchValue) {
      handleSearchSubmit();
    }
  }, [currentPage])


  const handleSpecially = async () => {
    setProfileLoading(true);
    let gender = user_data?.basic_details?.gender === "Male" ? "Female" : "Male";
    const response = await post_data(`user/get-all-specially-abled-users/${gender}`);
    if (response?.status) {
      setProfileLoading(false);
      setAllUsers(response?.data);
    } else {
      setProfileLoading(false);
    }
  }


  return (
    <>
      <div style={{ display: "flex" }}>
        <div>
          <h3
            className="global-h2"
            style={{ fontSize: 18, color: darkMode ? "white" : "black" }}
          >
            Search by Filter
          </h3>
        </div>

        <div className=" ml-[600px] w-96   flex items-center justify-end">
          <Tooltip title="Specially abled" arrow onClick={handleSpecially}>
            <IconButton>
              <BiHandicap size={30} color="#9E0050" />
            </IconButton>
          </Tooltip>
          {/* <BiHandicap size={30} color="#9E0050" /> */}
        </div>

        <div
          style={{
            width: "20%",
            display: "flex",
            background: darkMode ? secondaryDarkColor : "white",
            height: 44,
            borderRadius: 8,
            padding: "1.5% 3% 1.5% 1%",
            alignItems: "center",
            marginLeft: "auto",
            border: darkMode ? "none" : "1px solid gainsboro",
          }}
        >
          <TextField
            onChange={handleSearchChange}
            onKeyPress={(event) => handleEnter(event)}
            hiddenLabel
            placeholder="Search"
            variant="standard"
            size="small"
            fullWidth
            value={searchValue}
            style={{ fontWeight: 600 }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="end">
                  <SearchOutlinedIcon
                    style={{
                      color: darkMode ? "gray" : "gainsboro",
                      height: 25,
                      width: 25,
                    }}
                  />
                </InputAdornment>
              ),
              inputProps: {
                style: { color: darkMode ? "gray" : "black" },
              },
            }}
          />
        </div>
      </div>
      <div style={{ display: "flex" }}>
        {multiSelect()}
        {selectFields()}
      </div>
      <a
        href="/dashboard/find-match"
        style={{
          color: app_color,
          textDecoration: "underline",
          fontWeight: 500,
          marginLeft: "auto",
          fontSize: 14,
          cursor: "pointer",
        }}
      >
        Clear Filters
      </a>
    </>
  );
}
