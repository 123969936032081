import React from "react"
import { useState, useEffect } from "react"
import { Grid, Button } from "@mui/material"
import DefaultTable from "../Components/DefaultTable"
import { useNavigate } from "react-router-dom"
import { get_data, post_data } from "../../../Api"
import { subAdminsTableHeader } from "../../../Constants/globalConstants"
import TableSkeleton from "../../UserDshboard/Components/skeleton/TableSkeleton"
import SuccessModal from "../../UserDshboard/Components/SuccessModal"

export default function SubAdmins({ setProfileLoading }) {

    const [allSubAdmins, setAllSubAdmins] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [successStatus, setSuccessStatus] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const [sureModal, setSureModal] = useState(false)
    const [tableLoading, setTableLoading] = useState(true)
    const [orgData, setOrgData] = useState([])
    const itemsPerPage = 15;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);

    const fetchAllSubAdmin = async (pageNumber) => {
        const response = await get_data(`sub-admin/get-all-sub-admins?page_number=${pageNumber}`)
        if (response?.status) {
            setAllSubAdmins(response?.data?.subAdmins)
            setOrgData(response?.data)
            setTotalPages(response?.data?.totalPages);
            setTableLoading(false)
        }
    }

    useEffect(() => {
        if (currentPage) {
            fetchAllSubAdmin(currentPage);
        }
    }, [currentPage]);

    const sub_admins_page = {
        width: '100%',
    }

    const page_container = {
        width: "100%",
        margin: "2% 0",
    };

    const handleUserEdit = (item) => {
        setTimeout(async () => {
            let payload = { '_id': item }
            const response = await post_data('', payload)
            if (response?.status) {
                navigate('/dashboard')
            }
        }, 1000);
    }

    const handleConfirm = async (id) => {
        setTimeout(async () => {
            const response = await post_data(`sub-admin/delete-sub-admin/${id}`)
            if (response?.status) {
                setLoading(false)
                setSuccessStatus(true);
                setSuccessOpen(true);
                fetchAllSubAdmin()
                setSureModal(false)
            }
            else {
                setLoading(false)
                setSuccessStatus(false);
                setSuccessOpen(true);
                setSureModal(false)
            }
        }, 1000);
    }

    return (
        <>
            <Grid container spacing={0} style={sub_admins_page}>

                <div style={page_container}>
                    {
                        tableLoading ?
                            <>
                                <TableSkeleton />
                            </>
                            :
                            <>
                                <DefaultTable
                                    setProfileLoading={setProfileLoading}
                                    fetchAllSubAdmin={fetchAllSubAdmin}
                                    title='Sub Admins'
                                    count={allSubAdmins?.length}
                                    sliceEnd={allSubAdmins.length}
                                    loading={loading}
                                    setLoading={setLoading}
                                    handleConfirm={handleConfirm}
                                    handleEdit={handleUserEdit}
                                    data={allSubAdmins}
                                    itemsPerPage={itemsPerPage}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    type='Sub Admins'
                                    orgData={orgData}
                                    setData={setAllSubAdmins}
                                    setSureModal={setSureModal}
                                    sureModal={sureModal}
                                    tableHeader={subAdminsTableHeader}
                                    totalPages={totalPages}
                                />
                            </>
                    }
                </div>
                <SuccessModal
                    status={successStatus}
                    open={successOpen}
                    setOpen={setSuccessOpen}
                    title={
                        successStatus
                            ? `Sub Admin deleted successfully!`
                            : "Something went wrong..."
                    }
                />
            </Grid>
        </>
    )
}