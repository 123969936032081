import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal, { modalClasses } from '@mui/material/Modal';
import { useState } from 'react';
import { Grid, colors } from '@mui/material';
import { useDatabaseConstants } from '../../../Constants/globalConstants';
import { useNavigate } from 'react-router-dom';
import { post_data, serverURLImg } from '../../../Api';
import { login, logout } from '../../../redux/slices/user-slice';
import { useDispatch } from 'react-redux';
import SuccessModal from './SuccessModal';

export default function BackgroundImgModal({
    open,
    setOpen,
    bgImg,
    user
}) {

    const handleClose = () => setOpen(false);
    const user_id = user?._id
    const { app_color } = useDatabaseConstants()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [successStatus, setSuccessStatus] = useState(false)
    const [backgroundPicture, setBackgroundPicture] = useState({ bytes: '', filename: '' })
    const dispatch = useDispatch()
    const [btnStatus, setBtnStatus] = useState(false)
    const [successOpen, setSuccessOpen] = useState(false)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 550,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 4,
        padding: '3%'
    };

    const modal_title = {
        marginTop: '4%',
        textAlign: 'center',
        width: '80%',
        fontSize: 21,
        fontWeight: 500,
        color: app_color
    }

    const button_right_style = {
        background: app_color,
        width: '50%',
        color: 'white',
        boxShadow: 'none',
        borderRadius: 7,
        fontWeight: 500,
        fontSize: 15,
        textTransform: 'capitalize',
        padding: '3% 0'
    }

    const button_left_style = {
        background: 'white',
        width: '50%',
        color: app_color,
        boxShadow: 'none',
        borderRadius: 7,
        padding: '3% 0',
        fontWeight: 500,
        fontSize: 15,
        textTransform: 'capitalize',
        border: `2px solid ${app_color}`
    }

    const disabled_btn = {
        background: '#9e004f8e'
    }

    const handleUpdate = (e) => {
        setLoading(true)
        setTimeout(() => {
            updateBgImg()
        }, 1000);
    }

    const updateBgImg = async () => {
        var formData = new FormData()
        formData.append('background_picture', backgroundPicture.bytes)
        formData.append('old_background_picture', bgImg ? bgImg : '')
        const response = await post_data(`user/update-background-picture/${user_id}`, formData)
        if (response?.status) {
            dispatch(login(response?.data));
            handleClose()
            setLoading(false)
            setSuccessOpen(true)
            setSuccessStatus(true)
        }
        else{
            handleClose()
            setLoading(false)
            setSuccessOpen(true)
            setSuccessStatus(false)
        }
    }

    const edit_div = {
        position: 'absolute',
        right: '2%',
        bottom: '6%',
        background: app_color,
        borderRadius: '50%',
        height: '30px',
        width: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    }

    const handleImage = (event) => {
        setBackgroundPicture({ bytes: event.target.files[0], filename: URL.createObjectURL(event.target.files[0]) })
        setBtnStatus(true)
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <div onClick={handleClose} style={{
                        position: 'absolute',
                        right: '3%',
                        top: '3%',
                        cursor: 'pointer',
                    }}
                    >
                        <img src='/images/modal-close-icon.svg' style={{ width: '100%', height: '100%' }} />
                    </div>
                    <Grid container spacing={0} style={{ marginTop: '5%' }}>
                        <Grid item md={12}>
                            <center>
                                <div style={{ width: '100%', height: 150, background: '#DCD2C8', borderRadius: 10 }}>
                                    {
                                        backgroundPicture?.filename && (
                                            <img src={backgroundPicture?.filename} style={{ height: '100%', width: '100%', borderRadius: 10 }} />
                                        )
                                    }
                                </div>
                                <Button
                                    onChange={handleImage}
                                    component="label" variant="outlined"
                                    style={{ border: 'none', margin: 0, padding: 0 }} fullWidth>
                                    <div style={edit_div}>
                                        <img src="/images/edit-icon.svg" style={{ width: '80%', height: '80%' }}
                                        />
                                    </div>
                                    <input hidden type="file" accept="images/*" />
                                </Button>
                            </center>
                        </Grid>
                    </Grid>

                    <Grid item md={12} style={{
                        width: '70%',
                        display: 'flex',
                        gap: '4%',
                        margin: '6% auto 0 auto',
                    }}>
                        <Button onClick={handleClose} variant='contained' style={button_left_style}>
                            Cancel
                        </Button>

                        <Button onClick={(e) => handleUpdate(e)} disabled={loading || !backgroundPicture?.filename} variant='contained'
                            style={loading || !backgroundPicture?.filename ? { ...button_right_style, ...disabled_btn } : button_right_style}
                        >{loading ? 'Saving...' : 'Save'}
                        </Button>

                    </Grid>
                    <SuccessModal
                        status={successStatus}
                        open={successOpen}
                        setOpen={setSuccessOpen}
                        title={
                            successStatus ? 'Background updated successfully!' : 'Something went wrong...'
                        }
                    />
                </Box>
            </Modal>
        </div>
    );
}
