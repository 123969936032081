import React from "react"
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import CheckoutForm from "../Components/CheckoutForm";
import { useLocation } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import EmptyPage from "../../WebApp/UserDshboard/Components/EmptyPage"
import BottomBar from "../Components/BottomBar";

export default function Checkout() {

    const location = useLocation()
    let amount = location?.state?.amount
    let plan_id = location?.state?.id
    let selectedPlan = location?.state?.selectedPlan
    const theme = useTheme();
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'));
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));

    const registerFormFields = [
        {
            field: 'First name',
            type: 'text'
        },
        {
            field: 'Last name',
            type: 'text'
        },
        // {
        //     field: 'Email',
        //     type: 'text',
        // },
        // {
        //     field: 'Contact number',
        //     type: 'number'
        // }
    ]

    const paymentFormFields = [
        {
            field: 'Name on Card',
            type: 'text'
        },
        {
            field: 'Card number',
            type: 'number',
            title: 'card'
        },
        {
            field: 'Expiray date',
            type: 'number'
        },
        {
            field: 'CVV',
            type: 'number'
        },
    ]

    return (
        <>
            {
                matches_md ?
                    <>
                        <EmptyPage title={'Please install our Mobile App'} />
                    </>
                    :
                    <>
                        <Header />
                        <div>
                            <CheckoutForm
                                registerFormFields={registerFormFields}
                                paymentFormFields={paymentFormFields}
                                amount={amount}
                                plan_id={plan_id}
                                selectedPlan={selectedPlan}
                            />
                        </div>
                        <Footer />
                        <BottomBar />
                    </>
            }
        </>
    )
}