import { Grid, useMediaQuery } from "@mui/material"
import React from "react"

export default function AboutCompanySection() {

    const isDesktop = useMediaQuery('(min-width: 768px)');

    return (
        <>
            <div className="about-company-section" style={{ position: 'relative' }}>

                {
                    isDesktop && (
                        <img
                            src="/images/flower-1.png"
                            style={{
                                position: 'absolute',
                                width: 250,
                                left: '35%',
                                bottom: '10%',
                                transform: 'rotate(15deg)'
                            }}
                        />
                    )
                }
                <img src='/images/blur-div.svg' style={{ position: 'absolute', right: 0, bottom: 0, zIndex: -1, transform: 'rotate(180deg)' }} />
                <Grid container spacing={4}>
                    <Grid item md={6}>
                        <img src="/images/about-company-img.png" />
                    </Grid>
                    <Grid item md={6} style={{ display: "flex", justifyContent: 'center', flexDirection: 'column' }}>
                        <h2 className="global-h2" style={{ color: 'black', fontSize: 27 }}>
                            About Us: The Heart of 'DilSey'
                        </h2>
                        <p className="about-para" style={{ marginTop: '1%' }}>
                            At DilSey, we believe that every heart deserves to find its perfect match. Established with the vision of transforming the way people connect and build relationships, DilSey combines the best of traditional values and modern technology to create a unique and effective matchmaking experience.
                        </p>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}