import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function DefaultAlert({
    title,
    type
}) {

    return (
        <div style={{ width: '100%' }}>
            {type === 'success' && <Alert severity="success">{title}</Alert>}
            {type === 'info' && <Alert severity="info">{title}</Alert>}
            {type === 'warning' && <Alert severity="warning">{title}</Alert>}
            {type === 'error' && <Alert severity="error">{title}</Alert>}
        </div>
    );
}