import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal, { modalClasses } from '@mui/material/Modal';
import { useState } from 'react';
import { Grid } from '@mui/material';
import { age_range_options_male, annual_income_options, boolean_options, country_options, darkPrimaryColor, food_options, gender_options, getAvatar, getFieldName, handleCreateActivity, marital_status_options, qualifications_options, religion_options, secondaryDarkColor, state_options, useDatabaseConstants } from '../../../Constants/globalConstants';
import DefaultMultiSelect from './DefaultMultiSelect';
import { Dropdown } from 'primereact/dropdown';
import { useEffect } from 'react';
import SuccessModal from './SuccessModal';
import { post_data, serverURL, serverURLProfilePicture } from '../../../Api';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../redux/slices/user-slice';
import PhotosGuidelines from './PhotosGuidelines';
import { Link } from 'react-router-dom';
import { DarkModeContext } from './DarkModeContext';

export default function ProfileEditModal({
    open,
    setOpen,
    editItem,
    title,
    user,
    modalType,
    setDeletePicOpen,
    isCompletion,
    type,
    setUser
}) {

    const { darkMode } = React.useContext(DarkModeContext);
    const user_id = user?._id
    const dispatch = useDispatch()
    const { user_data } = useSelector((state) => state.user);
    const [loading, setLoading] = useState(false)
    const [loadingImg, setLoadingImg] = useState(false)
    const { app_color } = useDatabaseConstants()
    const [successStatus, setSuccessStatus] = useState(false)
    const [successOpen, setSuccessOpen] = useState(false)
    const [formData, setFormData] = useState(user)
    const [isMainDetails, setIsMainDetails] = useState(false)
    const [profileImg, setProfileImg] = useState('')
    const [defaultMultiSelectValues, setDefaultMultiSelectValues] = useState([])
    const [selectObj, setSelectObj] = useState({})
    const [profilePicture, setProfilePicture] = useState({ bytes: '', filename: '' })
    const [oldPicture, setOldPicture] = useState('')
    const [btnStatus, setBtnStatus] = useState(false)
    const [profilePictureUpdte, setProfilePictureUpdte] = useState(false)
    const [photoGuidelinesOpen, setPhotoGuidelinesOpen] = useState(false)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        overflowY: 'auto',
        bgcolor: darkMode ? secondaryDarkColor : 'white',
        color: darkMode ? 'white' : 'black',
        boxShadow: 24,
        borderRadius: 4,
        padding: '2%',
    };

    let avatar = getAvatar(user)

    useEffect(() => {
        const multiSelectObjects = editItem?.filter(obj => obj?.type === 'multi_select');
        setDefaultMultiSelectValues(multiSelectObjects)
    }, [editItem])

    useEffect(() => {
        const selectTransformedData = {};
        editItem?.forEach(item => {
            if (item.type === 'select') {
                const field_name = getFieldName(item?.field);
                selectTransformedData[field_name] = item?.value;
            }
        });
        if (selectTransformedData) {
            setSelectObj(selectTransformedData)
        }
    }, [editItem])

    const transformedData = {};
    editItem?.forEach(item => {
        const field_name = getFieldName(item?.field);
        transformedData[field_name] = item?.value;
    });

    const handleClose = () => {
        setOpen(false)
        handlePictureCancel()
    };

    useEffect(() => {
        setIsMainDetails(false)
        const findImage = editItem.find((item, i) => item?.type === 'image');

        if (findImage) {
            setIsMainDetails(true);
            setProfileImg(findImage?.value);
        }

        setFormData(prevFormData => {
            const fieldName = getFieldName(title);
            if (!fieldName) {
                return prevFormData;
            }
            return {
                ...prevFormData,
                [fieldName]: {
                    ...prevFormData?.[fieldName],
                    ...transformedData
                }
            };
        });

    }, [editItem]);

    useEffect(() => {
        if (isMainDetails) {
            setFormData(transformedData)
        }
    }, [isMainDetails]);

    const modal_title = {
        textAlign: 'center',
        width: '80%',
        fontSize: 23,
        fontWeight: 600,
    }

    const text_fields = editItem?.filter(subItem => subItem?.type === 'text');
    const number_fields = editItem?.filter(subItem => subItem?.type === 'number');
    const select_fields = editItem?.filter(subItem => subItem?.type === 'select');
    const multi_select = editItem?.filter(subItem => subItem?.type === 'multi_select');
    const date_picker = editItem?.filter(subItem => subItem?.type === 'date_picker');

    const input_style = {
        margin: '3% 0'
    }

    const optionsMap = {
        country: country_options,
        state: state_options,
        annual_income: annual_income_options,
        income: annual_income_options,
        age_range: age_range_options_male,
        gender: gender_options,
        religion: religion_options,
        family_income: annual_income_options,
        food: food_options,
        owns_house: boolean_options,
        owns_car: boolean_options,
        marital_status: marital_status_options
    };

    const handleInputChange = (e, field, i, type) => {

        if (type === 'number') {
            if (!/^\d*$/.test(e.target.value)) {
                return;
            }

            if (parseInt(e.target.value) < 0) {
                return;
            }

            if (isMainDetails) {
                setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
            } else {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    [getFieldName(title)]: {
                        ...prevFormData[getFieldName(title)],
                        [e.target.name]: e.target.value
                    },
                }));
            }
        }

        if (type === 'select') {
            let modified_value = e.target.value?.name
            setFormData(prevFormData => ({
                ...prevFormData,
                [getFieldName(title)]: {
                    ...prevFormData[getFieldName(title)],
                    [e.target.name]: modified_value
                },
            }));
        }

        else {
            if (isMainDetails) {
                setFormData({ ...formData, [e.target.name]: e?.target?.value })
            }

            else {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    [getFieldName(title)]: {
                        ...prevFormData[getFieldName(title)],
                        [e.target.name]: e.target.value
                    },
                }));
            }
        }
    }

    const handleDob = (date, name) => {
        const parsedDate = dayjs(date);
        if (!parsedDate.isValid()) {
            return;
        }
        let dateOfBirth = parsedDate?.$d.toISOString();
        setFormData(prevFormData => ({
            ...prevFormData,
            [getFieldName(title)]: {
                ...prevFormData[getFieldName(title)],
                [name]: dateOfBirth
            },
        }));
    };

    const textFields = () => {
        return text_fields.map((field, i) => {
            let field_name = getFieldName(field?.field)
            return (
                <>
                    <Grid item md={6} style={{ margin: '1% 0' }}>
                        <div style={input_style}>
                            <label className="input-label color-label">
                                {field?.display_field ? field?.display_field : field?.field}
                            </label>
                            <input
                                style={{ height: 42, background: darkMode ? darkPrimaryColor : 'white', border: darkMode ? 'none' : '' }}
                                onKeyPress={(event) => handleEnter(event)}
                                value={
                                    isMainDetails ? formData?.[field_name] : formData?.[getFieldName(title)]?.[field_name]
                                }
                                // className={`register-from-input ${invalid?.first_name ? 'invalid-input' : ''}`}
                                className='register-from-input'
                                type="text"
                                name={getFieldName(field?.field)}
                                onChange={(e) => handleInputChange(e, field, i, 'text')}
                            />
                        </div>
                    </Grid>
                </>
            )
        })
    }

    const multiSelect = () => {
        return multi_select.map((field, i) => {
            const fieldOptions = field && typeof field.value === 'string' ? field.value.split(',') : [];
            return (
                <>
                    <Grid item md={6} style={{ margin: '1% 0' }}>
                        <div style={input_style}>
                            <label className="input-label color-label">{field?.field}</label>
                            <div className='multi-select'>
                                <DefaultMultiSelect
                                    screen='modal'
                                    options={fieldOptions}
                                    formData={formData}
                                    setFormData={setFormData}
                                    title={title}
                                    type={getFieldName(field?.field)}
                                    defaultMultiSelectValues={defaultMultiSelectValues}
                                    setDefaultMultiSelectValues={setDefaultMultiSelectValues}
                                />
                            </div>
                        </div>
                    </Grid>
                </>
            )
        })
    }

    const datePicker = () => {
        return date_picker.map((field, i) => {
            return (
                <>
                    <Grid item md={6} style={{ margin: '1% 0' }}>
                        <div style={input_style}>
                            <label className="input-label color-label">Date of Birth</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    disabled
                                    value={dayjs(field?.value)}
                                    // onChange={(e) => handleDob(e, 'dob')}
                                    format="DD/MM/YYYY"
                                />
                            </LocalizationProvider>
                        </div>
                    </Grid>
                </>
            )
        })
    }

    const numberFields = () => {
        return number_fields.map((field, i) => {
            let field_name = getFieldName(field?.field)
            return (
                <>
                    <Grid item md={6} style={{ margin: '1% 0' }}>
                        <div style={input_style}>
                            <label className="input-label color-label">{field?.field}</label>
                            <input
                                onKeyPress={(event) => handleEnter(event)}
                                value={
                                    isMainDetails ? formData?.[field_name] : formData?.[getFieldName(title)]?.[field_name]
                                }
                                style={{ height: 42, background: darkMode ? darkPrimaryColor : 'white', border: darkMode ? 'none' : '' }}
                                // className={`register-from-input ${invalid?.first_name ? 'invalid-input' : ''}`}
                                className='register-from-input'
                                type="text"
                                pattern="[0-9]*"
                                maxLength={10}
                                name={getFieldName(field?.field)}
                                onChange={(e) => handleInputChange(e, field, i, 'number')}
                            />
                        </div>
                    </Grid>
                </>
            )
        })
    }

    const selectFields = () => {
        return select_fields.map((field, i) => {
            let field_name = getFieldName(field?.field)
            let field_value = { name: formData?.[getFieldName(title)]?.[field_name], code: formData?.[getFieldName(title)]?.[field_name] }
            return (
                <>
                    <Grid item md={6} style={{ margin: '1% 0' }}>
                        <div style={input_style}>
                            <label className="input-label color-label">{field?.field}</label>
                            <Dropdown
                                disabled={field_name === 'gender' ? true : false}
                                style={{ opacity: field_name === 'gender' ? '60%' : '', width: '95%', background: darkMode ? darkPrimaryColor : 'white', border: darkMode ? 'none' : '' }}
                                // value={formData?.[getFieldName(title)]?.[field_name]} 
                                value={field_value}
                                onChange={(e) => handleInputChange(e, field, i, 'select')}
                                options={optionsMap[getFieldName(field?.field)]}
                                optionLabel="name"
                                name={getFieldName(field?.field)}
                                placeholder={field?.field}
                                className="global-select"
                            />
                        </div>
                    </Grid>
                </>
            )
        })
    }

    const button_right_style = {
        background: app_color,
        width: '50%',
        color: 'white',
        boxShadow: 'none',
        borderRadius: 7,
        fontWeight: 500,
        fontSize: 15,
        textTransform: 'capitalize',
        padding: '3% 0'
    }

    const button_left_style = {
        background: 'white',
        width: '50%',
        color: app_color,
        boxShadow: 'none',
        borderRadius: 7,
        padding: '3% 0',
        fontWeight: 500,
        fontSize: 15,
        textTransform: 'capitalize',
        border: `2px solid ${app_color}`
    }

    const disabled_btn = {
        background: '#9e004f8e'
    }

    const transformPayload = (payload) => {
        const transform = (data) => {
            if (typeof data === 'object' && data !== null) {
                if ('name' in data && 'code' in data) {
                    return data['name'];
                } else if (Array.isArray(data)) {
                    return data.map(item => transform(item));
                } else {
                    const newData = {};
                    for (let key in data) {
                        newData[key] = transform(data[key]);
                    }
                    return newData;
                }
            }
            return data;
        };

        return transform(payload);
    };


    const handleEnter = (event) => {
        if (event.key == 'Enter') {
            handleSaveDetails()
        }
    }

    const handleSaveDetails = (e) => {

        e?.preventDefault();

        setLoading(true)
        if (modalType === 'completion') {
            let completionPayload = {
                first_name: formData?.first_name,
                last_name: formData?.last_name,
                profile_picture: formData?.profile_picture,
                email: formData?.email,
                contact_number: formData?.contact_number,
                education_and_careers: {
                    ...user?.education_and_careers,
                    qualifications: formData?.complete_profile?.qualifications,
                    occupation: formData?.complete_profile?.occupation
                }
            }
            if (completionPayload) {
                handleSubmit(completionPayload)
            }
        }
        else {
            let new_payload = transformPayload(formData);
            if (new_payload) {
                handleSubmit(new_payload)
            }
        }
    }

    const handleSubmit = async (payload) => {
        const response = await post_data(`user/update-user/${user?._id}`, payload)
        if (response?.status) {
            if (type === 'Admin') {
                setUser(response?.data)
                handleCreateActivity('User profile updated', user_data)
            }
            else {
                dispatch(login(response?.data));
            }
            handleClose()
            setLoading(false)
            setSuccessStatus(true)
            setSuccessOpen(true)
        }
        else {
            setLoading(false)
            handleClose()
            setSuccessStatus(false)
            setSuccessOpen(true)
        }
    }


    const handleImage = () => {
        setPhotoGuidelinesOpen(true)
    }

    const handleDeleteProfilePic = () => {
        setDeletePicOpen(true)
        setOpen(false)
    }

    const handlePictureCancel = () => {
        if (profileImg) {
            setProfilePicture({ filename: oldPicture, bytes: '' })
        }
        else {
            setProfilePicture({ filename: '', bytes: '' })
        }
        setBtnStatus(false)
    }

    useEffect(() => {
        setOldPicture(`${serverURLProfilePicture}/${profileImg}`)
    }, [profileImg])

    const handlePictureUpdate = async () => {
        setLoadingImg(true)
        var formData = new FormData()
        formData.append('profile_picture', profilePicture.bytes)
        formData.append('old_profile_picture', profileImg ? profileImg : '')
        const response = await post_data(`user/update-profile-picture/${user_id}`, formData)
        if (response?.status) {
            dispatch(login(response?.data));
            handleClose()
            setSuccessStatus(true)
            setLoadingImg(false)
            setSuccessOpen(true)
            setProfilePictureUpdte(true)
            setBtnStatus(false)
        }
        else {
            setLoadingImg(false)
            handleClose()
            setSuccessStatus(false)
            setSuccessOpen(true)
        }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <div onClick={handleClose} style={{
                        position: 'absolute',
                        right: '3%',
                        top: '3%',
                        cursor: 'pointer',
                    }}
                    >
                        <img src='/images/modal-close-icon.svg' style={{ width: '100%', height: '100%' }} />
                    </div>

                    <Grid container spacing={0}>

                        <Grid item md={12} style={{ marginBottom: '5%', display: 'flex', justifyContent: 'center' }}>
                            <h2 style={modal_title}>Edit {title}</h2>
                        </Grid>

                        {
                            isMainDetails && (
                                <Grid item md={12} style={{ marginBottom: '3%', display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ position: 'relative' }}>
                                        {
                                            profileImg && !profilePicture?.filename ?
                                                <>
                                                    <img src={`${serverURLProfilePicture}/${profileImg}`} style={{ width: '130px', height: '130px', borderRadius: '50%' }} />
                                                </>
                                                :
                                                profilePicture?.filename ?
                                                    <>
                                                        <img src={profilePicture?.filename} style={{ width: '130px', height: '130px', borderRadius: '50%' }} />
                                                    </>
                                                    :
                                                    <>
                                                        <img src={`/images/${avatar}`} style={{ width: '130px', height: '130px', borderRadius: '50%' }} />
                                                    </>
                                        }
                                        <div style={{ display: 'flex', gap: 0 }}>
                                            <Button
                                                onClick={handleImage}
                                                variant="outlined"
                                                style={{ border: 'none', margin: 0, padding: 0 }} fullWidth>
                                                <img src="/images/edit-img.svg" style={{ width: 35, height: 35 }} />
                                            </Button>
                                            {/* {
                                                user?.profile_picture !== '' && (
                                                    <Button
                                                        onClick={handleDeleteProfilePic}
                                                        variant="outlined"
                                                        style={{ border: 'none', margin: 0, padding: 0 }} fullWidth>
                                                        <img src="/images/delete-img.svg" style={{ width: 35, height: 35 }} />
                                                    </Button>
                                                )
                                            } */}
                                        </div>
                                    </div>
                                </Grid>
                            )
                        }
                        {
                            btnStatus ?
                                <>
                                    <Grid item md={4} style={{
                                        display: 'flex',
                                        gap: '4%',
                                        margin: '0 auto',
                                    }}>
                                        <Button onClick={handlePictureCancel} variant='contained' style={button_left_style}>
                                            Close
                                        </Button>
                                        <Button onClick={handlePictureUpdate} variant='contained'
                                            style={loadingImg ? { ...button_right_style, ...disabled_btn } : button_right_style}>
                                            {loadingImg ? 'Saving' : 'Save'}
                                        </Button>
                                    </Grid>
                                </>
                                : <></>
                        }

                        <Grid container spacing={0}>
                            {textFields()}
                            {numberFields()}
                            {datePicker()}
                            {selectFields()}
                            {multiSelect()}
                        </Grid>

                    </Grid>


                    {
                        !isMainDetails && (
                            <Grid item md={12} style={{
                                width: '60%',
                                display: 'flex',
                                gap: '4%',
                                margin: '3% auto 0 auto',
                            }}>
                                <Button onClick={handleClose} variant='contained' style={button_left_style}>
                                    Close
                                </Button>

                                <Button
                                    onClick={(e) => handleSaveDetails(e)} disabled={loading} variant='contained'
                                    style={loading ? { ...button_right_style, ...disabled_btn } : button_right_style}
                                >
                                    {loading ? 'Saving...' : 'Save'}
                                </Button>
                            </Grid>
                        )
                    }

                    {
                        isCompletion && (
                            <div style={{ marginTop: '5%' }}>
                                <Link to={'/dashboard/settings'} style={{ color: app_color, textDecoration: 'underline' }}>Update Email and Mobile Number</Link>
                            </div>
                        )
                    }
                </Box>
            </Modal>

            <SuccessModal
                status={successStatus}
                open={successOpen}
                setOpen={setSuccessOpen}
                title={
                    successStatus && profilePictureUpdte ? `Profile picture updated successfully!` :
                        successStatus ? `${title} updated successfully!` : 'Something went wrong...'
                }
            />
            <PhotosGuidelines
                type='profile-pic'
                setProfilePicture={setProfilePicture}
                setBtnStatus={setBtnStatus}
                open={photoGuidelinesOpen} setOpen={setPhotoGuidelinesOpen}
            />
        </div>
    );
}
