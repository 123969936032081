import React, { useContext, useRef, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import {
  button_right_style,
  useDatabaseConstants,
} from "../../../Constants/globalConstants";
import { DarkModeContext } from "./DarkModeContext";
import { useDispatch } from "react-redux";
import { selectedoptions } from "../../../redux/slices/user-slice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import BioData from "./BioData";

const ExportModal = ({ isOpen, onRequestClose, user }) => {

  const { app_color } = useDatabaseConstants();
  const [selectedOption, setSelectedOption] = useState("BasicDetails");
  const { darkMode } = useContext(DarkModeContext);
  const dispatch = useDispatch();
  const [noContact, setNoContact] = useState(false)

  const contentRef = useRef(null);

  const handleExport = () => {
    dispatch(selectedoptions(selectedOption));
    onRequestClose();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: '90%',
    overflowY: 'auto',
    bgcolor: "white",
    borderRadius: "12px",
    boxShadow: 24,
    p: 4,
  };

  const handleDownloadPdf = async () => {
    if (contentRef.current) {
      const canvas = await html2canvas(contentRef.current);
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("invoice.pdf");
      onRequestClose(false);
    }
  };


  return (
    <Modal
      open={isOpen}
      onClose={onRequestClose}
      aria-labelledby="export-modal-title"
    >
      <Box sx={style}>

        <div style={{ width: 400, marginBottom: '5%' }}>
          <div>
            <Typography id="export-modal-title" variant="h6" component="h2">
              Download
            </Typography>
            <Select
              value={selectedOption}
              onChange={(e) => {
                setSelectedOption(e.target.value);
                setNoContact(!noContact)
              }}
              fullWidth
              sx={{ mt: 2, mb: 2 }}
            >
              <MenuItem value="BasicDetails">Basic Details</MenuItem>
              <MenuItem value="BasicDetailsNoContact">
                Basic Details (No Contact Details)
              </MenuItem>
            </Select>
          </div>
          <div sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleDownloadPdf}
              style={{ ...button_right_style, width: "40%" }}
            >
              Download
            </Button>
          </div>
        </div>

        <div ref={contentRef} id="invoice">
          <BioData noContact={noContact} user={user} />
        </div>
      </Box>
    </Modal>
  );
};

export default ExportModal;
