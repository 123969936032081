import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDatabaseConstants } from "../../Constants/globalConstants";
import { get_data } from "../../Api";
import { useNavigate } from "react-router-dom";
import SignupSignModal from "./SignupLoginModal";

export default function MobilePricingPlans({
    screen,
    allPlansPermisison,
    fetchPlansPermissions,
    setAllPlansPermissions,
    is_purchased,
    admin_type,
    isSubAdmin,
    permissions
}) {

    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState("Monthly");
    const { user_data, isLoggedIn } = useSelector(state => state.user);
    const [loading, setLoading] = useState(true)
    const [modalOpen, setModalOpen] = useState(false)
    const { app_color } = useDatabaseConstants()
    const [allPlans, setAllPlans] = useState([])
    const [plan1Permissions, setPlan1Permissions] = useState([])
    const [plan2Permissions, setPlan2Permissions] = useState([])
    const [plan3Permissions, setPlan3Permissions] = useState([])

    const fetchAllPlans = async () => {
        const response = await get_data('plan/get-all-plans')
        if (response?.status) {
            setAllPlans(response?.data)
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchAllPlans()
    }, [])

    useEffect(() => {
        setPlan1Permissions(allPlans?.[0]?.permissions)
        setPlan2Permissions(allPlans?.[1]?.permissions)
        setPlan3Permissions(allPlans?.[2]?.permissions)
    }, [allPlans])

    const tabs_div = {
        background: '#F6E6EE',
        padding: '1%',
        borderRadius: 30,
        width: 'fit-content'
    }

    const pricing_title = {
        fontWeight: 600,
        fontSize: 25
    }

    const plans_tenure = {
        margin: '2% 0',
        fontWeight: 500,
        fontSize: 16
    }

    const border_style = {
        border: '1px solid #E6E9F5',
        borderRight: 'none',
        borderLeft: 'none',
        borderBottom: '1px solid #E6E9F5'
    }

    const plan_cost_title = {
        fontWeight: 600,
        fontSize: 26,
        color: 'black',
        margin: '6% 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2%'
    }

    const button_styles = {
        boxShadow: 'none',
        color: 'white',
        padding: '4% 0',
        fontWeight: 600,
        fontSize: 14,
        textTransform: 'capitalize'
    }

    const handleClick = (item) => {
        if (is_purchased && user_data?.plan_id?.[0]?._id === item?._id) {
            return
        }

        let plan_price = activeTab === 'Monthly' ? item?.monthly_discounted_price : item?.yearly_discounted_price
        if (admin_type === 'super-admin') {
            return
        }
        else {
            if (isLoggedIn) {
                console.log('activeTab', activeTab?.toLocaleLowerCase());
                navigate('/checkout', { state: { amount: plan_price, id: item?._id, selectedPlan: activeTab?.toLocaleLowerCase() } })
                window.scrollTo(0, 0)
            }
            else {
                setModalOpen(true)
            }
        }
    }


    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };

    const TabItem = ({ tabName }) => (
        <div
            onClick={() => handleTabChange(tabName)}
            style={{
                color: activeTab === tabName ? 'white' : "#808080",
                background: activeTab === tabName ? app_color : "",
                borderRadius: 30
            }}
            className="active-tabname"
        >
            <p style={{ fontSize: 11 }}>{tabName}</p>
        </div>
    );

    const renderPlanAction = (plan_id, is_highest, plan_cost) => {
        if (admin_type === 'super-admin') {
            return 'Edit This Plan';
        } else if (is_purchased) {
            if (user_data?.plan_id?.[0]?._id === plan_id) {
                return 'Your Plan';
            } else if (is_highest) {
                return 'Upgrade';
            } else if (user_data?.plan_id?.[0]?.monthly_discounted_price > plan_cost || user_data?.plan_id?.[0]?.yearly_discounted_price > plan_cost) {
                return 'Downgrade';
            } else {
                return 'Upgrade';
            }
        } else {
            return 'Choose This Plan';
        }
    };

    const pricingPlansDetails = (is_highest, plan_id, plans_name, item, plan_para, plan_cost, plan_arr, type) => {
        const formated_price = new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(plan_cost);
        return (
            <>
                <div style={{ position: 'relative', border: '1px solid #E6E9F5', margin: '10% 0' }}>
                    {
                        is_purchased && user_data?.plan_id?.[0]?._id === plan_id ?
                            <>
                                <div style={{
                                    top: '1%',
                                    left: 0,
                                    position: "absolute",
                                    width: 130,
                                    padding: '1%',
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    background: '#0069ff',
                                    textTransform: 'capitalize',
                                    borderRadius: '0 4px 4px 0',
                                }}>
                                    <p style={{
                                        color: 'white',
                                        fontSize: 12
                                    }}>
                                        Current Plan - {user_data?.billing?.plan_type}
                                    </p>
                                </div>
                            </>
                            :
                            <></>
                    }
                    <div style={{ padding: '7%', height: 250 }}>
                        <center>
                            <h3 style={{ ...pricing_title, color: app_color }}>{plans_name}</h3>
                            <p style={{ color: '#B5AEBB' }}>{plan_para}</p>
                            <div style={plan_cost_title}>
                                <h3>{formated_price}</h3>
                                <p style={{ color: '#858BA0', fontSize: 14, fontWeight: 500 }}>
                                    {activeTab === 'Monthly' ? '/Per month' : '/Yearly'}
                                </p>
                            </div>

                            {
                                !is_purchased || user_data?.plan_id?.[0]?._id !== plan_id ? (
                                    (isSubAdmin && permissions?.includes('Edit Plans')) || !isSubAdmin ? (
                                        <Button
                                            onClick={(e) => handleClick(item)}
                                            variant="contained"
                                            style={{
                                                ...button_styles,
                                                background: type === 'recommended' ? app_color : '#252430'
                                            }}
                                            fullWidth
                                        >
                                            {renderPlanAction(plan_id, is_highest, plan_cost)}
                                        </Button>
                                    ) : <></>
                                ) : <></>
                            }

                        </center>
                    </div>

                    <Grid container spacing={0} style={{ marginTop: '8%' }} >
                        <Grid item xs={8}>
                            {
                                allPlansPermisison?.map((item, i) => {
                                    return (
                                        <div style={{
                                            ...border_style,
                                            padding: '6%',
                                            height: 70,
                                            display: "flex",
                                            alignItems: 'center',
                                            fontWeight: 500,
                                            fontSize: 14
                                        }}>
                                            <p>{item?.permission_name}</p>
                                            {
                                                admin_type === 'super-admin' && (
                                                    <div style={{
                                                        width: 70,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        background: item?.is_active ? '#E8FFF3' : '#FFF5F8',
                                                        borderRadius: 5,
                                                        padding: '1%',
                                                        marginLeft: 'auto'
                                                    }}>
                                                        <p style={{
                                                            color: item?.is_active ? '#50CD89' : '#F1416C',
                                                            fontSize: 13
                                                        }}>
                                                            {item?.is_active ? 'Active' : 'Deactive'}
                                                        </p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                })
                            }
                        </Grid>
                        <Grid item xs={4}>
                            {
                                plan_arr?.map((item, i) => {
                                    return (
                                        <div style={{
                                            ...border_style,
                                            padding: '6%',
                                            display: "flex",
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            fontWeight: 500,
                                            fontSize: 14,
                                            height: 70
                                        }}>
                                            {
                                                item?.value === 'true' || item?.value === 'Yes' ?
                                                    <>
                                                        <img src="/images/green-check-icon.svg" style={{ width: 23 }} />
                                                    </>
                                                    :
                                                    item?.value === 'false' || item?.value === 'No' ?
                                                        <>
                                                            <img src="/images/wrong-icon.svg" style={{ width: 23 }} />
                                                        </>
                                                        :
                                                        <>
                                                            <p className="about-para"
                                                                style={{ color: app_color, textAlign: 'center', fontWeight: 600, fontSize: 14, textTransform: 'capitalize' }}>
                                                                {item?.value}
                                                            </p>
                                                        </>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                </div>
            </>
        )
    }

    return (
        <>
            <div style={{
                width: '100%',
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                padding: '2%'
            }}>
                <div className='user-profile-tabs-div' style={tabs_div}>
                    <TabItem tabName="Monthly" />
                    <TabItem tabName="Quaterly" />
                    <TabItem tabName="Semi-Yearly" />
                    <TabItem tabName="Yearly" />
                </div>
            </div>

            <div>
                <div
                    style={{
                        padding: '2%',
                        position: "relative"
                    }}
                >

                    {activeTab === "Monthly" && (
                        <div>
                            {
                                pricingPlansDetails(allPlans[0]?.is_highest, allPlans[0]?._id, allPlans[0]?.name, allPlans[0], allPlans[0]?.description, allPlans[0]?.monthly_discounted_price, plan1Permissions)
                            }
                            {
                                pricingPlansDetails(allPlans[1]?.is_highest, allPlans[1]?._id, allPlans[1]?.name, allPlans[1], allPlans[1]?.description, allPlans[1]?.monthly_discounted_price, plan2Permissions, 'recommended')
                            }
                            {
                                pricingPlansDetails(allPlans[2]?.is_highest, allPlans[2]?._id, allPlans[2]?.name, allPlans[2], allPlans[2]?.description, allPlans[2]?.monthly_discounted_price, plan3Permissions)
                            }
                        </div>
                    )}

                    {activeTab === "Quaterly" && (
                        <div>
                            {
                                pricingPlansDetails(allPlans[0]?.is_highest, allPlans[0]?._id, allPlans[0]?.name, allPlans[0], allPlans[0]?.description, allPlans[0]?.quaterly_discounted_price, plan1Permissions)
                            }
                            {
                                pricingPlansDetails(allPlans[1]?.is_highest, allPlans[1]?._id, allPlans[1]?.name, allPlans[1], allPlans[1]?.description, allPlans[1]?.quaterly_discounted_price, plan2Permissions, 'recommended')
                            }
                            {
                                pricingPlansDetails(allPlans[2]?.is_highest, allPlans[2]?._id, allPlans[2]?.name, allPlans[2], allPlans[2]?.description, allPlans[2]?.quaterly_discounted_price, plan3Permissions)
                            }
                        </div>
                    )}

                    {activeTab === "Semi-Yearly" && (
                        <div>
                            {
                                pricingPlansDetails(allPlans[0]?.is_highest, allPlans[0]?._id, allPlans[0]?.name, allPlans[0], allPlans[0]?.description, allPlans[0]?.semi_yearly_discounted_price, plan1Permissions)
                            }
                            {
                                pricingPlansDetails(allPlans[1]?.is_highest, allPlans[1]?._id, allPlans[1]?.name, allPlans[1], allPlans[1]?.description, allPlans[1]?.semi_yearly_discounted_price, plan2Permissions, 'recommended')
                            }
                            {
                                pricingPlansDetails(allPlans[2]?.is_highest, allPlans[2]?._id, allPlans[2]?.name, allPlans[2], allPlans[2]?.description, allPlans[2]?.monthly_discounted_price, plan3Permissions)
                            }
                        </div>
                    )}

                    {
                        activeTab === 'Yearly' && (
                            <div>
                                {
                                    pricingPlansDetails(allPlans[0]?.is_highest, allPlans[0]?._id, allPlans[0]?.name, allPlans[0], allPlans[0]?.description, allPlans[0]?.yearly_discounted_price, plan1Permissions)
                                }
                                {
                                    pricingPlansDetails(allPlans[1]?.is_highest, allPlans[1]?._id, allPlans[1]?.name, allPlans[1], allPlans[1]?.description, allPlans[1]?.yearly_discounted_price, plan2Permissions, 'recommended')
                                }
                                {
                                    pricingPlansDetails(allPlans[2]?.is_highest, allPlans[2]?._id, allPlans[2]?.name, allPlans[2], allPlans[2]?.description, allPlans[2]?.yearly_discounted_price, plan3Permissions)
                                }
                            </div>
                        )
                    }
                </div>
                <SignupSignModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
            </div>
        </>
    )
}