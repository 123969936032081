import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OrderSummary from './OrderSummary';
import Coupon from './Coupon';
import { useState } from 'react';
import toast, { Toaster } from "react-hot-toast";

export default function CheckoutAccordions({ step, screen, amount, setPayableAmount, savedCost, payableAmount }) {

    const [expandedAccordions, setExpandedAccordions] = React.useState([false, true]);
    const [coupon, setCoupon] = useState('')
    const [discountedAmount, setDiscountedAmount] = useState(payableAmount);
    const [savedAmount, setSavedAmount] = useState(savedCost);

    const handleExpansion = (index) => {
        setExpandedAccordions(prevState =>
            prevState.map((expanded, i) => i === index ? !expanded : expanded)
        );
    };

    React.useEffect(() => {
        if (setPayableAmount) {
            setPayableAmount(amount)
        }
    }, [amount])

    const handleApplyCoupon = () => {
        if (coupon === 'DS10') {
            const originalAmount = parseFloat(amount);
            const discountAmount = originalAmount * 0.1;
            const discounted = originalAmount - discountAmount;
            if (discounted) {
                setDiscountedAmount(discounted.toFixed(2));
                setPayableAmount(discounted.toFixed(2))
            }
            if (discountAmount) {
                setSavedAmount(discountAmount.toFixed(2));
            }
            toast.success('Coupon applied successfully!')
        }
        else {
            toast.error("Coupon doesn't exist!")
        }
    }

    const accordianItems = [
        {
            title: "Coupon",
            para: <Coupon setCoupon={setCoupon} coupon={coupon} handleApplyCoupon={handleApplyCoupon} />
        },
        {
            title: screen === 'success' ? 'Order Summary' : "Checkout Summary",
            para: <OrderSummary
                step={step}
                discountedAmount={discountedAmount}
                savedAmount={savedAmount}
                amount={amount}
                screen={screen}
            />
        },
    ]

    const checkoutAccordians = () => {
        const arr = screen === 'success' ? accordianItems?.slice(1) : accordianItems
        return (
            <div>
                {
                    arr.map((item, i) => {
                        return (
                            <>
                                <div style={{ margin: '3% 0' }}>
                                    <Accordion
                                        style={{ border: '1px solid #ededed', margin: '2% 0', boxShadow: 'none' }}
                                        expanded={expandedAccordions[i]}
                                        onChange={() => handleExpansion(i)}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`panel${i + 1}bh-content`}
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '3%', padding: 0, width: '80%' }}>
                                                <h2 sx={{ flexShrink: 0 }} style={{ fontSize: 15 }} className='accordian-title'>{item.title} </h2>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <p>{item.para}</p>
                                        </AccordionDetails>
                                    </Accordion >
                                </div>
                            </>
                        )
                    })
                }
            </div >
        )
    }

    return (
        <div>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            {checkoutAccordians()}
        </div>
    );
}
