import React, { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import StatisticsCard from "./StatisticsCard";
import DefaultTable from "./DefaultTable";
import { get_data, post_data } from "../../../Api";
import { useNavigate } from "react-router-dom";
import SuccessModal from "../../UserDshboard/Components/SuccessModal"
import { formatPrice, userTableHeader } from "../../../Constants/globalConstants";
import TableSkeleton from "../../UserDshboard/Components/skeleton/TableSkeleton";
import LineChartComponent from "./ChartComponent";
import AdminDashboardSkeleton from "../../UserDshboard/Components/skeleton/AdminDashboardSkeleton";
import DoughnutChartComponent from "./DoughnutChartComponent"

export default function DashboardComponent({
    isSubAdmin,
    permissions,
    setProfileLoading,
    profileLoading
}) {

    const navigate = useNavigate()
    const [allUsers, setAllUsers] = useState([])
    const [allSubAdmins, setAllSubAdmins] = useState([])
    const [successStatus, setSuccessStatus] = useState(false);
    const [sureModal, setSureModal] = useState(false)
    const [successOpen, setSuccessOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState(true)
    const [tableLoading, setTableLoading] = useState(true)
    const [orgData, setOrgData] = useState([])
    const [totalMale, setTotalMale] = useState('')
    const [totalFemale, setTotalFemale] = useState('')
    const [totalSales, setTotalSales] = useState('')
    const [grossSales, setGrossSales] = useState('')
    const [filteredStatsItem, setFilteredStatsItem] = useState([])

    const [totalUsers, setTotalUsers] = useState('')
    const [totalSubAdmins, setTotalSubAdmins] = useState('')
    const [totalVerificationPlanSales, setTotalVerificationPlanSales] = useState('')

    const fetchAllUsers = async (pageNumber) => {
        const response = await get_data(`user/get-all-users/?page_number=${pageNumber}`)
        if (response?.status) {
            setAllUsers(response?.data?.allUsers)
            setOrgData(response?.data)
            setTableLoading(false)
        }
    }


    const fetchGrossSales = async (pageNumber) => {
        const response = await get_data(`billing/total-sales`)
        if (response?.status) {
            setGrossSales(response?.data?.totalSales)
            setTableLoading(false)
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setPageLoading(false)
        }, 1000);
    }, [])

    const fetchAllSubAdmin = async () => {
        const response = await get_data('sub-admin/get-all-sub-admins')
        if (response?.status) {
            setAllSubAdmins(response?.data?.subAdmins)
        }
        else {
            setAllSubAdmins([])
        }
    }


    const fetchCount = async () => {
        const response = await get_data(`user/get-users-count`)
        if (response?.status) {
            setTotalSubAdmins(response?.data?.totalSubAdmins)
            setTotalUsers(response?.data?.totalUsers)
            setTotalVerificationPlanSales(response?.data?.totalVerificationPlanSales)
        }
    }

    useEffect(() => {
        fetchAllSubAdmin()
        fetchAllUsers()
        fetchCount()
        fetchGrossSales()
    }, [])


    useEffect(() => {
        const filtered_stats_item = filterStatsItems();
        if (filtered_stats_item) {
            setFilteredStatsItem(filtered_stats_item)
        }
    }, [permissions, isSubAdmin, allUsers, allSubAdmins, totalSales])

    const dashboard_page = {
        width: '100%',
        position: 'relative'
    }

    const page_container = {
        width: "100%",
        margin: "2% 0",
        background: 'transparent'
    }


    const stats_item = [
        {
            title: 'Sales',
            number: formatPrice(grossSales),
            bg: 'white',
            icon: 'sales-icon',
            color: 'black',
            link: '/admin/dashboard/sales',
            permission: 'View Sales'
        },
        {
            title: 'Users',
            number: totalUsers,
            bg: '#181C32',
            icon: 'all-users-icon',
            color: 'white',
            link: '/admin/dashboard/users',
            permission: 'View Users'
        },
        {
            title: allSubAdmins?.length === 1 ? 'Sub Admin' : 'Sub Admins',
            number: totalSubAdmins,
            icon: 'sub-admins-icon',
            bg: '#F6C000',
            color: 'white',
            link: '/admin/dashboard/sub-admins',
            permission: 'View Sub Admins'
        },
        {
            title: 'Verification Plan Sales',
            number: formatPrice(totalVerificationPlanSales),
            icon: 'sub-admins-icon',
            bg: '#7239EA',
            color: 'white',
            link: '/admin/dashboard/verification-plan-sales',
            permission: 'View Sub Admins'
        }
    ];

    function filterStatsItems() {
        return stats_item?.filter(item => {
            if (isSubAdmin) {
                if (item?.permission === 'View Sub Admins') {
                    return false;
                }
                return permissions?.includes(item?.permission);
            } else {
                return true;
            }
        });
    }

    const handleUserEdit = (number) => {
        setTimeout(async () => {
            let payload = { 'contact_number': number }
            const response = await post_data('', payload)
            if (response?.status) {
                navigate('/dashboard')
            }
        }, 1000);
    }

    useEffect(() => {
        const male_count = allUsers?.filter((item) => item?.basic_details?.gender === 'Male')
        if (male_count) {
            setTotalMale(male_count?.length)
        }

        const female_count = allUsers?.filter((item) => item?.basic_details?.gender === 'Female')
        if (female_count) {
            setTotalFemale(female_count?.length)
        }
    }, [allUsers])

    const handleConfirm = async (id) => {
    }

    const dashboardStats = () => {
        return (
            <>
                <Grid container spacing={3} style={{ width: '100%' }}>
                    {
                        filteredStatsItem?.map((item, i) => {
                            return (
                                <>
                                    <Grid item md={3}>
                                        <StatisticsCard
                                            item={item}
                                            index={i} />
                                    </Grid>
                                </>
                            )
                        })
                    }
                </Grid>
            </>
        )
    }

    return (
        <>
            <Grid container spacing={0} style={dashboard_page}>
                <div style={page_container}>

                    {
                        pageLoading ?
                            <>
                                <AdminDashboardSkeleton />
                            </>
                            :
                            <>
                                {dashboardStats()}

                                <Grid container spacing={2} style={{ marginTop: '2%' }}>
                                    {(!isSubAdmin || (isSubAdmin && permissions?.includes('View Sales'))) && (
                                        <Grid item md={7}>
                                            <LineChartComponent
                                                padding={'5%'} height={380} width={600}
                                                totalSales={totalSales} setTotalSales={setTotalSales} />
                                        </Grid>
                                    )}

                                    {
                                        (isSubAdmin && !permissions?.includes('View Users')) ? null : (
                                            <Grid item md={5}>
                                                <DoughnutChartComponent
                                                    padding={'7%'} height={380} width={600} totalSales={totalSales} setTotalSales={setTotalSales} />
                                            </Grid>
                                        )
                                    }
                                </Grid>

                                {
                                    (isSubAdmin ? (
                                        permissions?.includes('View Users') ||
                                        permissions?.includes('Edit Users') ||
                                        permissions?.includes('Delete Users')
                                    ) : true) && (
                                        <div style={{ margin: '3% 0' }}>
                                            {tableLoading ? (
                                                <TableSkeleton />
                                            ) : (
                                                <DefaultTable
                                                    setProfileLoading={setProfileLoading}
                                                    fetchAllUsers={fetchAllUsers}
                                                    title='Users'
                                                    setSureModal={setSureModal}
                                                    sureModal={sureModal}
                                                    count={allUsers?.length}
                                                    sliceEnd={5}
                                                    permissions={permissions}
                                                    loading={loading}
                                                    setLoading={setLoading}
                                                    handleConfirm={handleConfirm}
                                                    handleEdit={handleUserEdit}
                                                    data={allUsers}
                                                    setData={setAllUsers}
                                                    type='Users'
                                                    orgData={orgData}
                                                    tableHeader={userTableHeader}
                                                    totalMale={totalMale}
                                                    totalFemale={totalFemale}
                                                    screen='Dashboard'
                                                    isSubAdmin={isSubAdmin}
                                                />
                                            )}
                                        </div>
                                    )
                                }


                            </>
                    }
                </div>
                <SuccessModal
                    status={successStatus}
                    open={successOpen}
                    setOpen={setSuccessOpen}
                    title={
                        successStatus
                            ? `User deleted successfully!`
                            : "Something went wrong..."
                    }
                />
            </Grid>
        </>
    )
}