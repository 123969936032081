import React, { useEffect, useState } from "react"
import { IoDiamondOutline } from "react-icons/io5";
import { useSelector } from "react-redux";

export default function FeaturedLabel() {

    const profile_label = () => {
        return (
            <>
                <div style={{
                    width: 100,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: '#EEF6FF',
                    borderRadius: 8,
                    padding: '4%',
                    gap: '4%'
                }}>
                    <IoDiamondOutline style={{ color: '#3E97FF', width: 18, height: 18 }} />
                    <p style={{
                        color: '#3E97FF',
                        fontSize: 14
                    }}>
                        Featured
                    </p>
                </div>
            </>
        )
    }

    return (
        <>
            <div>
                {profile_label()}
            </div>
        </>
    )
}