import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Grid, TextField, InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";
import {
  button_right_style,
  useDatabaseConstants,
  disabled_btn,
  avatar_female,
  avatar_male,
  secondaryDarkColor,
  darkPrimaryColor,
} from "../../../Constants/globalConstants";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import moment from "moment/moment";
import ScrollToBottom from "react-scroll-to-bottom";
import { socketServerUrl } from "../../../Api";
import { DarkModeContext } from "./DarkModeContext";
import { serverURL } from "../../../lib/axios-request";
import EmptyPage from "./EmptyPage";

export default function ChatScreen({
  selectedUser,
  allMessages,
  setCurrentMessage,
  currentMessage,
  handleMessage,
  scroll,
  onMessageChange,
  userTyping,
  noPlan,
  fileInputRef,
  handleFileChange,
}) {
  const { darkMode } = React.useContext(DarkModeContext);
  const { app_color } = useDatabaseConstants();
  const { isLoggedIn, user_data } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [sortedMessages, setSortedMessages] = useState([]);

  const chat_screen = {
    border: darkMode ? "none" : "1px solid #E4E6EF",
    height: "100%",
  };

  const chat_top_bar = {
    padding: "2%",
    display: "flex",
    alignItems: "center",
    gap: "3%",
    borderBottom: darkMode ? "2px solid #182130" : "2px solid #E2E8F0",
  };

  const profile_pic = {
    borderRadius: "50%",
    height: 50,
    width: 50,
  };

  const user_name = {
    fontWeight: 500,
    fontSize: 17,
  };

  const user_status = {
    color: "#99A1B7",
    fontWeight: 400,
    fontSize: 13,
    display: "flex",
    gap: "3%",
    width: 150,
    alignItems: "center",
  };

  const chat_icons = {
    display: "flex",
    gap: "10%",
    marginLeft: "auto",
    justifyContent: "center",
    alignItems: "center",
  };

  const conversation_box = {
    width: "100%",
    padding: "3% 1%",
    // height: "70%",
    // overflowY: "auto",
  };

  const text_bar = {
    width: "100%",
    padding: "1%",
    display: "flex",
    gap: "2%",
  };

  useEffect(() => {
    const sorted_messages = allMessages.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateA - dateB;
    });

    setSortedMessages(sorted_messages);
  }, [allMessages]);

  const displayUserAvatar = () => {
    let getAvatar =
      user_data?.basic_details?.gender === "Male" ? avatar_female : avatar_male;
    let userPrivacy = selectedUser?.privacy?.profile_picture;
    return (
      <>
        {userPrivacy ? (
          <>
            <img style={profile_pic} src={`/images/${getAvatar}`} />
          </>
        ) : (
          <>
            {selectedUser?.profile_picture ? (
              <>
                {noPlan ? (
                  <>
                    <img
                      style={profile_pic}
                      className="blur-photos"
                      src={`${socketServerUrl}/images/profile-picture/${selectedUser?.profile_picture}`}
                    />
                  </>
                ) : (
                  <>
                    <img
                      style={profile_pic}
                      src={`${socketServerUrl}/images/profile-picture/${selectedUser?.profile_picture}`}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                <img style={profile_pic} src={`/images/${getAvatar}`} />
              </>
            )}
          </>
        )}
      </>
    );
  };

  const chatTopBar = () => {
    return (
      <>
        <div style={chat_top_bar}>
          <div>{displayUserAvatar()}</div>
          <div>
            <h3 style={user_name}>{selectedUser?.name}</h3>
            <div style={user_status}>
              {!darkMode && (
                <img
                  src={
                    selectedUser?.is_online
                      ? "/images/online-icon.svg"
                      : "/images/offline-icon.svg"
                  }
                />
              )}
              {selectedUser?.is_online
                ? userTyping
                  ? "Typing..."
                  : "Online"
                : "Offline"}
            </div>
          </div>
        </div>
      </>
    );
  };

  const button_send = {
    background: app_color,
    width: "100%",
    color: "white",
    boxShadow: "none",
    borderRadius: 0,
    fontWeight: 500,
    height: "100%",
    fontSize: 15,
    textTransform: "capitalize",
  };

  const textBar = () => {
    return (
      <>
        <div style={text_bar}>
          <div
            style={{
              display: "flex",
              background: darkMode ? darkPrimaryColor : "#F1F1F2",
              color: darkMode ? "white" : "black",
              height: 55,
              padding: "1.5% 2% 1.5% 3%",
              alignItems: "center",
              width: "85%",
            }}
          >
            <TextField
              onKeyDown={handleMessage}
              onChange={onMessageChange}
              value={currentMessage}
              hiddenLabel
              placeholder="Enter your message..."
              variant="standard"
              size="small"
              fullWidth
              style={{ fontWeight: 600 }}
              InputProps={{
                inputProps: {
                  style: { color: darkMode ? "white" : "black" },
                },
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <div style={{ display: "flex", gap: 10 }}>
                      <img
                        src="/images/attachement-icon.svg"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </InputAdornment>
                ),
              }}
            />
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              accept="image/*"
              multiple
              onChange={handleFileChange}
            />
          </div>

          <div style={{ width: "15%" }}>
            <Button
              variant="contained"
              style={button_send}
              onClick={handleMessage}
              disabled={!currentMessage}
            >
              Send
            </Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {
        !selectedUser ?
          <>
            <EmptyPage title={'Select a Chat to start conversation'} />
          </>
          :
          <>
            <div
              style={{
                background: darkMode ? secondaryDarkColor : "white",
                height: "100%",
              }}
            >
              <div style={chat_screen} className="flex flex-col justify-between">
                {chatTopBar()}

                {noPlan && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#ffffcf",
                      padding: "0.5% 0",
                    }}
                  >
                    <p style={{ color: 'black' }}>Please Purchase a Plan to view messages!</p>
                  </div>
                )}

                <ScrollToBottom className="flex-1 overflow-auto">
                  {sortedMessages?.map((message, j) => (
                    <div
                      key={j}
                      className={`${message.senderId === user_data?._id
                        ? "col-start-6 col-end-13"
                        : "col-start-1 col-end-8"
                        } p-3 rounded-lg`}
                    // ref={scroll}
                    >
                      <div
                        className={`flex items-center ${message.senderId === user_data?._id
                          ? "justify-start flex-row-reverse"
                          : `flex-row ${noPlan ? "blur-details-div" : ""}`
                          }`}
                      >
                        <div
                          className="relative mr-3 text-sm py-2 px-4 rounded-xl"
                          style={{
                            minWidth: 60,
                            background: darkMode ? darkPrimaryColor : "#F5F7FB",
                          }}
                        >
                          {message.message ? (
                            <div>{message?.message}</div>
                          ) : (
                            <div className="flex flex-col">
                              {message.attachments.map((item, i) => (
                                <a
                                  href={`${serverURL}/images/${item.split('uploads/')[1]}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="cursor-pointer"
                                >
                                  <img
                                    style={{ width: '100%' }}
                                    src={`${serverURL}/images/${item.split('uploads/')[1]}`}
                                    alt="chat"
                                    className="w-20 h-20"
                                  />
                                </a>
                              ))}
                            </div>
                          )}

                          <div className="absolute text-xs bottom-0 right-0 -mb-5 mr-2 text-gray-500" style={{ minWidth: '100%' }}>
                            {moment(message?.createdAt).format("hh:mm A")}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </ScrollToBottom>

                {textBar()}
              </div>
            </div>
          </>
      }
    </>
  );
}
