import React, { useRef, useEffect } from "react";
import { TextField, Button } from "@mui/material";
import { useState } from "react";
import { useDatabaseConstants } from "../../Constants/globalConstants";
import toast, { Toaster } from "react-hot-toast";
import { post_data } from "../../Api";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/slices/user-slice";
import { DarkModeContext } from "../../WebApp/UserDshboard/Components/DarkModeContext";

export default function OtpComponent({
  formData,
  type,
  number,
  email,
  setOtpForm,
  setOtpBox,
  setVerifyModal,
  setOpen,
  resendOtpFunc,
  tempOtp,
}) {
  const { darkMode } = React.useContext(DarkModeContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState(["", "", "", ""]);
  const { app_color, app_second_color } = useDatabaseConstants();
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [loading2, setLoading2] = useState(false);
  const otpInputRefs = useRef([]);
  const theme = useTheme();
  const matches_sm = useMediaQuery(theme.breakpoints.down("sm"));
  const matches_md = useMediaQuery(theme.breakpoints.down("md"));
  const { user_data } = useSelector((state) => state.user);
  const [timer, setTimer] = useState(59);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      setCanResend(true);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleFocus = (index) => {
    setFocusedIndex(index);
  };

  const otpBox = {
    width: matches_md ? 60 : 80,
    height: matches_md ? 60 : 80,
    background: "transparent",
    padding: "2.5% 4%",
    borderRadius: matches_md ? 15 : 19,
    display: "flex",
    alignItems: "center",
  };

  const handleOtpChange = (index, value) => {
    let arr = value !== "" ? value.split("") : "";
    let currentValue = "";
    if (arr !== "") {
      currentValue = arr[arr.length - 1];
    }

    const newOtp = [...otp];
    newOtp[index] = currentValue;
    setOtp(newOtp);

    if (currentValue !== "" && index < otpInputRefs.current.length - 1) {
      otpInputRefs.current[index + 1].focus();
    }

    if (currentValue === "" && index > 0) {
      otpInputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = async () => {
    setLoading2(true);

    if (type === "register") {
      setTimeout(async () => {
        const response = await post_data("user/create-user", formData); // user register
        if (response?.status) {
          setLoading2(false);
          toast.success(response?.message);
        } else {
          setLoading2(false);
          toast.error(response?.message);
        }
      }, 1000);
    }
    else if (type === "email-verify") {
      setTimeout(async () => {
        const response = await post_data("user/verify-otp-for-email", {
          email: email,
          otp: otp?.join(""),
        });
        if (response?.status) {
          setLoading2(false);
          setOtp(["", "", "", ""]);
          dispatch(login(response?.data));
          toast.success("Verified successfully!");
          setOpen(false);
          setOtpBox(false);
          setVerifyModal(true);
        } else {
          setOtp(["", "", "", ""]);
          setLoading2(false);
          toast.error("Something went wrong!");
        }
        setLoading2(false);
      }, 1000);
    }
    else if (type === "sub-admin-login") {
      setTimeout(async () => {
        let payload = { email: formData?.email, otp: otp?.join("") };
        const response = await post_data(
          "sub-admin/sub-admin-login-otp-verification",
          payload
        );
        if (response?.status) {
          setLoading2(false);
          dispatch(login(response?.data?.user));
          localStorage?.setItem("authToken", response?.data.token); // later token will not be store in localstorage
          navigate("/admin/dashboard");
        } else {
        }
      }, 1000);
    } else if (type === "super-admin-login") {
      setTimeout(async () => {
        let payload = { email: formData?.email, otp: otp?.join("") };
        const response = await post_data(
          "super-admin/super-admin-login-otp-verification",
          payload
        );
        if (response?.status) {
          setLoading2(false);
          dispatch(login(response?.data?.user));
          localStorage?.setItem("authToken", response?.data.token); // later token will not be store in localstorage
          navigate("/admin/dashboard");
        }
      }, 1000);
    } else if (type === "change-user-number") {
      const response = await post_data(
        "user/change-user-number",
        formData?.number
      );
      if (response?.status) {
        setLoading2(false);
      } else {
        setLoading2(false);
      }
    } else if (type === "change-user-email") {
      setTimeout(async () => {
        let paylod = {
          email: user_data?.email,
          new_email: formData?.new_email,
          otp: otp?.join(""),
        };
        const response = await post_data(
          "user/verify-otp-for-email-updation",
          paylod
        );
        if (response?.status) {
          setLoading2(false);
          setOpen(false);
          setOtpForm(false);
          dispatch(login(response?.data));
        } else {
          setLoading2(false);
        }
      }, 1000);
    } else if (type === "new-user-email") {
      // for creating new email for new users
      setTimeout(async () => {
        let paylod = { email: formData?.email, otp: otp?.join("") };
        const response = await post_data(
          `user/verify-email-creation/${user_data?._id}`,
          paylod
        );
        if (response?.status) {
          setLoading2(false);
          setOpen(false);
          setOtpForm(false);
          dispatch(login(response?.data));
        } else {
          setLoading2(false);
        }
      }, 1000);
    } else if (type === "change-super-admin-email") {
      setTimeout(async () => {
        let paylod = {
          email: formData?.email,
          new_email: formData?.new_email,
          otp: otp?.join(""),
        };
        const response = await post_data(
          "super-admin/verify-otp-for-email-updation",
          paylod
        );
        if (response?.status) {
          setLoading2(false);
          setOpen(false);
          dispatch(login(response?.data));
        } else {
          setOtp(["", "", "", ""]);
          toast.error("Something went wrong!");
          setLoading2(false);
        }
      }, 1000);
    } else {
      setTimeout(async () => {
        let payload = { contact_number: number, otp: otp.join("") }; // user login with number
        const response = await post_data("user/verify-otp", payload);
        if (response?.status) {
          toast.success("Login successfully!");
          dispatch(login(response?.data?.user));
          localStorage.setItem("authToken", response?.data.token);
          navigate("/dashboard");
        } else {
          toast.error("Otp didn't match!");
        }
        setLoading2(false);
      }, 1000);
    }
  };

  const handleResendOtp = async () => {
    setTimer(59);
    setCanResend(false);

    resendOtpFunc()
  };

  const handleEnter = (event) => {
    if (event.key == "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    alert(tempOtp);
  }, []);

  return (
    <>
      <div className="otp-form">
        <Toaster />
        {otp?.map((digit, index) => {
          return (
            <>
              <div
                style={{
                  ...otpBox,
                  border:
                    focusedIndex === index
                      ? `2px solid ${app_color}`
                      : "2px solid #CBCBCB",
                }}
              >
                <TextField
                  type="password"
                  onKeyPress={(event) => handleEnter(event)}
                  onFocus={() => handleFocus(index)}
                  value={digit}
                  inputRef={(ref) => (otpInputRefs.current[index] = ref)}
                  onChange={(e) =>
                    handleOtpChange(index, e.target.value, "one")
                  }
                  // id="zero"
                  sx={{
                    input: {
                      color: "black",
                      fontSize: 30,
                      textAlign: "center",
                      fontWeight: "bold",
                    },
                  }}
                  variant="standard"
                  hiddenLabel
                  placeholder=" "
                  InputProps={{
                    disableUnderline: true,
                    inputProps: { maxLength: 1 },
                  }}
                />
              </div>
            </>
          );
        })}
      </div>

      <center>
        <p
          className="otp-form-txt"
          style={{ color: darkMode ? "gainsboro" : "" }}
        >
          {type === "email-verify" ||
            type === "sub-admin-login" ||
            type === "super-admin-login" ||
            type === "new-user-email" ||
            type === "change-user-email" ||
            type === "change-super-admin-email" ? (
            <>
              You have received 4-digit pin at your mail, check your inbox and
              enter that pin here to confirm your email.
            </>
          ) : (
            <>
              You have received 4-digit pin at your mobile number, check your
              sms inbox and enter that pin here to confirm your it.
            </>
          )}
        </p>
      </center>

      <Button
        disabled={loading2}
        onClick={handleSubmit}
        fullWidth
        className={loading2 ? "register-btn-disable" : "register-btn"}
        style={{ margin: "10% 0" }}
      >
        {loading2 ? "Verifying..." : "Verify"}
      </Button>

      <center>
        {canResend ? (
          <p
            onClick={handleResendOtp}
            style={{
              cursor: "pointer",
              color: app_color,
              fontWeight: 500,
              textDecoration: "underline",
            }}
          >
            Resend OTP
          </p>
        ) : (
          <p
            className="otp-form-txt"
            style={{ color: darkMode ? "gainsboro" : "" }}
          >
            Didn't receive OTP? Resend in{" "}
            <b>{`00:${timer < 10 ? `0${timer}` : timer} seconds`}</b>
          </p>
        )}
      </center>
    </>
  );
}
