import React from 'react';

export default function LogoLoading() {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            background: 'white'
        }}>
            <video
                autoPlay
                loop
                muted
                width={500}
                style={{
                    display: 'block',
                    margin: 0,
                    padding: 0,
                    border: 'none'
                }}
            >
                <source src="/images/animated-logo.mp4" type="video/mp4" />
            </video>
        </div>
    );
}
