import React, { useEffect } from "react"
import { extractNumber } from "../../../Constants/globalConstants"
import DefaultModal from "./DefaultModal"
import { useSelector } from "react-redux";

export default function InterestConditionsModal({
    noPlan,
    receivedInterestMatch,
    permissions,
    open,
    setOpen,
    setSuccessOpen,
    setSuccessStatus,
    item,
    successStatus,
    btnClicked,
    sentInterestMatch,
    screen,
    interestStatus
}) {

    const { user_data } = useSelector(state => state.user);

    useEffect(() => {

    }, [user_data, receivedInterestMatch, sentInterestMatch, interestStatus])

    return (
        <>
            {

                permissions?.hasOwnProperty('send_interest') && !noPlan &&
                    screen === 'Match' &&
                    receivedInterestMatch === 'Send Interest' &&
                    // sentInterestMatch !== 'pending' &&
                    permissions?.send_interest !== 'Unlimited' &&
                    user_data?.interest_sent?.length === extractNumber(permissions?.send_interest) ?
                    <>
                        <DefaultModal
                            open={open}
                            setOpen={setOpen}
                            setSuccessOpen={setSuccessOpen}
                            screen={screen}
                            item={item}
                            limit='exceed'
                            noPlan={noPlan}
                            successStatus={successStatus}
                            setSuccessStatus={setSuccessStatus}
                            sentInterestMatch={sentInterestMatch}

                            title_left='You have exceed limit!'

                            para_left='Please upgrade your Plan, if you want to send interest to more people.'

                            title_right='You have exceed limit!'

                            para_right='Please upgrade your Plan, if you want to send interest to more people.'

                            image='modal-img-plans'

                            button_clicked={btnClicked}

                            button_right='Upgrade your Plan'
                        />
                    </>
                    :
                    permissions?.hasOwnProperty('receive_interest') && !noPlan &&
                        (screen === 'Interest Received' || screen === 'Received') &&
                        interestStatus === 'pending' &&
                        permissions?.receive_interest !== 'Unlimited' &&
                        user_data?.interest_received?.filter(item => item?.status === 'accepted')?.length === extractNumber(permissions?.receive_interest) ?
                        <>
                            <DefaultModal
                                open={open}
                                setOpen={setOpen}
                                setSuccessOpen={setSuccessOpen}
                                screen={screen}
                                item={item}
                                limit='exceed'
                                noPlan={noPlan}
                                successStatus={successStatus}
                                setSuccessStatus={setSuccessStatus}
                                sentInterestMatch={sentInterestMatch}

                                title_left='You have exceed limit!'

                                para_left='Please upgrade your Plan, if you want to accept/decline more interests from people.'

                                title_right='You have exceed limit!'

                                para_right='Please upgrade your Plan, if you want to accept/decline more interests from people.'

                                image='modal-img-plans'

                                button_clicked={btnClicked}

                                button_right='Upgrade your Plan'
                            />
                        </>
                        :
                        permissions?.hasOwnProperty('receive_interest') && !noPlan &&
                            screen === 'Match' &&
                            sentInterestMatch === 'pending' &&
                            // receivedInterestMatch !== 'Send Interest' &&
                            permissions?.receive_interest !== 'Unlimited' &&
                            user_data?.interest_received?.filter(item => item?.status === 'accepted')?.length === extractNumber(permissions?.receive_interest) ?
                            <>
                                <DefaultModal
                                    open={open}
                                    setOpen={setOpen}
                                    setSuccessOpen={setSuccessOpen}
                                    screen={screen}
                                    item={item}
                                    limit='exceed'
                                    noPlan={noPlan}
                                    successStatus={successStatus}
                                    setSuccessStatus={setSuccessStatus}
                                    sentInterestMatch={sentInterestMatch}

                                    title_left='You have exceed limit!'

                                    para_left='Please upgrade your Plan, if you want to accept/decline more interests from people.'

                                    title_right='You have exceed limit!'

                                    para_right='Please upgrade your Plan, if you want to accept/decline more interests from people.'

                                    image='modal-img-plans'

                                    button_clicked={btnClicked}

                                    button_right='Upgrade your Plan'
                                />
                            </>
                            :
                            screen === 'Interest Received' && noPlan ?
                                <>
                                    <DefaultModal
                                        open={open}
                                        setOpen={setOpen}
                                        setSuccessOpen={setSuccessOpen}
                                        screen={screen}
                                        item={item}
                                        noPlan={noPlan}
                                        successStatus={successStatus}
                                        setSuccessStatus={setSuccessStatus}
                                        sentInterestMatch={sentInterestMatch}

                                        title_left='Purchase a Plan'

                                        para_left='Please purchase a Plan to decline Interests'

                                        title_right='Purchase a Plan'

                                        para_right='Please purchase a Plan to accept Interests'

                                        image='modal-img-plans'

                                        button_clicked={btnClicked}

                                        button_right='Purchase a Plan'
                                    />
                                </>
                                :
                                screen === 'Match' && sentInterestMatch === 'pending' && noPlan ?
                                    <>
                                        <DefaultModal
                                            open={open}
                                            setOpen={setOpen}
                                            setSuccessOpen={setSuccessOpen}
                                            screen={screen}
                                            item={item}
                                            noPlan={noPlan}
                                            successStatus={successStatus}
                                            setSuccessStatus={setSuccessStatus}
                                            sentInterestMatch={sentInterestMatch}

                                            title_left='Purchase a Plan'

                                            para_left='Please purchase a Plan to decline Interests'

                                            title_right='Purchase a Plan'

                                            para_right='Please purchase a Plan to accept Interests'

                                            image='modal-img-plans'

                                            button_clicked={btnClicked}

                                            button_right='Purchase a Plan'
                                        />
                                    </>
                                    :
                                    screen === 'Match' && sentInterestMatch === 'Send Interest' && noPlan ?
                                        <>
                                            <DefaultModal
                                                open={open}
                                                setOpen={setOpen}
                                                setSuccessOpen={setSuccessOpen}
                                                screen={screen}
                                                item={item}
                                                noPlan={noPlan}
                                                successStatus={successStatus}
                                                setSuccessStatus={setSuccessStatus}
                                                sentInterestMatch={sentInterestMatch}

                                                title_right='Purchase a Plan'

                                                para_right='Please purchase a Plan to send Interests'

                                                title_left='Purchase a Plan'

                                                para_left='Please purchase a Plan to send Interests'

                                                image='modal-img-plans'

                                                button_clicked={btnClicked}

                                                button_right='Purchase a Plan'
                                            />
                                        </>
                                        :
                                        <>
                                            {/* No use */}
                                            <DefaultModal
                                                open={open}
                                                setOpen={setOpen}
                                                setSuccessOpen={setSuccessOpen}
                                                screen={screen}
                                                item={item}
                                                successStatus={successStatus}
                                                setSuccessStatus={setSuccessStatus}
                                                sentInterestMatch={sentInterestMatch}

                                                title_left={
                                                    screen === 'Match' && sentInterestMatch === 'pending' ? 'Are you sure ?' :
                                                        screen === 'Interest Received' && interestStatus === 'pending' ? 'Are you sure you ?' : 'Please send Interest first!'

                                                }

                                                para_left={
                                                    screen === 'Match' && sentInterestMatch === 'pending' ? 'Are you sure you to want to Decline the Interest?' :
                                                        screen === 'Interest Received' && interestStatus === 'pending' ? 'Are you sure you to want to Decline the Interest?' :
                                                            'First send Interest, once approved then you can initaite chat.'
                                                }

                                                title_right={
                                                    screen === 'Match' && sentInterestMatch === 'pending' ? 'Are you sure ?' :
                                                        screen === 'Interest Received' && interestStatus === 'pending' ? 'Are you sure you ?' : 'Are you sure ?'
                                                }

                                                para_right={
                                                    screen === 'Match' && sentInterestMatch === 'pending' ? 'Are you sure you to want to accept the Interest?' :
                                                        screen === 'Interest Received' && interestStatus === 'pending' ? 'Are you sure you to want to accept the Interest?' : 'Are you sure you want to send Interest to this user.'
                                                }

                                                image={
                                                    screen === 'Match' && btnClicked === 'right' && sentInterestMatch === 'pending' ? 'modal-img-approve' :
                                                        screen === 'Match' && btnClicked === 'left' && sentInterestMatch === 'pending' ? 'modal-img-reject' :

                                                            screen === 'Match' && btnClicked === 'right' ? 'modal-img-send' :
                                                                screen === 'Match' && btnClicked === 'left' ? 'modal-img-chat' :

                                                                    screen === 'Interest Received' && interestStatus === 'pending' && btnClicked === 'left' ? 'modal-img-reject' :
                                                                        screen === 'Interest Received' && interestStatus === 'pending' && btnClicked === 'right' ? 'modal-img-approve' : ''
                                                }

                                                button_clicked={btnClicked}

                                                button_left={
                                                    screen === 'Match' && sentInterestMatch === 'pending' ? 'Cancel' :
                                                        screen === 'Interest Received' && interestStatus === 'pending' ? 'Cancel' : 'Cancel'

                                                }

                                                button_right={
                                                    screen === 'Match' && sentInterestMatch === 'pending' ? 'Confirm' :
                                                        screen === 'Interest Received' && interestStatus === 'pending' ? 'Confirm' : 'Confirm'
                                                }

                                                loading_right_btn_txt={
                                                    screen === 'Match' ? 'Confirming...' :
                                                        screen === 'Interest Received' && btnClicked === 'left' ? 'Declining...' :
                                                            screen === 'Interest Received' && btnClicked === 'right' ? 'Accepting...' : ''
                                                }
                                            />
                                        </>
            }
        </>
    )
}