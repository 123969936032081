import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Chip } from '@mui/material';
import { useSelector } from 'react-redux';

const ProfileCompleteInfoModal = ({ open, setOpen }) => {

    const { user_data } = useSelector((state) => state.user);

    const handleClose = () => {
        setOpen(false);
    };

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 450,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 8,
            textAlign: 'center',
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        buttons: {
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            marginTop: '20px',
        }
    };


    const heading = {
        fontWeight: 600,
        fontSize: 22,
        marginBottom: '10px',
        textAlign: 'left',
        p: 3,
        borderBottom: '1px solid gainsboro'
    }


    const DataRow = ({ label, value }) => (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '2% 0',
        }}>
            <Typography variant="body1">{label}</Typography>
            <Typography variant="body1">
                {
                    value === true ?
                        <img src="/images/green-check-icon.svg" alt="Check Icon" style={{ width: 20, height: 20 }} />
                        :
                        <img src='/images/wrong-icon.svg' alt="Wrong Icon" style={{ width: 20, height: 20 }} />
                }
            </Typography>
        </Box>
    )


    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={styles.modalBox}>
                <Typography id="logout-modal-title" variant="h6" sx={heading}>
                    Profile Completion Info
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2, padding: '2% 6% 6% 6%' }}>
                    <DataRow label="Full Name" value={user_data?.first_name !== '' && user_data?.last_name !== '' ? true : false} />
                    <DataRow label="Profile Photo" value={user_data?.profile_photo !== '' ? true : false} />
                    <DataRow label="Qualifications" value={user_data?.education_and_careers?.qualifications?.length > 0 ? true : false} />
                    <DataRow label="Occupation" value={user_data?.education_and_careers?.occupation?.length > 0 ? true : false} />
                    <DataRow label="Email Verified" value={user_data?.is_email_verfied} />
                    <DataRow label="Phone number" value={true} />
                </Typography>
            </Box>
        </Modal>
    );
};

export default ProfileCompleteInfoModal;
