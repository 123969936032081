import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { formatDate } from "../../../Constants/globalConstants";
import { serverURLProfilePicture } from "../../../Api";

const BioData = ({ noContact, user }) => {

  return (
    <div className="bio-data modal-v2">
      <img src="/images/dilsey-logo.svg" style={{ width: 120 }} />
      <div
        className="image-section"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* {
          user?.profile_picture !== '' && (
            <img
              src={`${serverURLProfilePicture}/${user?.profile_picture}`}
            />
          )
        } */}
        <span style={{ fontWeight: 600, fontSize: 20, marginTop: 10 }}>
          {user?.first_name || 'N/A'} {user?.last_name || 'N/A'}
        </span>
      </div>
      <hr />
      <div className="personal-details">
        <h2>Personal Details</h2>
        <ul>
          <li>
            <span className="key">Date of Birth:</span>{" "}
            <span className="value">{formatDate(user?.basic_details?.dob) || 'N/A'}</span>
          </li>
          <li>
            <span className="key">Height:</span>{" "}
            <span className="value">{user?.basic_details?.height} cm</span>
          </li>
          <li>
            <span className="key">Qualifications:</span>{" "}
            <span className="value">{user?.education_and_careers?.qualifications || 'N/A'}</span>
          </li>
          <li>
            <span className="key">Occupation:</span>{" "}
            <span className="value">{user?.education_and_careers?.occupation || 'N/A'}</span>
          </li>
          <li>
            <span className="key">Annual Income:</span>{" "}
            <span className="value">{user?.education_and_careers?.annual_income || 'N/A'}</span>
          </li>
          <li>
            <span className="key">Religion:</span>{" "}
            <span className="value">{user?.basic_details?.religion || 'N/A'}</span>
          </li>
          <li>
            <span className="key">Caste:</span>{" "}
            <span className="value">{user?.basic_details?.caste || 'N/A'}</span>
          </li>
          <li>
            <span className="key">Location:</span>{" "}
            <span className="value">{user?.contact_details?.city || 'N/A'}, {user?.contact_details?.state || 'N/A'}</span>
          </li>
        </ul>
      </div>
      <hr />
      <div className="family-details">
        <h2>Family Details</h2>
        <ul>
          <li>
            <span className="key">Father's Name:</span>{" "}
            <span className="value">{user?.family_details?.father_name || 'N/A'}</span>
          </li>
          <li>
            <span className="key">Father's Occupation:</span>{" "}
            <span className="value">{user?.family_details?.father_occupation || 'N/A'}</span>
          </li>
          <li>
            <span className="key">Mother's Name:</span>{" "}
            <span className="value">{user?.family_details?.mother_name || 'N/A'}</span>
          </li>
        </ul>
      </div>
      <hr />
      {
        noContact === false && (
          <div className="contact-details">
            <h2>Contact Details</h2>
            <ul>
              <li>
                <span className="key">Contact Number:</span>{" "}
                <span className="value">+91 {user?.contact_number || 'N/A'}</span>
              </li>
              <li>
                <span className="key">Email ID:</span>{" "}
                <span className="value">{user?.email || 'N/A'}</span>
              </li>
            </ul>
          </div>
        )
      }
    </div>
  );
};

export default BioData;
