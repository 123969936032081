import { IoArrowBackSharp } from "react-icons/io5";
import { useDatabaseConstants } from "../../../Constants/globalConstants";
import { useNavigate } from "react-router-dom";

export default function BackButton() {

    const { app_color } = useDatabaseConstants()
    const navigate = useNavigate()

    const handleBack = () => {
        navigate(-1)
    }

    return (
        <>
            <div
                onClick={handleBack}
                style={{
                    width: 25,
                    height: 25,
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: app_color,
                    color: 'white',
                    borderRadius: '50%',
                    cursor: "pointer"
                }}>
                <IoArrowBackSharp />
            </div>
        </>
    )
}