import React, { useEffect, useState } from "react"
import { darkPrimaryColor, secondaryDarkColor, useDatabaseConstants } from "../../../Constants/globalConstants"
import { DarkModeContext } from "./DarkModeContext";

export default function UserStatsCard({
    item,
    index,
    selectedCard,
    setSelectedCard,
    setSelectedStats,
    selectedStats
}) {

    const { app_color } = useDatabaseConstants()
    const { darkMode } = React.useContext(DarkModeContext);

    const user_stats_card = {
        boxShadow: '0 3px 4px 0 #00000008',
        background: darkMode ? secondaryDarkColor : 'white',
        borderRadius: 10,
        padding: '8%',
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        gap: '5%',
        cursor: 'pointer',
    }

    const title_styles = {
        fontWeight: 500,
        fontSize: 16
    }

    const number_styles = {
        color: darkMode ? 'gray' : 'black',
        fontWeight: 500,
        fontSize: 19
    }

    const handleClick = (item) => {
        setSelectedCard(index)
        setSelectedStats(item?.title)
    }

    useEffect(() => {
        setSelectedCard(0)
    }, [])

    return (
        <>
            <div style={{ ...user_stats_card, border: selectedCard === index ? `3px solid ${app_color}` : darkMode ? `3px solid ${darkPrimaryColor}` : `3px solid white` }} onClick={() => handleClick(item)}>
                <div>
                    <img src="/images/square-heart.svg" style={{ width: '100%', height: '100%' }} />
                </div>
                <div>
                    {/* <p style={title_styles}>{item?.title}</p> */}
                    <p style={number_styles}>{item?.title}</p>
                </div>
            </div>
        </>
    )
}