import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, Grid } from '@mui/material';
import { button_right_style, useDatabaseConstants } from '../../Constants/globalConstants';
import MenuIcon from '@mui/icons-material/Menu';
import FunctionConstant from '../../Constants/functionConstant';
import { useSelector } from 'react-redux';

export default function MenuDrawer({
    setModalOpen,
}) {

    const navigate = useNavigate()
    const { app_color } = useDatabaseConstants()
    const { user_data, isLoggedIn } = useSelector(state => state.user);
    const [state, setState] = React.useState({ right: false })
    const [selectedItemIndex, setSelectedItemIndex] = useState('')
    const [anchorEl, setAnchorEl] = useState(null)
    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false)
    const open = Boolean(anchorEl)
    const { handleBtnClick } = FunctionConstant({ setModalOpen })

    const toggleDrawer = (anchor, open) => (event) => {
        if (event?.type === 'keydown' && (event?.key === 'Tab' || event?.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    useEffect(() => {
        setSelectedItemIndex(0)
    }, [])

    const menuItems =
        [
            {
                icon: 'Home',
                title: 'Home',
                link: '/'
            },
            {
                icon: 'Pricing',
                title: 'Pricing',
                link: '/pricing'
            },
            {
                icon: 'Contact',
                title: 'Contact',
                link: '/contact'
            },
            {
                icon: 'About',
                title: 'About',
                link: '/about'
            }
        ]

    const handleMenuItemClick = (item) => {
        navigate(item?.link)
        window.scrollTo(0, 0);
    }

    const menuItemsFunc = () => {
        return (
            <>
                <div>
                    <Grid container spacing={1} style={{ padding: '10% 0 0', display: 'flex', justifyContent: 'left' }}>
                        <h2 style={{ margin: 0, fontWeight: 600, fontSize: 25, paddingLeft: '10%' }}>Navigate</h2>
                        <hr style={{ width: '100%', marginTop: '5%' }} />
                        <Grid item xs={12} style={{ marginTop: '8%' }}>
                            {
                                menuItems?.map((item, i) => {
                                    let last_item = menuItems?.length - 1
                                    return (
                                        <div style={{ paddingLeft: '10%', display: 'flex', alignItems: 'center', margin: i === 0 || i === last_item ? '0' : '10% 0' }}>
                                            <p onClick={() => handleMenuItemClick(item)}
                                                style={{ fontWeight: 500, color: 'black', fontSize: 17, cursor: 'pointer' }}>{item?.title}</p>
                                        </div>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                </div >
            </>
        )
    }

    const list = (anchor) => (
        <Box
            style={{ width: 350, position: 'relative' }}
            role="presentation"
            onKeyDown={(event) => {
                if (event.key === 'Escape') {
                    toggleDrawer(anchor, false)(event);
                }
            }}
        >

            <div onClick={toggleDrawer('right', false)} style={{
                position: 'absolute',
                right: '3%',
                top: '3%',
                cursor: 'pointer',
            }}
            >
                <img src='/images/modal-close-icon.svg' style={{ width: '100%', height: '100%' }} />
            </div>

            {menuItemsFunc()}
            <Button
                onClick={() => { setModalOpen(true); toggleDrawer('right', false)() }}
                variant='contained'
                style={{ ...button_right_style, marginLeft: '10%', marginTop: '10%', width: '80%' }}>
                {isLoggedIn ? 'My Account' : 'Register/Login'}
            </Button>
        </Box >
    );


    return (
        <div>
            <React.Fragment key={'right'}>
                <div onClick={toggleDrawer('right', true)}><MenuIcon style={{ width: 40, height: 40, cursor: 'pointer' }} /></div>
                <Drawer
                    anchor={'right'}
                    open={state['right']}
                    onClose={toggleDrawer('right', false)}
                >
                    {list('right')}
                </Drawer>
            </React.Fragment>
        </div>
    );
}