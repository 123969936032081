import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import { button_left_style, button_right_style, getFieldName, disabled_btn, browseProfilesOptions, sendInterestOptions, featuredOptions, boolean_options, videoOptions, receiveInterestOptions, handleCreateActivity } from '../../../Constants/globalConstants';
import { post_data } from '../../../Api';
import { Dropdown } from 'primereact/dropdown';
import { useSelector } from 'react-redux';

export default function EditPlanModal({
    open,
    setOpen,
    plan,
    plansPermissions,
    editItems,
    fetchPlansPermissions,
    fetchAllPlans,
    setSuccessStatus,
    setSuccessOpen,
}) {

    const [formData, setFormData] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const { user_data } = useSelector(state => state.user);

    React.useEffect(() => {
        setFormData(plan)
    }, [plan])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 850,
        height: '80%',
        overflowY: 'auto',
        bgcolor: 'white',
        boxShadow: 24,
        padding: '2%',
        borderRadius: 4
    };

    const modal_title = {
        textAlign: 'center',
        width: '80%',
        fontSize: 23,
        fontWeight: 600,
    }

    const input_style = {
        margin: '3% 0'
    }

    const optionsMap = {
        browse_profiles: browseProfilesOptions,
        send_interest: sendInterestOptions,
        get_featured: featuredOptions,
        view_contact_details: boolean_options,
        video_communication: videoOptions,
        get_up_to_2x_more_matches: boolean_options,
        unlock_access_to_advanced_search: boolean_options,
        receive_interest: receiveInterestOptions
    };

    const getPermissionsField = () => {
        return plan?.permissions?.map((field, i) => {
            let field_name = field?.permission_id?.permission_name || ''
            let field_value = { name: formData?.permissions?.[i]?.value, code: formData?.permissions?.[i]?.value }
            return (
                <>
                    <Grid item md={6} style={{ margin: '1% 0' }}>
                        <div style={input_style}>
                            <label className="input-label color-label">{field_name}</label>
                            <Dropdown
                                style={{ width: '95%' }}
                                value={field_value}
                                options={optionsMap[getFieldName(field_name)]}
                                optionLabel="name"
                                name={getFieldName(field_name)}
                                placeholder={field_name}
                                className="global-select"
                                onChange={(e) => handlePermissionsInputChange(field?.permission_id?._id, e?.target?.value)}
                            />
                        </div>
                    </Grid>
                </>
            )
        })
    }

    React.useEffect(() => {
        getPermissionsField()
    }, [])

    const handleClose = () => setOpen(false);

    const text_fields = editItems?.filter(subItem => subItem?.type === 'text');
    const number_fields = editItems?.filter(subItem => subItem?.type === 'number');

    const handleInputChange = (e, field, i, type) => {
        if (type === 'number') {
            if (!/^\d*$/.test(e.target.value)) {
                return;
            }

            if (parseInt(e.target.value) < 0) {
                return;
            }
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }

        else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    }

    const handlePermissionsInputChange = (permissionId, newValue) => {
        let modified_newValue = newValue?.name
        const permissionIndex = formData?.permissions?.findIndex(permission => permission?.permission_id?._id === permissionId);

        if (permissionIndex !== -1) {
            const updatedFormData = { ...formData };
            updatedFormData.permissions[permissionIndex].value = modified_newValue;
            if (updatedFormData) {
                setFormData(updatedFormData);
            }
        } else {
            console.error("Permission not found!");
        }
    };


    const handleEnter = (event) => {
        if (event.key == 'Enter') {
            handleSubmit()
        }
    }

    const textFields = () => {
        return text_fields.map((field, i) => {
            let field_name = getFieldName(field?.field)
            return (
                <>
                    <Grid item md={6} style={{ margin: '1% 0' }}>
                        <div style={input_style}>
                            <label className="input-label color-label">
                                {field?.field}
                            </label>
                            <input
                                disabled={field?.field === 'Name' ? true : false}
                                onKeyPress={(event) => handleEnter(event)}
                                value={formData?.[field_name]}
                                style={{ height: 42 }}
                                // className={`register-from-input ${invalid?.first_name ? 'invalid-input' : ''}`}
                                className='register-from-input'
                                type="text"
                                name={getFieldName(field?.field)}
                                onChange={(e) => handleInputChange(e, field, i, 'text')}
                            />
                        </div>
                    </Grid>
                </>
            )
        })
    }

    const numberFields = () => {
        return number_fields.map((field, i) => {
            let field_name = getFieldName(field?.field)
            return (
                <>
                    <Grid item md={4} style={{ margin: '1% 0' }}>
                        <div style={input_style}>
                            <label className="input-label color-label">{field?.field}</label>
                            <input
                                onKeyPress={(event) => handleEnter(event)}
                                value={formData?.[field_name]}
                                style={{ height: 42 }}
                                // className={`register-from-input ${invalid?.first_name ? 'invalid-input' : ''}`}
                                className='register-from-input'
                                type="text"
                                pattern="[0-9]*"
                                maxLength={10}
                                name={getFieldName(field?.field)}
                                onChange={(e) => handleInputChange(e, field, i, 'number')}
                            />
                        </div>
                    </Grid>
                </>
            )
        })
    }

    const handleSubmit = () => {
        setLoading(true)
        setTimeout(async () => {
            const response = await post_data(`plan/update-plan/${formData?._id}`, formData)
            if (response?.status) {
                setOpen(false)
                setLoading(false)
                setSuccessOpen(true)
                setSuccessStatus(true)
                fetchPlansPermissions()
                fetchAllPlans()
                handleCreateActivity('Plan Updated', user_data)
            }
            else {
                setOpen(false)
                setLoading(false)
                setSuccessOpen(true)
                setSuccessStatus(false)
            }
        }, 1000);
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}
                //  className="conversation-box"
                >
                    <div onClick={handleClose} style={{
                        position: 'absolute',
                        right: '3%',
                        top: '3%',
                        cursor: 'pointer',
                    }}
                    >
                        <img src='/images/modal-close-icon.svg' style={{ width: '100%', height: '100%' }} />
                    </div>

                    <Grid container spacing={0}>
                        <Grid item md={12} style={{ marginBottom: '5%', display: 'flex', justifyContent: 'center' }}>
                            <h2 style={modal_title}>Edit {plan?.name} Plan</h2>
                        </Grid>
                    </Grid>

                    <Grid container spacing={0}>
                        {textFields()}
                        {getPermissionsField()}
                        {numberFields()}
                    </Grid>

                    <Grid item md={5} style={{
                        width: '60%',
                        display: 'flex',
                        gap: '4%',
                        margin: '3% auto 0 auto',
                        marginTop: '5%'
                    }}>
                        <Button onClick={handleClose} variant='contained' style={button_left_style}>
                            Close
                        </Button>

                        <Button
                            onClick={(e) => handleSubmit(e)} disabled={loading} variant='contained'
                            style={loading ? { ...button_right_style, ...disabled_btn } : button_right_style}
                        >{loading ? 'Saving...' : 'Save'}
                        </Button>
                    </Grid>

                </Box>
            </Modal>
        </div>
    );
}
