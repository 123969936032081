import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal, { modalClasses } from "@mui/material/Modal";
import { useState } from "react";
import { Grid, colors } from "@mui/material";
import { secondaryDarkColor, useDatabaseConstants } from "../../../Constants/globalConstants";
import { post_data } from "../../../Api";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../redux/slices/user-slice";
import { Link, useNavigate } from "react-router-dom";
import { socketServerUrl } from "../../../lib/axios-request";
import { io } from "socket.io-client";
import { useEffect } from "react";
import { useMemo } from "react";
import { DarkModeContext } from "./DarkModeContext";

export default function DefaultModal({
  open,
  setOpen,
  title_right,
  title_left,
  image,
  item,
  para_right,
  para_left,
  button_left,
  button_right,
  screen,
  loading_right_btn_txt,
  setSuccessOpen,
  button_clicked,
  successStatus,
  setSuccessStatus,
  sentInterestMatch,
  noPlan,
  limit,
}) {


  const dispatch = useDispatch();
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const { app_color } = useDatabaseConstants();
  const navigate = useNavigate();
  const { isLoggedIn, user_data } = useSelector((state) => state.user);
  const { darkMode } = React.useContext(DarkModeContext);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: darkMode ? secondaryDarkColor : "white",
    boxShadow: 24,
    borderRadius: 4,
    padding: "3%",
  };

  const modal_title = {
    fontSize: 25,
    fontWeight: 400,
    color: app_color,
    textAlign: "center",
  };

  const modal_para = {
    fontWeight: 500,
    textAlign: "center",
    fontSize: 14,
    color: "#00000080",
  };

  const button_right_style = {
    background: app_color,
    width: "50%",
    color: "white",
    boxShadow: "none",
    borderRadius: 7,
    fontWeight: 500,
    fontSize: 15,
    textTransform: "capitalize",
    padding: "3% 0",
  };

  const button_left_style = {
    background: "white",
    width: "50%",
    color: app_color,
    boxShadow: "none",
    borderRadius: 7,
    padding: "3% 0",
    fontWeight: 500,
    fontSize: 15,
    textTransform: "capitalize",
    border: `2px solid ${app_color}`,
  };

  const disabled_btn = {
    background: "#9e004f8e",
  };

  const handleRightBtnClick = () => {
    setLoading(true);
    if (noPlan) {
      navigate("/pricing");
    } else if (limit === "exceed") {
      navigate("/pricing");
    } else {
      // setTimeout(() => {
      //   handleSubmit();
      // }, 1000);
    }
  };

  const handleLeftBtnClick = async () => {
    handleClose();
  };

  // const handleSubmit = async () => {
  //   let payload = {};
  //   let endpoint = "";

  //   if (
  //     screen === "Match" &&
  //     sentInterestMatch === "pending" &&
  //     button_clicked === "right"
  //   ) {
  //     payload = {
  //       sender_id: item?._id,
  //       receiver_id: user_data?._id,
  //       status: "accepted",
  //     };
  //     endpoint = "user/update-interest-status";
  //   }
  //   if (
  //     screen === "Match" &&
  //     sentInterestMatch === "pending" &&
  //     button_clicked === "left"
  //   ) {
  //     payload = {
  //       sender_id: item?._id,
  //       receiver_id: user_data?._id,
  //       status: "declined",
  //     };
  //     endpoint = "user/update-interest-status";
  //   }
  //   if (screen === "Match" && sentInterestMatch === "Send Interest") {
  //     payload = { sender_id: user_data?._id, receiver_id: item?._id };
  //     endpoint = "user/send-interest";
  //   } else if (screen === "Interest Received") {
  //     if (button_clicked === "right") {
  //       payload = {
  //         sender_id: item?._id,
  //         receiver_id: user_data?._id,
  //         status: "accepted",
  //       };
  //       endpoint = "user/update-interest-status";
  //     } else {
  //       payload = {
  //         sender_id: item?._id,
  //         receiver_id: user_data?._id,
  //         status: "declined",
  //       };
  //       endpoint = "user/update-interest-status";
  //     }
  //   }
  //   const response = await post_data(endpoint, payload);
  //   if (response?.status) {
  //     dispatch(login(response?.data));
  //     setLoading(false);
  //     setSuccessOpen(true);
  //     handleClose();
  //     setSuccessStatus(true);
  //   }
  // };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div
            onClick={handleClose}
            style={{
              position: "absolute",
              right: "3%",
              top: "3%",
              cursor: "pointer",
            }}
          >
            <img
              src="/images/modal-close-icon.svg"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <Grid container spacing={0}>

            {
              !darkMode && (
                <Grid item md={12}>
                  <center>
                    <img
                      src={`/images/${image}.svg`}
                      style={{ width: "70%", height: "70%" }}
                    />
                  </center>
                </Grid>
              )
            }

            <Grid
              item
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h2 style={{ ...modal_title, color: darkMode ? 'white' : '' }}>
                {button_clicked === "right" ? title_right : title_left}
              </h2>
              <p style={{ ...modal_para, color: darkMode ? 'gray' : '' }}>
                {button_clicked === "right" ? para_right : para_left}
              </p>
            </Grid>

            <Grid
              item
              md={9}
              style={{
                display: "flex",
                gap: "4%",
                margin: "6% auto 0 auto",
              }}
            >
              {noPlan ? (
                <>
                  {button_right?.length !== 0 && (
                    <Button
                      onClick={handleRightBtnClick}
                      disabled={loading}
                      variant="contained"
                      style={
                        loading
                          ? {
                              ...button_right_style,
                              ...disabled_btn,
                              width: "100%",
                            }
                          : { ...button_right_style, width: "100%" }
                      }
                    >
                      {button_right}
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {limit === "exceed" ? (
                    <>
                      {button_right?.length !== 0 && (
                        <Button
                          onClick={handleRightBtnClick}
                          disabled={loading}
                          variant="contained"
                          style={
                            loading
                              ? {
                                  ...button_right_style,
                                  ...disabled_btn,
                                  width: "100%",
                                }
                              : { ...button_right_style, width: "100%" }
                          }
                        >
                          {button_right}
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      {button_left?.length !== 0 && (
                        <Button
                          onClick={handleLeftBtnClick}
                          variant="contained"
                          style={button_left_style}
                        >
                          {button_left}
                        </Button>
                      )}
                      {button_right?.length !== 0 && (
                        <Button
                          onClick={handleRightBtnClick}
                          disabled={loading}
                          variant="contained"
                          style={
                            loading
                              ? { ...button_right_style, ...disabled_btn }
                              : button_right_style
                          }
                        >
                          {loading ? loading_right_btn_txt : button_right}
                        </Button>
                      )}
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
