import { Grid } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading"
import SettingsComponent from "../Components/SettingsComponent"
import { useEffect, useState } from "react";
import SettingsSkeleton from "../Components/skeleton/SettingsSkeleton";
import AdminSettings from "../../Admin/Components/AdminSettings";
import BackButton from "../Components/BackButton";

export default function Settings({
    type
}) {

    const [loading, setLoading] = useState(true)

    const settings_page = {
        width: '100%',
    }

    const page_container = {
        width: "100%",
        margin: "2% 0",
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }, [])

    return (
        <>
            <Grid container spacing={0} style={{ ...settings_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <BackButton />
                <DashboardHeading title='Settings 📌' />

                <div style={page_container}>
                    {
                        loading ?
                            <>
                                <SettingsSkeleton />
                            </>
                            :
                            <>
                                {
                                    type === 'admin' ?
                                        <>
                                            <AdminSettings type={'admin'} />
                                        </>
                                        :
                                        <>
                                            <SettingsComponent />
                                        </>
                                }
                            </>
                    }
                </div>

            </Grid>
        </>
    )
}