import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function InterestButtonsFunction({
    screen,
    item,
}) {

    const [interestStatus, setInterestStatus] = useState('')
    const [sentInterestMatch, setSentInterestMatch] = useState('')
    const [receivedInterestMatch, setReceivedInterestMatch] = useState('')
    const { user_data } = useSelector(state => state.user);

    useEffect(() => {
        if (screen === 'Match') {
            const checkIsSentInterest = item?.interest_sent?.find((subItem) => subItem?.user_id === user_data?._id)
            const checkIsReceivedInterest = item?.interest_received?.find((subItem) => subItem?.user_id === user_data?._id)

            if (checkIsSentInterest) {
                if (checkIsSentInterest?.status === 'pending') {
                    setSentInterestMatch('pending')
                }
                else if (checkIsSentInterest?.status === 'accepted') {
                    setSentInterestMatch('accepted')
                }
                else if (checkIsSentInterest?.status === 'declined') {
                    setSentInterestMatch('declined')
                }
            }
            else {
                setSentInterestMatch('Send Interest')
            }

            if (checkIsReceivedInterest) {
                if (checkIsReceivedInterest?.status === 'pending') {
                    setReceivedInterestMatch('pending')
                }
                else if (checkIsReceivedInterest?.status === 'accepted') {
                    setReceivedInterestMatch('accepted')
                }
                else if (checkIsReceivedInterest?.status === 'declined') {
                    setReceivedInterestMatch('declined')
                }
            }
            else {
                setReceivedInterestMatch('Send Interest')
            }
        }
        else if (screen === 'Interest Sent') {
            const checkIsSentInterest = item?.interest_received?.find((subItem) => subItem?.user_id === user_data?._id)
            if (checkIsSentInterest) {
                if (checkIsSentInterest?.status === 'pending') {
                    setInterestStatus('pending')
                }
                else if (checkIsSentInterest?.status === 'accepted') {
                    setInterestStatus('accepted')
                }
                else if (checkIsSentInterest?.status === 'declined') {
                    setInterestStatus('declined')
                }
            }
        }
        else if (screen === 'Interest Received') {
            const checkIsSentInterest = item?.interest_sent?.find((subItem) => subItem?.user_id === user_data?._id)
            if (checkIsSentInterest?.status === 'pending') {
                setInterestStatus('pending')
            }
            else if (checkIsSentInterest?.status === 'accepted') {
                setInterestStatus('accepted')
            }
            else if (checkIsSentInterest?.status === 'declined') {
                setInterestStatus('declined')
            }
        }
    }, [item])


    return { interestStatus, sentInterestMatch, receivedInterestMatch }
}
