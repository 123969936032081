import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal, { modalClasses } from '@mui/material/Modal';
import { useState } from 'react';
import { Grid, colors } from '@mui/material';
import { secondaryDarkColor, useDatabaseConstants } from '../../../Constants/globalConstants';
import { DarkModeContext } from './DarkModeContext';

export default function SuccessModal({
    open,
    setOpen,
    title,
    setModalType,
    status,
}) {

    const handleClose = () => {
        setOpen(false);
        if (setModalType) {
            setModalType('')
        }
    };
    const { app_color } = useDatabaseConstants()
    const { darkMode } = React.useContext(DarkModeContext);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 350,
        bgcolor: darkMode ? secondaryDarkColor : 'white',
        boxShadow: 24,
        borderRadius: 4,
        padding: '5% 1%'
    };

    const modal_title = {
        marginTop: '4%',
        textAlign: 'center',
        width: '80%',
        fontSize: 24,
        fontWeight: 500
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <div onClick={handleClose} style={{
                        position: 'absolute',
                        right: '3%',
                        top: '3%',
                        cursor: 'pointer',
                    }}
                    >
                        <img src='/images/modal-close-icon.svg' style={{ width: '100%', height: '100%' }} />
                    </div>
                    <Grid container spacing={0}>
                        <Grid item md={12}>
                            <center><img src={status ? `/images/success-image.svg` : '/images/failure-image.svg'} style={{ width: '40%', height: '40%' }} /></center>
                        </Grid>

                        <Grid item md={12} style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                            <h2 style={{ ...modal_title, color: darkMode ? 'white' : app_color }}>{title}</h2>
                        </Grid>

                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
