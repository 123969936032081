import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { Button, Grid } from "@mui/material";
import {
    button_right_style,
    useDatabaseConstants,
    disabled_btn,
    handleCreateActivity,
} from "../../../Constants/globalConstants";
import { login } from "../../../redux/slices/user-slice";
import EmptyPage from "../Components/EmptyPage";
import { post_data, serverURLPhotos, serverURLProfilePicture, serverURLVideos } from "../../../Api";
import SuccessModal from "./SuccessModal";

export default function UserVideo({
    openVideoDropzone,
    setOpenVideoDropzone,
    deleteVideo,
    setDeleteVideo,
    showDisplayVideo,
    setShowDisplayVideo,
    permissions,
    screen,
    user,
    type,
    setUser
}) {

    const dispatch = useDispatch();
    const [selectedVideo, setSelectedVideo] = useState("");
    const [successStatus, setSuccessStatus] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const { app_color } = useDatabaseConstants();
    const { isLoggedIn, user_data } = useSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const [noPlan, setNoPlan] = useState(false);


    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: "video/*",
        multiple: false, // Allow only one video file
        onDrop: (acceptedFiles) => {
            if (acceptedFiles?.length > 0) {
                setSelectedVideo(acceptedFiles[0]); // Set the first video file
            }
        },
    });

    useEffect(() => {
        if (user?.plan_id?.length === 0) {
            setNoPlan(true)
        }
    }, [user])

    const displayVideo = () => {
        return (
            <>
                <video
                    className={(screen === 'Match' || screen === 'Sent' || screen === 'Interest Sent' || screen === 'Interest Received' || screen === 'Received') && noPlan ? "blur-photos" : ""}
                    autoPlay
                    loop
                    // muted
                    width={350}
                    style={{
                        display: 'block',
                        margin: 0,
                        padding: 0,
                        border: 'none'
                    }}
                >
                    <source src={`${serverURLVideos}/${user?.video}`} type="video/mp4" />
                </video>
            </>
        )
    };

    const handleRemoveVideo = () => {
        setSelectedVideo("");
    };

    const handleSubmit = (e) => {
        e?.preventDefault();
        setLoading(true);

        setTimeout(async () => {
            let payload = { old_video: "", video: selectedVideo };

            let form_data = new FormData();

            form_data.append("old_video", "");
            form_data.append("video", selectedVideo);

            const response = await post_data(
                `user/upload-user-video/${user?._id}`,
                form_data
            );
            if (response?.status) {
                setLoading(false);
                setSuccessOpen(true);
                setSuccessStatus(true);
                if (type === 'Admin') {
                    setUser(response?.data)
                    handleCreateActivity('User video uploaded', user_data)
                }
                else {
                    dispatch(login(response?.data));
                }
                setOpenVideoDropzone(false)
            } else {
                setLoading(false);
                setSuccessOpen(true);
                setSuccessStatus(false);
                setOpenVideoDropzone(false)
            }
        }, 1000);
    };

    const addVideo = () => {
        return (
            <>
                <div>
                    <div {...getRootProps()} className="dropzone">
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <p>Drop a video here ...</p>
                        ) : (
                            <p>Drag your video here, or click to select a video</p>
                        )}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            gap: 0,
                            marginTop: "3%",
                            flexWrap: "wrap",
                        }}
                    >
                        {selectedVideo && (
                            <div style={{ position: "relative", padding: "2%" }}>
                                <div
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        background: app_color,
                                        borderRadius: 5,
                                    }}
                                >
                                    <ClearOutlinedIcon
                                        style={{
                                            color: "white",
                                            cursor: "pointer",
                                        }}
                                        onClick={handleRemoveVideo}
                                    />
                                </div>
                                <video
                                    src={`${URL.createObjectURL(selectedVideo)}`}
                                    style={{
                                        width: 180,
                                        height: 180,
                                        margin: "3%",
                                        borderRadius: 7,
                                    }}
                                    controls
                                />
                            </div>
                        )}
                    </div>
                </div>
                <Grid
                    item
                    md={3}
                    style={{
                        width: "60%",
                        display: "flex",
                        gap: "4%",
                        margin: 0,
                    }}
                >
                    <Button
                        onClick={handleSubmit}
                        disabled={loading}
                        variant="contained"
                        style={
                            loading
                                ? { ...button_right_style, ...disabled_btn }
                                : button_right_style
                        }
                    >
                        {loading ? "Saving..." : "Save"}
                    </Button>
                </Grid>
            </>
        );
    };

    return (
        <>
            <Grid container spacing={0}>
                {screen !== "Match" &&
                    screen !== "Sent" &&
                    screen !== "Received" &&
                    screen !== "Interest Sent" &&
                    screen !== "Interest Received" &&
                    openVideoDropzone && (
                        <Grid item md={12} style={{ marginTop: "2%" }}>
                            {addVideo()}
                        </Grid>
                    )}

                <Grid item md={12}>
                    {user?.video === "" ? (
                        <>
                            <EmptyPage title="No Video uploaded yet..." />
                        </>
                    ) : (
                        <>
                            {setShowDisplayVideo && (
                                <div style={{ marginTop: "3%" }}>{displayVideo()}</div>
                            )}
                        </>
                    )}
                </Grid>
            </Grid>
            <SuccessModal
                status={successStatus}
                open={successOpen}
                setOpen={setSuccessOpen}
                title={
                    successStatus
                        ? `Video uploaded successfully!`
                        : "Something went wrong..."
                }
            />
        </>
    );
}
