import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { button_right_style, formatDate, formatPrice, handleCreateActivity, secondaryDarkColor } from "../../../Constants/globalConstants";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
import { DarkModeContext } from "./DarkModeContext";
import { post_data } from "../../../Api";

const InvoiceModal = ({ open, setOpen, currentInvoice, currentIndex, type, screen }) => {

  const contentRef = useRef(null);
  const { user_data } = useSelector(state => state.user);
  const { darkMode } = React.useContext(DarkModeContext);

  const styles = {
    invoice: {
      padding: "4%",
      height: 470,
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "2px solid #000",
      paddingBottom: "10px",
      marginBottom: "5%",
    },
    brand: {
      textAlign: "left",
    },
    info: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    infoSection: {
      textAlign: "left",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
    },
    th: {
      border: "1px solid #ddd",
      padding: "8px",
    },
    td: {
      border: "1px solid #ddd",
      padding: "8px",
    },
    footer: {
      marginTop: "20px",
      borderTop: "2px solid #000",
      marginTop: "5%",
      paddingTop: "10px",
      display: "flex",
      justifyContent: "space-between",
    },
    terms: {
      textAlign: "left",
    },
    payment: {
      textAlign: "left",
    },
    summary: {
      marginTop: "20px",
      textAlign: "right",
    },
    text: {
      fontSize: 14,
    },
  };

  const details = [
    {
      invoice_to: type === 'admin' ? (currentInvoice?.user_id?.first_name + ' ' + currentInvoice?.user_id?.last_name) : (user_data?.first_name + ' ' + user_data?.last_name),
      invoice_to_address: "24 Dummy Street Area, Location, Lorem Ipsum, 570xx59x",
      item_description: screen === 'verification' ? 'Verification Plan' : currentInvoice?.plan_id?.name + ` Plan (${currentInvoice?.plan_type})`,
      item_price: screen === 'verification' ? currentInvoice?.amount : currentInvoice?.payment_amount,
      qty: 1,
      total: screen === 'verification' ? currentInvoice?.amount : currentInvoice?.payment_amount,
      tax: 0,
      grand_total: screen === 'verification' ? currentInvoice?.amount : currentInvoice?.payment_amount,
      invoice_number: "#" + currentIndex + 1,
      invoice_date: currentInvoice?.createdAt,
    },
  ];


  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: '90%',
    borderRadius: 5,
    overflowY: 'auto',
    bgcolor: darkMode ? secondaryDarkColor : "white",
    color: darkMode ? 'white' : 'black',
    border: "none",
    boxShadow: 24,
    p: 4,
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownloadPdf = async () => {
    if (contentRef.current) {
      const canvas = await html2canvas(contentRef.current);
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("invoice.pdf");
      setOpen(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={styleModal}>
        <Button variant="contained" onClick={() => { handleDownloadPdf(); handleCreateActivity('Invoice Downloaded', user_data) }} style={{
          ...button_right_style,
          width: '20%',
          padding: '1%',
          marginBottom: '3%'
        }}>Download</Button>
        <div ref={contentRef} id="invoice" style={styles.invoice}>
          <div style={styles.header}>
            <div style={styles.brand}>
              <h2 style={{ fontWeight: 600 }}>DilSey</h2>
              <p style={{ fontSize: 11, opacity: "70%" }}>
                Rishtey Jodo DilSey
              </p>
            </div>
            <h1 style={{ fontWeight: 600 }}>INVOICE</h1>
          </div>
          <div style={{ ...styles.info, ...styles.text }}>
            <div style={styles.infoSection}>
              <p>
                <strong>Invoice to:</strong>
              </p>
              <p>{details[0]?.invoice_to}</p>
              {/* <p>{details[0]?.invoice_to_address}</p> */}
            </div>
            <div style={styles.infoSection}>
              {/* <p>
                <strong>Invoice: </strong> {details[0]?.invoice_number}
              </p> */}
              <p>
                <strong>Date: </strong> {formatDate(details[0]?.invoice_date)}
              </p>
            </div>
          </div>
          <table style={{ ...styles.table, ...styles.text, marginTop: "3%" }}>
            <thead style={{ textAlign: "left" }}>
              <tr>
                <th>SL.</th>
                <th>Item Description</th>
                <th>Price</th>
                <th>Qty.</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.</td>
                <td>{details[0]?.item_description}</td>
                <td>{formatPrice(details[0]?.item_price)}</td>
                <td>{details[0]?.qty}</td>
                <td>{formatPrice(details[0]?.total)}</td>
              </tr>
            </tbody>
          </table>
          <div style={{ ...styles.footer, ...styles.text }}>
            <div style={styles.terms}>
              <p>Thanks for doing Business with us</p>
            </div>
            <div style={{ ...styles.summary, ...styles.text }}>
              <p>Sub Total: {formatPrice(details[0]?.total)}</p>
              <p>Tax: {details[0]?.tax}</p>
              <p>
                <strong>Total: {formatPrice(details[0]?.grand_total)}</strong>
              </p>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default InvoiceModal;
