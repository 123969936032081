import { createSlice } from "@reduxjs/toolkit";

export const subAdminSlice = createSlice({
  name: "sub-admin",
  initialState: {
    isLoggedIn: false,
    sub_admin_data: [],
  },
  reducers: {
    subAdminLogout: (state) => {
      state.isLoggedIn = false;
      state.sub_admin_data = [];
    },
    subAdminLogin: (state, action) => {
      state.isLoggedIn = true;
      state.sub_admin_data = action.payload;
    },
  },
});

export const { subAdminLogin, subAdminLogout } = subAdminSlice.actions;

export default subAdminSlice.reducer;
