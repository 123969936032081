import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Grid, Button, Checkbox, Link } from '@mui/material';
import { button_right_style, useDatabaseConstants, disabled_btn, button_left_style } from '../../../Constants/globalConstants';
import { post_data } from '../../../Api';
import { useNavigate, useParams } from 'react-router-dom';

const SuperAdminResetPassword = () => {

    const { app_color } = useDatabaseConstants()
    const [loading, setLoading] = useState(false)
    const [passwordMatch, setPasswordMatch] = useState(true)
    let { token } = useParams();
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        new_password: '',
        confirm_new_password: ''
    })

    const [invalid, setInvalid] = useState({
        new_password: false,
        confirm_new_password: false,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setInvalid({ ...invalid, [name]: false })
        setPasswordMatch(true)
    };

    const handleSubmit = (e) => {
        e?.preventDefault();

        let is_false = 0;
        let obj = {
            new_password: false,
            confirm_new_password: false,
        }

        if (formData?.new_password === '') {
            obj.new_password = true;
            is_false = 1;
        }

        if (formData?.confirm_new_password === '') {
            obj.confirm_new_password = true;
            is_false = 1;
        }

        if (is_false) {
            setInvalid(obj);
            return "";
        }

        if (formData?.new_password !== formData?.confirm_new_password) {
            setPasswordMatch(false)
            return
        }

        setLoading(true)

        setTimeout(async () => {
            let payload = { new_password: formData?.new_password, confirm_new_password: formData?.confirm_new_password, token: token }
            const response = await post_data('super-admin/verify-and-change-password', payload)
            if (response?.status) {
                setFormData({
                    new_password: '',
                    confirm_new_password: ''
                })
                toast.success("Password reset successfully.");
                setLoading(false)
                setTimeout(() => {
                    navigate('/admin/dashboard')
                }, 1000);
            }
            else {
                setFormData({
                    new_password: '',
                    confirm_new_password: ''
                })
                setLoading(false)
                toast.error("Something went wrong.");
            }
        }, 1000);
    }

    const handleEnter = (event) => {
        if (event.key == 'Enter') {
            handleSubmit()
        }
    }

    return (
        <>
            <div className="container-new modal">
                <Toaster />
                <div className="fp-card">
                    <img src="/images/reset-password.svg" style={{ width: 280, height: 280 }} />
                    <h3 className='global-h3' style={{ marginBottom: '1%', fontSize: 25, color: 'black' }}>Reset your password</h3>
                    <p className='about-para'>Enter your new password below securely.</p>
                    <div style={{ textAlign: 'left', marginTop: '5%', width: '100%' }}>
                        <input
                            onKeyPress={(event) => handleEnter(event)}
                            placeholder='Enter new Password'
                            value={formData?.new_password}
                            type='text'
                            style={{ height: 42, paddingLeft: '3%' }}
                            className={`register-from-input ${invalid?.new_password ? 'invalid-input' : ''}`}
                            name={'new_password'}
                            onChange={handleInputChange}
                        />
                        {
                            invalid?.new_password && <p className="invalid-txt" style={{ display: "flex" }}>
                                Enter new password
                            </p>
                        }

                        <input
                            onKeyPress={(event) => handleEnter(event)}
                            placeholder='Confirm new Password'
                            value={formData?.confirm_new_password}
                            type='text'
                            style={{ height: 42, paddingLeft: '3%', marginTop: '5%' }}
                            className={`register-from-input ${invalid?.confirm_new_password ? 'invalid-input' : ''}`}
                            name={'confirm_new_password'}
                            onChange={handleInputChange}
                        />
                        {
                            invalid?.confirm_new_password && <p className="invalid-txt" style={{ display: "flex" }}>
                                Confirm new password
                            </p>
                        }

                        {
                            !passwordMatch && <p className="invalid-txt" style={{ display: "flex" }}>
                                Password didn't match!
                            </p>
                        }
                    </div>
                    <Button
                        disabled={loading}
                        onClick={handleSubmit}
                        style={{
                            ...button_right_style,
                            width: '40%',
                            margin: 'auto',
                            marginTop: '6%',
                            ...(loading ? disabled_btn : {})
                        }}
                    >
                        {loading ? 'Confirming...' : 'Reset Password'}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default SuperAdminResetPassword;