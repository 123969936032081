import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import { button_left_style, button_right_style, getFieldName, disabled_btn, handleCreateActivity } from '../../../Constants/globalConstants';
import { post_data } from '../../../Api';
import CustomToggle from '../../UserDshboard/Components/CustomToggle';
import { useSelector } from 'react-redux';

export default function EditPlanPermissionModal({
    open,
    setOpen,
    fetchPlansPermissions,
    setSuccessOpen,
    setSuccessStatus,
    allPlansPermisison
}) {

    const [formData, setFormData] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const { user_data } = useSelector(state => state.user);

    React.useEffect(() => {
        setFormData(allPlansPermisison)
    }, [allPlansPermisison])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 550,
        overflowY: 'auto',
        bgcolor: 'white',
        boxShadow: 24,
        padding: '2%',
        borderRadius: 4
    };

    const modal_title = {
        textAlign: 'center',
        width: '80%',
        fontSize: 23,
        fontWeight: 600,
    }

    const input_style = {
        margin: '3% 0'
    }

    const handleClose = () => setOpen(false);

    const handleInputChange = (e, i, type) => {
        const updatedFormData = [...formData];
        if (type === 'text') {
            updatedFormData[i] = {
                ...updatedFormData[i],
                permission_name: e.target.value
            };
        } else if (type === 'active') {
            updatedFormData[i] = {
                ...updatedFormData[i],
                is_active: !formData?.[i]?.is_active
            };
        }
        setFormData(updatedFormData);
    };


    const textFields = () => {
        return allPlansPermisison?.map((field, i) => {
            return (
                <>
                    <Grid item md={9} style={{ margin: '3% 0' }}>
                        <div>
                            <label className="input-label">
                                {field?.permission_name}
                            </label>
                            {/* <input
                                value={formData?.[i]?.permission_name}
                                style={{ height: 42 }}
                                // className={`register-from-input ${invalid?.first_name ? 'invalid-input' : ''}`}
                                className='register-from-input'
                                type="text"
                                name={getFieldName(field?.field)}
                                onChange={(e) => handleInputChange(e, i, 'text')}
                            /> */}
                        </div>
                    </Grid>
                    <Grid item md={3} style={{ margin: '3% 0' }}>
                        {/* <h3 className='global-h3' style={{ fontSize: 15, fontWeight: 500, marginRight: '5%' }}>Active</h3> */}
                        <CustomToggle
                            field={'active'}
                            payload={formData}
                            setPayload={setFormData}
                            handleInputChange={handleInputChange}
                            toggle_value={formData?.[i]?.is_active}
                            index={i}
                            screen='permissions'
                        />
                    </Grid>
                </>
            )
        })
    }

    const handleSubmit = () => {
        setLoading(true)
        setTimeout(async () => {
            const response = await post_data(`permission/bulk-update-permissions`, { permissions: formData })
            if (response?.status) {
                setOpen(false)
                setLoading(false)
                setSuccessOpen(true)
                setSuccessStatus(true)
                fetchPlansPermissions()
                handleCreateActivity('Plan Permission Updated', user_data)
            }
            else {
                setOpen(false)
                setLoading(false)
                setSuccessOpen(true)
                setSuccessStatus(false)
            }
        }, 1000);
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}
                >
                    <div onClick={handleClose}
                        style={{
                            position: 'absolute',
                            right: '3%',
                            top: '3%',
                            cursor: 'pointer',
                        }}
                    >
                        <img src='/images/modal-close-icon.svg' style={{ width: '100%', height: '100%' }} />
                    </div>

                    <Grid container spacing={0}>
                        <Grid item md={12} style={{ marginBottom: '5%', display: 'flex', justifyContent: 'center' }}>
                            <h2 style={modal_title}>Edit Permissions</h2>
                        </Grid>
                    </Grid>

                    <Grid container spacing={0} style={{ display: 'flex', alignItems: 'center' }}>
                        {textFields()}
                    </Grid>

                    <Grid item md={8} style={{
                        width: '60%',
                        display: 'flex',
                        gap: '4%',
                        margin: '5% auto 0 auto',
                    }}>
                        <Button onClick={handleClose} variant='contained' style={button_left_style}>
                            Close
                        </Button>

                        <Button
                            onClick={(e) => handleSubmit(e)} disabled={loading} variant='contained'
                            style={loading ? { ...button_right_style, ...disabled_btn } : button_right_style}
                        >{loading ? 'Saving...' : 'Save'}
                        </Button>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
