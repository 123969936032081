import React from "react"
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Testimonials from "../Components/Testimonials";
import PageHeroSection from '../Components/PageHeroSection'
import ContactForm from "../Components/ContactForm";
import BottomBar from "../Components/BottomBar";

export default function Contact() {

    return (
        <>
            <Header />
            <PageHeroSection title='Contact Us' />
            <ContactForm />
            <Footer />
            <BottomBar />
        </>
    )
}