import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { DarkModeContext } from '../DarkModeContext';
import { secondaryDarkColor } from '../../../../Constants/globalConstants';

export default function TableSkeleton() {

    const { darkMode } = React.useContext(DarkModeContext);

    const table_div = {
        padding: '3%',
        borderRadius: 10,
        border: darkMode ? 'none' : '1px solid #F1F1F2',
        boxShadow: '0px 3px 4px 0 #00000008',
        background: darkMode ? secondaryDarkColor : 'white'
    }

    return (
        <Stack spacing={2} style={table_div}>
            <Skeleton variant="rounded" width={'100%'} height={50} />
            <Skeleton variant="rounded" width={'100%'} height={50} />
            <Skeleton variant="rounded" width={'100%'} height={50} />
            <Skeleton variant="rounded" width={'100%'} height={50} />
            <Skeleton variant="rounded" width={'100%'} height={50} />
            <Skeleton variant="rounded" width={'100%'} height={50} />
            <Skeleton variant="rounded" width={'100%'} height={50} />
        </Stack>
    );
}