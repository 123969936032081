import { useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDatabaseConstants } from '../../Constants/globalConstants';
import FunctionConstant from '../../Constants/functionConstant';
import SignupSignModal from "../../Website/Components/SignupLoginModal"

const HeroSection = () => {

  const isDesktop = useMediaQuery('(min-width: 768px)');
  const { app_color } = useDatabaseConstants();

  const headings = ["Matchings", "Ristey"];

  const [modalOpen, setModalOpen] = useState(false);
  const { handleBtnClick } = FunctionConstant({ setModalOpen })

  const [currentHeading, setCurrentHeading] = useState(0);
  const [displayText, setDisplayText] = useState("");
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayText((prev) => prev + headings[currentHeading][charIndex]);
      setCharIndex((prev) => prev + 1);
    }, 100);

    if (charIndex === headings[currentHeading].length) {
      clearInterval(interval);
      setTimeout(() => {
        setCurrentHeading((prev) => (prev + 1) % headings.length);
        setDisplayText("");
        setCharIndex(0);
      }, 2000);
    }

    return () => clearInterval(interval);
  }, [charIndex, currentHeading, headings]);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: isDesktop ? 'row' : 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: isDesktop ? '4% 8%' : '20px 1px',
      position: 'relative'
    },
    leftSide: {
      flex: 1.1,
      padding: '20px',
      order: isDesktop ? 1 : 2,
    },
    rightSide: {
      flex: 1,
      padding: '20px',
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      order: isDesktop ? 2 : 1
    },
    title: {
      fontSize: 20,
      fontWeight: 500,
    },
    heading: {
      fontSize: isDesktop ? 120 : 65,
      fontWeight: '700',
      color: app_color,
      height: isDesktop ? 150 : 100,
      margin: 0,
    },
    highlight: {
      color: app_color,
    },
    description: {
      fontSize: '16px',
      marginTop: 0,
      marginBottom: isDesktop ? '7%' : '20px',
    },
    button: {
      padding: isDesktop ? '3% 6%' : '3% 6%',
      fontSize: '16px',
      color: '#fff',
      backgroundColor: app_color,
      border: 'none',
      borderRadius: 0,
      cursor: 'pointer',
    },
    image: {
      maxWidth: isDesktop ? '100%' : '100%',
      height: 'auto',
      borderRadius: '10px',
    },
    flower: {
      position: 'absolute',
      width: 250,
      right: isDesktop ? 0 : '50%',
      bottom: 0,
      transform: 'rotate(15deg)',
      animation: 'float 4s ease-in-out infinite',
    }
  };

  return (
    <div style={styles.container} className='hero-section'>
      <img src='/images/blur-div.svg' style={{ position: 'absolute', left: 0, bottom: 0, zIndex: -1 }} />
      <img src='/images/petal-1.svg' style={{ position: 'absolute', left: 0, bottom: 0, zIndex: -1, transform: 'rotate(100deg)', opacity: '50%' }} />
      <img src='/images/blur-div.svg' style={{ position: 'absolute', right: 0, bottom: 0, zIndex: -1, transform: 'rotate(180deg)' }} />
      {/* <div className='blurDiv' style={{ zIndex: 1, left: '25%' }}></div> */}

      <div style={styles.leftSide}>
        <img src='/images/plan-perfect-img.svg' style={{ width: isDesktop ? 350 : 300 }} />
        <h2 style={{ ...styles.heading, zIndex: 999 }}>{displayText}</h2>
        <p style={styles.description}>
          Are you ready to find that special someone who makes your heart skip a beat? At DilSey, we believe in the power of love and lasting connections.
        </p>
        <button style={styles.button} onClick={handleBtnClick}>Find Your Match</button>
      </div>

      <div style={styles.rightSide}>
        <img
          src="/images/hero-img-v2.svg"
          style={styles.image}
        />
        <img
          src="/images/flower-1.png"
          style={styles.flower}
        />
      </div>

      <SignupSignModal modalOpen={modalOpen} setModalOpen={setModalOpen} />

      <style>
        {`
          @keyframes float {
            0% {
              transform: translateY(0) rotate(15deg);
            }
            50% {
              transform: translateY(-10px) rotate(15deg);
            }
            100% {
              transform: translateY(0) rotate(15deg);
            }
          }
        `}
      </style>
    </div>
  );
};

export default HeroSection;