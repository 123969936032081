import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { secondaryDarkColor, useDatabaseConstants } from '../../../Constants/globalConstants';
import { DarkModeContext } from "../../UserDshboard/Components/DarkModeContext"

const faqs = [
    {
        title: "Is DilSey safe to use?",
        para: "Yes, DilSey prioritizes user safety. We have implemented security measures to protect your information, and our team monitors activity to ensure a positive experience for everyone.",
    },
    {
        title: "How does DilSey ensure profile quality?",
        para: "We have a system that allows the users to verify their profiles using Govt Id like Aadhar, Pan etc and thus getting a Verified badge, this ensure the genuinity of a profile.",
    },
    {
        title: "What makes DilSey different from other matrimony apps?",
        para: "DilSey goes beyond simple swiping. We focus on creating genuine connections based on shared values, interests, and lifestyle preferences. We also offer personalized support and a safe space for meaningful conversation.",
    },
    {
        title: "Is DilSey for everyone?",
        para: "DilSey welcomes individuals of all backgrounds and cultural preferences seeking long-term love. Our diverse community allows you to find a compatible match who shares your values.",
    },

    {
        title: "How does DilSey handle user data?",
        para: "DilSey follows strict data privacy regulations to ensure that user data is handled securely and responsibly.",
    },
    {
        title: "What features does DilSey offer for free?",
        para: "DilSey offers some free features including profile creation, browsing matches, and chat communication with users.",
    },
    {
        title: "How can I contact DilSey support?",
        para: "You can contact DilSey support via email at info@dilsey.in.",
    },
    {
        title: "Does DilSey offer any premium features?",
        para: "Yes, DilSey offers premium features such as advanced matchmaking algorithms, virtual AI assistant support.",
    },
];

const HelpCenter = () => {

    const { app_color } = useDatabaseConstants()
    const { darkMode } = React.useContext(DarkModeContext);

    const styles = {
        container: {
            padding: '3%',
            position: 'relative',
            background: darkMode ? secondaryDarkColor : 'white',
            borderRadius: 5
        },
        heading: {
            fontSize: 32,
            fontWeight: '600',
            marginBottom: '20px',
            width: '60%',
            color: darkMode ? 'white' : app_color
        },
        subheading: {
            fontSize: '18px',
            color: 'gray',
            marginBottom: '20px',
        },
        faqItem: {
            marginBottom: '10px',
            boxShadow: 'none',
            background: 'transparent'
        },
    };

    return (
        <Box sx={styles.container}>
            <Typography sx={styles.heading}>We're here to help you at every step!</Typography>
            {faqs.map((faq, index) => (
                <Accordion key={index} sx={styles.faqItem}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                    >
                        <Typography style={{ fontSize: 20, fontWeight: 500, color: darkMode ? 'white' : 'black' }}>{index + 1}. &nbsp; {faq.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{ color: darkMode ? 'white' : 'black', opacity: darkMode ? '70%' : '' }}>{faq.para}</Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};

export default HelpCenter;