import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal, { modalClasses } from '@mui/material/Modal';
import { useState } from 'react';
import { Grid, colors } from '@mui/material';
import { button_left_style, button_right_style, useDatabaseConstants, disabled_btn, secondaryDarkColor } from '../../../Constants/globalConstants';
import { DarkModeContext } from './DarkModeContext';

export default function AreYouSureModal({
    open,
    setOpen,
    title,
    image,
    setLoading,
    loading,
    confirmFunc
}) {

    const handleClose = () => setOpen(false);
    const { app_color } = useDatabaseConstants()
    const { darkMode } = React.useContext(DarkModeContext);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 550,
        bgcolor: darkMode ? secondaryDarkColor : 'white',
        boxShadow: 24,
        borderRadius: 4,
        padding: '2%'
    };

    const modal_title = {
        marginTop: '4%',
        textAlign: 'center',
        width: '80%',
        fontSize: 24,
        fontWeight: 500,
        color: app_color
    }

    const handleConfirmButton = () => {
        setLoading(true)
        setTimeout(() => {
            confirmFunc()
        }, 1000);
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <div onClick={handleClose} style={{
                        position: 'absolute',
                        right: '3%',
                        top: '3%',
                        cursor: 'pointer',
                    }}
                    >
                        <img src='/images/modal-close-icon.svg' style={{ width: '100%', height: '100%' }} />
                    </div>
                    <Grid container spacing={0}>

                        {
                            !darkMode && (
                                <Grid item md={12}>
                                    <center><img src={image} style={{ width: '70%', height: '70%' }} /></center>
                                </Grid>
                            )
                        }

                        <Grid item md={12} style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                            <h2 style={{ ...modal_title, color: darkMode ? 'white' : 'black' }}>{title}</h2>
                        </Grid>

                        <Grid item md={8} style={{
                            width: '60%',
                            display: 'flex',
                            gap: '4%',
                            margin: '3% auto 0 auto',
                            marginTop: '6%'
                        }}>
                            <Button onClick={handleClose} variant='contained' style={button_left_style}>
                                Close
                            </Button>

                            <Button
                                onClick={(e) => handleConfirmButton(e)}
                                disabled={loading}
                                variant='contained'
                                style={loading ? { ...button_right_style, ...disabled_btn } : button_right_style}
                            >{loading ? 'Confirming...' : 'Confirm'}
                            </Button>

                        </Grid>

                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
