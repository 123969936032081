import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import { darkPrimaryColor, secondaryDarkColor, useDatabaseConstants } from "../../../Constants/globalConstants";
import { useNavigate } from "react-router-dom";
import { DarkModeContext } from "./DarkModeContext";

export default function VerifyAadharModal({
    open,
    setOpen,
    title
}) {

    const handleClose = () => setOpen(false);
    const { app_color } = useDatabaseConstants();
    const navigate = useNavigate();
    const { darkMode } = React.useContext(DarkModeContext);
    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState(false);

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 550,
        bgcolor: darkMode ? secondaryDarkColor : "white",
        boxShadow: 24,
        borderRadius: 4,
        padding: "3%",
    };

    const modal_title = {
        fontSize: 25,
        fontWeight: 400,
        color: app_color,
        textAlign: "center",
    };

    const modal_para = {
        fontWeight: 500,
        textAlign: "center",
        fontSize: 14,
        color: "#00000080",
    };

    const button_right_style = {
        background: app_color,
        width: "50%",
        color: "white",
        boxShadow: "none",
        borderRadius: 7,
        fontWeight: 500,
        fontSize: 15,
        textTransform: "capitalize",
        padding: "3% 0",
    };

    const handleVerify = () => {
        if (value === '') {
            setError(true);
        } else {
            setError(false);
            // Add further verification logic here
        }
    };

    const input_style = {
        margin: '3% 0',
        width: '100%'
    };

    return (
        <div>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <div
                        onClick={handleClose}
                        style={{
                            position: "absolute",
                            right: "3%",
                            top: "3%",
                            cursor: "pointer",
                        }}
                    >
                        <img
                            src="/images/modal-close-icon.svg"
                            style={{ width: "100%", height: "100%" }}
                        />
                    </div>
                    <Grid container spacing={0}>

                        {
                            !darkMode && (
                                <Grid item md={12}>
                                    <center>
                                        <img
                                            src='/images/aadhar.svg'
                                            style={{ width: "60%", height: "60%" }}
                                        />
                                    </center>
                                </Grid>
                            )
                        }

                        <Grid
                            item
                            md={12}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <h2 style={{ ...modal_title, color: darkMode ? 'white' : app_color }}>
                                Verify Your {title}
                            </h2>
                            <p style={{ ...modal_para, color: darkMode ? 'gray' : '#00000080' }}>
                                Please verify your {title}, if you want to get Verified label at your profile.
                            </p>

                            <div style={input_style}>
                                <label className="input-label color-label">{title} number</label>
                                <input
                                    value={value}
                                    style={{
                                        height: 42,
                                        background: darkMode ? darkPrimaryColor : 'white',
                                        border: error ? '1px solid red' : darkMode ? 'none' : '',
                                    }}
                                    className='register-from-input'
                                    type="text"
                                    pattern="[0-9]*"
                                    maxLength={12}
                                    name={title}
                                    onChange={(e) => {
                                        setValue(e?.target?.value);
                                        if (error) setError(false); // Remove error if the user starts typing again
                                    }}
                                />
                                {error && (
                                    <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                                        Please enter a valid number.
                                    </p>
                                )}
                            </div>

                        </Grid>

                        <Grid
                            item
                            md={9}
                            style={{
                                display: "flex",
                                gap: "4%",
                                margin: "2% auto 0 auto",
                            }}
                        >
                            <Button
                                onClick={handleVerify}
                                variant="contained"
                                style={{ ...button_right_style, width: "50%", margin: 'auto' }}
                            >
                                Verify
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}