import React from "react"

export default function PageHeroSection({
    title
}) {

    return (
        <>
            <div className="page-hero-section whyUsBg">
                <img src='/images/blur-div.svg' style={{ position: 'absolute', left: 0, bottom: 0, zIndex: -1 }} />
                <h1
                    className="global-h2">{title}
                </h1>
            </div>
        </>
    )
}