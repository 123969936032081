import React from "react";
import { DarkModeContext } from "./DarkModeContext";

export default function EmptyPage({
    title
}) {

    const { darkMode } = React.useContext(DarkModeContext);

    const empty_page_div = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2% 0',
        flexDirection: 'column'
    }

    return (
        <>
            <div style={empty_page_div}>
                {!darkMode && <img src='/images/empty.svg' style={{ width: 400 }} />
                }
                <h3 className="global-h3" style={{ margin: '2% 0', textAlign: 'center' }}>{title}</h3>
            </div>
        </>
    )
}