import React, { useState, useEffect, useContext } from "react";
import { MultiSelect } from "react-multi-select-component";
import {
    occupation_options, country_options, getFieldName,
    sortArrAscending, hobbies_options, qualifications_options,
    languages_options, age_range_options_male, age_range_options_female,
    state_options, indian_cities,
    darkPrimaryColor
} from "../../../Constants/globalConstants";
import { useSelector } from "react-redux";
import { post_data } from "../../../Api";
import { DarkModeContext } from "./DarkModeContext";

export default function DefaultMultiSelect({
    permissionsOptions,
    setFormData,
    formData,
    type,
    disabled,
    title,
    defaultMultiSelectValues,
    handlePermissionChange,
    permissions,
    screen,
    setAllUsers,
    setProfileLoading,
    setProfileLoadingType,
    currentPage,
    setSearchValue,
    setIsFilter,
    searchValue,
    setIsMultiSelect
}) {

    const { darkMode } = useContext(DarkModeContext);
    const [sortedOptions, setSortedOptions] = useState([]);
    const { isLoggedIn, user_data } = useSelector(state => state.user);
    const [multiSelectOptions, setMultiSelectOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const getSelectedObject = (type) => {

        if (type === 'admin') {
            const transformData = (data) => {
                return data?.map(item => {
                    return {
                        label: item?.name,
                        value: item?.code
                    };
                });
            }

            const modifiedPermissionsArr = transformData(defaultMultiSelectValues)
            if (modifiedPermissionsArr) {
                setSelectedOptions(modifiedPermissionsArr)
            }
        }

        const objWithField = defaultMultiSelectValues?.find((obj) => {
            let new_field_name = getFieldName(obj?.field);
            return new_field_name === type;
        });

        if (objWithField) {
            const transformedValues = objWithField?.value?.map((val) => {
                return {
                    label: val,
                    value: val
                };
            });
            if (transformedValues) {
                setSelectedOptions(transformedValues)
            }
            return transformedValues;
        } else {
            return [];
        }
    };

    useEffect(() => {
        if (searchValue != "")
            setSelectedOptions([]);
    }, [searchValue])

    useEffect(() => {
        if (type === 'hobbies') {
            setMultiSelectOptions(hobbies_options)
            getSelectedObject('hobbies')
        }
        else if (type === 'location') {
            setMultiSelectOptions(sortArrAscending(indian_cities))
            getSelectedObject('location')
        }
        else if (type === 'travelled_locally') {
            setMultiSelectOptions(indian_cities)
            getSelectedObject('travelled_locally')
        }
        else if (type === 'travelled_abroad') {
            setMultiSelectOptions(country_options)
            getSelectedObject('travelled_abroad')
        }
        else if (type === 'qualifications') {
            setMultiSelectOptions(qualifications_options)
            getSelectedObject('qualifications')
        }
        else if (type === 'languages') {
            setMultiSelectOptions(languages_options)
            getSelectedObject('languages')
        }
        else if (type === 'occupation') {
            setMultiSelectOptions(occupation_options)
            getSelectedObject('occupation')
        }
        else if (type === 'state') {
            setMultiSelectOptions(state_options)
            getSelectedObject('state')
        }
        else if (type === 'city') {
            setMultiSelectOptions(indian_cities)
            getSelectedObject('city')
        }
        else if (type === 'permissions') {
            setMultiSelectOptions(permissionsOptions)
            getSelectedObject('permissions')
        }
        else if (type === 'admin') {
            setMultiSelectOptions(permissions)
            getSelectedObject('admin')
        }
        else if (type === 'age_range') {
            if (user_data?.basic_details?.gender === 'Male') {
                setMultiSelectOptions(age_range_options_female)
            }
            else {
                setMultiSelectOptions(age_range_options_male)
            }
            getSelectedObject('age_range')
        }
    }, [type, defaultMultiSelectValues])

    const handleOptionsFormat = () => {
        try {
            const newOptions = multiSelectOptions?.map(option => ({
                label: option?.name,
                value: option?.code
            }));
            const sortedArr = sortArrAscending(newOptions, 'label');
            setSortedOptions(sortedArr);
        } catch (error) {
            // console.error("Error formatting options:", error);
        }
    };

    useEffect(() => {
        handleOptionsFormat();
    }, [multiSelectOptions]);

    useEffect(() => {
        if (type === 'permissions') {
            handlePermissionChange(type, selectedOptions?.map(option => option?.value))
        }

        setFormData(prevFormData => ({
            ...prevFormData,
            [getFieldName(title)]: {
                ...prevFormData[getFieldName(title)],
                [type]: selectedOptions?.map(option => option.value),
            },
        }));
    }, [selectedOptions, setFormData, title, type]);


    const handleSelectChange = async (selectedOptions) => {
        setSearchValue('');
        setIsMultiSelect(true);
        setSelectedOptions(selectedOptions);

        if (setProfileLoadingType) {
            setProfileLoadingType('filter')
        }
        if (setProfileLoading) {
            setProfileLoading(true)
        }
        // setTimeout(async () => {
        let payload = {
            gender: user_data?.basic_details?.gender === 'Male' ? 'Female' : 'Male',
            age_range: formData?.age_range?.age_range,
            languages: formData?.languages?.languages,
            occupation: formData?.occupation?.occupation,
            state: formData?.state?.state,
            religion: formData?.religion?.name,
            income: formData?.income?.name
        }
        const response = await post_data(`user/get-users-by-filters?pageNumber=${currentPage}`, payload);
        if (response?.status) {
            if (setProfileLoading) {
                setProfileLoading(false)
            }
            if (setAllUsers) {
                setIsFilter(true);
                setAllUsers(response?.data)
            }
        }
        else {
            if (setProfileLoading) {
                setProfileLoading(false)
            }
        }
        // }, 1000);

    };


    const renderSelectTitle = () => {
        return (
            <div>
                {selectedOptions.length === 0 ? 'Select' :
                    selectedOptions.length >= 2 ? `${selectedOptions.length} Selected` :
                        selectedOptions.map(option => option.label).join(', ')
                }
            </div>
        );
    };

    return (
        <>
            <MultiSelect
                disabled={disabled}
                valueRenderer={renderSelectTitle}
                options={sortedOptions}
                value={selectedOptions}
                onChange={handleSelectChange}
                labelledBy="Select"
                overrideStrings={{ selectSomeItems: "Select..." }}
                className={
                    screen === 'modal'
                        ? (darkMode ? "modal-dark-custom-multi-select" : "custom-multi-select")
                        : (darkMode ? "dark-custom-multi-select" : "custom-multi-select")
                }
            />
        </>
    );
}
