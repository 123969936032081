import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function FunctionConstant({ setModalOpen }) {

    const navigate = useNavigate()
    const { isLoggedIn } = useSelector(state => state.user);

    const handleBtnClick = () => {
        if (isLoggedIn) {
            navigate('/dashboard')
        }
        else {
            setModalOpen(true)
        }
    }

    return { handleBtnClick }
}
