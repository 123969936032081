import { useContext, useState } from "react";
import { Switch } from "@headlessui/react";
import { DarkModeContext } from "./DarkModeContext";

export default function CustomToggle({
  obj,
  field,
  toggle_value,
  payload,
  setPayload,
  handleInputChange = () => {},
  index,
  screen,
}) {
  const [enabled, setEnabled] = useState(toggle_value);
  const { darkMode } = useContext(DarkModeContext);

  let field_value = screen === "permissions" ? toggle_value : enabled;

  const handleChange = (e, obj, field) => {
    if (obj) {
      setEnabled(!enabled);
      setPayload((prevFormData) => ({
        ...prevFormData,
        [obj]: {
          ...prevFormData[obj],
          [field]: screen === "permissions" ? !toggle_value : !enabled,
        },
      }));
    } else {
      handleInputChange("", index, "active");
    }
  };

  return (
    <Switch
      checked={field_value}
      onChange={(e) => handleChange(e, obj, field)}
      className={`${
        field_value ? "bg-pink-700" : darkMode ? "bg-gray-600" : "bg-gray-200"
      } relative inline-flex h-6 w-11 items-center rounded-full`}
    >
      <span
        className={`${
          field_value ? "translate-x-6" : "translate-x-1"
        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
      />
    </Switch>
  );
}
