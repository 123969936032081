import React, { useState } from "react";
import { Grid, TextField, InputAdornment } from "@material-ui/core";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { formatDate, formatPrice, secondaryDarkColor } from "../../../Constants/globalConstants";
import { Pagination, Typography } from "@mui/material";
import SerialNo from "../../Admin/Components/SerialNo";
import InvoiceModal from "../../UserDshboard/Components/InvoiceModal";

export default function VerificationSalesTable({
    tableHeader,
    data,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    count,
    totalPages,
}) {

    const [invoiceModal, setInvoiceModal] = useState(false);
    const [currentInvoice, setCurrentInvoice] = useState({})
    const [currentIndex, setCurrentIndex] = useState('')

    const table_div = {
        padding: "3%",
        borderRadius: 10,
        border: "1px solid #F1F1F2",
        boxShadow: "0px 3px 4px 0 #00000008",
        background: "white",
    };

    const table_heading = {
        fontWeight: 600,
        fontSize: 17,
        color: "#3F4254",
    };

    const table_tagline = {
        fontWeight: 500,
        color: "#A1A5B7",
        fontSize: 13,
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, count);
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };


    const table_header_title = {
        margin: 0,
        fontWeight: 600,
        fontSize: 14,
        color: "#3F4254",
    };

    const table_header_div = {
        width: "100%",
        margin: "1% 0",
        padding: "1% 0",
        display: "flex",
        alignItems: "center",
    };

    const table_row_font = { margin: 0, fontWeight: 500, fontSize: 14, color: '' };

    const displayTableHeader = () => {
        return (
            <div style={{ width: "100%" }}>
                <Grid container spacing={0} style={table_header_div}>
                    {tableHeader?.map((header, index) => (
                        <Grid
                            key={index}
                            item
                            xs={header?.grid}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: index === 0 || index === 1 ? "left" : "center",
                            }}
                        >
                            <h3 style={table_header_title}>{header?.title}</h3>
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    };

    const displayAllInvoices = () => {
        return (
            <div>
                <InvoiceModal type={'admin'} screen='verification' open={invoiceModal} setOpen={setInvoiceModal} currentInvoice={currentInvoice} currentIndex={currentIndex} />
                <div style={{ display: "flex" }}>
                    <div>
                        <h3 style={table_heading}>Invoices</h3>
                        <p style={table_tagline}>
                            Effortlessly check billing and invioces history.
                        </p>
                    </div>
                </div>
                {displayTableHeader()}
                {data?.map((item, i) => {
                    return (
                        <div style={{ width: "100%" }}>
                            <Grid
                                container
                                spacing={0}
                                style={{
                                    width: "100%",
                                    padding: "1.5% 0",
                                    display: "flex",
                                    alignItems: "center",
                                    borderTop: "1px dashed gainsboro",
                                }}
                            >
                                <Grid item xs={1}>
                                    <SerialNo index={i} />
                                </Grid>
                                <Grid
                                    item
                                    xs={5}
                                    style={{ display: "flex", alignItems: "center" }}
                                >
                                    <h3
                                        style={{
                                            ...table_row_font,
                                            color: item?.user_id?.first_name ? "#3F4254" : "red",
                                        }}
                                    >
                                        {item?.user_id?.first_name} {item?.user_id?.last_name}
                                    </h3>
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <h3 style={{ ...table_row_font, color: "#3F4254" }}>
                                        {formatPrice(item?.amount)}
                                    </h3>
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <h3
                                        style={{
                                            margin: 0,
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: "black",
                                        }}
                                    >
                                        {formatDate(item?.createdAt)}
                                    </h3>
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div style={{ width: "60%", margin: "auto" }}>
                                        <p
                                            onClick={() => { setInvoiceModal(true); setCurrentInvoice(item); setCurrentIndex(i) }}
                                            style={{
                                                fontSize: 14,
                                                padding: "4% 2%",
                                                borderRadius: 4,
                                                background: "#E8FFF3",
                                                color: "#50CD89",
                                                textAlign: "center",
                                                fontWeight: 500,
                                                cursor: "pointer",
                                            }}
                                        >
                                            Download
                                        </p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <>
            <div style={table_div}>
                {displayAllInvoices()}

                {data?.length > 0 && (
                    <div
                        style={{
                            borderTop: "1px dashed gainsboro",
                            paddingTop: "2%",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            style={{
                                fontWeight: 500,
                                fontSize: 15,
                                color: "black",
                            }}
                        // >{`Showing ${count} to ${endEntry} of ${count} invoices`}</Typography>
                        >{`Showing ${count} invoices`}</Typography>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </div>
                )}
            </div>
        </>
    );
}
