import React, { useState } from 'react';
import { Box, Container, Typography, Button, useMediaQuery } from '@mui/material';
import { useDatabaseConstants } from '../../Constants/globalConstants';
import FunctionConstant from '../../Constants/functionConstant';
import SignupSignModal from './SignupLoginModal';

const Cta = () => {

    const { app_color } = useDatabaseConstants()
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const [modalOpen, setModalOpen] = useState(false);
    const { handleBtnClick } = FunctionConstant({ setModalOpen })

    return (
        <div style={{ paddingBottom: '5%' }}>
            <Box
                sx={{
                    background: 'linear-gradient(90deg, #FF0844, #FFB199)',
                    color: '#FFFFFF',
                    padding: (theme) => theme.spacing(6, 0),
                    width: isDesktop ? '85%' : '100%',
                    margin: 'auto',
                    textAlign: 'center',
                    borderRadius: 5,
                    position: 'relative',
                }}
            >
                <Container>
                    <Typography
                        sx={{
                            fontSize: '2rem',
                            fontWeight: 'bold',
                            zIndex: 99,
                            marginBottom: (theme) => theme.spacing(2),
                        }}
                    >
                        Find Your Perfect Match at DilSey!
                    </Typography>
                    <Typography
                        sx={{
                            width: isDesktop ? '70%' : '100%',
                            margin: 'auto',
                            fontSize: '16', zIndex: 99,
                            marginBottom: (theme) => theme.spacing(4),
                        }}
                    >
                        Are you ready to find that special someone who makes your heart skip a beat? At DilSey, we believe in the power of love and lasting connections.
                    </Typography>
                    <Button
                        onClick={handleBtnClick}
                        variant="contained"
                        sx={{
                            backgroundColor: app_color,
                            color: 'white',
                            borderRadius: '50px',
                            boxShadow: 'none',
                            zIndex: 99,
                            padding: (theme) => theme.spacing(2, 6),
                            fontSize: 13,
                            '&:hover': {
                                boxShadow: 'none',
                                color: 'white',
                                backgroundColor: app_color,
                            },
                        }}
                    >
                        Get Started
                    </Button>
                </Container>
                <img
                    src="/images/petal-4.png"
                    alt="Decorative element"
                    style={{
                        position: 'absolute',
                        left: 0,
                        opacity: '14%',
                        top: 0,
                        height: '100%',
                        borderTopLeftRadius: '24px',
                        borderBottomLeftRadius: '24px',
                        zIndex: 1,
                    }}
                />
            </Box>
            <SignupSignModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </div>
    );
};

export default Cta;