import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import { secondaryDarkColor, useDatabaseConstants } from '../../../Constants/globalConstants';
import { DarkModeContext } from './DarkModeContext';

export default function LimitPhotosModal({
    open,
    setOpen,
}) {

    const { app_color } = useDatabaseConstants()
    const { darkMode } = React.useContext(DarkModeContext);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 550,
        overflowY: 'auto',
        bgcolor: darkMode ? secondaryDarkColor : 'white',
        boxShadow: 24,
        borderRadius: 4,
        padding: '2%',
    };

    const handleClose = () => {
        setOpen(false)
    };

    const modal_title = {
        fontSize: 25,
        fontWeight: 400,
        textAlign: 'center',
    }

    const modal_para = {
        fontWeight: 500,
        textAlign: 'center',
        fontSize: 14,
        color: '#00000080'
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <div onClick={handleClose} style={{
                        position: 'absolute',
                        right: '3%',
                        top: '3%',
                        cursor: 'pointer',
                    }}
                    >
                        <img src='/images/modal-close-icon.svg' style={{ width: '100%', height: '100%' }} />
                    </div>

                    <Grid container spacing={0}>
                        {
                            !darkMode && (
                                <Grid item md={12}>
                                    <center><img src={`/images/modal-img-reject.svg`} style={{ width: '70%', height: '70%' }} /></center>
                                </Grid>
                            )
                        }
                        <Grid item md={12} style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <h2 style={{ ...modal_title, color: darkMode ? 'white' : app_color }}>Maximum Photos uploaded</h2>
                            <p style={{ ...modal_para, color: darkMode ? 'gray' : 'gray' }}>You were allowed to upload only 10 Photos.</p>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
