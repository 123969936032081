import React, { useEffect, useState } from "react"
import { Grid, Button } from "@mui/material"
import DashboardHeading from "../../UserDshboard/Components/DashboardHeading"
import PricingPlans from "../../UserDshboard/Components/PricingPlans"
import { button_left_style, button_right_style } from "../../../Constants/globalConstants"
import NewPlanPermissionModal from "../Components/NewPlanPermissionModal"
import SuccessModal from "../../UserDshboard/Components/SuccessModal"
import { get_data } from "../../../Api"
import EditPlanPermissionModal from "../Components/EditPlanPermissions"

export default function Plans({ isSubAdmin, permissions }) {

    const [open, setOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [successOpen, setSuccessOpen] = React.useState(false)
    const [successStatus, setSuccessStatus] = React.useState(false)
    const [allPlansPermisison, setAllPlansPermissions] = useState([])

    const plans_page = {
        width: '100%',
    }

    const page_container = {
        width: "100%",
        margin: "2% 0",
        background: 'transparent'
    };

    const fetchPlansPermissions = async () => {
        const response = await get_data('permission/get-all-permission')
        if (response?.status) {
            setAllPlansPermissions(response?.data)
        }
        else {
            setAllPlansPermissions([])
        }
    }

    useEffect(() => {
        fetchPlansPermissions()
    }, [])

    const handleEditPermissions = () => {
        setEditOpen(true)
    }

    // <Button
    //     onClick={() => setOpen(true)}
    //     variant='contained'
    //     style={{ ...button_left_style, marginLeft: 'auto', width: '100%', padding: '3% 0', background: 'transparent', marginTop: 0 }}
    // >
    //     New Permission
    // </Button>

    return (
        <>
            <Grid container spacing={0} style={plans_page}>

                <DashboardHeading title='Plans' />

                {
                    (isSubAdmin && permissions?.includes('Edit Plans Permissions')) || !isSubAdmin ?
                        <div style={{ display: "flex", marginLeft: 'auto', width: '13%', gap: '3%' }}>
                            <Button
                                onClick={() => handleEditPermissions()}
                                variant='contained'
                                style={{ ...button_right_style, marginLeft: 'auto', width: '100%', padding: '6% 0', marginTop: 0 }}
                            >
                                Edit Permissions
                            </Button>
                        </div>
                        : <></>
                }

                <div style={page_container}>
                    <PricingPlans
                        isSubAdmin={isSubAdmin}
                        permissions={permissions}
                        allPlansPermisison={allPlansPermisison}
                        fetchPlansPermissions={fetchPlansPermissions}
                        setAllPlansPermissions={setAllPlansPermissions}
                        screen='Dashboard' admin_type='super-admin' />
                </div>

                <NewPlanPermissionModal
                    fetchPlansPermissions={fetchPlansPermissions}
                    open={open} setOpen={setOpen}
                    successOpen={successOpen} setSuccessOpen={setSuccessOpen}
                    successStatus={successStatus} setSuccessStatus={setSuccessStatus}
                />
                <EditPlanPermissionModal
                    allPlansPermisison={allPlansPermisison}
                    fetchPlansPermissions={fetchPlansPermissions}
                    open={editOpen} setOpen={setEditOpen}
                    successOpen={successOpen} setSuccessOpen={setSuccessOpen}
                    successStatus={successStatus} setSuccessStatus={setSuccessStatus}
                />
                <SuccessModal
                    status={successStatus}
                    open={successOpen}
                    setOpen={setSuccessOpen}
                    title={successStatus ? `Permissions updated successfully` : 'Something went wrong...'}
                />

            </Grid>
        </>
    )
}