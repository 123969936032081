import React from "react";
import { useState, useEffect } from "react";
import { get_data, post_data, serverURLProfilePicture } from "../../../Api";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AreYouSureModal from "../../UserDshboard/Components/AreYouSureModal";
import {
  Grid,
  Button,
  TextField,
  InputAdornment,
  Pagination,
  Typography,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { CiShare1 } from "react-icons/ci";
import {
  button_left_style,
  getAvatar,
  useDatabaseConstants,
} from "../../../Constants/globalConstants";
import EmptyPage from "../../UserDshboard/Components/EmptyPage";
import SubAdminModal from "./SubAdminModal";
import SuccessModal from "../../UserDshboard/Components/SuccessModal";
import NewPermissionModal from "./NewPermissionModal";
import SerialNo from "./SerialNo";
import CustomToggle from "../../UserDshboard/Components/CustomToggle";
import Dropdown from "./Dropwown";
import ExportModal from "./ModalComponent";

export default function DefaultTable({
  fetchAllUsers,
  fetchAllSubAdmin,
  tableHeader,
  data,
  setData,
  handleEdit,
  handleConfirm,
  loading,
  setLoading,
  sliceEnd,
  title,
  count,
  type,
  setSureModal,
  sureModal,
  orgData,
  totalMale,
  totalFemale,
  screen,
  itemsPerPage,
  currentPage,
  setCurrentPage,
  fetchAllPermisisons,
  permissions,
  isSubAdmin,
  setProfileLoading,
  totalPages,
  setTotalPages,
  handleGetFilterUsers,
  setFilterState,
  setSearchValueFilter,
  setUpdateDays,
  setUpdatePlan
}) {

  const theme = useTheme();
  const matches_md = useMediaQuery(theme.breakpoints.down("md"));
  const matches_sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [deleteUserId, setDeleteUserId] = useState("");
  const [permissionId, setPermissionId] = useState("");
  const [userItem, setUserItem] = useState("");
  const [subAdminId, setSubAdminId] = useState("");
  const [subAdminItem, setSubAdminItem] = useState({});
  const [permissionItem, setPermissionItem] = useState({});
  const [modalType, setModalType] = useState("");
  const [open, setOpen] = useState(false);
  const [permissionOpen, setPermissionOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successStatus, setSuccessStatus] = useState(false);
  const { app_color } = useDatabaseConstants()
  const [searchValue, setSearchValue] = useState('')

  const [days, setDays] = useState(null)
  const [plan, setPlan] = useState('')

  const startEntry = (currentPage - 1) * itemsPerPage + 1;
  const endEntry = Math.min(currentPage * itemsPerPage, count);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const table_div = {
    padding: "3%",
    borderRadius: 10,
    border: "1px solid #F1F1F2",
    boxShadow: "0px 3px 4px 0 #00000008",
    background: "white",
    position: "relative",
  };

  const table_heading = {
    fontWeight: 600,
    fontSize: 17,
    color: "#3F4254",
  };

  const table_tagline = {
    fontWeight: 500,
    color: "#A1A5B7",
    fontSize: 13,
  };

  const table_header_title = {
    margin: 0,
    fontWeight: 600,
    fontSize: 14,
    color: "#3F4254",
  };
  const table_header_div = {
    width: "100%",
    margin: "1% 0",
    padding: "1% 0",
    display: "flex",
    alignItems: "center",
  };
  const table_row_font = { margin: 0, fontWeight: 500, fontSize: 14 };

  const accountCreatedOptions = [
    { value: 1, label: "Today" },
    { value: 7, label: "Last 7 days" },
    { value: 30, label: "Last 30 days" },
  ];
  const Plan = [
    { value: "Free Plan", label: "Free Plan" },
    { value: "Silver", label: "Silver Plan" },
    { value: "Gold", label: "Gold Plan" },
    { value: "Platinum", label: "Platinum Plan" },
  ];

  const navigate = useNavigate()

  const handleEditFunc = (item) => {
    if (type === "Users") {
      // setModalType("Edit");
      // setSureModal(true);
      // setUserItem(item);
      navigate(`/admin/dashboard/edit-user`, {
        state: { user: item, screen: "Admin" },
      });
    } else if (type === "Permissions") {
      setModalType("Edit");
      setPermissionItem(item?.permission);
      setPermissionOpen(true);
    } else if (type === "Sub Admins") {
      setSubAdminItem(item);
      setOpen(true);
      setModalType("Edit");
    }
    else {

    }
  };

  const handleDeleteFunc = (id) => {
    if (type === "Users") {
      setModalType("Delete");
      setDeleteUserId(id);
      setSureModal(true);
    } else if (type === "Permissions") {
      setModalType("Delete");
      setPermissionId(id);
      setSureModal(true);
    } else {
      setModalType("Delete");
      setSubAdminId(id);
      setSureModal(true);
    }
  };

  const handleConfirmFunc = () => {
    if (type === "Users") {
      if (modalType === "Edit") {
        handleEdit(userItem);
      } else {
        handleConfirm(deleteUserId);
      }
    } else if (type === "Permissions") {
      handleConfirm(permissionId);
    } else {
      handleConfirm(subAdminId);
    }
  };

  const displayTableHeader = () => {
    return (
      <div style={{ width: "100%" }}>
        <Grid container spacing={0} style={table_header_div}>
          {tableHeader?.map((header, index) => (
            <Grid
              key={index}
              item
              xs={header?.grid}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent:
                  index === tableHeader.length - 1
                    ? "right"
                    : index === 0 || index === 1
                      ? "left"
                      : "center",
              }}
            >
              <h3 style={table_header_title}>{header?.title}</h3>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };


  const handleEnter = (event) => {
    if (event.key == "Enter") {
      handleSearchSubmit();
    }
  };

  useEffect(() => {
    if (searchValue) {
      handleSearchSubmit();
    }
  }, [currentPage]);

  const handleSearchSubmit = async () => {
    setProfileLoading(true)
    setTimeout(async () => {
      if (type === 'Users') {
        if (searchValue !== '') {
          const response = await get_data(`user/search-users-admin/${searchValue}?pageNumber=${currentPage}`);
          if (response?.status) {
            setSearchValueFilter(true);
            setData(response?.data?.users);
            setTotalPages(response?.data?.totalPages);
            setProfileLoading(false)
          }
          else {
            setProfileLoading(false)
            setData([]);
          }
        }
        else {
          fetchAllUsers()
          setProfileLoading(false)
        }
      }
      else if (type === 'Sub Admins') {
        if (searchValue !== '') {
          const response = await get_data(`user/search-sub-admins/${searchValue}`);
          if (response?.status) {
            setData(response?.data);
            setProfileLoading(false)
          }
          else {
            setProfileLoading(false)
            setData([]);
          }
        }
        else {
          fetchAllSubAdmin()
          setProfileLoading(false)
        }
      }
      else {
        const filterData = orgData?.filter((item) => {
          return item?.permission?.permission_name
            ?.toLowerCase()
            .includes(searchValue);
        });
        setData(filterData);
        setProfileLoading(false)
      }
    }, 1000);
  };

  const handleSearch = async (e) => {
    setSearchValue(e?.target?.value?.toLowerCase())
  };

  const searchComponent = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            background: "#F1F1F2",
            height: 44,
            borderRadius: 8,
            padding: "1.5% 3%",
            alignItems: "center",
            margin: "auto",
          }}
        >
          <TextField
            onChange={handleSearch}
            onKeyPress={(event) => handleEnter(event)}
            hiddenLabel
            placeholder="Search"
            variant="standard"
            size="small"
            fullWidth
            style={{ fontWeight: 600 }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon
                    style={{ color: "gainsboro", height: 30, width: 30 }}
                  />
                </InputAdornment>
              ),
              inputProps: {
                style: { color: "black" },
              },
            }}
          />
        </div>
      </>
    );
  };

  const handleToggleChange = (item) => {
    setProfileLoading(true);
    setTimeout(async () => {
      let payload = { is_active: !item?.is_active };
      const response = await post_data(
        `user/update-user/${item?._id}`,
        payload
      );
      if (response?.status) {
        fetchAllUsers();
        setProfileLoading(false);
      }
    }, 1000);
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleAccountCreatedChange = async (value) => {
    setUpdateDays(value);
    setFilterState(true);
    setCurrentPage(1);
    setDays((prevDays) => {
      const newDays = value;
      handleFilterUsers(newDays, plan); // pass the updated days and current plan
      return newDays;
    });
  };

  const handlePlanChange = async (value) => {
    setUpdatePlan(value);
    setFilterState(true);
    setCurrentPage(1);
    setPlan((prevPlan) => {
      const newPlan = value;
      handleFilterUsers(days, newPlan); // pass the current days and updated plan
      return newPlan;
    });
  };

  const handleFilterUsers = async (updatedDays, updatedPlan) => {
    // try {
    //   let payload = { days: updatedDays, plan: updatedPlan };
    //   const response = await post_data(`user/get-account-plan-controller?page_number=${currentPage}`, payload);
    //   setData(response?.data?.filteredUsers);
    // } catch (e) {
    //   // console.log(e);
    // }
    handleGetFilterUsers(updatedDays, updatedPlan)
  };


  const displayAllUsers = () => {
    // let users_count = type === 'Users' ? `(${totalMale} Males & ${totalFemale} Females)` : ''
    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: '3%' }}>
            <h3 style={table_heading}>{title}</h3>
          </div>

          {
            screen !== 'Dashboard' && (
              <div>
                {type === "Users" && (
                  <div
                    style={{ marginTop: "-19px", display: 'flex', gap: 10 }}
                  >
                    <div className="flex items-center">
                      <button
                        type="button"
                        className="py-2.5 px-5 me-2 mt-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
                        onClick={openModal}
                      >
                        Export
                      </button>
                      <div style={{ display: 'flex', gap: 2, width: 500 }}>
                        <Dropdown
                          label="Account created"
                          options={accountCreatedOptions}
                          placeholder="Account created"
                          onChange={handleAccountCreatedChange}
                        />
                        <Dropdown
                          label="Plan"
                          options={Plan}
                          placeholder="Plan"
                          onChange={handlePlanChange}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <ExportModal isOpen={modalIsOpen} onRequestClose={closeModal} />
              </div>
            )
          }


          <div
            style={{
              width:
                type === "Sub Admins"
                  ? // type === 'Permissions' ?
                  "40%"
                  : "25%",
              marginLeft: "auto",
              display: "flex",
            }}
          >
            {type === "Sub Admins" && (
              <Button
                style={{
                  ...button_left_style,
                  padding: "1.5% 0",
                  width: "35%",
                  marginRight: '3%'
                }}
                onClick={() => setOpen(true)}
              >
                New Sub Admin
              </Button>
            )}

            {/* {
                            type === 'Permissions' && (
                                <Button style={{ ...button_left_style, padding: '1.5% 0', width: '35%' }}
                                    onClick={() => setPermissionOpen(true)}
                                >
                                    New Permissions
                                </Button>
                            )
                        } */}

            <div
              style={{
                width:
                  type === "Sub Admins"
                    ? // type === 'Permissions' ?
                    "65%"
                    : "100%",
              }}
            >
              {
                screen !== 'Dashboard' && (
                  <>{searchComponent()}</>
                )
              }
            </div>
          </div>
        </div>

        {displayTableHeader()}
        {data?.length > 0 ? (
          <>
            {data?.map((item, i) => {
              let date = new Date(item?.createdAt);
              let day = date?.getDate();
              let month = date?.getMonth() + 1;
              let year = date?.getFullYear();
              day = day < 10 ? "0" + day : day;
              month = month < 10 ? "0" + month : month;
              let formated_date = day + "/" + month + "/" + year;
              let avatar = getAvatar(item);
              let profile_pic = item?.profile_picture
                ? `${serverURLProfilePicture}/${item?.profile_picture}`
                : `/images/${avatar}`;

              return (
                <div style={{ width: "100%" }}>
                  <Grid
                    container
                    spacing={0}
                    style={{
                      width: "100%",
                      padding: "1.5% 0",
                      display: "flex",
                      alignItems: "center",
                      borderTop: "1px dashed gainsboro",
                    }}
                  >
                    <Grid item xs={1}>
                      {type === "Users" ? (
                        <>
                          <img
                            src={profile_pic}
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: "50%",
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <SerialNo index={i} />
                        </>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={type === "Permissions" ? 6 : 2}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <h3
                        style={{
                          ...table_row_font,
                          color: "#3F4254",
                          textTransform: "capitalize",
                        }}
                      >
                        {type === "Permissions" ? (
                          <>{item?.permission?.permission_name}</>
                        ) : (
                          <>
                            {item?.first_name} {item?.last_name}
                          </>
                        )}
                      </h3>
                    </Grid>
                    <Grid
                      item
                      xs={type === "Permissions" ? 3 : 2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3 style={table_row_font}>
                        {type === "Users" ? (
                          <>
                            <p style={{ color: "#3F4254" }}>
                              {item?.contact_number}
                            </p>
                          </>
                        ) : type === "Permissions" ? (
                          <>
                            <p style={{ color: "#009EF6" }}>
                              {item?.count} Sub Admins
                            </p>
                          </>
                        ) : (
                          <>
                            <p style={{ color: "#3F4254" }}>{item?.email}</p>
                          </>
                        )}
                      </h3>
                    </Grid>
                    {type !== "Permissions" && (
                      <>
                        <Grid
                          item
                          xs={2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <h3
                            style={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: 14,
                              color: "green",
                            }}
                          >
                            {formated_date}
                          </h3>
                        </Grid>
                        <Grid
                          item
                          xs={type === "Users" ? 1 : 3}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#3F4254",
                            justifyContent: "center",
                          }}
                        >
                          <h3
                            style={{
                              ...table_row_font,
                              color:
                                item?.basic_details?.gender === "Female"
                                  ? "#F60085"
                                  : "#009EF6",
                            }}
                          >
                            {type === "Users"
                              ? item?.basic_details?.gender
                              : (item?.permissions?.length || 0) +
                              `${item?.permissions?.length > 1
                                ? " Permissions"
                                : " Permission"
                              }`}
                          </h3>
                        </Grid>
                      </>
                    )}
                    {type === "Users" && (
                      <Grid
                        item
                        xs={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "#3F4254",
                          justifyContent: "center",
                        }}
                      >
                        <h3
                          style={{
                            ...table_row_font,
                            color: "#3F4254",
                            color: "#3F4254",
                          }}
                        >
                          {item?.plan_id?.length > 0
                            ? item?.plan_id?.[0]?.name +
                            `/${item?.billing?.plan_type}`
                            : "Free Plan"}
                        </h3>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {isSubAdmin ? (
                        <>
                          {type === "Users" ? (
                            <>
                              {permissions?.includes("Edit Users") ? (
                                <>
                                  <CustomToggle
                                    field={"active"}
                                    handleInputChange={(e) =>
                                      handleToggleChange(item)
                                    }
                                    toggle_value={item?.is_active}
                                    screen={"permissions"}
                                  />
                                  {/* <CiLogin
                                    style={{
                                      width: 30,
                                      height: 30,
                                      cursor: "pointer",
                                    }}
                                  /> */}
                                </>
                              ) : (
                                <>
                                  <p
                                    style={{ fontSize: 13, textAlign: "left" }}
                                  >
                                    No Permission
                                  </p>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <>
                          {type === "Users" ? (
                            <>
                              <CustomToggle
                                field={"active"}
                                handleInputChange={(e) =>
                                  handleToggleChange(item)
                                }
                                toggle_value={item?.is_active}
                                screen={"permissions"}
                              />
                              {/* <CiLogin
                                style={{
                                  width: 30,
                                  height: 30,
                                  cursor: "pointer",
                                }}
                              /> */}
                            </>
                          ) : type === "Permissions" ? (
                            <div style={{ width: "80%" }}>
                              <p
                                style={{
                                  fontSize: 14,
                                  padding: "4% 2%",
                                  borderRadius: 4,
                                  background: item?.permission?.is_active
                                    ? "#E8FFF3"
                                    : "#FFF5F8",
                                  color: item?.permission?.is_active
                                    ? "#50CD89"
                                    : "#F1416C",
                                  textAlign: "center",
                                  fontWeight: 500,
                                }}
                              >
                                {item?.permission?.is_active
                                  ? "Acitve"
                                  : "Deactive"}
                              </p>
                            </div>
                          ) : (
                            <div style={{ width: "80%" }}>
                              <p
                                style={{
                                  fontSize: 14,
                                  padding: "4% 2%",
                                  borderRadius: 4,
                                  background: item?.is_active
                                    ? "#E8FFF3"
                                    : "#FFF5F8",
                                  color: item?.is_active
                                    ? "#50CD89"
                                    : "#F1416C",
                                  textAlign: "center",
                                  fontWeight: 500,
                                }}
                              >
                                {item?.is_active ? "Acitve" : "Deactive"}
                              </p>
                            </div>
                          )}
                        </>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "right",
                        gap: "5%",
                      }}
                    >
                      {isSubAdmin ? (
                        <>
                          {permissions?.includes("Edit Users") && (
                            <img
                              src="/images/edit-img.svg"
                              style={{
                                width: 30,
                                height: 30,
                                cursor: "pointer",
                              }}
                              onClick={() => handleEditFunc(item)}
                            />
                          )}

                          {permissions?.includes("Delete Users") && (
                            <img
                              src="/images/delete-img.svg"
                              style={{
                                width: 30,
                                height: 30,
                                cursor: "pointer",
                              }}
                              onClick={() => handleDeleteFunc(item?._id)}
                            />
                          )}
                          {!permissions?.includes("Delete Users") && !permissions?.includes("Edit Users") && (
                            <>
                              <p style={{ fontSize: 13, textAlign: "right" }}>
                                No Permission
                              </p>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <img
                            src="/images/edit-img.svg"
                            style={{
                              width: 30,
                              height: 30,
                              cursor: "pointer",
                            }}
                            onClick={() => handleEditFunc(item)}
                          />
                          {type === "Permissions" && (
                            <img
                              src="/images/delete-img.svg"
                              style={{
                                width: 30,
                                height: 30,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleDeleteFunc(item?.permission?._id)
                              }
                            />
                          )}

                          {type !== "Permissions" && (
                            <img
                              src="/images/delete-img.svg"
                              style={{
                                width: 30,
                                height: 30,
                                cursor: "pointer",
                              }}
                              onClick={() => handleDeleteFunc(item?._id)}
                            />
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                </div>
              );
            })}
          </>
        ) : (
          <>
            <EmptyPage title={"No results to display..."} />
          </>
        )}
        {screen === "Dashboard" && (
          <Link to={"/admin/dashboard/users"}>
            <div
              style={{
                fontWeight: 500,
                display: "flex",
                fontSize: 15,
                margin: 0,
                padding: 0,
              }}
            >
              <p style={{ marginLeft: "auto" }}>See all</p>
            </div>
          </Link>
        )}
      </div>
    );
  };

  return (
    <>
      <div style={table_div}>

        {
          type === 'Users' && (
            <div style={{ position: 'absolute', right: '1%', top: '1%' }}>
              <a
                href="/admin/dashboard/users"
                style={{
                  color: app_color,
                  textDecoration: "underline",
                  fontWeight: 500,
                  marginLeft: "auto",
                  fontSize: 14,
                  cursor: "pointer",
                }}
              >
                Clear Filters
              </a>
            </div>
          )
        }

        {displayAllUsers()}
        {data?.length > 0 && screen !== "Dashboard" && (
          <div
            style={{
              borderTop: "1px dashed gainsboro",
              paddingTop: "2%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontWeight: 500,
                fontSize: 15,
                color: "black",
              }}
            >{`Showing ${count} ${type === 'Permissions' ? 'Permissions' : type === 'Sub Admins' ? 'Sub Admins' : 'Users'}`}</Typography>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </div>
        )}
        <AreYouSureModal
          setLoading={setLoading}
          loading={loading}
          confirmFunc={handleConfirmFunc}
          open={sureModal}
          setOpen={setSureModal}
          title={
            modalType === "Delete"
              ? "Are you Sure, you want to Delete ?"
              : "Are you sure, you want to login to Dashboard ?"
          }
          image={
            modalType === "Delete"
              ? "/images/modal-img-delete.svg"
              : "/images/modal-img-login.svg"
          }
          successOpen={successOpen}
          setSuccessOpen={setSuccessOpen}
        />
        <SubAdminModal
          open={open}
          setOpen={setOpen}
          setSubAdminItem={setSubAdminItem}
          modalType={modalType}
          setModalType={setModalType}
          subAdminItem={subAdminItem}
          fetchAllSubAdmin={fetchAllSubAdmin}
          successStatus={successStatus}
          setSuccessStatus={setSuccessStatus}
          setSuccessOpen={setSuccessOpen}
          successOpen={successOpen}
        />

        <NewPermissionModal
          open={permissionOpen}
          setOpen={setPermissionOpen}
          fetchAllPermisisons={fetchAllPermisisons}
          permissionItem={permissionItem}
          modalType={modalType}
          setModalType={setModalType}
          successStatus={successStatus}
          setSuccessStatus={setSuccessStatus}
          setSuccessOpen={setSuccessOpen}
          successOpen={successOpen}
        />

        <SuccessModal
          status={successStatus}
          open={successOpen}
          setOpen={setSuccessOpen}
          setModalType={setModalType}
          modalType={modalType}
          title={
            type === "Sub Admins" ? (
              <>
                {successStatus && modalType === "Edit"
                  ? "Sub Admin updated successfully"
                  : successStatus && modalType !== "Edit"
                    ? `Sub Admin created successfully!`
                    : "Something went wrong..."}
              </>
            ) : (
              <>
                {successStatus && modalType === "Edit"
                  ? "Permissions updated successfully"
                  : successStatus && modalType !== "Edit"
                    ? `Permissions created successfully!`
                    : "Something went wrong..."}
              </>
            )
          }
        />
      </div>
    </>
  );
}
