import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function PermissionsFunction() {

    const [permissions, setPermissions] = useState({})
    const { user_data } = useSelector(state => state.user);

    useEffect(() => {
        const permissions_arr = user_data?.plan_id?.[0]?.permissions
        const new_permissions_arr = extractPermissions(permissions_arr || [])

        if (new_permissions_arr) {
            setPermissions(new_permissions_arr)
        }
    }, [user_data])

    const extractPermissions = (permissionsArray) => {
        let result = {};

        permissionsArray?.forEach(permission => {
            if (permission?.permission_id?.is_active) {
                let key = permission?.permission_id?.permission_name
                    .toLowerCase()
                    .replace(/\s+/g, '_');
                result[key] = permission?.value;
            }
        });

        return result;
    }

    return { permissions }
}
