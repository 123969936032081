import { createSlice } from "@reduxjs/toolkit";

export const coupanSlice = createSlice({
  name: "coupan",
  initialState: {
    coupan: [],
  },
  reducers: {
    setCoupanData: (state, action) => {
      state.coupan = action.payload;
    },
  },
});

export const { setCoupanData } = coupanSlice.actions;

export default coupanSlice.reducer;
