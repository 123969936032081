import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { post_data } from "../../../Api";
import { ToastContainer, toast } from "react-toastify";
import {
  button_left_style,
  button_right_style,
  handleCreateActivity,
} from "../../../Constants/globalConstants";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  width: 600,
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
};

export default function SimpleModal({
  isOpen,
  onClose,
  onSave,
  editingCoupon,
}) {
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState("");
  const [couponError, setCouponError] = useState("");
  const [discountError, setDiscountError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { user_data } = useSelector(state => state.user);

  useEffect(() => {
    if (editingCoupon) {
      setCoupon(editingCoupon.coupon_name);
      setDiscount(editingCoupon.discount.toString());
    } else {
      setCoupon("");
      setDiscount("");
    }
  }, [editingCoupon]);

  const handleCouponChange = (e) => {
    setCoupon(e.target.value.toUpperCase());
    setCouponError("");
  };

  const handleDiscountChange = (e) => {
    const value = e.target.value;
    if (value === "" || (!isNaN(value) && parseFloat(value) >= 0)) {
      setDiscount(value);
      setDiscountError("");
    } else {
      setDiscountError(
        "Discount must be a non-negative number or empty for unlimited."
      );
    }
  };

  const validateInputs = () => {
    let hasError = false;

    if (!coupon.trim()) {
      setCouponError("Coupon is required.");
      hasError = true;
    }

    if (discount.trim() && (isNaN(discount) || parseFloat(discount) < 0)) {
      setDiscountError(
        "Discount must be a non-negative number or empty for unlimited."
      );
      hasError = true;
    }

    return !hasError;
  };

  const handleSave = async () => {
    if (validateInputs()) {
      setIsLoading(true);
      try {
        const payload = {
          coupon_name: coupon,
          discount: discount.trim() ? parseFloat(discount) : null,
        };
        let response;

        if (editingCoupon) {
          // Update existing coupon
          response = await post_data(
            `coupon/coupon-update/${editingCoupon._id}`,
            payload
          );
          if (response.status === true) {
            toast.success(response?.message);
            onSave(response.data);
            onClose();
            handleCreateActivity('Coupon updated', user_data)
          } else {
            throw new Error(response.message || "Failed to update coupon");
          }
        } else {
          // Create new coupon
          response = await post_data("coupon/coupon-create", payload);
          if (response?.status === true) {
            toast.success(response?.message);
            onSave(response.data);
            setDiscount("");
            setCoupon("");
            onClose();
            handleCreateActivity('New coupon created', user_data)
          } else {
            throw new Error(response.message || "Failed to create coupon");
          }
        }

        onClose(); // Close the modal after successful operation
      } catch (error) {
        console.error("Error saving coupon:", error);
        // toast.error(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <ToastContainer />
      <Modal open={isOpen} onClose={onClose}>
        <Box sx={style}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <label className="input-label color-label">Coupon</label>
              <input
                style={{ height: 42 }}
                className="register-from-input"
                placeholder="Enter Coupon"
                type="text"
                name="Coupon"
                value={coupon}
                onChange={handleCouponChange}
              />
              {couponError && (
                <div
                  style={{ color: "red", fontSize: "0.8rem", marginTop: "5px" }}
                >
                  {couponError}
                </div>
              )}
            </Grid>
            <Grid item xs={6}>
              <label className="input-label color-label">Discount (%)</label>
              <input
                style={{ height: 42 }}
                className="register-from-input"
                placeholder="Enter Discount"
                type="text"
                name="Discount"
                value={discount}
                onChange={handleDiscountChange}
              />
              {discountError && (
                <div
                  style={{ color: "red", fontSize: "0.8rem", marginTop: "5px" }}
                >
                  {discountError}
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                gap: "10px",
              }}
            >
              <Button
                onClick={onClose}
                variant="contained"
                style={{
                  ...button_left_style,
                  width: "25%",
                  padding: "1.8% 3%",
                }}
                disabled={isLoading}
              >
                Close
              </Button>
              <Button
                onClick={handleSave}
                variant="contained"
                style={{
                  ...button_right_style,
                  width: "25%",
                  padding: "1.8% 3%",
                }}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : editingCoupon ? (
                  "Update"
                ) : (
                  "Save"
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
