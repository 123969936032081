import * as React from 'react';
import { Button, Grid, Typography } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

export default function BottomBar(props) {

    var navigate = useNavigate()
    const theme = useTheme();
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'));
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div style={{ background: 'black', textAlign: 'center', color: 'white', padding: '1% 0' }}>
            <center>
                <p className='footer-txt'>
                    All rights reserved © 2024
                </p>
            </center>
        </div>
    )
}