import React from "react";
import Heading from "./Heading";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function AboutServices() {

    const theme = useTheme();
    const matches_sm = useMediaQuery(theme.breakpoints.down("sm"));
    const matches_md = useMediaQuery(theme.breakpoints.down("md"));

    const features = [
        {
            icon: "meet-icon",
            title: "Matching",
            para: "We connect you with compatible hearts, not just profiles."
        },
        {
            icon: "preferences-icon",
            title: "Genuine",
            para: "Safe space with genuine profiles seeking lasting love stories."
        },
        {
            icon: "calendar-icon",
            title: "Conversations",
            para: " Focus on meaningful interactions, build genuine connections."
        },
        {
            icon: "meet-icon",
            title: "Forever",
            para: "We cater to those seeking long-term love, not fleeting flings."
        },
    ];

    const featuresSection = () => {
        return (
            <div className="flex justify-between items-center flex-wrap gap-5">
                {features.map((item, i) => (
                    <div
                        key={i}
                        style={{
                            textAlign: 'center',
                            width: matches_sm ? '100%' : matches_md ? '45%' : '23%',
                            padding: matches_sm ? '6%' : matches_md ? '3%' : "2%",
                            height: matches_sm ? 240 : 220,
                            background: "white",
                        }}
                    >
                        <center>
                            <img
                                src={`/images/${item.icon}.svg`}
                                style={{ width: 55, height: 55 }}
                            />
                        </center>
                        <h3 className="features-div-title">{item.title}</h3>
                        <p className="features-div-para">{item.para}</p>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <>
            <div className="features-section" >
                <center>
                    <Heading title="What Makes Us Different?" />
                </center>

                <div
                    style={{ position: "relative", padding: matches_md ? '2% 3%' : "2% 6%", marginTop: matches_md ? '6%' : 0 }}
                >
                    {featuresSection()}
                </div>
            </div>
        </>
    );
}
