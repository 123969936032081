import React from "react";
import { useState, useEffect } from "react";
import { Grid, Button, Pagination, Typography } from "@mui/material";
import DefaultTable from "../Components/DefaultTable";
import { handleCreateActivity, userTableHeader } from "../../../Constants/globalConstants";
import { get_data, post_data } from "../../../Api";
import SuccessModal from "../../UserDshboard/Components/SuccessModal";
import TableSkeleton from "../../UserDshboard/Components/skeleton/TableSkeleton";
import { useDispatch, useSelector } from "react-redux";

export default function Users({ isSubAdmin, permissions, setProfileLoading }) {

  const { user_data } = useSelector(state => state.user);
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sureModal, setSureModal] = useState(false);
  const [successStatus, setSuccessStatus] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [orgData, setOrgData] = useState([]);
  const [totalMale, setTotalMale] = useState("");
  const [totalFemale, setTotalFemale] = useState("");
  const itemsPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [filterState, setFilterState] = useState(false);
  const [searchValueFilter, setSearchValueFilter] = useState(false);
  const [updateDays, setUpdateDays] = useState('');
  const [updatePlan, setUpdatePlan] = useState('');

  const fetchAllUsers = async () => {
    const response = await get_data(
      `user/get-all-users?page_number=${currentPage}`
    );
    if (response?.status) {
      setAllUsers(response?.data?.allUsers);
      setTableLoading(false);
      setTotalPages(response?.data?.totalPages);
      setOrgData(response?.data?.allUsers);
    }
  };

  useEffect(() => {
    if (currentPage) {
      if (filterState) {
        handleGetFilterUsers()
      }
      else {
        if (!searchValueFilter) {
          fetchAllUsers();
        }
      }
    }
  }, [currentPage, setAllUsers]);


  const handleGetFilterUsers = async (updatedDays, updatedPlan) => {
    try {
      let payload = { days: updatedDays ? updatedDays : updateDays, plan: updatedPlan ? updatedPlan : updatePlan };
      const response = await post_data(`user/get-account-plan-controller?page_number=${currentPage}`, payload);
      setAllUsers(response?.data?.filteredUsers);
      setTotalPages(response?.data?.totalPages);
      setOrgData(response?.data?.totalUsers);
    } catch (e) {
      // console.log(e);
    }
  };


  useEffect(() => {
    const male_count = allUsers?.filter(
      (item) => item?.basic_details?.gender === "Male"
    );
    if (male_count) {
      setTotalMale(male_count?.length);
    }

    const female_count = allUsers?.filter(
      (item) => item?.basic_details?.gender === "Female"
    );
    if (female_count) {
      setTotalFemale(female_count?.length);
    }
  }, [allUsers]);

  const users_page = {
    width: "100%",
  };

  const page_container = {
    width: "100%",
    margin: "2% 0",
    background: "transparent",
  };

  const handleUserEdit = (item) => { };

  const handleConfirm = async (id) => {
    setTimeout(async () => {
      const response = await post_data(`user/delete-user/${id}`);
      if (response?.status) {
        setSuccessStatus(true);
        setSuccessOpen(true);
        fetchAllUsers();
        setLoading(false)
        setSureModal(false);
        handleCreateActivity('User Deleted', user_data)
      } else {
        setSuccessStatus(false);
        setSuccessOpen(true);
        setLoading(false)
        setSureModal(false);
      }
    }, 1000);
  };

  return (
    <>
      <Grid container spacing={0} style={users_page}>
        <div style={page_container}>
          {tableLoading ? (
            <>
              <TableSkeleton />
            </>
          ) : (
            <>
              <DefaultTable
                setProfileLoading={setProfileLoading}
                fetchAllUsers={fetchAllUsers}
                isSubAdmin={isSubAdmin}
                permissions={permissions}
                setSureModal={setSureModal}
                sureModal={sureModal}
                title="Users"
                count={allUsers?.length}
                sliceEnd={allUsers.length}
                loading={loading}
                setLoading={setLoading}
                handleConfirm={handleConfirm}
                handleEdit={handleUserEdit}
                data={allUsers}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setData={setAllUsers}
                type="Users"
                orgData={orgData}
                totalMale={totalMale}
                totalFemale={totalFemale}
                tableHeader={userTableHeader}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                setFilterState={setFilterState}
                handleGetFilterUsers={handleGetFilterUsers}
                setSearchValueFilter={setSearchValueFilter}
                setUpdateDays={setUpdateDays}
                setUpdatePlan={setUpdatePlan}
              />
            </>
          )}
        </div>
        <SuccessModal
          status={successStatus}
          open={successOpen}
          setOpen={setSuccessOpen}
          title={
            successStatus
              ? `User deleted successfully!`
              : "Something went wrong..."
          }
        />
      </Grid>
    </>
  );
}
