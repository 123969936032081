import React, { useState, useRef, useEffect, useContext } from "react";
import SearchComponent from "./SearchComponent";
import { useNavigate } from "react-router-dom";
import ProfileLabel from "./ProfileLabel";
import DefaultModal from "./DefaultModal";
import LogoutModal from "./LogoutModal";
import { get_data, post_data, serverURLProfilePicture } from "../../../Api";
import { useSelector } from "react-redux";
import DarkModeToggle from "./DarkModeToggle";
import Theme from "./Theme";
import {
  darkPrimaryColor,
  getAvatar,
  secondaryDarkColor,
  useDatabaseConstants,
} from "../../../Constants/globalConstants";
import Badge from "@mui/material/Badge";
import moment from "moment";
import { useMemo } from "react";
import { socketServerUrl } from "../../../lib/axios-request";
import { io } from "socket.io-client";
import { DarkModeContext } from "./DarkModeContext";
import { IoIosHelpCircleOutline } from "react-icons/io";

export default function TopBar() {
  const socket = useMemo(
    () =>
      io(socketServerUrl, {
        path: "/api/socket",
      }),
    []
  );
  const navigate = useNavigate();
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [open, setOpen] = useState(false);
  const { isLoggedIn, user_data } = useSelector((state) => state.user);
  const [recentSearchDiv, setRecentSearchDiv] = useState(false);
  const [allNotifications, setAllNotifications] = useState([]);

  const { darkMode, setDarkMode } = useContext(DarkModeContext);

  const popupRef = useRef(null);
  const popupRefProfile = useRef(null);
  const popupRefSearch = useRef(null);
  const [onlineUsers, setOnlineUsers] = useState([]);

  useEffect(() => {
    socket.emit("new-user-add", user_data?._id);
    socket.on("get-users", (users) => {
      setOnlineUsers(users);
    });
  }, [socket, user_data?._id]);

  const top_section = {
    display: "flex",
    alignItems: "center",
    height: 65,
    padding: "1% 3%",
    background: darkMode ? darkPrimaryColor : "white",
    color: darkMode ? 'white' : 'black',
    boxShadow: "0 3px 4px 0 #00000008",
  };

  const notification_modal = {
    position: "absolute",
    top: "10%",
    right: "6%",
    backgroundColor: darkMode ? secondaryDarkColor : "#fff",
    color: darkMode ? 'white' : 'black',
    border: darkMode ? '' : "1px solid #F1F1F2",
    boxShadow: "0px 8px 14px 0px #0F2A5114",
    borderRadius: 10,
    zIndex: 9999,
    width: 400,
    height: 500,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
  };

  const profile_modal = {
    position: "absolute",
    top: "10%",
    right: "3%",
    backgroundColor: "#fff",
    border: darkMode ? '' : "1px solid #F1F1F2",
    boxShadow: "0px 8px 14px 0px #0F2A5114",
    borderRadius: 10,
    zIndex: 9999,
    width: 340,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
  };

  const logout_div = {
    display: "flex",
    gap: "5%",
    fontWeight: 600,
    color: "red",
    fontSize: 16,
    cursor: "pointer",
    padding: "5% 6%",
    background: darkMode ? secondaryDarkColor : 'white',
  };

  const user_info_div = {
    padding: "6%",
    display: "flex",
    alignItems: "center",
    gap: "4%",
    background: darkMode ? secondaryDarkColor : 'white',
    color: darkMode ? 'white' : 'black'
  };

  const user_list_div = {
    padding: "2% 6%",
    background: darkMode ? secondaryDarkColor : 'white',
    color: darkMode ? 'white' : 'black'
  };

  const user_name = {
    fontSize: 15,
    fontWeight: 500,
    color: darkMode ? 'white' : "#212121",
  };

  const user_email = {
    fontSize: 13,
    fontWeight: 400,
    color: darkMode ? 'white' : "#3F4254",
  };

  const togglePopup = () => {
    setShowNotificationModal(!showNotificationModal);
  };

  const togglePopupProfile = () => {
    setShowProfileModal(!showProfileModal);
  };

  const handleClickOutside = (event) => {
    if (popupRef?.current && !popupRef?.current.contains(event.target)) {
      setShowNotificationModal(false);
    }
    if (
      popupRefProfile.current &&
      !popupRefProfile.current.contains(event.target)
    ) {
      setShowProfileModal(false);
    }
    if (
      popupRefSearch?.current &&
      !popupRefSearch?.current.contains(event?.target)
    ) {
      setRecentSearchDiv(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const notification_title = {
    color: darkMode ? 'white' : "#3F4254",
    fontSize: 16,
    lineHeight: "18px",
    fontWeight: 400,
  };

  const notification_para = {
    color: "#B5B5C3",
    fontSize: 14,
    marginTop: "2%",
    fontWeight: 500,
    lineHeight: "15px",
  };

  const notification_div = {
    borderBottom: darkMode ? '1px solid gray' : "1px solid #F1F1F2",
    padding: "6%",
    display: "flex",
    alignItems: "center",
    gap: "5%",
  };

  const user_list_items = [
    {
      title: "My Profile",
      link: "/dashboard",
    },
    {
      title: "My Subscription",
      link: "/dashboard/billing",
    },
    {
      title: "Settings",
      link: "/dashboard/settings",
    },
  ];

  const list_item = {
    margin: "3% 0",
    fontWeight: 500,
    color: "#7E8299",
    fontSize: 16,
    cursor: "pointer",
  };

  const handleItemClick = (item) => {
    navigate(item?.link);
    setShowProfileModal(false);
  };

  const handleLogout = (e) => {
    setShowProfileModal(false);
    setOpen(true);
  };

  const profile_pic = {
    borderRadius: 8,
    height: "40px",
    width: "40px",
    cursor: "pointer",
  };
  let avatar = getAvatar(user_data);

  const recent_search_div = {
    height: 250,
    width: "100%",
    zIndex: 99,
    position: "absolute",
    background: "white",
    boxShadow: "3px 3px 4px 0 #00000008",
    border: "2px solid #F1F1F2",
    borderRadius: "0 0 10px 10px",
  };

  const get_notification = async () => {
    const current_data = await get_data(
      `notification/get-notification-by-user-id/${user_data._id}`
    );
    if (current_data?.status) {
      setAllNotifications(current_data.data.notifications);
    } else {
      console.log("error: ", current_data?.message);
    }
  };

  useEffect(() => {
    get_notification();
  }, [user_data]);

  const handleNotification = async (id) => {
    const current_data = await post_data(
      `notification/mark-notification-as-read/${id}`
    );
    if (current_data?.status) {
      get_notification();
      setShowNotificationModal(false);
    } else {
      console.log("error: ", current_data?.message);
    }
  };

  useEffect(() => {
    const handleMessageReceive = (data) => {
      if (data.receiverId === user_data?._id) {
        setAllNotifications((prev) => [
          {
            _id: allNotifications.length + 1,
            recipient: data.receiverId,
            sender: data.senderId,
            message: data.message,
            createdAt: data.createdAt,
            type: data.type,
          },
          ...prev,
        ]);
      }
    };

    socket.on("receive-notification", handleMessageReceive);

    // Clean up the event listener on component unmount
    return () => {
      socket.off("receive-notification", handleMessageReceive);
    };
  }, [socket, allNotifications.length, user_data?._id]);


  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const darkModeToggleStyles = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  }

  const darkModeToggle = () => {

    const toggleStyles = {
      toggleContainer: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px',
        marginLeft: '20px'
      },
      toggle: {
        width: '90px',
        height: '35px',
        backgroundColor: darkMode ? secondaryDarkColor : '#d1d1d1',
        borderRadius: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        transition: 'background-color 0.3s ease',
      },
      toggleCircle: {
        width: '25px',
        height: '25px',
        backgroundColor: '#ffffff',
        borderRadius: '50%',
        position: 'absolute',
        right: darkMode ? '40%' : '',
        left: darkMode ? '' : '40%',
        transition: 'transform 0.3s ease',
        transform: darkMode ? 'translateX(30px)' : 'translateX(-30px)',
      },
      toggleLabel: {
        fontSize: 13,
        color: darkMode ? '#ffffff' : '#000000',
        fontWeight: '500',
        marginLeft: darkMode ? '' : '30%',
        marginRight: darkMode ? '30%' : '',
      }
    };

    return (
      <div style={toggleStyles.toggleContainer} onClick={toggleDarkMode}>
        <div style={toggleStyles.toggle}>
          <div style={toggleStyles.toggleCircle}></div>
          <p style={toggleStyles.toggleLabel}>{darkMode ? 'DARK' : 'LIGHT'}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <LogoutModal open={open} setOpen={setOpen} />
      <div style={top_section}>
        <div style={{ width: "30%", position: "relative" }}>
          {/* <SearchComponent
            setRecentSearchDiv={setRecentSearchDiv}
            recentSearchDiv={recentSearchDiv}
          />
          {recentSearchDiv && (
            <div style={recent_search_div} ref={popupRefSearch}>
              {" "}
            </div>
          )} */}
          <h3 className="global-h3" style={{ color: darkMode ? 'white' : 'black' }}>Welcome {user_data?.first_name} 👋</h3>
        </div>
        <div
          style={{
            width: "70%",
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
            <div style={darkModeToggleStyles}>
              {darkModeToggle()}
            </div>

            <IoIosHelpCircleOutline style={{ width: 30, height: 30, cursor: 'pointer' }} onClick={() => navigate('/dashboard/help-center')} />

            <img
              src="/images/notification-icon-new.svg"
              style={{ height: "40px", width: "40px", cursor: "pointer" }}
              onClick={togglePopup}
            />

            {user_data?.profile_picture ? (
              <>
                <img
                  src={`${serverURLProfilePicture}/${user_data?.profile_picture}`}
                  style={profile_pic}
                  onClick={togglePopupProfile}
                />
              </>
            ) : (
              <>
                <img
                  src={`/images/${avatar}`}
                  style={profile_pic}
                  onClick={togglePopupProfile}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {showNotificationModal && (
        <div
          style={notification_modal}
          className="notifications-parent-div"
          ref={popupRef}
        >
          {
            allNotifications?.length === 0 ?
              <>
                <center><p style={{ opacity: '80%', marginTop: '10%' }}>No Notifications</p></center>
              </>
              :
              <>
                {allNotifications &&
                  allNotifications?.map((item, i) => {
                    return (
                      <>
                        <div
                          style={notification_div}
                          className="notifications-div"
                        // onClick={() => handleNotification(item._id)}
                        >
                          <div>
                            <img
                              src="/images/notification-icon.svg"
                              style={{ height: "25px", width: "25px" }}
                            />
                          </div>
                          <div>
                            <h3 style={notification_title}>{item.message}</h3>
                            <p style={notification_para}>
                              {moment(item.createdAt).format("DD-MM-YYYY hh:mm A")}
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </>
          }
        </div>
      )}

      {showProfileModal && (
        <div
          style={profile_modal}
          className="notifications-parent-div"
          ref={popupRefProfile}
        >
          <div style={user_info_div}>
            {user_data?.profile_picture ? (
              <>
                <img
                  src={`${serverURLProfilePicture}/${user_data?.profile_picture}`}
                  style={{ borderRadius: 6, height: "40px", width: "40px" }}
                  onClick={togglePopupProfile}
                />
              </>
            ) : (
              <>
                <img src={`/images/${avatar}`} style={profile_pic} />
              </>
            )}
            <div style={{ width: "100%", alignItems: "center" }}>
              <div style={{ display: "flex", gap: "5%", alignItems: "center" }}>
                <p style={user_name}>
                  {user_data?.first_name} {user_data?.last_name}
                </p>
                <ProfileLabel width={100} user={user_data} />
              </div>
              <p style={user_email}>{user_data?.email}</p>
            </div>
          </div>
          <hr
            style={{
              borderTop: darkMode ? "1px dashed #gray" : "1px dashed #A1A5B7",
            }}
          />
          <div style={user_list_div}>
            {user_list_items.map((item, i) => {
              return (
                <>
                  <p style={list_item} onClick={() => handleItemClick(item)}>
                    {item.title}
                  </p>
                </>
              );
            })}
          </div>
          <hr
            style={{
              borderTop: darkMode ? "1px dashed #gray" : "1px dashed #A1A5B7",
            }}
          />
          <div style={logout_div} onClick={(e) => handleLogout(e)}>
            <h3>Logout</h3>
          </div>
        </div>
      )}
    </>
  );
}
