import React, { useContext } from "react"
import { darkPrimaryColor, secondaryDarkColor, useDatabaseConstants } from '../../../Constants/globalConstants'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Badge from '@mui/material/Badge';
import { useNavigate } from "react-router-dom";
import { DarkModeContext } from "./DarkModeContext";

export default function CollapsedSideBar({
    collapse,
    setCollapse,
    menu_items,
    open,
    setOpen
}) {

    const navigate = useNavigate()
    const { app_color, user_dashboard_color } = useDatabaseConstants();
    const { darkMode, setDarkMode } = useContext(DarkModeContext);

    let current_path = window.location.pathname;

    const user_dashboard_sidebar = {
        background: darkMode ? darkPrimaryColor : '#32012F',
        borderRight: darkMode ? `2px solid ${secondaryDarkColor}` : '',
        width: '100%',
        height: '100vh',
        position: 'sticky',
        top: 0
    }

    const logo_section = {
        padding: '15% 10%',
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center'
    }

    const sidebar_items_div = {
        padding: '4% 0'
    }

    const handleListItem = (item, i) => {
        if (item.link) {
            navigate(item.link);
            window.scrollTo(0, 0)
        }
    }

    const handleCollapse = () => {
        setCollapse(false)
    }

    const display_sidebar_items = () => {
        return (
            <List sx={{ width: '100%', maxWidth: 360 }} component="nav">
                {menu_items.map((item, i) => {
                    return (
                        <div style={{
                            width: '100%',
                            margin: 'auto'
                        }}>
                            <ListItemButton
                                key={i}
                                onClick={() => handleListItem(item, i)}
                                style={{
                                    margin: '10% 0',
                                    // borderRadius: 3,
                                    display: "flex",
                                    justifyContent: 'center',
                                    background: current_path === item?.link ? app_color : 'transparent'
                                }}
                            >
                                <div style={{ display: "flex", width: '100%', alignItems: "center", justifyContent: "center" }}>
                                    {
                                        item?.title === 'Chat' ?
                                            <>
                                                <img
                                                    src={current_path === item?.link ? `/images/${item?.active_icon}.svg` : `/images/${item?.icon}.svg`}
                                                    style={{ width: '25px', height: '25px' }}
                                                />
                                            </>
                                            :
                                            <>
                                                <Badge>
                                                    <img
                                                        src={current_path === item?.link ? `/images/${item?.active_icon}.svg` : `/images/${item?.icon}.svg`}
                                                        style={{ width: '25px', height: '25px' }}
                                                    />
                                                </Badge>
                                            </>
                                    }
                                </div>
                            </ListItemButton>
                        </div>
                    );
                })}
            </List>
        )
    }

    return (
        <>
            <div style={user_dashboard_sidebar} className="sidebar-div">
                <div style={logo_section}>
                    <img src="/images/collapse-icon.svg" onClick={handleCollapse}
                        style={{
                            width: 25,
                            height: 25,
                            margin: 'auto',
                            cursor: 'pointer',
                            transform: 'rotate(180deg)'
                        }}
                    />
                </div>
                <hr style={{ opacity: '30%' }} />
                <div style={sidebar_items_div}>
                    {display_sidebar_items()}
                </div>
            </div>
        </>
    )
}