import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoggedIn: false,
    user_data: [],
    selectedoptions: "",
  },
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.user_data = [];
    },
    login: (state, action) => {
      state.isLoggedIn = true;
      state.user_data = action.payload;
    },
    selectedoptions: (state, action) => {
      state.selectedoptions = action.payload;
    },
  },
});

export const { login, logout, selectedoptions } = userSlice.actions;

export default userSlice.reducer;
