import React, { useContext, useEffect } from "react"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useState } from "react";
import { Grid, Button } from "@mui/material"
import DashboardHeading from "../Components/DashboardHeading"
import { useDatabaseConstants, button_right_style, checkEmptyProfileFields, darkPrimaryColor, secondaryDarkColor } from "../../../Constants/globalConstants";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import UserPrivacy from "../Components/UserPrivacy";
import UserPhotos from "../Components/UserPhotos";
import CompleteProfileModal from "../../../Website/Components/CompleteProfileModal";
import UserPersonalDetails from "../Components/UserPersonalDetails";
import ProfileSkeleton from "../Components/skeleton/ProfileSkeleton";
import UserVideo from "../Components/UserVideo";
import AreYouSureModal from "../Components/AreYouSureModal";
import PhotosGuidelines from "../Components/PhotosGuidelines";
import { useParams } from "react-router-dom";
import { get_data, post_data } from "../../../Api";
import BackButton from "../Components/BackButton";
import LimitPhotosModal from "../Components/LimitPhotosModal";
import { login } from "../../../redux/slices/user-slice";
import { DarkModeContext } from "../Components/DarkModeContext";
import Verification from "../Components/Verification";
import UserSelfie from "../Components/UserSelfie";

export default function MyProfile({ permissions }) {

    const { darkMode } = useContext(DarkModeContext);
    const location = useLocation()
    const [userProfileDetails, setUserProfileDetails] = useState({})
    const screen = location?.state?.screen
    // const user_profile_details = location?.state?.user_profile_details
    const { isLoggedIn, user_data } = useSelector(state => state.user);
    const { app_color } = useDatabaseConstants();
    const [activeTab, setActiveTab] = useState("Details");
    const theme = useTheme();
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'));
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loading2, setLoading2] = useState(false)
    const [openDropzone, setOpenDropzone] = useState(false)
    const [editPhotos, setEditPhotos] = useState(false)
    const [showDisplayPhotos, setShowDisplayPhotos] = useState(true)
    const [openVideoDropzone, setOpenVideoDropzone] = useState(false)
    const [deleteVideo, setDeleteVideo] = useState(false)
    const [showDisplayVideo, setShowDisplayVideo] = useState(true)
    const [sureModal, setSureModal] = useState(false)
    const [successStatus, setSuccessStatus] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const [photoGuidelinesOpen, setPhotoGuidelinesOpen] = useState(false)
    const [count, setCount] = useState(0)
    const [limitPhotosOpen, setLimitPhotosOpen] = useState(false)
    const dispatch = useDispatch()

    let { dil_sey_id } = useParams();

    const fetchUserByDilSeyId = async () => {
        const response = await get_data(`user/get-user/dil-sey-id/${dil_sey_id}`)
        if (response?.status) {
            setUserProfileDetails(response?.data)
        }
    }

    useEffect(() => {
        fetchUserByDilSeyId()
    }, [])

    const my_profile_page = {
        width: '100%',
    }

    const page_container = {
        width: '100%',
        margin: '2% 0',
    }


    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 500);
    }, [])

    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const checkEmptyProfile = checkEmptyProfileFields(user_data);
            if (checkEmptyProfile) {
                setOpen(true);
            }
        }, 10000);
        return () => clearTimeout(timeoutId);
    }, []);

    const TabItem = ({ tabName }) => (
        <div
            onClick={() => handleTabChange(tabName)}
            style={{
                color: activeTab === tabName ? 'white' : "#808080",
                background: activeTab === tabName ? (darkMode ? darkPrimaryColor : app_color) : "",
                borderRadius: 30,
            }}
            className="active-tabname"
        >
            <p style={{ fontSize: 14 }}>{tabName}</p>
        </div>
    );

    const tabs_div = {
        background: darkMode ? secondaryDarkColor : '#F6E6EE',
        padding: 5,
        borderRadius: 30,
        width: 'fit-content'
    }

    const outlineButtonStyle = {
        width: "50%",
        padding: "3% 0",
        backgroundColor: 'white',
        color: app_color,
        boxShadow: 'none',
        borderRadius: 7,
        fontWeight: 500,
        fontSize: 15,
        border: `2px solid ${app_color}`,
        textTransform: 'capitalize'
    };

    const handleAddPhotos = () => {
        if (user_data?.photos?.length === 10) {
            setLimitPhotosOpen(true)
        }
        else {
            if (count === 0) {
                setPhotoGuidelinesOpen(true);
                setCount(count + 1);
            }
            else {
                setOpenDropzone(!openDropzone)
                setEditPhotos(false)
                setShowDisplayPhotos(true)
            }
        }
    }

    const handleAddVideos = () => {
        setOpenVideoDropzone(!openVideoDropzone)
        setDeleteVideo(false)
        setShowDisplayVideo(true)
    }

    const handleEditPhotos = () => {
        setOpenDropzone(false)
        setEditPhotos(!editPhotos)
        setShowDisplayPhotos(!showDisplayPhotos)
    }

    const handleDeleteVideo = () => {
        setSureModal(true)
    }

    const handleDeleteVideoConfirm = async () => {
        let payload = { old_video: user_data?.video, video: '' }
        const response = await post_data(`user/delete-user-video/${user_data?._id}`, payload)
        if (response?.status) {
            setLoading2(false)
            setSureModal(false)
            dispatch(login(response?.data));
        }
        else {
            setLoading2(false)
        }
    }

    return (
        <>
            <Grid container spacing={0} style={my_profile_page}>

                <Grid item md={12} style={{ display: "flex", alignItems: 'center', gap: '1%' }}>
                    <BackButton />
                    <DashboardHeading title='My Profile 🗒️' />
                </Grid>

                <div style={page_container}>
                    {
                        loading ?
                            <>
                                <ProfileSkeleton />
                            </>
                            :
                            <>
                                <div className="user-profile-section">

                                    <div className='user-profile-tabs-div' style={tabs_div}>
                                        <TabItem tabName="Details" />
                                        {
                                            screen !== 'Match' && (screen !== 'Sent' && screen !== 'Interest Sent') && (screen !== 'Received' && screen !== 'Interest Received') && (
                                                <TabItem tabName="Privacy" />
                                            )
                                        }
                                        {
                                            screen !== 'Match' && (screen !== 'Sent' && screen !== 'Interest Sent') && (screen !== 'Received' && screen !== 'Interest Received') && (
                                                <TabItem tabName="Verification" />
                                            )
                                        }
                                        {
                                            screen !== 'Match' && (screen !== 'Sent' && screen !== 'Interest Sent') && (screen !== 'Received' && screen !== 'Interest Received') ?
                                                <><TabItem tabName="Photos" /></>
                                                :
                                                <>
                                                    {
                                                        userProfileDetails?.privacy?.photos ?
                                                            <></>
                                                            :
                                                            <><TabItem tabName="Photos" /></>
                                                    }
                                                </>
                                        }
                                        <TabItem tabName="Selfie" />
                                        <TabItem tabName="Video" />
                                    </div>

                                    <div
                                        style={{
                                            paddingTop: matches_md ? '12%' : '3%',
                                            position: "relative"
                                        }}
                                    >

                                        {activeTab === "Details" && (
                                            // user_profile_details
                                            <div>
                                                {
                                                    screen !== 'Match' && (screen !== 'Sent' && screen !== 'Interest Sent') && (screen !== 'Received' && screen !== 'Interest Received') ? (
                                                        <UserPersonalDetails permissions={permissions}
                                                            user={user_data}
                                                            screen={screen}
                                                        />
                                                    ) : (
                                                        <UserPersonalDetails permissions={permissions}
                                                            user={userProfileDetails}
                                                            screen={screen}
                                                        />
                                                    )
                                                }


                                            </div>
                                        )}
                                        {activeTab === "Privacy" && (
                                            <div>
                                                <UserPrivacy
                                                    user={user_data}
                                                />
                                            </div>
                                        )}
                                        {activeTab === "Verification" && (
                                            <div>
                                                <Verification setActiveTab={setActiveTab} />
                                            </div>
                                        )}
                                        {activeTab === "Photos" && (
                                            <div>
                                                {
                                                    screen !== 'Match' && (screen !== 'Sent' && screen !== 'Interest Sent') && (screen !== 'Received' && screen !== 'Interest Received') && (
                                                        <div style={{
                                                            position: "absolute",
                                                            right: '2%',
                                                            top: 0,
                                                            display: "flex",
                                                            gap: '2%'
                                                        }}>
                                                            {
                                                                user_data?.photos?.length > 0 && (
                                                                    <Button
                                                                        onClick={handleEditPhotos}
                                                                        variant="contained"
                                                                        style={{
                                                                            ...outlineButtonStyle, width: 150, height: 50
                                                                        }}
                                                                    >
                                                                        Edit Photos
                                                                    </Button>
                                                                )
                                                            }
                                                            <Button
                                                                onClick={handleAddPhotos}
                                                                variant="contained"
                                                                style={{
                                                                    ...button_right_style, width: 150, height: 50
                                                                }}
                                                            >
                                                                Add Photos
                                                            </Button>
                                                        </div>
                                                    )
                                                }
                                                <UserPhotos
                                                    permissions={permissions}
                                                    showDisplayPhotos={showDisplayPhotos}
                                                    editPhotos={editPhotos}
                                                    setEditPhotos={setEditPhotos}
                                                    setShowDisplayPhotos={setShowDisplayPhotos}
                                                    openDropzone={openDropzone}
                                                    setOpenDropzone={setOpenDropzone}
                                                    user={screen === 'Match' || screen === 'Sent' || screen === 'Interest Sent' || screen === 'Received' || screen === 'Interest Received' ? userProfileDetails : user_data}
                                                    screen={screen}
                                                />
                                            </div>
                                        )}
                                        {activeTab === "Video" && (
                                            <div>
                                                {
                                                    screen !== 'Match' && (screen !== 'Sent' && screen !== 'Interest Sent') && (screen !== 'Received' && screen !== 'Interest Received') && (
                                                        <div style={{
                                                            position: "absolute",
                                                            right: '2%',
                                                            top: 0,
                                                            display: "flex",
                                                            gap: '2%'
                                                        }}>
                                                            {
                                                                user_data?.video !== '' && (
                                                                    <Button
                                                                        onClick={handleDeleteVideo}
                                                                        variant="contained"
                                                                        style={{
                                                                            ...outlineButtonStyle, width: 150, height: 50
                                                                        }}
                                                                    >
                                                                        Delete Video
                                                                    </Button>
                                                                )
                                                            }

                                                            {
                                                                user_data?.video === '' && (
                                                                    <Button
                                                                        onClick={handleAddVideos}
                                                                        variant="contained"
                                                                        style={{
                                                                            ...button_right_style, width: 150, height: 50
                                                                        }}
                                                                    >
                                                                        Add Video
                                                                    </Button>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }
                                                <UserVideo
                                                    openVideoDropzone={openVideoDropzone}
                                                    setOpenVideoDropzone={setOpenVideoDropzone}
                                                    deleteVideo={deleteVideo} setDeleteVideo={setDeleteVideo}
                                                    showDisplayVideo={showDisplayVideo}
                                                    setShowDisplayVideo={setShowDisplayVideo}
                                                    permissions={permissions}
                                                    user={screen === 'Match' || screen === 'Sent' || screen === 'Interest Sent' || screen === 'Received' || screen === 'Interest Received' ? userProfileDetails : user_data}
                                                    screen={screen}
                                                />
                                            </div>
                                        )}
                                        {activeTab === "Selfie" && (
                                            <div>
                                                <UserSelfie
                                                    screen={screen}
                                                    user={screen === 'Match' || screen === 'Sent' || screen === 'Interest Sent' || screen === 'Received' || screen === 'Interest Received' ? userProfileDetails : user_data}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </Grid>
            <CompleteProfileModal open={open} setOpen={setOpen} />
            <AreYouSureModal
                setLoading={setLoading2}
                loading={loading2}
                confirmFunc={handleDeleteVideoConfirm}
                open={sureModal}
                setOpen={setSureModal}
                title='Are you Sure, you want to Delete ?'
                image='/images/modal-img-delete.svg'
                successOpen={successOpen}
                setSuccessOpen={setSuccessOpen}
            />
            <PhotosGuidelines
                open={photoGuidelinesOpen} setOpen={setPhotoGuidelinesOpen}
                setEditPhotos={setEditPhotos}
                setShowDisplayPhotos={setShowDisplayPhotos}
                openDropzone={openDropzone}
                setOpenDropzone={setOpenDropzone}
            />
            <LimitPhotosModal
                open={limitPhotosOpen} setOpen={setLimitPhotosOpen}
            />
        </>
    )
}