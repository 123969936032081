import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getFieldName, useDatabaseConstants, button_right_style, button_left_style, disabled_btn } from "../../../Constants/globalConstants";
import { post_data } from "../../../Api";
import SuccessModal from "../../UserDshboard/Components/SuccessModal";
import { Link } from "react-router-dom";
import AdminOtpModal from "./AdminOtpModal";

export default function AdminSettings({
    type
}) {

    const { user_data } = useSelector(state => state.user);
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [formData, setFormData] = useState({
        current_password: '',
        new_password: ''
    })
    const [formData2, setFormData2] = useState({
        new_email: ''
    })
    const [successStatus, setSuccessStatus] = useState(false)
    const [successOpen, setSuccessOpen] = useState(false)
    const { app_color } = useDatabaseConstants()
    const [adminType, setAdminType] = useState('')
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setAdminType(user_data?.is_sub_admin ? 'sub-admin' : 'super-admin')
    }, [user_data])

    const grid_box = {
        padding: '2%',
        borderRadius: 10,
        marginRight: '2%',
        background: 'white',
        border: '2px solid #F1F1F2',
        boxShadow: '3px 3px 4px 0 #00000008'
    }

    const details = [
        {
            field: "Current Password",
            value: formData?.current_password,
            type: 'password',
        },
        {
            field: "New Password",
            value: formData?.new_password,
            type: 'text',
        },

    ]

    const emailDetails = [
        {
            field: "Current Email",
            value: user_data?.email,
            type: 'text',
        },
        {
            field: "New Email",
            value: formData2?.new_email,
            type: 'text',
        },

    ]

    const [invalid, setInvalid] = useState({
        current_password: false,
        new_password: false
    });

    const [invalid2, setInvalid2] = useState({
        new_email: false
    });

    const handleInputChange2 = (e) => {
        setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
        setInvalid({ ...invalid, [e?.target?.name]: false })
    }

    const handleInputChange = (e) => {
        setFormData2({ ...formData2, [e?.target?.name]: e?.target?.value });
        setInvalid2({ ...invalid2, [e?.target?.name]: false })
    }

    const input_style = {
        margin: '3% 0',
        width: '100%'
    }

    const handleEnter = (event) => {
        if (event.key == 'Enter') {
            handleChangePassword()
        }
    }

    const handleEnter2 = (event) => {
        if (event.key == 'Enter') {
            handleChangeEmail()
        }
    }

    const handleChangePassword = (e) => {

        e?.preventDefault();

        let is_false = 0;
        let obj = {
            current_password: false,
            new_password: false
        }

        if (formData?.current_password === '') {
            obj.current_password = true;
            is_false = 1;
        }

        if (formData?.new_password === '') {
            obj.new_password = true;
            is_false = 1;
        }

        if (is_false) {
            setInvalid(obj);
            return "";
        }

        setLoading(true)

        setTimeout(() => {
            handleSubmit()
        }, 1000)
    }

    const handleSubmit = async () => {
        let payload = { email: user_data?.email, current_password: formData?.current_password, new_password: formData?.new_password }
        let response
        if (adminType === 'sub-admin') {
            response = await post_data(`sub-admin/password-change-by-self`, payload)
        }
        else {
            response = await post_data(`super-admin/password-change-by-self`, payload)
        }
        if (response?.status) {
            setFormData({ ...formData, current_password: '', new_password: '' })
            setLoading(false)
            setSuccessStatus(true)
            setSuccessOpen(true)
        }
        else {
            setFormData({ ...formData, current_password: '', new_password: '' })
            setLoading(false)
            setSuccessStatus(false)
            setSuccessOpen(true)
        }
    }

    const handleChangeEmail = (e) => {

        e?.preventDefault();

        let is_false = 0;
        let obj = {
            new_email: false
        }

        if (formData2?.new_email === '') {
            obj.new_email = true;
            is_false = 1;
        }

        if (is_false) {
            setInvalid2(obj);
            return "";
        }

        setLoading2(true)

        setTimeout(() => {
            handleSubmit2()
        }, 1000)
    }

    const handleSubmit2 = async () => {
        let payload = { email: user_data?.email, new_email: formData2?.new_email }
        let response = await post_data(`super-admin/send-update-email-otp`, payload)
        if (response?.status) {
            setFormData2({ ...formData2, new_email: '' })
            setLoading2(false)
            setOpen(true)
        }
        else {
            setFormData2({ ...formData2, new_email: '' })
            setLoading2(false)
            setSuccessStatus(false)
            setSuccessOpen(true)
        }
    }


    const textFields = () => {
        return details?.map((field, i) => {
            let field_name = getFieldName(field?.field)
            return (
                <>
                    <div style={{
                        position: "relative",
                        margin: '1% 0'
                    }}>
                        <div style={input_style}>
                            <label className="input-label color-label">
                                {field?.display_field || field?.field}
                            </label>
                            <input
                                autoComplete='off'
                                onKeyPress={(event) => handleEnter(event)}
                                value={field?.value}
                                type={field?.type}
                                style={{ height: 42, paddingLeft: '3%' }}
                                className={`register-from-input ${invalid?.[field_name] ? 'invalid-input' : ''}`}
                                name={getFieldName(field?.field)}
                                onChange={handleInputChange2}
                            />
                        </div>
                        {
                            invalid?.[field_name] && (
                                <p className="invalid-txt">Please enter {field?.field}</p>
                            )
                        }
                    </div>
                </>
            )
        })
    }

    const emailFields = () => {
        return emailDetails?.map((field, i) => {
            let field_name = getFieldName(field?.field)
            return (
                <>
                    <div style={{
                        position: "relative",
                        margin: '1% 0'
                    }}>
                        <div style={input_style}>
                            <label className="input-label color-label">
                                {field?.display_field || field?.field}
                            </label>
                            <input
                                autoComplete='off'
                                onKeyPress={(event) => handleEnter2(event)}
                                value={field?.value}
                                type={field?.type}
                                style={{ height: 42, paddingLeft: '3%' }}
                                className={`register-from-input ${invalid2?.[field_name] ? 'invalid-input' : ''}`}
                                name={getFieldName(field?.field)}
                                onChange={handleInputChange}
                            />
                        </div>
                        {
                            invalid2?.[field_name] && (
                                <p className="invalid-txt">Please enter {field?.field}</p>
                            )
                        }
                    </div>
                </>
            )
        })
    }

    return (
        <>
            <Grid container spacing={1}>

                <Grid item md={4} style={grid_box}>
                    <h3 style={{ color: 'black', fontSize: 20, marginBottom: '7%' }} className="global-h3">Change Password</h3>
                    {textFields()}
                    <div style={{ display: "flex", alignItems: 'center' }}>
                        <div style={{
                            width: '60%',
                            display: 'flex',
                            gap: '4%',
                        }}>
                            <Button
                                onClick={handleChangePassword}
                                disabled={loading}
                                variant='contained'
                                style={loading ? { ...button_right_style, ...disabled_btn } : button_right_style}
                            >
                                {loading ? 'Saving...' : 'Save'}
                            </Button>
                        </div>
                        <div style={{ marginRight: 'auto' }}>
                            <Link target="_blank" to={adminType === 'sub-admin' ? '/sub-admin/forget-password' : '/super-admin/forget-password'} style={{ color: app_color, textDecoration: 'underline' }}>Forget Password</Link>
                        </div>
                    </div>
                </Grid>

                {
                    !user_data?.is_sub_admin && (
                        <>
                            <Grid item md={4} style={grid_box}>
                                <h3 style={{ color: 'black', fontSize: 20, marginBottom: '7%' }} className="global-h3">Change Email</h3>
                                {emailFields()}
                                <div style={{ display: "flex", alignItems: 'center' }}>
                                    <div style={{
                                        width: '60%',
                                        display: 'flex',
                                        gap: '4%',
                                    }}>
                                        <Button
                                            onClick={handleChangeEmail}
                                            disabled={loading2}
                                            variant='contained'
                                            style={loading2 ? { ...button_right_style, ...disabled_btn } : button_right_style}
                                        >
                                            {loading2 ? 'Saving...' : 'Save'}
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                        </>
                    )
                }


            </Grid>
            <SuccessModal
                status={successStatus}
                open={successOpen}
                setOpen={setSuccessOpen}
                title={successStatus ? 'Updated successfully!' : 'Something went wrong...'}
            />
            <AdminOtpModal
                open={open}
                setOpen={setOpen}
                successOpen={successOpen}
                setSuccessOpen={setSuccessOpen}
                successStatus={successStatus}
                handleSubmit2={handleSubmit2}
                setSuccessStatus={setSuccessStatus}
                formData={formData2}
            />
        </>
    )
}