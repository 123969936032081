import axiosRequest from "./lib/axios-request";

// const serverURL = "http://localhost:8000";
const serverURL = "https://api.dilsey.in"

const serverURLProfilePicture = `${serverURL}/images/profile-picture`;
const serverURLBackgroundPicture = `${serverURL}/images/background-picture`;
const serverURLPhotos = `${serverURL}/images/user-photos`;
const serverURLVideos = `${serverURL}/images/user-videos`;

export const socketServerUrl = `${serverURL}`;

const post_data = async (url, body) => {
  try {
    var response = await axiosRequest.post(`/api/${url}`, body);
    var data = response.data;
    return data;
  } catch (e) {
    return null;
  }
};

const get_data = async (url) => {
  try {
    var response = await axiosRequest.get(`/api/${url}`);
    var data = response.data;
    return data;
  } catch (e) {
    return null;
  }
};

export {
  serverURL,
  serverURLProfilePicture,
  serverURLBackgroundPicture,
  serverURLPhotos,
  serverURLVideos,
  post_data,
  get_data,
};
