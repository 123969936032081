import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useState } from 'react';
import Heading from './Heading';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Testimonials from './Testimonials';
import { useMediaQuery } from '@mui/material';

export default function MatchingFeatures() {

    const [expanded, setExpanded] = useState('panel1');
    const isDesktop = useMediaQuery('(min-width: 768px)');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const accordianItems = [
        {
            title: "Govt. ID Verified & Genuine Profiles",
            para: "Ensure the authenticity of profiles with government ID verification, adding a layer of trust and security to your matchmaking experience."
        },
        {
            title: "Perfect Matchmaking Algorithms for Relevant Matching",
            para: "Our advanced algorithms analyze your interests and preferences to suggest the most compatible matches, helping you find the perfect partner."
        },
        {
            title: "Personalized Match Suggestions",
            para: "Receive personalized match suggestions based on your unique preferences, making your search for love more efficient and enjoyable."
        },
        {
            title: "Secure and Private Conversations with No Interpretation ",
            para: "Enjoy secure and private conversations with your matches, ensuring your personal information is always protected."
        },
    ];

    const featuresAccordians = () => {
        return (
            <>
                <div className='accordians-parent-div'>
                    {
                        accordianItems.map((item, i) => {
                            let last_item = accordianItems.length - 1
                            return (
                                <>
                                    <div>
                                        <Accordion
                                            style={{ borderBottom: last_item === i ? '' : '1px solid black' }}
                                            expanded={expanded === `panel${i + 1}`}
                                            onChange={handleChange(`panel${i + 1}`)}
                                            className='accordian-div'>
                                            <AccordionSummary
                                                // expandIcon={<AddIcon className='add-icon' />}
                                                expandIcon={expanded === `panel${i + 1}` ? <CloseIcon className='close-icon' /> : <AddIcon className='add-icon' />}
                                                aria-controls={`panel${i + 1}bh-content`}
                                                id="panel1bh-header"
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '3%', padding: 0, width: '80%' }}>
                                                    {
                                                        isDesktop && (
                                                            <div style={{ width: 20, height: 20 }}>
                                                                <div className={`circle-div-${i + 1}`}></div>
                                                            </div>
                                                        )
                                                    }
                                                    <h2 sx={{ flexShrink: 0 }} className='accordian-title'>{item.title} </h2>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <p className='accordian-para'>{item.para}</p>
                                            </AccordionDetails>
                                        </Accordion >
                                    </div>
                                </>
                            )
                        })
                    }
                </div >
            </>
        )
    }

    return (
        <>
            <div className='matching-features-section' style={{ position: 'relative' }}>
                <img src='/images/blur-div.svg' style={{ position: 'absolute', left: 0, bottom: 0, zIndex: -1 }} />
                <div style={{ marginBottom: '4%' }}>
                    <center>
                        <Heading title='Perfect Match Making' />
                    </center>
                </div>
                {featuresAccordians()}

                {/* <Testimonials /> */}
                {
                    isDesktop && (
                        <img
                            src="/images/flower-1.png"
                            style={{
                                position: 'absolute',
                                width: 250,
                                left: '-3%',
                                bottom: '15%',
                                transform: 'rotate(15deg)'
                            }}
                        />
                    )
                }
            </div>
        </>
    );
}
