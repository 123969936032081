import React from "react";
import { useState, useEffect } from "react";
import { Grid, Button, Pagination, Typography } from "@mui/material";
import DefaultTable from "../Components/DefaultTable";
import { permissionsTableHeader, sortArrAscending, userTableHeader } from "../../../Constants/globalConstants";
import { get_data, post_data } from "../../../Api";
import SuccessModal from "../../UserDshboard/Components/SuccessModal";
import TableSkeleton from "../../UserDshboard/Components/skeleton/TableSkeleton";
import NewPermissionModal from "../Components/NewPermissionModal";

export default function Permissions({ setProfileLoading }) {

    const [loading, setLoading] = useState(false);
    const [sureModal, setSureModal] = useState(false);
    const [successStatus, setSuccessStatus] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const [tableLoading, setTableLoading] = useState(true);
    const [orgData, setOrgData] = useState([]);
    const itemsPerPage = 20;
    const [currentPage, setCurrentPage] = useState(1);
    const [open, setOpen] = useState(false);

    const [permissions, setPermissions] = useState([])

    const fetchAllPermisisons = async () => {
        const response = await get_data('sub-admin/get-permission-statistics-by-sub-admin')
        if (response?.status) {
            setPermissions(sortArrAscending(response?.data))
            setTableLoading(false);
            setOrgData(response?.data);
        }
    }

    useEffect(() => {
        fetchAllPermisisons();
    }, []);


    const permissions_page = {
        width: "100%",
    };

    const page_container = {
        width: "100%",
        margin: "2% 0",
        background: "transparent",
    };

    const handleConfirm = async (id) => {
        setTimeout(async () => {
            const response = await post_data(`sub-admin-permission/delete-sub-admin-permission/${id}`);
            if (response?.status) {
                setLoading(false)
                setSuccessStatus(true);
                setSuccessOpen(true);
                fetchAllPermisisons();
                setSureModal(false);
            } else {
                setLoading(false)
                setSuccessStatus(false);
                setSuccessOpen(true);
                setSureModal(false);
            }
        }, 1000);
    };

    const handleClick = () => {
        setOpen(true)
    }

    return (
        <>
            <Grid container spacing={0} style={permissions_page}>
                <div style={page_container}>
                    {tableLoading ? (
                        <>
                            <TableSkeleton />
                        </>
                    ) : (
                        <>
                            {/* <NewPermissionModal open={open} setOpen={setOpen} fetchAllPermisisons={fetchAllPermisisons} modalType="create" />
                            <Button variant="contained" onClick={handleClick}>
                                Add Permission
                            </Button> */}
                            <DefaultTable
                                setProfileLoading={setProfileLoading}
                                setSureModal={setSureModal}
                                sureModal={sureModal}
                                title="Permissions"
                                count={permissions?.length}
                                sliceEnd={permissions?.length}
                                loading={loading}
                                setLoading={setLoading}
                                handleConfirm={handleConfirm}
                                data={permissions?.slice(
                                    (currentPage - 1) * itemsPerPage,
                                    currentPage * itemsPerPage
                                )}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                setData={setPermissions}
                                type="Permissions"
                                orgData={orgData}
                                fetchAllPermisisons={fetchAllPermisisons}
                                tableHeader={permissionsTableHeader}
                            />
                        </>
                    )}
                </div>
                <SuccessModal
                    status={successStatus}
                    open={successOpen}
                    setOpen={setSuccessOpen}
                    title={
                        successStatus
                            ? `Permission deleted successfully!`
                            : "Something went wrong..."
                    }
                />
            </Grid>
        </>
    );
}
