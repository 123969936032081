import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid, TextField } from "@mui/material";
import {
  useDatabaseConstants,
  gender_options,
} from "../../Constants/globalConstants";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
import { Dropdown } from "primereact/dropdown";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import OtpComponent from "./OtpComponent";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { post_data } from "../../Api";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { login } from "../../redux/slices/user-slice";
import dayjs from "dayjs";

export default function SignupSignModal({ modalOpen, setModalOpen }) {

  const dispatch = useDispatch();
  const { app_color, app_second_color } = useDatabaseConstants();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [otpForm, setOtpForm] = useState(false);
  const [registerForm, setRegisterForm] = useState(true);
  const [loginForm, setLoginForm] = useState(false);
  const [otpPin, setOtpPin] = useState("");
  const [otpType, setOtpType] = useState("");
  const [number, setNumber] = useState("");
  const [numberError, setNumberError] = useState("");
  const [emailLoginForm, setEmailLoginForm] = useState(false);
  const theme = useTheme();
  const matches_sm = useMediaQuery(theme.breakpoints.down("sm"));
  const matches_md = useMediaQuery(theme.breakpoints.down("md"));

  const [tempOtp, setTempOtp] = useState("");
  const currentDate = dayjs();

  const style = {
    position: "absolute",
    display: matches_md ? '' : "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches_md ? "90%" : otpForm || loginForm || emailLoginForm ? 600 : 800,
    // bgcolor: 'white',
    overflowY: 'auto',
    maxHeight: '95%',
    bgcolor: app_second_color,
    borderRadius: 4,
    border: "none",
    boxShadow: 24,
    p: matches_md ? "6% 3%" : 4,
  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    dob: "",
    contact_number: "",
    password: "",
    gender: "",
  });

  const [formData2, setFormData2] = useState({
    email: "",
    password: "",
  });

  const [invalid, setInvalid] = useState({
    first_name: false,
    last_name: false,
    dob: false,
    contact_number: false,
    gender: false,
    password: false,
  });

  const [invalid2, setInvalid2] = useState({
    email: false,
    password: false,
  });

  const handleClose = () => {
    setModalOpen(false);
    setOtpForm(false);
    setLoading(false);
    setLoading2(false);
    setRegisterForm(true);
    setLoginForm(false);
    setEmailLoginForm(false);
  };

  // const handleArrow = () => {
  //     setOtpForm(false);
  //     setLoading(false)
  //     setLoading2(false)
  //     setRegisterForm(true)
  //     setLoginForm(false)
  // }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name === "contact_number") {
      if (!/^\d*$/.test(value)) {
        return;
      }
      if (parseInt(value) < 0) {
        return;
      }
    }
    setFormData({ ...formData, [name]: value });
    setInvalid({ ...invalid, [name]: false });
  };

  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    setFormData2({ ...formData2, [name]: value });
    setInvalid2({ ...invalid2, [name]: false });
  };

  const handleNext = (e) => {
    e?.preventDefault();

    let is_false = 0;
    let obj = {
      first_name: false,
      last_name: false,
      email: false,
      dob: false,
      contact_number: false,
      gender: false,
      password: false,
    };

    if (formData?.first_name === "") {
      obj.first_name = true;
      is_false = 1;
    }

    if (formData?.last_name === "") {
      obj.last_name = true;
      is_false = 1;
    }

    if (formData?.email === "") {
      obj.email = true;
      is_false = 1;
    }

    if (formData?.dob === "") {
      obj.dob = true;
      is_false = 1;
    }

    if (formData?.password === "") {
      obj.password = true;
      is_false = 1;
    }

    if (formData?.contact_number.length < 10) {
      obj.contact_number = true;
      is_false = 1;
    }

    if (formData?.gender === "") {
      obj.gender = true;
      is_false = 1;
    }

    if (is_false) {
      setInvalid(obj);
      return "";
    }

    setLoading(true);
    // setOtpType('register')

    setTimeout(() => {
      handleCreateAccount();
    }, 1000);
  };

  const handleCreateAccount = async () => {
    let payload = {
      first_name: formData?.first_name,
      last_name: formData?.last_name,
      email: formData?.email,
      contact_number: formData?.contact_number,
      password: formData?.password,
      basic_details: {
        gender: formData?.gender?.name,
        dob: formData?.dob,
      },
    };
    const response = await post_data("user/create-user", payload);
    if (response?.status) {
      setLoading(false);
      toast.success(response?.message);
      dispatch(login(response?.data?.user));
      localStorage.setItem("authToken", response?.data.token);
      navigate("/dashboard");
    } else {
      setLoading(false);
      toast.error(response?.message);
    }
  };

  const handleSelectChange = (e) => {
    setFormData({ ...formData, gender: e?.value });
    setInvalid({ ...invalid, gender: false });
  };

  const handleDob = (date) => {
    const parsedDate = dayjs(date);
    if (!parsedDate.isValid()) {
      // console.error('Invalid date');
      setInvalid({ ...invalid, dob: true });
      return;
    }
    let dateOfBirth = parsedDate?.$d.toISOString();
    setFormData({ ...formData, dob: dateOfBirth });
    setInvalid({ ...invalid, dob: false });
  };

  const handleLoginUser = () => {
    if (number === "" || number.length < 10) {
      setNumberError(true);
      return;
    }

    setLoading3(true);

    setTimeout(async () => {
      let payload = { contact_number: number };
      const response = await post_data("user/user-login", payload);
      if (response?.status) {
        setLoading3(false);
        setLoginForm(false);
        setOtpForm(true);
        setTempOtp(response?.otp);
      } else {
        setLoading3(false);
        toast.error("User doesn't exist!");
      }
    }, 1000);
  };

  const form = () => {
    return (
      <>
        <div className="register-form">
          <Grid container spacing={3}>
            <Grid item md={4} style={{ width: "100%" }}>
              <label className="input-label color-label">First Name*</label>
              <input
                style={{ height: 42 }}
                className={`register-from-input ${invalid?.first_name ? "invalid-input" : ""
                  }`}
                placeholder="Enter Your First Name"
                type="text"
                name="first_name"
                value={formData?.first_name}
                onChange={handleInputChange}
              />
              {invalid?.first_name && (
                <p className="invalid-txt">Please Enter First name</p>
              )}
            </Grid>
            <Grid item md={4} style={{ width: "100%" }}>
              <label className="input-label color-label">Last Name</label>
              <input
                style={{ height: 42 }}
                className={`register-from-input ${invalid?.last_name ? "invalid-input" : ""
                  }`}
                placeholder="Enter Your Last Name"
                type="text"
                name="last_name"
                value={formData?.last_name}
                onChange={handleInputChange}
              />
              {invalid?.last_name && (
                <p className="invalid-txt">Please Enter last name</p>
              )}
            </Grid>

            <Grid item md={4} style={{ width: "100%" }}>
              <label className="input-label color-label">Email*</label>
              <input
                style={{ height: 42 }}
                className={`register-from-input ${invalid?.email ? "invalid-input" : ""
                  }`}
                placeholder="Enter Your Email"
                type="email"
                name="email"
                value={formData?.email}
                onChange={handleInputChange}
              />
              {invalid?.email && (
                <p className="invalid-txt">
                  Please Enter a valid email address
                </p>
              )}
            </Grid>

            <Grid
              item
              md={6}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label className="input-label color-label">Date of Birth</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={formData?.dob ? dayjs(formData?.dob) : null}
                  onChange={handleDob}
                  format="DD/MM/YYYY"
                  maxDate={currentDate}
                />
              </LocalizationProvider>
              {invalid?.dob && (
                <p className="invalid-txt">Please Enter Date of Birth</p>
              )}
            </Grid>
            <Grid item md={6} style={{ width: "100%" }}>
              <label className="input-label color-label">Phone*</label>
              <input
                style={{ height: 42 }}
                className={`register-from-input ${invalid?.contact_number ? "invalid-input" : ""
                  }`}
                placeholder="Enter Your Phone Number"
                type="text"
                name="contact_number"
                pattern="[0-9]*"
                minLength={10}
                maxLength={10}
                value={formData?.contact_number}
                onChange={handleInputChange}
              />
              {invalid?.contact_number && (
                <p className="invalid-txt">Please Enter Phone Number</p>
              )}
            </Grid>
            <Grid item md={6} style={{ width: "100%" }}>
              <label className="input-label color-label">Password*</label>
              <input
                style={{ height: 42 }}
                className={`register-from-input ${invalid?.password ? "invalid-input" : ""
                  }`}
                placeholder="Enter Your Phone Number"
                type="password"
                name="password"
                value={formData?.password}
                onChange={handleInputChange}
              />
              {invalid?.password && (
                <p className="invalid-txt">Please Enter Password</p>
              )}
            </Grid>
            <Grid item md={6} style={{ width: "100%" }}>
              <label className="input-label color-label">Gender</label>
              <div>
                <Dropdown
                  style={{ width: "98%", paddingLeft: "2%" }}
                  value={formData?.gender}
                  onChange={(e) => {
                    setFormData({ ...formData, gender: e.target.value });
                    setInvalid({ ...invalid, gender: false });
                  }}
                  options={gender_options}
                  optionLabel="name"
                  placeholder="Gender"
                  className="global-select"
                />
              </div>
              {invalid?.gender && (
                <p className="invalid-txt">Please Select Gender</p>
              )}
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  const handleNumberChange = (e) => {
    if (!/^\d*$/.test(e.target.value)) {
      return;
    }

    if (parseInt(e.target.value) < 0) {
      return;
    }
    setNumber(e.target.value);
    setNumberError(false);
  };

  const handleEmailLogin = (e) => {
    e?.preventDefault();

    let is_false = 0;
    let obj = {
      email: false,
      password: false,
    };

    if (formData2?.email === "") {
      obj.email = true;
      is_false = 1;
    }

    if (formData2?.password === "") {
      obj.password = true;
      is_false = 1;
    }

    if (is_false) {
      setInvalid2(obj);
      return "";
    }

    setLoading4(true);

    setTimeout(async () => {
      const response = await post_data("user/user-verify-by-email", { ...formData2, device_token: '', device_type: '' });
      if (response?.status) {
        setLoading4(false);
        dispatch(login(response?.data?.user));
        localStorage?.setItem("authToken", response?.data.token);
        setTimeout(() => {
          navigate("/dashboard");
        }, 1200);
      } else {
        setLoading4(false);
        toast.error("Something went wrong!");
      }
    }, 1000);
  };

  const emailLoginFormComponent = () => {
    return (
      <>
        <div style={{ margin: "7% 0 4%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <label className="input-label color-label">Email*</label>
              <input
                style={{ height: 55, width: "100%" }}
                className={`register-from-input ${invalid2?.email ? "invalid-input" : ""
                  }`}
                placeholder="Enter Your Email"
                type="text"
                name="email"
                value={formData2?.email}
                onKeyPress={(event) => handleEnter(event)}
                onChange={(e) => handleInputChange2(e)}
              />
              {invalid2?.email && (
                <p className="invalid-txt">Please Enter Mobile number</p>
              )}
            </Grid>
            <Grid item xs={12}>
              <label className="input-label color-label">Password*</label>
              <input
                style={{ height: 55, width: "100%" }}
                className={`register-from-input ${invalid2?.password ? "invalid-input" : ""
                  }`}
                placeholder="Enter Password"
                type="text"
                name="password"
                value={formData2?.password}
                onKeyPress={(event) => handleEnter(event)}
                onChange={(e) => handleInputChange2(e)}
              />
              {invalid2?.password && (
                <p className="invalid-txt">Please Enter Password</p>
              )}
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  const loginFormComponent = () => {
    return (
      <>
        <div style={{ margin: "7% 0 4%" }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <label className="input-label color-label">Mobile Number*</label>
              <input
                style={{ height: 55, width: "100%" }}
                className={`register-from-input ${numberError ? "invalid-input" : ""
                  }`}
                placeholder="Enter Your Mobile number"
                type="text"
                name="number"
                value={number}
                pattern="[0-9]*"
                minLength={10}
                maxLength={10}
                onKeyPress={(event) => handleEnter(event)}
                onChange={(e) => handleNumberChange(e)}
              />
              {numberError && (
                <p className="invalid-txt">Please Enter Mobile number</p>
              )}
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  const handleEnter = (event) => {
    if (event.key == "Enter") {
      handleLoginUser();
    }
  };

  const handleLoginClick = () => {
    setOtpType("login");
    setLoginForm(true);
    setRegisterForm(false);
    setOtpForm(false);
  };

  const handleSignupClick = () => {
    setLoginForm(false);
    setRegisterForm(true);
    setOtpForm(false);
    setEmailLoginForm(false);
  };

  return (
    <div>
      <Modal open={modalOpen} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            // width: otpForm ? "60%" : "1000px", // Adjust the width as needed
          }}
          className={`${otpForm ? "otp-modal" : "modal"}`}
        >
          <Toaster />
          <CloseIcon className="modal-close-btn" onClick={handleClose} />
          {otpForm && (
            <>
              <div sx={style}>
                {/* <img src='/images/arrow.svg' className="modal-arrow-btn" onClick={handleArrow} /> */}
                <div className="otp-form-inner-div">
                  <center>
                    <h2
                      className="global-h2"
                      style={{
                        fontSize: matches_md ? 25 : 30,
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      Verification Code
                    </h2>
                    <p
                      style={{ opacity: "50%", margin: "2% 0", padding: 0 }}
                      className="footer-txt"
                    >
                      We have sent the verification code to your email address
                    </p>
                  </center>

                  <div style={{ margin: "5% 0" }}>
                    <OtpComponent
                      tempOtp={tempOtp}
                      formData={formData}
                      number={number}
                      resendOtpFunc={loginForm ? handleLoginUser : emailLoginForm ? handleEmailLogin : ''}
                      type={otpType === "register" ? "register" : "login"}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {registerForm && (
            <>
              {matches_md ? (
                <></>
              ) : (
                <>
                  <center>
                    <h2
                      className="global-h2"
                      style={{ fontSize: 30, marginTop: 3, padding: 0 }}
                    >
                      Register Your Account
                    </h2>
                    <p
                      style={{ opacity: "50%", margin: "1% 0", padding: 0, fontSize: 16 }}
                      className="footer-txt"
                    >
                      Enter details as per your Aadhar
                    </p>
                  </center>
                </>
              )}

              <div style={{ marginTop: matches_md ? "5%" : "1%" }}>
                {/* <h3 style={{ fontWeight: 500, fontSize: 18 }}>Fill your details:</h3> */}
                {form()}
                <Button
                  disabled={loading}
                  onClick={handleNext}
                  fullWidth
                  className={loading ? "register-btn-disable" : "register-btn"}
                  style={{ marginTop: "3%" }}
                >
                  {loading ? "Creating..." : "Next"}
                </Button>
                <center>
                  <p className="otp-form-txt" style={{ marginTop: "1%" }}>
                    Already a Member?{" "}
                    <b style={{ cursor: "pointer" }} onClick={handleLoginClick}>
                      Login now
                    </b>
                  </p>
                </center>
              </div>
            </>
          )}

          {loginForm && (
            <>
              <div style={{ width: matches_md ? "100%" : "90%" }}>
                <center>
                  <h2
                    className="global-h2"
                    style={{ fontSize: 30, margin: 0, padding: 0 }}
                  >
                    Login to your Account
                  </h2>
                  <p
                    style={{ opacity: "50%", margin: "2% 0", padding: 0 }}
                    className="footer-txt"
                  >
                    Enter your personal details so we can run your OTP
                  </p>
                </center>

                <div style={{ marginTop: matches_md ? "10%" : "3%" }}>
                  {/* <h3 style={{ fontWeight: 500, fontSize: 18 }}>Fill your details:</h3> */}
                  {loginFormComponent()}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ opacity: "70%" }}>OR</p>
                    <h4
                      style={{
                        fontWeight: 500,
                        cursor: "pointer",
                        marginTop: "2%",
                        opacity: "70%",
                      }}
                      onClick={() => {
                        setLoginForm(false);
                        setEmailLoginForm(true);
                      }}
                    >
                      Login with Email & Password
                    </h4>
                  </div>
                  <Button
                    disabled={loading3}
                    onClick={handleLoginUser}
                    fullWidth
                    className={
                      loading3 ? "register-btn-disable" : "register-btn"
                    }
                    style={{ marginTop: "3%" }}
                  >
                    {loading3 ? "Logging in..." : "Login"}
                  </Button>
                  <center>
                    <p
                      className="otp-form-txt"
                      style={{ marginTop: "3%", cursor: "pointer" }}
                      onClick={handleSignupClick}
                    >
                      Create a new account? <b>Signup now</b>
                    </p>
                  </center>
                </div>
              </div>
            </>
          )}
          {emailLoginForm && (
            <div style={{ width: matches_md ? "100%" : "90%" }}>
              <center>
                <h2
                  className="global-h2"
                  style={{ fontSize: 30, margin: 0, padding: 0 }}
                >
                  Login to your Account
                </h2>
                <p
                  style={{ opacity: "50%", margin: "2% 0", padding: 0 }}
                  className="footer-txt"
                >
                  Enter your personal details so we can run your OTP
                </p>
              </center>

              <div style={{ marginTop: matches_md ? "10%" : "3%" }}>
                {emailLoginFormComponent()}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p style={{ opacity: "70%" }}>OR</p>
                  <h4
                    style={{
                      fontWeight: 500,
                      cursor: "pointer",
                      marginTop: "2%",
                      opacity: "70%",
                    }}
                    onClick={() => {
                      setLoginForm(true);
                      setEmailLoginForm(false);
                    }}
                  >
                    Login with Mobile number
                  </h4>
                </div>
                <center>
                  <Link
                    target="_blank"
                    to={"/user/forget-password"}
                    style={{ color: app_color, textDecoration: "underline" }}
                  >
                    Forget Password
                  </Link>
                </center>
                <Button
                  disabled={loading4}
                  onClick={handleEmailLogin}
                  fullWidth
                  className={loading4 ? "register-btn-disable" : "register-btn"}
                  style={{ marginTop: "3%" }}
                >
                  {loading4 ? "Logging in..." : "Login"}
                </Button>
                <center>
                  <p
                    className="otp-form-txt"
                    style={{ marginTop: "3%", cursor: "pointer" }}
                    onClick={handleSignupClick}
                  >
                    Create a new account? <b>Signup now</b>
                  </p>
                </center>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
