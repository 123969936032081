import { Button } from "@mui/material";
import { useDatabaseConstants } from "../../Constants/globalConstants";
import { useState } from 'react';
import { button_left_style } from "../../Constants/globalConstants";

export default function Coupon({
    setCoupon,
    coupon,
    handleApplyCoupon
}) {

    const { app_color } = useDatabaseConstants()

    const handleCheckCoupon = () => {
        handleApplyCoupon()
    };

    return (
        <>
            <div>
                <input
                    value={coupon}
                    style={{ height: 42, borderRadius: 5, width: '100%' }}
                    className='register-from-input'
                    type="text"
                    name='coupon'
                    placeholder="Enter coupon code"
                    onChange={(e) => setCoupon(e?.target?.value)}
                />
                <Button
                    variant='contained'
                    style={{ ...button_left_style, width: '100%', margin: '3% 0' }}
                    onClick={handleCheckCoupon}>
                    Apply
                </Button>
            </div>
        </>
    )
}