import React, { useState } from 'react';
import { Grid, Paper, Typography, TextField, Button, Checkbox, Link } from '@mui/material';
import { button_right_style, useDatabaseConstants, disabled_btn, button_left_style } from '../../../Constants/globalConstants';
import { post_data } from '../../../Api';
import toast, { Toaster } from 'react-hot-toast';
import OtpComponent from '../../../Website/Components/OtpComponent';

const AdminLogin = () => {

    const { app_color } = useDatabaseConstants()
    const [loading, setLoading] = useState(false)
    const [otpForm, setOtpForm] = useState(false)
    const [loginForm, setLoginForm] = useState(true)

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    })

    const [invalid, setInvalid] = useState({
        email: false,
        password: false,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setInvalid({ ...invalid, [name]: false })
    };

    const handleLogin = (e) => {
        e?.preventDefault();

        let is_false = 0;
        let obj = {
            email: false,
            password: false,
        }

        if (formData?.email === '') {
            obj.email = true;
            is_false = 1;
        }

        if (formData?.password === '') {
            obj.password = true;
            is_false = 1;
        }

        if (is_false) {
            setInvalid(obj);
            return "";
        }

        setLoading(true)

        setTimeout(async () => {
            const response = await post_data('sub-admin/sub-admin-login', formData)
            if (response?.status) {
                setLoading(false)
                setOtpForm(true)
                setLoginForm(false)
            }
            else {
                setLoading(false)
                toast.error("Something went wrong!");
            }
        }, 1000);
    }

    return (
        <Grid container style={{ minHeight: '100vh' }}>
            <Toaster />
            {/* Left side */}
            <Grid item xs={12} md={6} container justifyContent="center" alignItems="center">
                <Grid item xs={8} sm={8} md={6}>
                    {
                        loginForm && (
                            <>
                                <div>
                                    <h3 className='global-h3' style={{ color: 'black' }}>Login to Sub Admin Dashboard</h3>
                                    <p className='about-para'>Enter your credentials to access your account</p>

                                    <Grid container spacing={0} style={{ margin: '10% 0 5%' }}>
                                        <Grid item md={12} style={{ width: '100%' }}>
                                            <label className="input-label color-label">Email*</label>
                                            <input
                                                style={{ height: 42 }}
                                                className={`register-from-input ${invalid?.email ? 'invalid-input' : ''}`}
                                                placeholder="Enter Your Email"
                                                type="text"
                                                name="email"
                                                value={formData?.email}
                                                onChange={handleInputChange}
                                            />
                                            {
                                                invalid?.email && (
                                                    <p className="invalid-txt">Please enter Email</p>
                                                )
                                            }
                                        </Grid>
                                        <Grid item md={12} style={{ width: '100%', marginTop: '5%' }}>
                                            <label className="input-label color-label">Password*</label>
                                            <input
                                                style={{ height: 42 }}
                                                className={`register-from-input ${invalid?.password ? 'invalid-input' : ''}`}
                                                placeholder="Enter Your Password"
                                                type="password"
                                                name="password"
                                                value={formData?.password}
                                                onChange={handleInputChange}
                                            />
                                            {
                                                invalid?.password && (
                                                    <p className="invalid-txt">Please enter Password</p>
                                                )
                                            }
                                        </Grid>
                                    </Grid>

                                    <Typography variant="body2" align="right">
                                        <Link href="#" underline="hover" style={{ color: app_color }}>Forgot password?</Link>
                                    </Typography>
                                    <Button
                                        onClick={handleLogin}
                                        variant="contained" fullWidth
                                        style={loading ? { ...button_right_style, ...disabled_btn, width: '100%', marginTop: '5%' } : { ...button_right_style, width: '100%', marginTop: '5%' }}
                                    >
                                        {loading ? 'Logging in...' : 'Login'}
                                    </Button>
                                </div>
                            </>
                        )
                    }
                    {
                        otpForm && (
                            <>
                                <h3 className='global-h3' style={{ color: 'black' }}>Verify OTP</h3>
                                <p className='about-para'>Enter the 4-digit OTP, you have received at your mail.</p>
                                <OtpComponent
                                    formData={formData}
                                    resendOtpFunc={handleLogin}
                                    type={'sub-admin-login'}
                                />
                            </>
                        )
                    }
                </Grid>
            </Grid>
            {/* Right side */}
            <Grid
                className='modal'
                item
                xs={12}
                md={6}
                style={{
                    color: 'black',
                    padding: '5%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'left'
                }}
            >
                <img src={`/images/dilsey-logo.svg`} style={{ width: 180 }} />
                <Typography variant="h3" style={{ fontWeight: 500, marginBottom: '20px' }}>
                    The simplest way to manage Relations.
                </Typography>
                <Typography variant="body1" style={{ textAlign: 'left', marginBottom: '20px' }}>
                    Enter your credentials to access your account
                </Typography>
            </Grid>
        </Grid>
    );
};

export default AdminLogin;
