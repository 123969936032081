import React, { useCallback, useEffect, useState } from "react";
import { Button, Grid, TextField, InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { avatar_female, avatar_male, darkPrimaryColor, getAvatar, secondaryDarkColor, useDatabaseConstants } from "../../../Constants/globalConstants";
import { serverURLProfilePicture, socketServerUrl } from "../../../Api";
import { DarkModeContext } from "./DarkModeContext";

export default function ChatContacts({
  setSelectedUser,
  selectedUser,
  contactsList,
  noPlan
}) {
  const { isLoggedIn, user_data } = useSelector((state) => state.user);
  const [isActive, setIsActive] = useState(0);
  const { darkMode } = React.useContext(DarkModeContext);

  const chat_list_parent_div = {
    padding: "4%",
    border: darkMode ? 'none' : "1px solid #E4E6EF",
    display: "flex",
    flexDirection: "column",
    background: darkMode ? secondaryDarkColor : 'white',
    justifyContent: "left",
    alignItems: "start",
    height: "100%",
    overflowY: "auto",
  };

  const chat_list_div = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  };

  const user_chat = {
    display: "flex",
    gap: "4%",
    margin: "4% 0",
    cursor: "pointer",
    width: "100%",
    padding: "3%",
    borderRadius: 5,
    alignItems: "center",
  };

  const profile_pic = {
    borderRadius: "50%",
    height: 50,
    width: 50,
  };

  const user_name = {
    fontWeight: 400,
    fontSize: 15,
  };

  const user_last_msg = {
    fontWeight: 400,
    fontSize: 13,
  };

  const user_status = {
    color: "#99A1B7",
    fontWeight: 400,
    fontSize: 13,
  };

  const chat_info = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "auto",
    height: 20,
    width: 20,
  };

  const messages = {
    background: "#DBFFEB",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    color: "#22C86E",
    height: "100%",
    width: "100%",
    padding: "3%",
    fontSize: 11,
  };

  const searchBar = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            background: "white",
            height: 47,
            padding: "1.5% 3%",
            border: "1px solid #E4E6EF",
            alignItems: "center",
          }}
        >
          <TextField
            // onKeyPress={handleSearch}
            // onChange={(e) => setText(e.target.value)}
            hiddenLabel
            placeholder="Search by name or DS Id"
            variant="standard"
            size="small"
            fullWidth
            style={{ fontWeight: 600 }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon
                    style={{ color: "#A1A5B7", height: 30, width: 30 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </>
    );
  };

  const handleChatClick = (item, index) => {
    setSelectedUser(item);
    setIsActive(index);
  };

  const displayUserAvatar = (item) => {
    let getAvatar = user_data?.basic_details?.gender === 'Male' ? avatar_female : avatar_male
    let userPrivacy = item?.privacy?.profile_picture
    return (
      <>
        {
          userPrivacy ?
            <>
              <img style={profile_pic}
                src={`/images/${getAvatar}`}
              />
            </>
            :
            <>
              {
                item?.profile_picture ?
                  <>
                    {
                      noPlan ?
                        <>
                          <img style={profile_pic} className="blur-photos"
                            src={`${socketServerUrl}/images/profile-picture/${item?.profile_picture}`}
                          />
                        </>
                        :
                        <>
                          <img style={profile_pic}
                            src={`${socketServerUrl}/images/profile-picture/${item?.profile_picture}`}
                          />
                        </>
                    }
                  </>
                  :
                  <>
                    <img style={profile_pic}
                      src={`/images/${getAvatar}`}
                    />
                  </>
              }
            </>
        }
      </>
    )
  }

  const displayChatContacts = () => {
    return (
      <>
        <div style={chat_list_parent_div}>
          <div className="chat-contacts-div">
            <div style={chat_list_div}>
              {contactsList?.map((item, i) => {
                return (
                  <>
                    <div
                      style={{
                        ...user_chat,
                        background: darkMode ? (selectedUser?.user_id === item?.user_id ? darkPrimaryColor : "transparent") : (selectedUser?.user_id === item?.user_id ? "#E4E6EF" : "white")
                      }}
                      onClick={(e) => handleChatClick(item, i)}
                    >
                      <div>
                        {displayUserAvatar(item)}
                      </div>
                      <div>
                        <h3 style={user_name}>{item?.name}</h3>
                        {/* <p style={user_status}>{item?.last_message}</p> */}
                      </div>
                      <div style={chat_info}>
                        {/* <h3 style={user_last_msg}>{item?.last_msg}</h3> */}
                        {/* {item?.messages && ( */}
                        {/* <p style={messages}>{item?.count}</p> */}
                        {/* )} */}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div style={{ background: "white", height: "100%" }}>
        {displayChatContacts()}
      </div>
    </>
  );
}
