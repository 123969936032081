import * as React from 'react';
import { Button, Grid, Typography } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import SignupSignModal from './SignupLoginModal';
import FunctionConstant from '../../Constants/functionConstant';
import SocialIcons from "./SocialIcons"
import Cta from './Cta';

export default function Footer(props) {

  var navigate = useNavigate()
  const [dense, setDense] = React.useState(false);
  const theme = useTheme();
  const matches_md = useMediaQuery(theme.breakpoints.down('md'));
  const [modalOpen, setModalOpen] = React.useState(false);
  const { handleBtnClick } = FunctionConstant({ setModalOpen })

  const legal = [
    {
      title: 'Privacy Policy',
      link: '/privacy-policy'
    },
    {
      title: 'Terms and Conditions',
      link: '/terms-and-conditions'
    },
    {
      title: 'Customer Support',
      link: '/contact'
    }
  ];


  const help = [
    {
      title: 'Contact us',
      link: '/contact'
    },
    {
      title: 'FAQ',
      link: '/faq'
    }
  ];

  const services = [
    {
      title: 'Profile Verification',
    },
    {
      title: 'Background Verification',
    },
    {
      title: 'Perfect Matches',
    },
  ];


  const explore = [
    {
      title: 'Home',
      link: '/'
    },
    {
      title: 'About us',
      link: '/about'
    },
    {
      title: 'Membership Plans',
      link: '/pricing'
    }
  ];

  const handleItemClick = (item) => {
    if (item?.title === 'Profile Verification' || item?.title === 'Background Verification') {
      return
    }
    navigate(item?.link)
    window.scrollTo(0, 0)
  }

  const handleLogoclick = () => {
    navigate('/')
    window.scrollTo(0, 0);
  }

  return (
    <div>
      <div className='footer-section'>
        <Cta />

        <hr style={{ opacity: '50%', margin: '3% 0' }} />

        <Grid container spacing={1}>
          <Grid item md={4} style={{ width: '50%' }}>
            <img src={`/images/dilsey-logo.svg`} style={{ width: matches_md ? '70%' : '40%', cursor: 'pointer' }} onClick={handleLogoclick} />
            <p className='footer-txt' style={{ width: matches_md ? '100%' : '70%', marginTop: '5%' }}>
              Your partner in finding Perfect Life Partner for your Life.
            </p>
            <div style={{ margin: '4% 0 0' }}><SocialIcons variant='white' /></div>
          </Grid>

          <Grid item md={2} style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
            <div>
              <Typography style={{ fontWeight: 600 }}>
                Explore
              </Typography>
              <List dense={dense} style={{ marginTop: '2%' }}>
                {
                  explore?.map((item) => {
                    return (
                      <ListItem style={{ paddingLeft: 0, padding: '0', cursor: 'pointer' }}>
                        <ListItemText onClick={() => handleItemClick(item)}><span className='footer-txt'>{item?.title}</span></ListItemText>
                      </ListItem>
                    )
                  })
                }
              </List>
            </div>
          </Grid>

          <Grid item md={2} style={{ width: '30%', marginTop: matches_md ? '5%' : 0 }}>
            <div>
              <Typography style={{ fontWeight: 600 }}>
                Services & Features
              </Typography>
              <List dense={dense} style={{ marginTop: '2%' }}>
                {
                  services?.map((item) => {
                    return (
                      <ListItem style={{ paddingLeft: 0, padding: '0' }}>
                        <ListItemText><span className='footer-txt'>{item?.title}</span></ListItemText>
                      </ListItem>
                    )
                  })
                }
              </List>
            </div>
          </Grid>

          <Grid item md={2} style={{ width: '30%', marginTop: matches_md ? '5%' : 0 }}>
            <div>
              <Typography style={{ fontWeight: 600 }}>
                Help
              </Typography>
              <List dense={dense} style={{ marginTop: '2%' }}>
                {
                  help.map((item) => {
                    return (
                      <ListItem style={{ paddingLeft: 0, padding: '0', cursor: 'pointer' }}>
                        <ListItemText onClick={() => handleItemClick(item)}><span className='footer-txt'>{item?.title}</span></ListItemText>
                      </ListItem>
                    )
                  })
                }
              </List></div>
          </Grid>


          <Grid item md={2} style={{ width: '30%', marginTop: matches_md ? '5%' : 0 }}>
            <div>
              <Typography style={{ fontWeight: 600 }}>
                Legal
              </Typography>
              <List dense={dense} style={{ marginTop: '2%' }}>
                {
                  legal.map((item) => {
                    return (
                      <ListItem style={{ paddingLeft: 0, padding: '0', cursor: 'pointer' }}>
                        <ListItemText onClick={() => handleItemClick(item)}><span className='footer-txt'>{item?.title}</span></ListItemText>
                      </ListItem>
                    )
                  })
                }
              </List>
            </div>
          </Grid>
        </Grid>

        {/* <hr style={{ opacity: '30%', margin: '3% 0' }} /> */}

        <SignupSignModal modalOpen={modalOpen} setModalOpen={setModalOpen} />

      </div>
    </div>
  )
}