import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Website/Pages/Home";
import Dashboard from "./WebApp/UserDshboard/Pages/Dashboard";
import LogoLoading from "./Constants/LogoLoading";
import { get_data } from "./Api";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "./redux/slices/user-slice";
import About from "./Website/Pages/About";
import Pricing from "./Website/Pages/Pricing";
import Contact from "./Website/Pages/Contact";
import AdminDashboard from "./WebApp/Admin/Pages/AdminDashboard";
import Checkout from "./Website/Pages/Checkout";
import PaymentSuccess from "./Website/Pages/PaymentSuccess";
import AdminLogin from "./WebApp/Admin/Pages/AdminLogin";
import ForgetPassword from "./WebApp/UserDshboard/Components/ForgetPassword";
import ResetPassword from "./WebApp/UserDshboard/Components/ResetPassword";
import PermissionDenied from "./WebApp/Admin/Components/PermissionDenied";
import SuperAdminLogin from "./WebApp/Admin/Components/SuperAdminLogin";
import SubAdminForgetPassword from "./WebApp/Admin/Components/SubAdminForgetPassword";
import SubAdminResetPassword from "./WebApp/Admin/Components/SubAdminResetPassword";
import SuperAdminForgetPassword from "./WebApp/Admin/Components/SuperAdminForgetPassword";
import SuperAdminResetPassword from "./WebApp/Admin/Components/SuperAdmiResetPassword";
import ErrorPage from "./Website/Components/ErrorPage";
import InvoicePdf from "./WebApp/UserDshboard/Components/InvoicePdf";
import { DarkModeContext, DarkModeProvider } from "./WebApp/UserDshboard/Components/DarkModeContext";
import BioData from "./WebApp/UserDshboard/Components/BioData";
import PrivacyPolicy from "./Website/Pages/PrivacyPolicy";
import Terms from "./Website/Pages/Terms";
import FaqPage from "./Website/Pages/FaqPage";

function App() {
  return (
    <DarkModeProvider>
      <AppContent />
    </DarkModeProvider>
  );
}

function AppContent() {

  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(state => state.user);
  const [loading, setLoading] = useState(true)
  const { user_data } = useSelector((state) => state.user);

  const get_user = async () => {
    const current_data = await get_data("user/get-user");
    // console.log({ current_data });
    if (current_data?.status) {
      dispatch(login(current_data?.data));

    } else {
      // console.log("error: ", current_data?.message);
    }
  };

  useEffect(() => {
    get_user();

    setTimeout(() => {
      setLoading(false)
    }, 5000);
  }, []);

  const { darkMode } = useContext(DarkModeContext);

  return (
    <div>
      {
        loading ?
          <>
            <LogoLoading />
          </>
          :
          <>
            <Router>
              <Routes>
                {
                  isLoggedIn ?
                    <>
                      <Route element={<Dashboard />} path="/dashboard/*" />
                    </>
                    :
                    <>
                      <Route element={<PermissionDenied
                        type='auth'
                        img='modal-img-login.svg'
                        title={'Login to view your Dashboard'} />} path="/dashboard/*" />
                    </>
                }

                <Route element={<Home />} path="/" />
                <Route element={<About />} path="/about" />
                <Route element={<Contact />} path="/contact" />
                <Route element={<Pricing />} path="/pricing" />
                <Route element={<Checkout />} path="/checkout" />
                <Route element={<PaymentSuccess />} path="/payment-successfull" />

                {
                  isLoggedIn && !user_data?.hasOwnProperty('dil_sey_id') ?
                    <>
                      <Route element={<AdminDashboard />} path="/admin/dashboard/*" />
                    </>
                    :
                    <>
                      <Route element={<PermissionDenied
                        type='auth'
                        img='modal-img-login.svg'
                        title={'Login to view your Dashboard'} />} path="/admin/dashboard/*" />
                    </>
                }

                <Route element={<ForgetPassword />} path="/user/forget-password" />
                <Route element={<ResetPassword />} path="/user/reset-password/:token" />

                <Route element={<SubAdminForgetPassword />} path="/sub-admin/forget-password" />
                <Route element={<SubAdminResetPassword />} path="/sub-admin/reset-password/:token" />

                <Route element={<AdminLogin />} path="/sub-admin/login" />
                <Route element={<SuperAdminLogin />} path="/super-admin/login" />

                <Route element={<SuperAdminForgetPassword />} path="/super-admin/forget-password" />
                <Route element={<SuperAdminResetPassword />} path="/super-admin/reset-password/:token" />

                <Route path="*" element={<ErrorPage />} />

                <Route element={<InvoicePdf />} path="/invoice" />

                <Route element={<PrivacyPolicy />} path="/privacy-policy" />
                <Route element={<Terms />} path="/terms-and-conditions" />
                <Route element={<FaqPage />} path="/faq" />

              </Routes>
            </Router>
          </>
      }
    </div>
  );
}

export default App;
