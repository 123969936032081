import React from "react"

export default function PermissionDenied({
    title,
    img,
    type
}) {

    const empty_page_div = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2% 0',
        height: type ? '100vh' : '100%',
        flexDirection: 'column'
    }

    return (
        <>
            <div style={empty_page_div}>
                <img src={img ? `/images/${img}` : '/images/security-image.svg'} style={{ width: 400 }} />
                <h3 className="global-h3" style={{ margin: 0, color: 'black', fontSize: 23 }}>{title}</h3>
            </div>
        </>
    )
}