import React, { useRef, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Grid, Toolbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';

export default function MenuComponents(props) {

    var navigate = useNavigate()
    const theme = useTheme()
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))
    const matches_md = useMediaQuery(theme.breakpoints.down('md'))

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const servicesContent = () => {
        const servicesIcons = [
            {
                title: 'Muslim'
            },
            {
                title: 'Hindu'
            },
            {
                title: 'Christian'
            },
            {
                title: 'Sikh'
            },
            {
                title: 'Buddhist'
            },
            {
                title: 'Jain'
            },



            {
                title: 'Hindi'
            },
            {
                title: 'English'
            },
            {
                title: 'Urdu'
            },
            {
                title: 'Marathi'
            },
            {
                title: 'Tamil'
            },
            {
                title: 'Telagu'
            },
        ]
        return (
            <div>
                <Grid container spacing={1} style={{ padding: '3% 10%' }}>
                    <div onClick={handleClose} style={{
                        position: 'absolute',
                        right: '3%',
                        top: '3%',
                        cursor: 'pointer',
                    }}
                    >
                        <img src='/images/modal-close-icon.svg' style={{ width: '100%', height: '100%' }} />
                    </div>
                    <Grid item md={4} style={{ padding: '1%' }}>
                        <h3 style={{ fontWeight: 600, fontSize: 18, margin: 0, marginBottom: '1%' }}>Religion</h3>
                        {
                            servicesIcons.slice(0, 6).map((item, i) => {
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center', margin: '6% 0' }}>
                                        <p onClick={() => handleMenuItemClick(item)} style={{ fontWeight: 500, color: 'black', cursor: 'pointer' }}>{item.title}</p>
                                    </div>
                                )
                            })
                        }

                    </Grid>
                    <Grid item md={4} style={{ padding: '1%' }}>
                        <h3 style={{ fontWeight: 600, fontSize: 18, margin: 0, marginBottom: '1%' }}>Languages</h3>
                        {
                            servicesIcons.slice(6).map((item, i) => {
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center', margin: '6% 0' }}>
                                        <p onClick={() => handleMenuItemClick(item)} style={{ fontWeight: 500, color: 'black', cursor: 'pointer' }}>{item.title}</p>
                                    </div>
                                )
                            })
                        }
                    </Grid>
                    <Grid item md={4} style={{ display: 'flex', alignItems: 'center', justifyItems: 'left', padding: '4%' }}>
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                <h4 style={{ fontWeight: 500, fontSize: 20 }}>
                                    Ready to connect 'DilSey'
                                </h4>
                            </Grid>
                            <Grid item md={12}>
                                <Button variant='contained' className='globalButton' style={{
                                    background: 'linear-gradient(to right, blue, #8000ff)',
                                    color: 'white',
                                    padding: '4% 8%',
                                    fontWeight: 500,
                                    marginTop: '2%',
                                    border: 'none',
                                    fontFamily: 'Inter'
                                }}>Find your Match</Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </div>
        )
    }

    const handleMenuItemClick = (item) => {
        navigate(item.link)
        window.scrollTo(item.screenX, item.scrollY);
    }

    const menuItems = [
        {
            title: 'Browse',
            content: servicesContent(),
        }
    ]

    const showMenuItems = () => {
        return (
            menuItems.map((item, index) => {
                return (
                    <div>
                        <Button
                            key={index}
                            style={{
                                color: props.color,
                                fontWeight: 500,
                                fontFamily: 'Inter',
                                fontSize: 17,
                                textTransform: 'capitalize',
                                padding: '1% 0',
                            }}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={(event) => handleClick(event, item)}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p style={{ fontSize: 17, margin: 0, cursor: 'pointer' }} onClick={() => navigate(item.link)}>{item.title}</p>
                                {
                                    item.content ? <KeyboardArrowDownIcon /> : <></>
                                }
                            </div>
                        </Button>
                    </div>
                )
            })
        );
    }

    const handleClick = (event, item) => {
        setAnchorEl(event.currentTarget);
        setSelectedItem(item);
    };

    const staticMenuItems = [
        {
            title: 'Home',
            link: '/'
        },
        {
            title: 'Pricing',
            link: '/pricing'
        },
        {
            title: 'Contact',
            link: '/contact'
        },
        {
            title: 'About us',
            link: '/about'
        },
    ]

    const showStaticMenuItems = () => {
        return staticMenuItems.map((item, i) => {
            return (
                <div style={{ display: 'flex', gap: '3%' }}>
                    <Button
                        style={{
                            color: props.color,
                            fontWeight: 500,
                            fontFamily: 'Inter',
                            fontSize: 17,
                            textTransform: 'capitalize',
                            padding: '1% 0',
                        }}>
                        <div style={{ textDecoration: 'none', color: props.color }} onClick={() => navigate(item?.link)}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p style={{ fontSize: 17, margin: 0 }}>{item?.title}</p>
                            </div>
                        </div>
                    </Button>
                </div>
            )
        })
    }

    return (
        <div style={{ position: "sticky", top: '11%', zIndex: 999, width: '100%' }} >
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    {matches_md ? <></> :
                        <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none' }}>
                            <Toolbar style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: '5%' }}>
                                {showStaticMenuItems()}
                                <Menu
                                    PaperProps={{
                                        style: {
                                            width: '70%',
                                            boxShadow: props.shadow,
                                            borderRadius: 15,
                                            // border: props.border,
                                            background: selectedItem && selectedItem.content ? 'white' : 'transparent',
                                            position: 'relative',
                                            transform: 'translateX(-20%) translateY(2%)',
                                        },
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                                >
                                    {selectedItem ? selectedItem.content : <>:</>}
                                </Menu>
                            </Toolbar>
                        </AppBar>
                    }
                </Grid>
            </Grid>
        </div>
    );
}