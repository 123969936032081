import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

export default function ProfilePictureModal({
    image,
    setOpen,
    open
}) {

    console.log({ image });
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 400,
        height: 400,
        borderRadius: 2,
        bgcolor: 'background.paper',
        boxShadow: 24,
    };

    const pic_div = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }

    const image_styles = {
        borderRadius: 10,
        height: 400,
        width: 400
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}
                    className='modal'
                >
                    <div onClick={handleClose} style={{
                        position: 'absolute',
                        right: '3%',
                        top: '3%',
                        cursor: 'pointer',
                    }}
                    >
                        <img src='/images/modal-close-icon.svg' style={{ width: '100%', height: '100%' }} />
                    </div>
                    <div style={pic_div}>
                        <img src={image} style={image_styles} />
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
