import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import { button_left_style, button_right_style, getFieldName, disabled_btn } from '../../../Constants/globalConstants';
import { post_data } from '../../../Api';
import CustomToggle from '../../UserDshboard/Components/CustomToggle';

export default function NewPlanPermissionModal({
    open,
    setOpen,
    fetchPlansPermissions,
    setSuccessOpen,
    setSuccessStatus
}) {

    const [formData, setFormData] = React.useState({})
    const [loading, setLoading] = React.useState(false)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        overflowY: 'auto',
        bgcolor: 'white',
        boxShadow: 24,
        padding: '2%',
        borderRadius: 4
    };

    const modal_title = {
        textAlign: 'center',
        width: '80%',
        fontSize: 23,
        fontWeight: 600,
    }

    const input_style = {
        margin: '3% 0'
    }

    const handleClose = () => setOpen(false);

    const addItems = [
        {
            field: 'Permission name',
            type: 'text'
        }
    ]

    const text_fields = addItems?.filter(subItem => subItem?.type === 'text');

    const textFields = () => {
        return text_fields.map((field, i) => {
            let field_name = getFieldName(field?.field)
            return (
                <>
                    <Grid item md={9} style={{ margin: '1% 0' }}>
                        <div style={input_style}>
                            <label className="input-label color-label">
                                {field?.field}
                            </label>
                            <input
                                value={formData?.[field_name]}
                                style={{ height: 42 }}
                                // className={`register-from-input ${invalid?.first_name ? 'invalid-input' : ''}`}
                                className='register-from-input'
                                type="text"
                                name={getFieldName(field?.field)}
                                onChange={(e) => setFormData({ ...formData, [field_name]: e?.target?.value })}
                            />
                        </div>
                    </Grid>
                </>
            )
        })
    }

    const boolean = () => {
        return (
            <>
                <Grid item md={3} style={{ margin: '1% 0' }}>
                    <h3 className='global-h3' style={{ fontSize: 15, fontWeight: 500, marginRight: '5%' }}>Active</h3>
                    <CustomToggle
                        obj="is_active"
                        field={'active'}
                        payload={formData}
                        setPayload={setFormData}
                    />
                </Grid>
            </>
        )
    }

    const handleSubmit = () => {
        setLoading(true)
        setTimeout(async () => {
            let payload = {
                permission_name: formData?.permission_name,
                is_active: formData?.is_active?.active
            }
            const response = await post_data(`permission/create-permission`, payload)
            if (response?.status) {
                setOpen(false)
                setLoading(false)
                setSuccessOpen(true)
                setSuccessStatus(true)
                fetchPlansPermissions()
            }
            else {
                setOpen(false)
                setLoading(false)
                setSuccessOpen(true)
                setSuccessStatus(false)
            }
        }, 1000);
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}
                >
                    <div onClick={handleClose}
                        style={{
                            position: 'absolute',
                            right: '3%',
                            top: '3%',
                            cursor: 'pointer',
                        }}
                    >
                        <img src='/images/modal-close-icon.svg' style={{ width: '100%', height: '100%' }} />
                    </div>

                    <Grid container spacing={0}>
                        <Grid item md={12} style={{ marginBottom: '5%', display: 'flex', justifyContent: 'center' }}>
                            <h2 style={modal_title}>Add Permission</h2>
                        </Grid>
                    </Grid>

                    <Grid container spacing={0} style={{ display: 'flex', alignItems: 'center' }}>
                        {textFields()}
                        {boolean()}
                    </Grid>

                    <Grid item md={8} style={{
                        width: '60%',
                        display: 'flex',
                        gap: '4%',
                        margin: '3% auto 0 auto',
                        // marginTop: '8%'
                    }}>
                        <Button onClick={handleClose} variant='contained' style={button_left_style}>
                            Close
                        </Button>

                        <Button
                            onClick={(e) => handleSubmit(e)} disabled={loading} variant='contained'
                            style={loading ? { ...button_right_style, ...disabled_btn } : button_right_style}
                        >{loading ? 'Saving...' : 'Save'}
                        </Button>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
