import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  edit_div,
  getFieldName,
  useDatabaseConstants,
  button_right_style,
  button_left_style,
  disabled_btn,
  secondaryDarkColor,
  darkPrimaryColor,
} from "../../../Constants/globalConstants";
import { post_data } from "../../../Api";
import SuccessModal from "./SuccessModal";
import SettingsModal from "./SettingsModal";
import { Link } from "react-router-dom";
import { DarkModeContext } from "./DarkModeContext";
import CustomToggle from "./CustomToggle";
import CustomToggleWithoutProps from "./CustomToggleWithoutProps";

export default function SettingsComponent() {
  
  const { user_data } = useSelector((state) => state.user);
  // console.log({ user_data });
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    current_password: "",
    new_password: "",
  });
  const { darkMode } = React.useContext(DarkModeContext);
  const [successStatus, setSuccessStatus] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [contactNumber, setContactNumber] = useState("");
  const [email, setEmail] = useState("");
  const [otpForm, setOtpForm] = useState(false);
  const [settingsModalTitle, setSettingsModalTitle] = useState("");
  const [settingsInputValue, setSettingsInputValue] = useState("");
  const [otpType, setOtpType] = useState("");
  const [modalInputForm, setModalInputForm] = useState(true);
  const { app_color } = useDatabaseConstants();

  useEffect(() => {
    setContactNumber(user_data?.contact_number);
    setEmail(user_data?.email);
  }, [user_data]);

  const grid_box = {
    padding: "2%",
    borderRadius: 10,
    marginRight: "2%",
    background: darkMode ? secondaryDarkColor : "white",
    border: darkMode ? "none" : "2px solid #F1F1F2",
    boxShadow: "3px 3px 4px 0 #00000008",
    color: darkMode ? "white" : "black",
  };

  const details = [
    {
      field: "Current Password",
      value: formData?.current_password,
      type: "password",
    },
    {
      field: "New Password",
      value: formData?.new_password,
      type: "text",
    },
  ];

  const [invalid, setInvalid] = useState({
    current_password: false,
    new_password: false,
  });

  const handleInputChange2 = (e) => {
    setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
    setInvalid({ ...invalid, [e?.target?.name]: false });
  };

  const input_style = {
    margin: "3% 0",
    width: "100%",
  };

  const handleEnter = (event) => {
    if (event.key == "Enter") {
      handleChangePassword();
    }
  };

  const handleChangePassword = (e) => {
    e?.preventDefault();

    let is_false = 0;
    let obj = {
      current_password: false,
      new_password: false,
    };

    if (formData?.current_password === "") {
      obj.current_password = true;
      is_false = 1;
    }

    if (formData?.new_password === "") {
      obj.new_password = true;
      is_false = 1;
    }

    if (is_false) {
      setInvalid(obj);
      return "";
    }

    setLoading(true);

    setTimeout(() => {
      handleSubmit();
    }, 1000);
  };

  const handleSubmit = async () => {
    let payload = {
      email: user_data?.email,
      current_password: formData?.current_password,
      new_password: formData?.new_password,
    };
    const response = await post_data(`user/password-change-by-self`, payload);
    if (response?.status) {
      setFormData({ ...formData, current_password: "", new_password: "" });
      setLoading(false);
      setSuccessStatus(true);
      setSuccessOpen(true);
    } else {
      setFormData({ ...formData, current_password: "", new_password: "" });
      setLoading(false);
      setSuccessStatus(false);
      setSuccessOpen(true);
    }
  };

  const textFields = () => {
    return details?.map((field, i) => {
      let field_name = getFieldName(field?.field);
      return (
        <>
          <div
            style={{
              position: "relative",
              margin: "1% 0",
            }}
          >
            <div style={input_style}>
              <label className="input-label color-label">
                {field?.display_field || field?.field}
              </label>
              <input
                onKeyPress={(event) => handleEnter(event)}
                value={field?.value}
                type={field?.type}
                style={{
                  height: 42,
                  paddingLeft: "3%",
                  color: darkMode ? "white" : "black",
                  background: darkMode ? darkPrimaryColor : "white",
                  border: darkMode ? "none" : "",
                }}
                className={`register-from-input ${
                  invalid?.[field_name] ? "invalid-input" : ""
                }`}
                name={getFieldName(field?.field)}
                onChange={handleInputChange2}
              />
            </div>
            {invalid?.[field_name] && (
              <p className="invalid-txt">Please enter {field?.field}</p>
            )}
          </div>
        </>
      );
    });
  };

  const handleEdit = (title, value) => {
    setSettingsInputValue(getFieldName(value));
    setSettingsModalTitle(title);
    if (title === "Email") {
      if (user_data?.email === "") {
        setOtpType("new-user-email");
      } else {
        setOtpType("change-user-email");
      }
    } else {
      setOtpType("change-user-number");
    }
    setSettingsOpen(true);
  };

  return (
    <>
      <Grid container spacing={0}>
        <Grid item md={4} style={grid_box}>
          <div>
            <h3
              style={{
                color: darkMode ? "white" : "black",
                fontSize: 20,
                marginBottom: "7%",
              }}
              className="global-h3"
            >
              Login & Security
            </h3>

            <div
              style={{
                margin: "1% 0",
                position: "relative",
              }}
            >
              <div style={input_style}>
                <label className="input-label color-label">
                  Contact number
                </label>
                <input
                  onKeyPress={(event) => handleEnter(event)}
                  value={contactNumber}
                  type="text"
                  pattern="[0-9]*"
                  maxLength={10}
                  style={{
                    height: 42,
                    paddingLeft: "3%",
                    background: darkMode ? darkPrimaryColor : "white",
                    border: darkMode ? "none" : "",
                  }}
                  className="register-from-input"
                  name={"contact_number"}
                />
              </div>
              <div style={edit_div}>
                <img
                  src="/images/edit-icon.svg"
                  style={{ width: "80%", height: "80%" }}
                  onClick={(e) => handleEdit("Contact number", contactNumber)}
                />
              </div>
            </div>

            <div
              style={{
                margin: "1% 0",
                position: "relative",
              }}
            >
              <div style={input_style}>
                <label className="input-label color-label">Email address</label>
                <input
                  onKeyPress={(event) => handleEnter(event)}
                  value={email}
                  type="text"
                  style={{
                    height: 42,
                    paddingLeft: "3%",
                    background: darkMode ? darkPrimaryColor : "white",
                    border: darkMode ? "none" : "",
                  }}
                  className="register-from-input"
                  name={"email"}
                />
              </div>
              <div style={edit_div}>
                <img
                  src="/images/edit-icon.svg"
                  style={{ width: "80%", height: "80%" }}
                  onClick={() => handleEdit("Email", email)}
                />
              </div>
            </div>

            <div
              style={{
                margin: "1% 0",
                marginTop: "7%",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label className="input-label color-label">
                Deactivate Account
              </label>
              <CustomToggleWithoutProps user_data={user_data} />
            </div>
          </div>
        </Grid>

        <Grid item md={4} style={grid_box}>
          <h3
            style={{
              color: darkMode ? "white" : "black",
              fontSize: 20,
              marginBottom: "7%",
            }}
            className="global-h3"
          >
            Change Password
          </h3>
          {textFields()}
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "60%",
                display: "flex",
                gap: "4%",
              }}
            >
              <Button
                onClick={handleChangePassword}
                disabled={loading}
                variant="contained"
                style={
                  loading
                    ? { ...button_right_style, ...disabled_btn }
                    : button_right_style
                }
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </div>
            <div style={{ marginRight: "auto" }}>
              <Link
                target="_blank"
                to={"/user/forget-password"}
                style={{ color: app_color, textDecoration: "underline" }}
              >
                Forget Password
              </Link>
            </div>
          </div>
        </Grid>
      </Grid>
      <SuccessModal
        status={successStatus}
        open={successOpen}
        setOpen={setSuccessOpen}
        title={
          successStatus ? "Updated successfully!" : "Something went wrong..."
        }
      />
      <SettingsModal
        otpType={otpType}
        otpForm={otpForm}
        setOtpForm={setOtpForm}
        modalInputForm={modalInputForm}
        setModalInputForm={setModalInputForm}
        successStatus={successStatus}
        setSuccessStatus={setSuccessStatus}
        successOpen={successOpen}
        setSuccessOpen={setSuccessOpen}
        inputValue={settingsInputValue}
        open={settingsOpen}
        setOpen={setSettingsOpen}
        title={settingsModalTitle}
      />
    </>
  );
}
