import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal, { modalClasses } from '@mui/material/Modal';
import { useState } from 'react';
import { Grid, colors } from '@mui/material';
import { button_left_style, button_right_style, getFieldName, useDatabaseConstants, disabled_btn, secondaryDarkColor } from '../../../Constants/globalConstants';
import OtpComponent from '../../../Website/Components/OtpComponent';
import { post_data } from '../../../Api';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { DarkModeContext } from './DarkModeContext';

export default function SettingsModal({
    open,
    setOpen,
    otpType,
    title,
    inputValue,
    setSuccessOpen,
    successOpen,
    setSuccessStatus,
    successStatus,
    otpForm,
    setOtpForm,
    modalInputForm,
    setModalInputForm
}) {

    const { darkMode } = React.useContext(DarkModeContext);
    const { app_color } = useDatabaseConstants()
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        new_email: '',
        contact_number: ''
    })
    const { user_data } = useSelector((state) => state.user);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: darkMode ? secondaryDarkColor : 'white',
        border: 'none',
        boxShadow: 24,
        borderRadius: 4,
        padding: '2%'
    };

    const modal_title = {
        marginTop: '4%',
        textAlign: 'center',
        width: '80%',
        fontSize: 24,
        fontWeight: 500,
        color: app_color
    }

    const input_style = {
        margin: '3% 0',
        width: '100%'
    }

    const handleInputChange = (e, type) => {
        if (type === 'contact_number') {
            if (!/^\d*$/.test(e.target.value)) {
                return;
            }

            if (parseInt(e.target.value) < 0) {
                return;
            }
            setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
        }
        else {
            setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
        }
    }


    const handleSaveDetails = () => {
        setLoading(true)
        setTimeout(async () => {

            if (title === 'Email') {

                if (user_data?.email === '') {  // for creating new email for new users
                    const response = await post_data(`user/create-email/${user_data?._id}`, { email: formData?.new_email })
                    if (response?.status) {
                        setModalInputForm(false)
                        setOtpForm(true)
                    }
                    else {
                        toast.error('Something went wrong.')
                    }
                }
                else {
                    const response = await post_data('user/send-update-email-otp', { email: user_data?.email, new_email: formData?.new_email })
                    if (response?.status) {
                        setModalInputForm(false)
                        setOtpForm(true)
                    }
                    else {
                        toast.error('Something went wrong.')
                    }
                }
            }
            else {
                // handle otp for mobile number
            }

        }, 1000);
    }

    const handleClose = () => {
        setLoading(false)
        setModalInputForm(true)
        setOtpForm(false)
        setOpen(false)
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Toaster />
                    <div onClick={handleClose} style={{
                        position: 'absolute',
                        right: '3%',
                        top: '3%',
                        cursor: 'pointer',
                    }}
                    >
                        <img src='/images/modal-close-icon.svg' style={{ width: '100%', height: '100%' }} />
                    </div>
                    <Grid container spacing={0}>

                        {
                            modalInputForm && (
                                <>
                                    <Grid item md={12} style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}>
                                        <h2 style={modal_title}>Edit {title}</h2>
                                    </Grid>

                                    <div style={input_style}>
                                        <label className="input-label color-label">
                                            {getFieldName(title) === 'contact_number' ? title : 'Enter new email'}
                                        </label>
                                        <input
                                            value={getFieldName(title) === 'contact_number' ? formData?.[getFieldName(title)] : formData?.new_email}
                                            type='text'
                                            style={{ height: 42, paddingLeft: '3%' }}
                                            className='register-from-input'
                                            name={getFieldName(title) === 'contact_number' ? getFieldName(title) : 'new_email'}
                                            pattern={getFieldName(title) === 'contact_number' ? "[0-9]*" : ''}
                                            maxLength={getFieldName(title) === 'contact_number' ? 10 : ''}
                                            onChange={(e) => handleInputChange(e, getFieldName(title))}
                                        />
                                    </div>
                                    <Grid item md={6} style={{
                                        width: '60%',
                                        display: 'flex',
                                        gap: '4%',
                                        margin: '3% auto 0 auto',
                                    }}>
                                        <Button onClick={handleClose} variant='contained' style={button_left_style}>
                                            Close
                                        </Button>

                                        <Button
                                            onClick={handleSaveDetails}
                                            disabled={loading || title === 'Email' ? formData?.new_email === '' : formData?.contact_number === ''} variant='contained'
                                            style={(loading || title === 'Email' ? formData?.new_email === '' : formData?.contact_number === '') ? { ...button_right_style, ...disabled_btn } : button_right_style}
                                        >
                                            {loading ? 'Saving...' : 'Save'}
                                        </Button>
                                    </Grid>
                                </>
                            )
                        }

                        {
                            otpForm && (
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                        <OtpComponent
                                            setOpen={setOpen}
                                            setOtpForm={setOtpForm}
                                            successStatus={successStatus}
                                            setSuccessStatus={setSuccessStatus}
                                            successOpen={successOpen}
                                            setSuccessOpen={setSuccessOpen}
                                            formData={formData}
                                            resendOtpFunc={handleSaveDetails}
                                            type={otpType}   // change-user-number or change-user-email or change-user-password
                                        />
                                    </div>
                                </>
                            )
                        }

                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
