import React, { useEffect, useState } from "react"
import { useDatabaseConstants } from "../../../Constants/globalConstants"
import { useNavigate } from "react-router-dom"

export default function StatisticsCard({
    item,
    type
}) {

    const navigate = useNavigate()

    const user_stats_card = {
        background: item?.bg,
        color: item?.color,
        borderRadius: 12,
        padding: '8%',
        display: 'flex',
        width: '100%',
        gap: '5%',
        boxShadow: '0 3px 4px 0 #00000008',
        cursor: 'pointer',
    }

    const title_styles = {
        fontWeight: 500,
        fontSize: 16
    }

    const number_styles = {
        color: item?.color,
        fontWeight: 600,
        fontSize: 19
    }

    const handleClick = (item) => {
        navigate(item?.link)
    }

    return (
        <>
            <div style={user_stats_card} onClick={() => handleClick(item)}>
                <div>
                    <img src={`/images/${item?.icon}.svg`} style={{ width: 40, height: 40 }} />
                </div>
                <div>
                    <p style={title_styles}>{item?.title}</p>
                    <p style={number_styles}>{item?.number}</p>
                    {
                        type === 'plans' && (
                            <p className="about-para" style={{ color: item?.color }}>Total users: {item?.total_users}</p>
                        )
                    }
                </div>
            </div >
        </>
    )
}