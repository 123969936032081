import { Button, Grid, useMediaQuery } from "@mui/material"
import React, { useState } from "react"
import SocialIcons from "./SocialIcons"
import { button_right_style, disabled_btn } from "../../Constants/globalConstants"
import { post_data } from "../../Api"
import DefaultAlert from "./../../WebApp/UserDshboard/Components/DefaultAlert"

export default function ContactForm() {

    const isDesktop = useMediaQuery('(min-width: 768px)');

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        message: ''
    })
    const [invalid, setInvalid] = useState({
        first_name: false,
        last_name: false,
        email: false,
        message: false
    })
    const [loading, setLoading] = useState(false)
    const [alertStatus, setAlertStatus] = useState(false)
    const [alertTitle, setAlertTitle] = useState(false)
    const [alertType, setAlertType] = useState(false)

    const contactInfo = [
        {
            img: 'location-icon',
            title: 'Office No.- TA 715, Capital Highstreet Bhiwadi, Distt- Alwar, Rajasthan(301019)'
        },
        {
            img: 'phone-icon',
            title: '+91-7023546471',
            title2: '+91- 6350569558'
        },
        {
            img: 'mail-icon',
            title: 'info@dilsey.com'
        },
    ]

    const contact_info_div = {
        display: 'flex',
        gap: '3%',
        justifyContent: 'left',
        alignItems: 'start',
        margin: '7% 0'
    }

    const title_styles = {
        fontWeight: 400,
        fontSize: 15,
        zIndex: 99
    }

    const handleInputChange = (e) => {
        const { name, value } = e?.target;
        if (e.target.name === 'contact_number') {
            if (!/^\d*$/.test(value)) {
                return;
            }
            if (parseInt(value) < 0) {
                return;
            }
        }
        setFormData({ ...formData, [name]: value });
        setInvalid({ ...invalid, [name]: false })

    };

    const handleSubmit = (e) => {

        e?.preventDefault();

        let is_false = 0;
        let obj = {
            first_name: false,
            last_name: false,
            email: false,
            message: false,
        }

        if (formData.first_name === '') {
            obj.first_name = true;
            is_false = 1;
        }
        if (formData.last_name === '') {
            obj.last_name = true;
            is_false = 1;
        }
        if (formData.email === '') {
            obj.email = true;
            is_false = 1;
        }
        if (formData.message === '') {
            obj.message = true;
            is_false = 1;
        }

        if (is_false) {
            setInvalid(obj);
            return;
        }

        setLoading(true)

        setTimeout(() => {
            handleSubmitForm()
        }, 1000);
    }

    const handleSubmitForm = async () => {
        const response = await post_data('contact-message/create-contact-message', formData)
        if (response?.status) {
            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                message: ''
            })
            setLoading(false)
            setAlertStatus(true)
            setAlertTitle('Message sent successfully!')
            setAlertType('success')
        }
        else {
            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                message: ''
            })
            setLoading(false)
            setAlertStatus(true)
            setAlertTitle('Something went wrong!')
            setAlertType('error')
        }
    }

    return (
        <>
            <div className="contact-form-section" style={{ position: 'relative' }}>
                <img src='/images/blur-div.svg' style={{ position: 'absolute', left: 0, bottom: 0, zIndex: 1 }} />
                <img src='/images/blur-div.svg' style={{ position: 'absolute', right: 0, bottom: 0, zIndex: 1, transform: 'rotate(180deg)' }} />
                <Grid container spacing={0}>
                    <Grid item md={6} style={{ padding: '0 3%', position: 'relative' }} className="contact-form-left">
                        <div className="contact-info-div" style={{ position: 'relative' }}>

                            <img src='/images/petal-1.svg' style={{ position: 'absolute', right: isDesktop ? 0 : '30%', bottom: 0, zIndex: 1, transform: 'rotate(20deg)', opacity: '30%' }} />

                            <img src="/images/quarter-circle.svg" style={{ position: "absolute", right: 0, bottom: 0, width: 150, borderBottomRightRadius: 10 }} />
                            <h2 className="global-h2" style={{ fontSize: 24 }}>Contact Info</h2>
                            <p className="about-para">Any question or remarks? Just write us a message!</p>

                            <div style={{ margin: '7% 0' }}>
                                {
                                    contactInfo?.map((item, i) => {
                                        return (
                                            <>
                                                <div style={contact_info_div}>
                                                    <img src={`/images/${item?.img}.svg`} style={{ width: 20, height: 20 }} />
                                                    <p style={title_styles}>{item?.title}</p>
                                                    {
                                                        item?.title2 && (
                                                            <p style={title_styles}>{item?.title2}</p>
                                                        )
                                                    }
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                            <div style={{ margin: '20% 0' }}>
                                <SocialIcons />
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={6} style={{ border: '1px solid #E6E6E6', borderRadius: 5, background: 'white', zIndex: 99 }} className="contact-form-right">
                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <label className="input-label color-label">First Name*</label>
                                <input
                                    style={{ height: 42 }}
                                    className={`register-from-input ${invalid?.first_name ? 'invalid-input' : ''}`}
                                    placeholder="Enter Your First Name"
                                    type="text"
                                    name="first_name"
                                    value={formData?.first_name}
                                    onChange={handleInputChange}
                                />
                                {
                                    invalid?.first_name && (
                                        <p className="invalid-txt">Please enter first name</p>
                                    )
                                }
                            </Grid>

                            <Grid item xs={6}>
                                <label className="input-label color-label">Last Name*</label>
                                <input
                                    style={{ height: 42 }}
                                    className={`register-from-input ${invalid?.last_name ? 'invalid-input' : ''}`}
                                    placeholder="Enter Your First Name"
                                    type="text"
                                    name="last_name"
                                    value={formData?.last_name}
                                    onChange={handleInputChange}
                                />
                                {
                                    invalid?.last_name && (
                                        <p className="invalid-txt">Please enter last name</p>
                                    )
                                }
                            </Grid>

                            <Grid item xs={12} style={{ margin: '3% 0' }}>
                                <label className="input-label color-label">Email*</label>
                                <input
                                    style={{ height: 42 }}
                                    className={`register-from-input ${invalid?.email ? 'invalid-input' : ''}`}
                                    placeholder="Enter Your First Name"
                                    type="text"
                                    name="email"
                                    value={formData?.email}
                                    onChange={handleInputChange}
                                />
                                {
                                    invalid?.email && (
                                        <p className="invalid-txt">Please enter email</p>
                                    )
                                }
                            </Grid>

                            <Grid item xs={12} style={{ margin: '3% 0' }}>
                                <label className="input-label color-label">Message*</label>
                                <textarea
                                    style={{ height: 150 }}
                                    className={`from-textarea ${invalid?.message ? 'invalid-input' : ''}`}
                                    placeholder="Enter your message here..."
                                    type="text"
                                    name="message"
                                    value={formData?.message}
                                    onChange={handleInputChange}
                                />
                                {
                                    invalid?.message && (
                                        <p className="invalid-txt">Please enter some text</p>
                                    )
                                }
                            </Grid>

                            <Grid item xs={8}>
                                <Button
                                    onClick={(e) => handleSubmit(e)}
                                    disabled={loading}
                                    variant='contained'
                                    style={loading ? { ...button_right_style, ...disabled_btn } : button_right_style}
                                >
                                    {loading ? 'Sending...' : 'Send'}
                                </Button>
                            </Grid>

                            {
                                alertStatus && (
                                    <div style={{ padding: '2% 2% 2% 0', width: '100%', display: "flex", justifyContent: "left" }}>
                                        <DefaultAlert title={alertTitle} type={alertType} />
                                    </div>
                                )
                            }

                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}