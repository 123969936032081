import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { get_data } from '../../../Api';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChartComponent = ({ padding }) => {
  const [chartData, setChartData] = useState({
    labels: ['Male', 'Female'],
    datasets: [
      {
        label: 'User Count',
        data: [0, 0],
        backgroundColor: [
          '#009EF6',
          '#F60085',
        ],
        borderColor: [
          '#009EF6',
          '#F60085',
        ],
        borderWidth: 1,
      },
    ],
  });

  const [totalMale, setTotalMale] = useState(0);
  const [totalFemale, setTotalFemale] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await get_data('user/total-users-by-gender');
      if (response?.data) {
        const { total_male, total_female } = response?.data;
        setChartData((prevData) => ({
          ...prevData,
          datasets: [
            {
              ...prevData.datasets[0],
              data: [total_male, total_female],
            },
          ],
        }));
        setTotalMale(total_male);
        setTotalFemale(total_female);
      } else {
        // console.error('Error fetching data');
      }
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };

  const chartStyle = {
    width: '100%',
    height: '100%',
    border: '1px solid #F1F1F2',
    borderRadius: 14,
    background: 'white',
    padding: padding,
    position: 'relative',
    boxShadow: '0 3px 4px 0 #00000008'
  };

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  };

  const titleStyle = {
    fontWeight: 600,
    fontSize: 20
  };

  const doughnutContainerStyle = {
    width: '300px',
    height: '300px',
    margin: '17% auto 0 auto',
  };

  const statsMainDivStyles = {
    display: 'flex',
    justifyContent: 'left',
    textAlign: 'center',
    gap: '4%',
    alignItems: 'center'
  };

  const statItemStyle = {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    justifyContent: 'left',
    paddingRight: '8%',
    borderRight: '1px dashed #E1E3EA'
  };

  const statValueStyle = {
    fontSize: 20,
    color: '#3F4254',
    fontWeight: 600,
  };

  const statLabelStyle = {
    fontSize: 14,
    fontWeight: 500,
  };

  return (
    <div style={chartStyle}>
      <div style={headerStyle}>
        <div style={titleStyle}>Gender Distribution</div>
      </div>
      <div style={statsMainDivStyles}>
        <div style={statItemStyle}>
          <h3 className='global-h3' style={statValueStyle}>{totalMale}</h3>
          <p className='about-para' style={statLabelStyle}>Total Males</p>
        </div>
        <div style={{ ...statItemStyle, borderRight: 'none' }}>
          <h3 className='global-h3' style={statValueStyle}>{totalFemale}</h3>
          <p className='about-para' style={statLabelStyle}>Total Females</p>
        </div>
      </div>
      <div style={doughnutContainerStyle}>
        <Doughnut data={chartData} />
      </div>
    </div>
  );
};

export default DoughnutChartComponent;
