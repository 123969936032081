import React, { useState } from "react";
import { Grid, TextField, InputAdornment } from "@material-ui/core";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { formatDate, formatPrice, secondaryDarkColor } from "../../../Constants/globalConstants";
import { Pagination, Typography } from "@mui/material";
import SerialNo from "../../Admin/Components/SerialNo";
import InvoiceModal from "./InvoiceModal";
import { DarkModeContext } from "./DarkModeContext";
import { post_data } from "../../../Api";
import { useSelector } from "react-redux";

export default function InvoicesTable({
  tableHeader,
  type,
  data,
  itemsPerPage,
  currentPage,
  setCurrentPage,
  count,
  totalPages,
}) {

  const { darkMode } = React.useContext(DarkModeContext);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState({})
  const [currentIndex, setCurrentIndex] = useState('')

  const table_div = {
    padding: "3%",
    borderRadius: 10,
    border: darkMode ? 'none' : "1px solid #F1F1F2",
    boxShadow: "0px 3px 4px 0 #00000008",
    background: darkMode ? secondaryDarkColor : "white",
  };

  const table_heading = {
    fontWeight: 600,
    fontSize: 17,
    color: darkMode ? 'white' : "#3F4254",
  };

  const table_tagline = {
    fontWeight: 500,
    color: "#A1A5B7",
    fontSize: 13,
  };

  const startEntry = (currentPage - 1) * itemsPerPage + 1;
  const endEntry = Math.min(currentPage * itemsPerPage, count);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  const table_header_title = {
    margin: 0,
    fontWeight: 600,
    fontSize: 14,
    color: darkMode ? 'white' : "#3F4254",
  };

  const table_header_div = {
    width: "100%",
    margin: "1% 0",
    padding: "1% 0",
    display: "flex",
    alignItems: "center",
  };

  const table_row_font = { margin: 0, fontWeight: 500, fontSize: 14, color: darkMode ? 'white' : '' };

  const displayTableHeader = () => {
    return (
      <div style={{ width: "100%" }}>
        <Grid container spacing={0} style={table_header_div}>
          {tableHeader.map((header, index) => (
            <Grid
              key={index}
              item
              xs={header?.grid}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: index === 0 || index === 1 ? "left" : "center",
              }}
            >
              <h3 style={table_header_title}>{header?.title}</h3>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };

  const displayAllInvoices = () => {
    return (
      <div>
        <InvoiceModal type={type} open={invoiceModal} setOpen={setInvoiceModal} currentInvoice={currentInvoice} currentIndex={currentIndex} />
        <div style={{ display: "flex" }}>
          <div>
            <h3 style={table_heading}>Invoices</h3>
            <p style={table_tagline}>
              Effortlessly check billing and invioces history.
            </p>
          </div>
        </div>

        {displayTableHeader()}
        {data?.map((item, i) => {
          const formated_price = formatPrice(item?.payment_amount || 0);
          return (
            <div style={{ width: "100%" }}>
              <Grid
                container
                spacing={0}
                style={{
                  width: "100%",
                  padding: "1.5% 0",
                  display: "flex",
                  alignItems: "center",
                  borderTop: "1px dashed gainsboro",
                }}
              >
                <Grid item xs={1}>
                  <SerialNo index={i} />
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h3
                    style={{
                      ...table_row_font,
                      color: item?.user_id?.first_name ? "#3F4254" : "red",
                    }}
                  >
                    {type === "Users" ? (
                      <>
                        <p style={{ color: darkMode ? 'white' : "#3F4254" }}>
                          {item?.plan_id?.name}
                        </p>
                      </>
                    ) : (
                      <>
                        {item?.user_id?.first_name || "Past"}{" "}
                        {item?.user_id?.last_name || "user"}
                      </>
                    )}
                  </h3>
                </Grid>
                <Grid
                  item
                  xs={type === "admin" ? 1 : 3}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3 style={{ ...table_row_font, color: darkMode ? 'white' : "#3F4254" }}>
                    {type === "Users" ? (
                      <>{formatDate(item?.createdAt)}</>
                    ) : (
                      <>{item?.plan_id?.name}</>
                    )}
                  </h3>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3
                    style={{
                      margin: 0,
                      fontWeight: 500,
                      fontSize: 14,
                      color: type === "Users" ? "red" : "black",
                    }}
                  >
                    {type === "Users" ? (
                      <>{formatDate(item?.expiry_date)}</>
                    ) : (
                      <>{formatDate(item?.createdAt)}</>
                    )}
                  </h3>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3
                    style={{
                      margin: 0,
                      fontWeight: 500,
                      fontSize: 14,
                      color: type === "Users" ? "green" : "red",
                    }}
                  >
                    {type === "Users" ? (
                      <>{formated_price}</>
                    ) : (
                      <>{formatDate(item?.expiry_date)}</>
                    )}
                  </h3>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#3F4254",
                    justifyContent: "center",
                  }}
                >
                  {type === "Users" ? (
                    <>
                      <div style={{ width: "60%", margin: "auto" }}>
                        <p
                          onClick={() => { setInvoiceModal(true); setCurrentInvoice(item); setCurrentIndex(i) }}
                          style={{
                            fontSize: 14,
                            padding: "4% 2%",
                            borderRadius: 4,
                            background: "#E8FFF3",
                            color: "#50CD89",
                            textAlign: "center",
                            fontWeight: 500,
                            cursor: "pointer",
                          }}
                        >
                          Download
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <h3
                        style={{
                          margin: 0,
                          fontWeight: 500,
                          fontSize: 14,
                          color: "green",
                        }}
                      >
                        {formated_price}
                      </h3>
                    </>
                  )}
                </Grid>
                {type === "admin" && (
                  <Grid
                    onClick={() => {
                      setInvoiceModal(true);
                      setCurrentInvoice(item);
                      setCurrentIndex(i);
                    }}
                    item
                    xs={2}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ width: "60%", margin: "auto" }}>
                      <p
                        style={{
                          fontSize: 14,
                          padding: "4% 2%",
                          borderRadius: 4,
                          background: "#E8FFF3",
                          color: "#50CD89",
                          textAlign: "center",
                          fontWeight: 500,
                          cursor: "pointer",
                        }}
                      >
                        Download
                      </p>
                    </div>
                  </Grid>
                )}
              </Grid>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div style={table_div}>
        {displayAllInvoices()}

        {data?.length > 0 && (
          <div
            style={{
              borderTop: "1px dashed gainsboro",
              paddingTop: "2%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontWeight: 500,
                fontSize: 15,
                color: "black",
              }}
            // >{`Showing ${count} to ${endEntry} of ${count} invoices`}</Typography>
            >{`Showing ${count} invoices`}</Typography>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </div>
        )}
      </div>
    </>
  );
}
