import React, { useContext } from "react"
import { DarkModeContext } from "./DarkModeContext";

export default function DashboardHeading({
    title
}) {

    const { darkMode } = useContext(DarkModeContext);

    const heading_styles = {
        color: darkMode ? 'white' : 'black',
        fontWeight: 600,
        fontSize: 22
    }

    return (
        <>
            <h2 style={heading_styles}>{title}</h2>
        </>
    )
}