import React, { useCallback, useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { serverURLPhotos, serverURLProfilePicture } from "../../../Api";
import {
    button_right_style,
    useDatabaseConstants,
} from "../../../Constants/globalConstants";
import EmptyPage from "../Components/EmptyPage";
import ProfilePictureModal from "./ProfilePictureModal";

export default function UserSelfie({
    user,
    screen
}) {

    const dispatch = useDispatch();
    const { app_color } = useDatabaseConstants();
    const { isLoggedIn, user_data } = useSelector((state) => state.user);
    const [noPlan, setNoPlan] = useState(false)
    const [photoModal, setPhotoModal] = useState(false)
    const [selectedPhoto, setSelectedPhoto] = useState('')

    useEffect(() => {
        if (user_data?.plan_id?.length === 0) {
            setNoPlan(true)
        }
    }, [user_data])

    const user_album_div = {
        display: "flex",
        gap: "2%",
        padding: "1%",
        flexWrap: "wrap",
    };

    const user_photo = {
        height: 200,
        width: 200,
        borderRadius: 10,
    };

    const handlePhotoClick = (item) => {
        if ((screen === 'Match' || screen === 'Sent' || screen === 'Interest Sent' || screen === 'Interest Received' || screen === 'Received') && noPlan) {
        }
        else {
            setPhotoModal(true)
            setSelectedPhoto(`${serverURLProfilePicture}/${item}`)
        }
    }

    const displaySelfie = () => {
        return (
            <div style={user_album_div}>
                <div style={{ marginBottom: '2%', cursor: "pointer" }}
                    onClick={() => handlePhotoClick(user?.user_selfie)}
                >
                    <img src={`${serverURLProfilePicture}/${user?.user_selfie}`} style={user_photo}
                        className={(screen === 'Match' || screen === 'Sent' || screen === 'Interest Sent' || screen === 'Interest Received' || screen === 'Interest Sent' || screen === 'Received' || screen === 'Interest Received') && noPlan ? "blur-photos" : ""}
                    />
                </div>
            </div>
        );
    };


    return (
        <>
            <Grid container spacing={0}>

                <Grid item md={12}>
                    {user?.user_selfie === '' ? (
                        <>
                            <EmptyPage title="No Selfie uploaded yet..." />
                        </>
                    ) : (
                        <>
                            <div style={{ marginTop: "3%" }}>{displaySelfie()}</div>
                        </>
                    )}
                </Grid>
            </Grid>

            <ProfilePictureModal open={photoModal} setOpen={setPhotoModal}
                image={selectedPhoto} />
        </>
    );
}
