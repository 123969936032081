import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { DarkModeContext } from '../DarkModeContext';
import { secondaryDarkColor } from '../../../../Constants/globalConstants';

export default function ChatSkeleton() {

    const { darkMode } = React.useContext(DarkModeContext);

    return (
        <>
            <Stack spacing={0} style={{ display: 'flex', flexDirection: 'row', gap: '2%' }}>
                <Skeleton variant="rounded" width={'33%'} height={570} style={{ background: darkMode ? secondaryDarkColor : '' }} />
                <Skeleton variant="rounded" width={'67%'} height={570} style={{ background: darkMode ? secondaryDarkColor : '' }} />
            </Stack>
        </>
    );
}