import React, { useEffect, useMemo, useState } from "react"
import { Button } from "@mui/material"
import { post_data, socketServerUrl } from "../../../Api"
import { useNavigate } from "react-router-dom"
import { extractNumber, useDatabaseConstants } from "../../../Constants/globalConstants";
import { io } from "socket.io-client";
import { useDispatch } from "react-redux";
import LimitExceedModal from "./LimitExceedModal";
import { login } from "../../../redux/slices/user-slice";

export default function InterestButtons({
    screen,
    interestStatus,
    sentInterestMatch,
    receivedInterestMatch,
    setBtnClicked,
    setOpen,
    open,
    permissions,
    item,
    user_data,
    noPlan,
    refresh,
    setRefresh,
    forceReload
}) {

    const socket = useMemo(
        () =>
            io(socketServerUrl, {
                path: "/api/socket",
            }),
        []
    );

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { app_color } = useDatabaseConstants()
    const [loading, setLoading] = useState(false)
    const [leftLoading, setLeftLoading] = useState(false)
    const [onlineUsers, setOnlineUsers] = useState([])
    const [openLimitExceed, setOpenLimitExceed] = useState(false)

    useEffect(() => {
        socket.emit("new-user-add", user_data?._id);
        socket.on("get-users", (users) => {
            setOnlineUsers(users);
        });
    }, [socket, user_data?._id]);

    useEffect(() => {
    }, [user_data, receivedInterestMatch, sentInterestMatch, interestStatus])

    const buttonStyle = {
        width: "50%",
        padding: "3% 0",
        background: app_color,
        color: 'white',
        boxShadow: 'none',
        borderRadius: 7,
        fontWeight: 500,
        fontSize: 14,
        textTransform: 'capitalize'
    };

    const outlineButtonStyle = {
        width: "50%",
        padding: "3% 0",
        backgroundColor: 'white',
        color: app_color,
        boxShadow: 'none',
        borderRadius: 7,
        fontWeight: 500,
        fontSize: 14,
        border: `2px solid ${app_color}`,
        textTransform: 'capitalize'
    };

    const sendNotification = async (recipentMessage) => {
        let payload = {
            recipient: item?._id,
            sender: user_data?._id,
            message: recipentMessage
        };
        const response = await post_data(
            "notification/create-new-notification",
            payload
        );

        if (response?.status) {
            const { recipient, message, type } = response.data;
            let data = {
                receiverId: recipient,
                senderId: user_data?._id,
                message: message,
                type: type,
            };
            socket.emit("send-notification", {
                ...data,
                createdAt: new Date(),
            });
        }
    };



    const handleLeftBtn = async (e, item) => {

        setLeftLoading(true)
        let payload = { "sender_id": user_data?._id, "receiver_id": item?._id }
        const response = await post_data('chat/create-new-chat', payload)

        let selected_user

        if (response?.status) {

            selected_user = {
                chat_id: response?.data?._id,
                count: response?.data?.count,
                is_online: 0,
                last_message: response?.data?.last_message,
                last_msg: undefined,
                messages: undefined,
                name: `${item?.first_name} ${item?.last_name}`,
                profile_picture: item?.profile_picture,
                user_id: item?._id
            }


            if (screen === 'Match' && sentInterestMatch !== 'pending') {
                navigate('/dashboard/chat', { state: { selectedUser: selected_user } })
                return
            }
            if (screen === 'Match' && receivedInterestMatch === 'accepted') {
                navigate('/dashboard/chat', { state: { selectedUser: selected_user } })
                return
            }
            if (screen === 'Interest Sent') {
                navigate('/dashboard/chat', { state: { selectedUser: selected_user } })
                return
            }
        }

        if (screen === 'Interest Received' && (interestStatus === 'accepted' || interestStatus === 'declined')) {
            navigate('/dashboard/chat', { state: { selectedUser: selected_user } })
            return
        }


        const isPermission = checkPermissionsFunc()
        if (isPermission === false) {
            setLeftLoading(false)
            return
        }
        else {
            handleSubmit('left')
        }

        // if (noPlan) {
        //     setOpen(true)
        //     return
        // }
    }


    const handleRightBtn = (e, item) => {
        const isPermission = checkPermissionsFunc()
        if (isPermission === false) {
            return
        }
        else {
            if (noPlan) {
                setOpen(true)
                return
            }
            setLoading(true)
            handleSubmit('right')
        }
    }


    const handleSubmit = async (buttonClicked) => {
        let payload = {};
        let endpoint = "";

        if (
            screen === "Match" &&
            sentInterestMatch === "pending" &&
            buttonClicked === "right"
        ) {
            payload = {
                sender_id: item?._id,
                receiver_id: user_data?._id,
                status: "accepted",
            };
            endpoint = "user/update-interest-status";
            sendNotification(`Your interest has been accepted by ${user_data?.first_name}`)
        }

        if (
            screen === "Match" &&
            sentInterestMatch === "pending" &&
            buttonClicked === "left"
        ) {
            payload = {
                sender_id: item?._id,
                receiver_id: user_data?._id,
                status: "declined",
            };
            endpoint = "user/update-interest-status";
            sendNotification(`Your interest has been declined by ${user_data?.first_name}`)
        }

        if (screen === "Match" && sentInterestMatch === "Send Interest") {
            payload = { sender_id: user_data?._id, receiver_id: item?._id };
            endpoint = "user/send-interest";
            sendNotification(`You have received interest from ${user_data?.first_name}`);
        }

        else if (screen === "Interest Received") {
            if (buttonClicked === "right") {
                payload = {
                    sender_id: item?._id,
                    receiver_id: user_data?._id,
                    status: "accepted",
                };
                endpoint = "user/update-interest-status";
                sendNotification(`Your interest has been accepted by ${user_data?.first_name}`)
            } else {
                payload = {
                    sender_id: item?._id,
                    receiver_id: user_data?._id,
                    status: "declined",
                };
                endpoint = "user/update-interest-status";
                sendNotification(`Your interest has been declined by ${user_data?.first_name}`)
            }
        }
        const response = await post_data(endpoint, payload);
        if (response?.status) {
            dispatch(login(response?.data));
            setTimeout(() => {
                if (buttonClicked === 'right') {
                    setLoading(false);
                }
                else {
                    setLeftLoading(false);
                }
            }, 1500);
        }
    };


    const disabled_btn = {
        background: "#9e004f8e",
    }



    const checkPermissionsFunc = () => {
        if (permissions?.hasOwnProperty('send_interest') && !noPlan &&
            screen === 'Match' &&
            receivedInterestMatch === 'Send Interest' &&
            // sentInterestMatch !== 'pending' &&
            permissions?.send_interest !== 'Unlimited' &&
            user_data?.interest_sent?.length === extractNumber(permissions?.send_interest)) {
            setOpenLimitExceed(true)
            return false
        }
        else if (permissions?.hasOwnProperty('receive_interest') && !noPlan &&
            (screen === 'Interest Received' || screen === 'Received') &&
            interestStatus === 'pending' &&
            permissions?.receive_interest !== 'Unlimited' &&
            user_data?.interest_received?.filter(item => item?.status === 'accepted')?.length === extractNumber(permissions?.receive_interest)) {
            setOpenLimitExceed(true)
            return false
        }
        else if (permissions?.hasOwnProperty('receive_interest') && !noPlan &&
            screen === 'Match' &&
            sentInterestMatch === 'pending' &&
            // receivedInterestMatch !== 'Send Interest' &&
            permissions?.receive_interest !== 'Unlimited' &&
            user_data?.interest_received?.filter(item => item?.status === 'accepted')?.length === extractNumber(permissions?.receive_interest)) {
            setOpenLimitExceed(true)
            return false
        }
        else if (screen === 'Interest Received' && noPlan) {
            setOpen(true)
            return false
        }
        else if (screen === 'Match' && sentInterestMatch === 'pending' && noPlan) {
            setOpen(true)
            return false
        }
        else if (screen === 'Match' && sentInterestMatch === 'Send Interest' && noPlan) {
            setOpen(true)
            return false
        }
        else { }
    }

    return (
        <>
            <LimitExceedModal open={openLimitExceed} setOpen={setOpenLimitExceed} />
            <div
                style={{
                    display: "flex",
                    gap: '4%',
                    marginTop: '5%',
                }}
            >
                {
                    screen === 'Match' && sentInterestMatch !== 'pending' ?
                        <>
                            <Button variant="contained" style={outlineButtonStyle} disabled={leftLoading}
                                onClick={(e) => handleLeftBtn(e, item)}
                            >
                                {leftLoading ? 'Chat...' : 'Chat'}
                            </Button>
                        </>
                        :
                        <></>
                }
                {
                    (screen === 'Interest Sent' || screen === 'Sent') && (interestStatus === 'accepted' || interestStatus === 'declined' || interestStatus === 'pending') ?
                        <>
                            <Button variant="contained" style={outlineButtonStyle} disabled={leftLoading}
                                onClick={(e) => handleLeftBtn(e, item)}
                            >
                                {leftLoading ? 'Chat...' : 'Chat'}
                            </Button>
                        </>
                        :
                        <></>
                }
                {
                    (screen === 'Interest Received' || screen === 'Received') && (interestStatus === 'accepted' || interestStatus === 'declined') ?
                        <>
                            <Button variant="contained" style={outlineButtonStyle} disabled={leftLoading}
                                onClick={(e) => handleLeftBtn(e, item)}
                            >
                                {leftLoading ? 'Chat...' : 'Chat'}
                            </Button>
                        </>
                        :
                        <></>
                }
                {
                    screen === 'Match' && sentInterestMatch === 'pending' ?
                        <>
                            <Button variant="contained"
                                style={outlineButtonStyle}
                                disabled={leftLoading}
                                onClick={(e) => handleLeftBtn(e, item)}>
                                {leftLoading ? 'Decline...' : 'Decline'}
                            </Button>
                            <Button variant="contained"
                                disabled={loading}
                                style={loading ? { ...buttonStyle, ...disabled_btn } : buttonStyle}
                                onClick={(e) => handleRightBtn(e, item)}>
                                {loading ? 'Accept...' : 'Accept'}
                            </Button>
                        </>
                        :
                        screen === 'Match' && sentInterestMatch === 'declined' ?
                            <>
                                <Button variant="contained"
                                    disabled={true}
                                    style={{ ...buttonStyle, width: '50%', background: 'red', color: 'white', cursor: 'default' }}
                                    onClick={(e) => handleRightBtn(e, item)}>
                                    Declined by You
                                </Button>
                            </>
                            :
                            screen === 'Match' && sentInterestMatch === 'accepted' ?
                                <>
                                    <Button variant="contained"
                                        disabled={true}
                                        style={{ ...buttonStyle, background: 'green', color: 'white', cursor: 'default' }}
                                        onClick={(e) => handleRightBtn(e, item)}>
                                        Accepted by You
                                    </Button>
                                </>
                                :


                                screen === 'Match' && receivedInterestMatch === 'pending' ?
                                    <>
                                        <Button variant="contained"
                                            style={{ ...buttonStyle, background: '#F6C000', color: 'white', cursor: 'default' }} disabled={true}
                                            onClick={(e) => handleRightBtn(e, item)}>
                                            Pending
                                        </Button>
                                    </>
                                    :
                                    screen === 'Match' && receivedInterestMatch === 'declined' ?
                                        <>
                                            <Button variant="contained"
                                                disabled={true}
                                                style={{ ...buttonStyle, width: '50%', background: 'red', color: 'white', cursor: 'default' }}
                                                onClick={(e) => handleRightBtn(e, item)}>
                                                Declined by {user_data?.basic_details?.gender === 'Male' ? 'Her' : 'Him'}
                                            </Button>
                                        </>
                                        :
                                        screen === 'Match' && receivedInterestMatch === 'accepted' ?
                                            <>
                                                <Button variant="contained"
                                                    disabled={true}
                                                    style={{ ...buttonStyle, background: 'green', color: 'white', cursor: 'default' }}
                                                    onClick={(e) => handleRightBtn(e, item)}>
                                                    Accepted by {user_data?.basic_details?.gender === 'Male' ? 'Her' : 'Him'}
                                                </Button>
                                            </>
                                            :
                                            screen === 'Match' && receivedInterestMatch === 'Send Interest' ?
                                                <>
                                                    <Button variant="contained"
                                                        style={loading ? { ...buttonStyle, ...disabled_btn } : buttonStyle}
                                                        disabled={loading}
                                                        onClick={(e) => handleRightBtn(e, item)}>
                                                        {/* Send Interest */}
                                                        {loading ? 'Send Interest...' : 'Send Interest'}
                                                    </Button>
                                                </>
                                                :

                                                (screen === 'Interest Sent' || screen === 'Sent') && interestStatus === 'pending' ?
                                                    <>
                                                        <Button variant="contained" style={{ ...buttonStyle, background: '#F6C000', color: 'white', cursor: 'default' }} disabled={true}>
                                                            Pending
                                                        </Button>
                                                    </>
                                                    :
                                                    (screen === 'Interest Sent' || screen === 'Sent') && interestStatus === 'declined' ?
                                                        <>
                                                            <Button variant="contained"
                                                                disabled={true}
                                                                style={{ ...buttonStyle, width: '50%', background: 'red', color: 'white', cursor: 'default' }}
                                                                onClick={(e) => handleRightBtn(e, item)}>
                                                                Declined
                                                            </Button>
                                                        </>
                                                        :
                                                        (screen === 'Interest Sent' || screen === 'Sent') && interestStatus === 'accepted' ?
                                                            <>
                                                                <Button variant="contained"
                                                                    disabled={true}
                                                                    style={{ ...buttonStyle, background: 'green', color: 'white', cursor: 'default' }}
                                                                    onClick={(e) => handleRightBtn(e, item)}>
                                                                    Accepted
                                                                </Button>
                                                            </>
                                                            :
                                                            (screen === 'Interest Received' || screen === 'Received') && interestStatus === 'pending' ?
                                                                <>
                                                                    <Button variant="contained"
                                                                        disabled={leftLoading}
                                                                        style={outlineButtonStyle}
                                                                        onClick={(e) => handleLeftBtn(e, item)}>
                                                                        {leftLoading ? 'Decline...' : 'Decline'}
                                                                    </Button>
                                                                    <Button variant="contained" disabled={loading}
                                                                        style={loading ? { ...buttonStyle, ...disabled_btn } : buttonStyle}
                                                                        onClick={(e) => handleRightBtn(e, item)}>
                                                                        {loading ? 'Accept...' : 'Accept'}
                                                                    </Button>
                                                                </>
                                                                :
                                                                (screen === 'Interest Received' || screen === 'Received') && interestStatus === 'declined' ?
                                                                    <>
                                                                        <Button variant="contained"
                                                                            disabled={true}
                                                                            style={{ ...buttonStyle, width: '50%', background: 'red', color: 'white', cursor: 'default' }}
                                                                            onClick={(e) => handleRightBtn(e, item)}>
                                                                            Declined
                                                                        </Button>
                                                                    </>
                                                                    :
                                                                    (screen === 'Interest Received' || screen === 'Received') && interestStatus === 'accepted' ?
                                                                        <>
                                                                            <Button variant="contained"
                                                                                disabled={true}
                                                                                style={{ ...buttonStyle, background: 'green', color: 'white', cursor: 'default' }}
                                                                                onClick={(e) => handleRightBtn(e, item)}>
                                                                                Accepted
                                                                            </Button>
                                                                        </>
                                                                        :
                                                                        <></>
                }
            </div>
        </>
    )
}