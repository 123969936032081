import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Grid } from '@mui/material';
import TableSkeleton from './TableSkeleton';

const arr = [1, 2, 3, 4]

export default function AdminDashboardSkeleton() {
    return (
        <>
            <Grid container spacing={3}>
                {
                    arr?.map((item) => {
                        return (
                            <Grid item xs={3} style={{ marginBottom: '3%' }}>
                                <Skeleton variant="rounded" width={'100%'} height={120} />
                            </Grid>
                        )
                    })
                }
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={6} style={{ marginBottom: '6%' }}>
                    <Skeleton variant="rounded" width={'100%'} height={300} />
                </Grid>
                <Grid item xs={6} style={{ marginBottom: '3%' }}>
                    <Skeleton variant="rounded" width={'100%'} height={300} />
                </Grid>
            </Grid>

            <TableSkeleton />
        </>

    );
}
