import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { get_data } from '../Api';

export default function SubAdminPermissions() {

    const [permissions, setPermissions] = useState([])
    const [allPermissions, setAllPermissions] = useState([])
    const { user_data } = useSelector(state => state.user);
    const [isSubAdmin, setIsSubAdmin] = useState(false)


    const fetchAllPermisisons = async () => {
        const response = await get_data('sub-admin-permission/get-all-sub-admin-permissions')
        if (response?.status) {
            setAllPermissions(response?.data)
        }
    }

    useEffect(() => {
        fetchAllPermisisons();
    }, []);

    useEffect(() => {
        const permissions_arr = extractPermissions()

        if (permissions_arr) {
            allPermissions?.forEach(item => {
                if (!item.is_active) {
                    permissions_arr?.push(item?.permission_name);
                }
            });
            setPermissions(permissions_arr)
        }
        if (user_data?.is_sub_admin) {
            setIsSubAdmin(true)
        }
    }, [user_data, allPermissions])

    const extractPermissions = () => {
        return user_data?.permissions?.map(permission => permission?.permission_name);
    }

    return { permissions, isSubAdmin }
}
