import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useDatabaseConstants } from '../../../Constants/globalConstants';

export default function CustomProgressBar({ completionPercentage }) {

    const { app_color } = useDatabaseConstants()

    const value = 0.66;
    return (
        <>
            <CircularProgressbar
                styles={buildStyles({
                    rotation: 0.25,
                    strokeLinecap: 'butt',
                    textSize: '16px',
                    pathTransitionDuration: 0.5,
                    pathColor: completionPercentage < 100 ? app_color : 'green',
                    textColor: completionPercentage < 100 ? app_color : 'green',
                    trailColor: '#d6d6d6',
                    backgroundColor: '#3e98c7',
                })}
                value={completionPercentage} text={`${completionPercentage}%`} />
        </>
    )
}