import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';
import { DarkModeContext } from '../DarkModeContext';
import { secondaryDarkColor } from '../../../../Constants/globalConstants';

export default function ProfileSkeleton() {

    const { darkMode } = React.useContext(DarkModeContext);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Skeleton variant="rounded" width={'100%'} height={370} style={{ background: darkMode ? secondaryDarkColor : '' }} />
                </Grid>
                <Grid item xs={4} style={{ margin: 0 }}>
                    <div style={{ height: '100%', margin: 0 }}>
                        <div style={{ height: '25%' }}>
                            <div>
                                <Skeleton variant="rounded" width={'100%'} height={170} style={{ background: darkMode ? secondaryDarkColor : '' }} />
                            </div>
                        </div>
                        <div style={{ height: '35%', marginTop: '23%' }}>
                            <Skeleton variant="rounded" width={'100%'} height={170} style={{ background: darkMode ? secondaryDarkColor : '' }} />
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Skeleton variant="rounded" width={'100%'} height={150} style={{ background: darkMode ? secondaryDarkColor : '' }} />
            <Skeleton variant="rounded" width={'100%'} height={150} style={{ marginTop: '3%', background: darkMode ? secondaryDarkColor : '' }} />
        </>
    )
}