import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Grid, Button, Tooltip, IconButton } from "@mui/material";
import {
  button_right_style,
  calculateProfileCompletion,
  formatDate,
  maskEmail,
  maskNumber,
  maskOther,
  useDatabaseConstants,
  disabled_btn,
  button_left_style,
  getAvatar,
  formatDateTime,
  extractNumber,
  featuredLimitDays,
  secondaryDarkColor,
} from "../../../Constants/globalConstants";
import ProfileLabel from "./ProfileLabel";
import ProfileEditModal from "./ProfileEditModal";
import {
  post_data,
  serverURL,
  serverURLBackgroundPicture,
  serverURLProfilePicture,
} from "../../../Api";
import { CiShare1 } from "react-icons/ci";
import BackgroundImgModal from "./BackgroundImgModal";
import { IoMdInformationCircleOutline } from "react-icons/io";
import ProfilePictureModal from "./ProfilePictureModal";
import { useDispatch, useSelector } from "react-redux";
import { IoWarningOutline } from "react-icons/io5";
import DefaultAlert from "../../UserDshboard/Components/DefaultAlert";
import VerifyEmailModal from "../../../Website/Components/VerifyEmailModal";
import CustomProgressBar from "./CustomProgressBar";
import CompatiblityScore from "./CompatiblityScore";
import FeaturedLabel from "./FeaturedLabel";
import InterestButtons from "./InterestButtons";
import InterestButtonsFunction from "../../../Constants/interestButtonsFunction";
import InterestConditionsModal from "./InterestConditionsModal";
import { Link, useNavigate } from "react-router-dom";
import SuccessModal from "./SuccessModal";
import AreYouSureModal from "./AreYouSureModal";
import { login } from "../../../redux/slices/user-slice";
import { DarkModeContext } from "./DarkModeContext";
import PlanLabel from "./PlanLabel";
import ExportModal from "./ModalComponent";
import { IoShareSocial } from "react-icons/io5";
import BioData from "./BioData";
import { BiHandicap } from "react-icons/bi";
import CustomToggleWithoutProps from "../Components/CustomToggleWithoutProps"
import ProfileCompleteInfoModal from "./ProfileCompleteInfoModal";

export default function UserPersonalDetails({ user, screen, permissions, type, setUser }) {

  const dispatch = useDispatch();
  const { darkMode } = useContext(DarkModeContext);
  const { app_color } = useDatabaseConstants();
  const [open, setOpen] = useState(false);
  const [verifyEmailOpen, setVerifyEmailOpen] = useState(false);
  const [bgImgModal, setBgImgModal] = useState(false);
  const [item2, setItem2] = useState([]);
  const [editTitle, setEditTitle] = useState("");
  const [modalType, setModalType] = useState("");
  const [completionPercentage, setCompletionPercentage] = useState("");
  const [hasPermission, setHasPermission] = useState(false);
  const [noPlan, setNoPlan] = useState(false);
  const [item, setItem] = useState(user || {});
  const [btnClicked, setBtnClicked] = useState("");
  const { user_data } = useSelector((state) => state.user);
  const [interestOpen, setInterestOpen] = useState(false);
  const [successStatus, setSuccessStatus] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const { interestStatus, sentInterestMatch, receivedInterestMatch } = InterestButtonsFunction({ screen, item });
  const navigate = useNavigate();
  const [isFeatured, setIsFeatured] = useState(false);
  const [featuredLimit, setFeaturedLimit] = useState("");
  const [deletePicOpen, setDeletePicOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCompletion, setIsCompletion] = useState(false);

  const [completionModal, setCompletionModal] = useState(false)

  const [refresh, setRefresh] = useState(false)

  const forceReload = () => setRefresh(prev => !prev);


  let avatar = getAvatar(user);

  const profile_card = {
    boxShadow: "3px 3px 4px 0 #00000008",
    borderRadius: 10,
    paddingBottom: "6%",
    width: "100%",
    position: "relative",
    background: "white",
    zIndex: 2,
  };

  const bg_img = {
    borderRadius: "5px 5px 0 0",
    width: "100%",
    height: 150,
    zIndex: -1,
    position: "relative",
  };

  const profile_pic = {
    borderRadius: "50%",
    width: 150,
    height: 150,
    marginTop: "-10%",
    border: darkMode ? `7px solid ${secondaryDarkColor}` : "7px solid white",
    zIndex: 2,
    cursor: "pointer",
    marginRight: "auto",
    marginLeft: "3%",
  };

  const profile_card_title = {
    fontWeight: 500,
    fontSize: 15,
  };

  const profile_card_para = {
    fontWeight: 500,
    fontSize: 14,
    color: darkMode ? "white" : "#00000080",
  };

  const edit_div = {
    position: "absolute",
    right: "2%",
    top: "6%",
    background: app_color,
    borderRadius: "50%",
    height: "30px",
    width: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  };

  const upload_img = {
    position: "absolute",
    bottom: "2%",
    left: "1%",
    cursor: "pointer",
    width: 30,
    height: 30,
  };

  let minAge = Number.MAX_VALUE;
  let maxAge = 0;

  if (
    user?.my_preferences?.age_range &&
    user.my_preferences.age_range.length > 0
  ) {
    user?.my_preferences?.age_range?.forEach((range) => {
      let ages = range.split(" - ");
      let min = parseInt(ages[0]);
      let max = parseInt(ages[1]);
      if (!isNaN(min) && !isNaN(max)) {
        // Check if parsed values are valid numbers
        if (min < minAge) {
          minAge = min;
        }
        if (max > maxAge) {
          maxAge = max;
        }
      }
    });
  }

  let combined_range;
  if (minAge === Number.MAX_VALUE || maxAge === 0) {
    combined_range = "N/A";
  } else {
    combined_range = minAge + "-" + maxAge + " Years";
  }

  const details = [
    {
      title: "Basic Details",
      obj: [
        {
          field: "Marital Status",
          value: user?.basic_details?.marital_status,
          type: "select",
        },
        {
          field: "Gender",
          value: user?.basic_details?.gender,
          type: "select",
        },
        {
          field: "Mother Tounge",
          value: user?.basic_details?.mother_tounge,
          type: "text",
        },
        {
          field: "Languages",
          value: user?.basic_details?.languages,
          type: "multi_select",
          display_value:
            user?.basic_details?.languages.length > 0 &&
              Array.isArray(user?.basic_details?.languages)
              ? user?.basic_details?.languages.join(", ")
              : "N/A",
        },
        {
          field: "DOB",
          value: user?.basic_details?.dob,
          type: "date_picker",
          display_value: formatDate(user?.basic_details?.dob),
          hidden: user?.privacy?.dob,
        },
        {
          field: "Height",
          value: user?.basic_details?.height,
          type: "number",
          display_value:
            user?.basic_details?.height > 0
              ? user?.basic_details?.height + " cm"
              : "",
        },
        {
          field: "Weight",
          value: user?.basic_details?.weight,
          type: "number",
          display_value:
            user?.basic_details?.weight > 0
              ? user?.basic_details?.weight + " Kg"
              : "",
        },
        {
          field: "Religion",
          value: user?.basic_details?.religion,
          type: "select",
        },
        {
          field: "Caste",
          value: user?.basic_details?.caste,
          type: "text",
        },
      ],
    },

    {
      title: "Contact Details",
      obj: [
        // {
        //     field: 'Alternate Email Id',
        //     value: user?.contact_details?.alternate_email_id,
        //     type: 'text'
        // },
        {
          field: "Alternate Mobile Number",
          value: user?.contact_details?.alternate_mobile_number,
          type: "number",
        },
        {
          field: "Landline Number",
          value: user?.contact_details?.landline_number,
          type: "number",
        },
        {
          field: "Address",
          value: user?.contact_details?.address,
          type: "text",
          hidden: user?.privacy?.address,
        },
        {
          field: "City",
          value: user?.contact_details?.city,
          type: "text",
        },
        {
          field: "State",
          value: user?.contact_details?.state,
          type: "select",
        },
      ],
    },

    {
      title: "My Preferences",
      obj: [
        {
          field: "Age Range",
          value: user?.my_preferences?.age_range,
          type: "multi_select",
          display_value: combined_range,
        },
        {
          field: "Religion",
          value: user?.my_preferences?.religion,
          type: "select",
        },
        {
          field: "Qualifications",
          value: user?.my_preferences?.qualifications,
          type: "multi_select",
          display_value:
            user?.my_preferences?.qualifications.length > 0 &&
              Array.isArray(user?.my_preferences?.qualifications)
              ? user?.my_preferences?.qualifications.join(", ")
              : "N/A",
        },
        {
          field: "Occupation",
          value: user?.my_preferences?.occupation,
          type: "multi_select",
          display_value:
            user?.my_preferences?.occupation?.length > 0 &&
              Array.isArray(user?.my_preferences?.occupation)
              ? user?.my_preferences?.occupation?.join(", ")
              : "N/A",
        },
        {
          field: "Income",
          value: user?.my_preferences?.income,
          type: "select",
        },
        {
          field: "Location",
          value: user?.my_preferences?.location,
          type: "multi_select",
          display_value:
            user?.my_preferences?.location.length > 0 &&
              Array.isArray(user?.my_preferences?.location)
              ? user?.my_preferences?.location.join(", ")
              : "N/A",
        },
      ],
    },

    {
      title: "Family Details",
      obj: [
        {
          field: "Father Name",
          value: user?.family_details?.father_name,
          type: "text",
          display_field: "Father's Name",
        },
        {
          field: "Father Occupation",
          value: user?.family_details?.father_occupation,
          type: "text",
          display_field: "Father's Occupation",
        },
        {
          field: "Mother Name",
          value: user?.family_details?.mother_name,
          type: "text",
          display_field: "Mother's Name",
        },
        {
          field: "Sisters",
          value: user?.family_details?.sisters,
          type: "number",
        },
        {
          field: "Brothers",
          value: user?.family_details?.brothers,
          type: "number",
        },
        {
          field: "Family Income",
          value: user?.family_details?.family_income,
          type: "select",
        },
      ],
    },

    {
      title: "Education and Careers",
      obj: [
        {
          field: "Qualifications",
          value: user?.education_and_careers?.qualifications,
          type: "multi_select",
          display_value:
            user?.education_and_careers?.qualifications.length > 0 &&
              Array.isArray(user?.education_and_careers?.qualifications)
              ? user?.education_and_careers?.qualifications.join(", ")
              : "N/A",
          // display_value: item2?.value && Array.isArray(item2?.value).length > 0 ? item2?.value.join(', ') : ''
        },
        {
          field: "Country",
          value: user?.education_and_careers?.country,
          type: "select",
        },
        {
          field: "State",
          value: user?.education_and_careers?.state,
          type: "select",
        },
        {
          field: "City",
          value: user?.education_and_careers?.city,
          type: "text",
        },
        {
          field: "College Name",
          value: user?.education_and_careers?.college_name,
          type: "text",
        },
        {
          field: "Occupation",
          value: user?.education_and_careers?.occupation,
          type: "multi_select",
          display_value:
            user?.education_and_careers?.occupation.length > 0 &&
              Array.isArray(user?.education_and_careers?.occupation)
              ? user?.education_and_careers?.occupation.join(", ")
              : "N/A",
        },
        {
          field: "Annual Income",
          value: user?.education_and_careers?.annual_income,
          type: "select",
          hidden: user?.privacy?.income,
        },
      ],
    },

    {
      title: "Life Style and Hobbies",
      obj: [
        {
          field: "Owns House",
          value: user?.life_style_and_hobbies?.owns_house,
          type: "select",
        },
        {
          field: "Owns Car",
          value: user?.life_style_and_hobbies?.owns_car,
          type: "select",
        },
        {
          field: "Hobbies",
          value: user?.life_style_and_hobbies?.hobbies,
          type: "multi_select",
          display_value:
            user?.life_style_and_hobbies?.hobbies.length > 0 &&
              Array.isArray(user?.life_style_and_hobbies?.hobbies)
              ? user?.life_style_and_hobbies?.hobbies.join(", ")
              : "N/A",
        },
        {
          field: "Travelled Locally",
          value: user?.life_style_and_hobbies?.travelled_locally,
          type: "multi_select",
          display_value:
            user?.life_style_and_hobbies?.travelled_locally.length > 0 &&
              Array.isArray(user?.life_style_and_hobbies?.travelled_locally)
              ? user?.life_style_and_hobbies?.travelled_locally.join(", ")
              : "N/A",
        },
        {
          field: "Travelled Abroad",
          value: user?.life_style_and_hobbies?.travelled_abroad,
          type: "multi_select",
          display_value:
            user?.life_style_and_hobbies?.travelled_abroad.length > 0 &&
              Array.isArray(user?.life_style_and_hobbies?.travelled_abroad)
              ? user?.life_style_and_hobbies?.travelled_abroad.join(", ")
              : "N/A",
        },
        {
          field: "Food",
          value: user?.life_style_and_hobbies?.food,
          type: "select",
        },
      ],
    },

    {
      title: "Social Links",
      obj: [
        {
          field: "Facebook",
          value: user?.social_links?.facebook,
          type: "text",
        },
        {
          field: "Instagram",
          value: user?.social_links?.instagram,
          type: "text",
        },
        {
          field: "Linkedin",
          value: user?.social_links?.linkedin,
          type: "text",
        },
        {
          field: "Twitter",
          value: user?.social_links?.twitter,
          type: "text",
        },
      ],
    },
  ];

  const profile_main_details = [
    {
      field: "Profile picture",
      value: user?.profile_picture,
      type: "image",
      hidden: user?.privacy?.profile_picture,
    },
  ];

  const profile_completion_details = [
    {
      field: "Profile picture",
      value: user?.profile_picture,
      type: "image",
    },
    // {
    //   field: "First name",
    //   value: user?.first_name,
    //   type: "text",
    // },
    // {
    //   field: "Last name",
    //   value: user?.last_name,
    //   type: "text",
    // },
    // {
    //     field: "Contact number",
    //     value: user?.contact_number,
    //     type: 'number',
    // },
    // {
    //     field: "Email",
    //     value: user?.email,
    //     type: 'text',
    // },
    {
      field: "Qualifications",
      value: user?.education_and_careers?.qualifications,
      type: "multi_select",
    },
    {
      field: "Occupation",
      value: user?.education_and_careers?.occupation,
      type: "multi_select",
    },
  ];


  useEffect(() => {
    const has_permission = permissions?.hasOwnProperty("view_contact_details")
      ? permissions?.view_contact_details === "Yes"
        ? true
        : false
      : true;
    if (has_permission) {
      setHasPermission(true);
    }
  }, [user_data, refresh]);

  useEffect(() => {
    const profileCompletionPercentage = parseFloat(
      calculateProfileCompletion(user, profile_completion_details)
    );
    if (profileCompletionPercentage) {
      setCompletionPercentage(profileCompletionPercentage);
    }

    if (user?.plan_id?.length > 0) {
      setIsFeatured(true);
      const featured_value = user?.plan_id?.[0]?.permissions?.find(
        (permission) =>
          permission?.permission_id?.permission_name === "Get featured"
      )?.value;
      if (featured_value) {
        if (featured_value === "Unlimited") {
          setFeaturedLimit("Unlimited");
        } else {
          setFeaturedLimit(extractNumber(featured_value));
        }
      }
    }
  }, [user, refresh]);

  const handleEdit = (item2, title, type) => {
    setOpen(true);
    setItem2(item2);
    setEditTitle(title);
    if (type === "completion") {
      setIsCompletion(true);
      setModalType("completion");
    } else {
      setModalType("");
    }
  };

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    if (user_data?.plan_id?.length === 0) {
      setNoPlan(true);
    }
  }, [user_data, refresh]);

  const handleVerifyEmailClick = () => {
    setVerifyEmailOpen(true);
  };

  const profileDetailsGrid = () => {
    return details?.map((item, i) => {
      const profile_details_grid = {
        border: darkMode ? "" : "2px solid #F1F1F2",
        boxShadow: "3px 3px 4px 0 #00000008",
        borderRadius: 10,
        background: darkMode ? secondaryDarkColor : "white",
        padding: "2%",
        margin: "3% 0",
        position: "relative",
      };

      return (
        <>
          <div
            style={profile_details_grid}
            className={
              (screen === "Match" ||
                screen === "Sent" ||
                screen === "Interest Sent" ||
                screen === "Interest Received" ||
                screen === "Received") &&
                noPlan
                ? "blur-details-div"
                : ""
            }
          >
            <h3
              style={{
                color: app_color,
                fontWeight: 500,
                fontSize: 18,
                marginBottom: "2%",
              }}
            >
              {item?.title}
            </h3>
            <div style={{ display: "flex" }}>
              {item?.obj?.map((item2, i2) => {
                let last_item = item?.obj?.length - 1;
                return (
                  <>
                    {screen !== "Match" &&
                      screen !== "Sent" &&
                      screen !== "Interest Sent" &&
                      screen !== "Interest Received" &&
                      screen !== "Received" && (
                        <div style={edit_div}>
                          <img
                            src="/images/edit-icon.svg"
                            style={{ width: "80%", height: "80%" }}
                            onClick={() => handleEdit(item?.obj, item?.title)}
                          />
                        </div>
                      )}
                    <div
                      style={{
                        borderRight:
                          last_item === i2 ? "" : "1.5px dashed #BFBFBF",
                        padding: i2 === 0 ? "0 2% 0 0" : "0 2%",
                      }}
                    >
                      <p style={{ ...profile_card_title, fontSize: 14 }}>
                        {item2.field}
                      </p>
                      {(screen !== "Match" &&
                        screen !== "Sent" &&
                        screen !== "Interest Sent" &&
                        screen !== "Interest Received" &&
                        screen !== "Received") ||
                        !item2?.hidden ? (
                        <>
                          <p style={{ ...profile_card_para, fontSize: 14 }}>
                            {item2?.display_value || item2?.value || "N/A"}
                          </p>
                        </>
                      ) : (
                        <>
                          {item2?.hidden &&
                            (item2?.display_value || item2?.value) ? (
                            <p style={profile_card_para}>****Privacy</p>
                          ) : (
                            <p style={{ ...profile_card_para, fontSize: 14 }}>
                              {item2?.display_value || item2?.value || "N/A"}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </>
      );
    });
  };

  const [profilePicModal, setProfilePicModal] = useState(false);

  const handleProfilePicClick = () => {
    setProfilePicModal(true);
  };

  const handleChatLink = async (e, item) => {
    let payload = { sender_id: user_data?._id, receiver_id: item?._id };
    const response = await post_data("chat/create-new-chat", payload);
    if (response?.status) {
      navigate("/dashboard/chat");
    }
  };

  const renderFeaturedLabel = () => {
    const is_within_limit = featuredLimitDays(user, featuredLimit);
    return (
      <>
        {is_within_limit && (
          <>
            <FeaturedLabel />
          </>
        )}
      </>
    );
  };

  const handleConfirmFunc = async () => {
    const response = await post_data(
      `user/delete-user-profile-picture/${user_data?._id}`,
      { profile_picture: user_data?.profile_picture }
    );
    if (response?.status) {
      setLoading(false);
      setDeletePicOpen(false);
      dispatch(login(response?.data));
    }
  };

  const planImg = {
    width: 350,
  };

  const displayPlanLabel = () => {
    return (
      <>
        {user?.plan_id?.[0]?.name === "Silver" ? (
          <img src="/images/silver-badge.png" style={planImg} />
        ) : user?.plan_id?.[0]?.name === "Gold" ? (
          <img src="/images/gold-badge.png" style={planImg} />
        ) : user?.plan_id?.[0]?.name === "Platinum" ? (
          <img src="/images/platinum-badge.png" style={planImg} />
        ) : (
          <></>
        )}
      </>
    );
  };

  const profileCard = () => {
    return (
      <>
        <div
          style={{
            ...profile_card,
            color: darkMode ? "white" : "black",
            border: darkMode ? "" : "2px solid #F1F1F2",
            backgroundColor: darkMode ? secondaryDarkColor : "white",
          }}
        >
          <div>
            <div
              style={{ position: "relative", ...bg_img }}
              className="profile-bg"
            ></div>
            {screen !== "Match" &&
              screen !== "Sent" &&
              screen !== "Interest Sent" &&
              screen !== "Interest Received" &&
              screen !== "Received" ? (
              <>
                {user?.profile_picture ? (
                  <>
                    <div style={{ position: "relative" }}>
                      <img
                        src={`${serverURLProfilePicture}/${user?.profile_picture}`}
                        style={profile_pic}
                        onClick={() => handleProfilePicClick()}
                      />
                      <div
                        style={{
                          ...edit_div,
                          left: "17%",
                          bottom: "4%",
                          top: "",
                        }}
                      >
                        <img
                          src="/images/edit-icon.svg"
                          style={{ width: "80%", height: "80%" }}
                          onClick={() =>
                            handleEdit(profile_main_details, "Prfile Picture")
                          }
                        />
                        {/* Main details */}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ position: "relative" }}>
                      <img src={`/images/${avatar}`} style={profile_pic} />
                      <div
                        style={{
                          ...edit_div,
                          left: "17%",
                          bottom: "4%",
                          top: "",
                        }}
                      >
                        <img
                          src="/images/edit-icon.svg"
                          style={{ width: "80%", height: "80%" }}
                          onClick={() =>
                            handleEdit(profile_main_details, "Prfile Picture")
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {profile_main_details[0]?.hidden ? (
                  <>
                    <img src={`/images/${avatar}`} style={profile_pic} />
                  </>
                ) : (
                  <>
                    {user?.profile_picture ? (
                      <>
                        <img
                          src={`${serverURLProfilePicture}/${user?.profile_picture}`}
                          style={profile_pic}
                          onClick={() => handleProfilePicClick()}
                          className={noPlan ? "blur-photos" : ""}
                        />
                      </>
                    ) : (
                      <>
                        <img src={`/images/${avatar}`} style={profile_pic} />
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <div
              style={{
                position: "absolute",
                right: "10%",
                top: screen === "Match" ? "29%" : "32%",
                display: "flex",
                gap: "4%",
                alignItems: "center",
              }}
            >
              <div>
                <p style={profile_card_para}>
                  {user?.dil_sey_id ? user?.dil_sey_id.toUpperCase() : ""}
                </p>
              </div>
              {isFeatured ? (
                <>
                  {featuredLimit === "Unlimited" ? (
                    <>
                      <FeaturedLabel />
                    </>
                  ) : (
                    <>{renderFeaturedLabel()}</>
                  )}
                </>
              ) : (
                <></>
              )}
              <ProfileLabel width={100} user={user} />
              {/* <PlanLabel user={user} /> */}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              padding: "2% 4% 3% 4%",
              position: "relative",
            }}
          >
            <div style={{ width: "50%" }}>
              <p
                style={{
                  ...profile_card_para,
                  fontSize: 23,
                  fontWeight: 600,
                  color: darkMode ? "white" : "black",
                }}
              >
                {screen !== "Match" &&
                  screen !== "Sent" &&
                  screen !== "Received" ? (
                  <>
                    {user?.first_name} {user?.last_name}
                  </>
                ) : (
                  <>
                    {noPlan ? (
                      <>
                        {maskOther(user?.first_name, 2)}{" "}
                        {maskOther(user?.last_name, 2)}
                      </>
                    ) : (
                      <>
                        {user?.first_name} {user?.last_name}
                      </>
                    )}
                  </>
                )}
              </p>
            </div>
          </div>

          <div style={{ display: "flex", gap: "7%", padding: "0 4%" }}>
            <div style={{ borderRight: "0.6px dashed #BFBFBF", width: "50%" }}>
              <p style={profile_card_title}>Mobile</p>
              <p
                style={{
                  ...profile_card_para,
                  display: "flex",
                  alignItems: "center",
                  gap: "3%",
                }}
              >
                {screen !== "Match" &&
                  screen !== "Sent" &&
                  screen !== "Interest Sent" &&
                  screen !== "Interest Received" &&
                  screen !== "Received" ? (
                  <>
                    {user?.contact_number}
                    <img
                      src="/images/green-check-icon.svg"
                      style={{ width: 20, height: 20 }}
                    />
                  </>
                ) : (
                  <>
                    {profile_main_details[3]?.hidden ? (
                      <>****Privacy</>
                    ) : (
                      <>
                        {noPlan ? (
                          <>
                            {maskNumber(user?.contact_number)}
                            <img
                              src="/images/green-check-icon.svg"
                              style={{ width: 20, height: 20 }}
                            />
                          </>
                        ) : (
                          <>
                            {hasPermission ? (
                              <>
                                {user?.contact_number}
                                <img
                                  src="/images/green-check-icon.svg"
                                  style={{ width: 20, height: 20 }}
                                />
                              </>
                            ) : (
                              <>
                                {maskNumber(user?.contact_number)}
                                <img
                                  src="/images/green-check-icon.svg"
                                  style={{ width: 20, height: 20 }}
                                />
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </p>
            </div>
            <div>
              <p style={profile_card_title}>Email</p>
              <p
                style={{
                  ...profile_card_para,
                  display: "flex",
                  alignItems: "center",
                  gap: "3%",
                }}
              >
                {screen !== "Match" &&
                  screen !== "Sent" &&
                  screen !== "Interest Sent" &&
                  screen !== "Interest Received" &&
                  screen !== "Received" ? (
                  <>
                    {user?.email || (
                      <>
                        <Link
                          to={"/dashboard/settings"}
                          style={{
                            color: app_color,
                            textDecoration: "underline",
                          }}
                        >
                          Provide Email
                        </Link>
                      </>
                    )}
                    {user?.email !== "" && !user?.is_email_verfied && (

                      <Tooltip title="Unverified email" arrow>
                        <IconButton>
                          <IoWarningOutline
                            style={{
                              width: 20,
                              height: 20,
                              marginLeft: "auto",
                              cursor: "pointer",
                            }}
                            onClick={handleVerifyEmailClick}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    {user?.email !== "" && user?.is_email_verfied && (
                      <img
                        src="/images/green-check-icon.svg"
                        style={{ width: 20, height: 20 }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {user?.privacy?.email ? (
                      <>****Privacy</>
                    ) : (
                      <>
                        {noPlan ? (
                          <>
                            {user?.email ? maskEmail(user?.email) : "N/A"}
                            {user?.email !== "" && !user?.is_email_verfied && (
                              <Tooltip title="Unverified email" arrow>
                                <IconButton>
                                  <IoWarningOutline
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginLeft: "auto",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            {user?.email !== "" && user?.is_email_verfied && (
                              <img
                                src="/images/green-check-icon.svg"
                                style={{ width: 20, height: 20 }}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            {hasPermission ? (
                              <>
                                {user?.email || "N/A"}
                                {user?.email !== "" &&
                                  !user?.is_email_verfied && (
                                    <Tooltip title="Unverified email" arrow>
                                      <IconButton>
                                        <IoWarningOutline
                                          style={{
                                            width: 20,
                                            height: 20,
                                            marginLeft: "auto",
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                {user?.email !== "" &&
                                  user?.is_email_verfied && (
                                    <img
                                      src="/images/green-check-icon.svg"
                                      style={{ width: 20, height: 20 }}
                                    />
                                  )}
                              </>
                            ) : (
                              <>
                                {user?.email ? maskEmail(user?.email) : "N/A"}
                                {user?.email !== "" &&
                                  !user?.is_email_verfied && (
                                    <Tooltip title="Unverified email" arrow>
                                      <IconButton>
                                        <IoWarningOutline
                                          style={{
                                            width: 20,
                                            height: 20,
                                            marginLeft: "auto",
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                {user?.email !== "" &&
                                  user?.is_email_verfied && (
                                    <img
                                      src="/images/green-check-icon.svg"
                                      style={{ width: 20, height: 20 }}
                                    />
                                  )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              gap: "7%",
              padding: "0 4%",
              marginTop: "3%",
            }}
          >
            <div style={{ borderRight: "0.6px dashed #BFBFBF", width: "50%" }}>
              <p style={profile_card_title}>City</p>
              <p
                style={{
                  ...profile_card_para,
                  display: "flex",
                  alignItems: "center",
                  gap: "3%",
                }}
              >
                {user?.contact_details?.city || "N/A"}
              </p>
            </div>
            <div>
              <p style={profile_card_title}>State</p>
              <p
                style={{
                  ...profile_card_para,
                  display: "flex",
                  alignItems: "center",
                  gap: "3%",
                }}
              >
                {user?.contact_details?.state || "N/A"}
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              gap: "7%",
              padding: "0 4%",
              marginTop: "3%",
            }}
          >
            <div style={{ borderRight: "0.6px dashed #BFBFBF", width: "50%" }}>
              <p style={profile_card_title}>DOB</p>
              <p
                style={{
                  ...profile_card_para,
                  display: "flex",
                  alignItems: "center",
                  gap: "3%",
                }}
              >
                {screen !== "Match" &&
                  screen !== "Sent" &&
                  screen !== "Interest Sent" &&
                  screen !== "Interest Received" &&
                  screen !== "Received" ? (
                  <>{formatDate(user?.basic_details?.dob) || "N/A"}</>
                ) : (
                  <>
                    {user?.privacy?.dob ? (
                      <>****Privacy</>
                    ) : (
                      <>{formatDate(user?.basic_details?.dob) || "N/A"}</>
                    )}
                  </>
                )}
              </p>
            </div>
            <div>
              <p style={profile_card_title}>Religion</p>
              <p
                style={{
                  ...profile_card_para,
                  display: "flex",
                  alignItems: "center",
                  gap: "3%",
                }}
              >
                {user?.basic_details?.religion || "N/A"}
              </p>
            </div>

          </div>


          {
            screen !== "Match" &&
            screen !== "Sent" &&
            screen !== "Interest Sent" &&
            screen !== "Interest Received" &&
            screen !== "Received" && (
              <div
                style={{
                  display: "flex",
                  gap: "7%",
                  padding: "0 4%",
                  marginTop: "3%",
                }}
              >
                <div style={{ borderRight: "0.6px dashed #BFBFBF", width: "50%" }}>
                  <p style={profile_card_title}>Specially Abled</p>
                  <CustomToggleWithoutProps user_data={user_data} type='specially' />
                </div>

              </div>
            )
          }


          <div
            style={{ position: "absolute", right: '1%', bottom: '3%', display: 'flex', gap: '3%', alignItems: 'center' }}
          >
            {
              screen !== "Match" &&
              screen !== "Sent" &&
              screen !== "Interest Sent" &&
              screen !== "Interest Received" &&
              screen !== "Received" && (
                <IoShareSocial
                  size={25}
                  onClick={openModal}
                  className="pointer"
                  style={{ cursor: "pointer" }}
                />
              )
            }

            {
              user?.specially_abled && (
                <Tooltip title="Specially abled" arrow>
                  <IconButton>
                    <BiHandicap size={30} color={app_color} />
                  </IconButton>
                </Tooltip>
              )
            }

            <ExportModal isOpen={isModalOpen} onRequestClose={closeModal} user={user} />

          </div>


          {(screen !== "Match" &&
            screen !== "Sent" &&
            screen !== "Interest Sent" &&
            screen !== "Received" &&
            screen !== "Interest Received") || noPlan ? (
            <></>
          ) : (
            <>
              <div
                style={{
                  width: "40%",
                  marginLeft: "3%",
                  marginTop: "3%",
                }}
              >
                <InterestButtons
                  screen={screen}
                  interestStatus={interestStatus}
                  sentInterestMatch={sentInterestMatch}
                  receivedInterestMatch={receivedInterestMatch}
                  setBtnClicked={setBtnClicked}
                  setOpen={setInterestOpen}
                  item={user}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  user_data={user_data}
                  forceReload={forceReload}
                />
                {((screen === "Interest Received" || screen === "Received") &&
                  interestStatus === "pending") ||
                  (screen === "Match" && sentInterestMatch === "pending") ? (
                  <>
                    <p
                      className="about-para"
                      style={{
                        color: app_color,
                        textAlign: "center",
                        fontWeight: 500,
                        fontSize: 14,
                        marginTop: "3%",
                        cursor: "pointer",
                      }}
                      onClick={(e) => handleChatLink(e, user)}
                    >
                      or Chat Instead
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div key={refresh}>
        <Grid container spacing={0}>
          {(screen === "Match" || screen === "Sent" || screen === "Interest Sent" || screen === "Interest Received" || screen === "Received") &&
            noPlan && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "left",
                  marginBottom: "3%",
                }}
              >
                <DefaultAlert
                  title={
                    "Please purchase a Plan to view all details of this profile"
                  }
                  type={"warning"}
                />
              </div>
            )}

          <Grid item md={12}>
            {screen !== "Match" &&
              screen !== "Sent" &&
              screen !== "Interest Sent" &&
              screen !== "Interest Received" &&
              screen !== "Received" && (
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: 14,
                    opacity: "60%",
                    marginBottom: "1%",
                  }}
                >
                  Last Modified: &nbsp;
                  {formatDateTime(user?.updatedAt) || ""}
                </p>
              )}
          </Grid>
          <Grid item md={8}>
            <div style={{ height: "100%", paddingRight: "2%" }}>
              {profileCard()}
            </div>
          </Grid>
          <Grid item md={4}>
            <div style={{ height: "100%" }}>
              <div style={{ height: "50%" }}>
                <div
                  style={{
                    borderRadius: 7,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: darkMode ? secondaryDarkColor : "white",
                    color: darkMode ? "white" : "",
                    padding: "8% 4%",
                    flexDirection: "column",
                    border: darkMode ? "" : "2px solid #F1F1F2",
                    boxShadow: "3px 3px 4px 0 #00000008",
                  }}
                >
                  {screen !== "Match" &&
                    screen !== "Sent" &&
                    screen !== "Interest Sent" &&
                    screen !== "Interest Received" &&
                    screen !== "Received" ? (
                    <>
                      <div
                        style={{
                          marginBottom: "auto",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          gap: "3%",
                        }}
                      >
                        <h3
                          className="global-h3"
                          style={{
                            color: darkMode ? "white" : "black",
                            fontSize: 20,
                          }}
                        >
                          Profile Completion Status
                        </h3>
                        <IoMdInformationCircleOutline
                          style={{ width: 30, height: 30, cursor: "pointer" }}
                          onClick={() =>
                            setCompletionModal(true)
                          }
                        />
                      </div>

                      <div
                        style={{
                          width: 140,
                          height: 135,
                          marginBottom: "auto",
                          marginTop: "4%",
                        }}
                      >
                        <CustomProgressBar
                          completionPercentage={completionPercentage}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <h3
                        className="global-h3"
                        style={{
                          color: darkMode ? "white" : "black",
                          fontSize: 20,
                        }}
                      >
                        Compatiblity Score
                      </h3>

                      <div
                        style={{
                          width: 140,
                          height: 150,
                          marginBottom: "auto",
                          marginTop: "4%",
                        }}
                      >
                        <CompatiblityScore item={user} />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div style={{ height: "50%" }}>
                {user?.plan_id?.length !== 0 && (
                  <div
                    style={{
                      height: "100%",
                      borderRadius: 7,
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: darkMode ? secondaryDarkColor : "#EEE9E3",
                      padding: "8% 4%",
                      flexDirection: "column",
                      border: darkMode ? "" : "2px solid #F1F1F2",
                      boxShadow: "3px 3px 4px 0 #00000008",
                    }}
                  >
                    {displayPlanLabel()}
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item md={12}>
            {profileDetailsGrid()}
          </Grid>
        </Grid>

        <ProfileEditModal
          isCompletion={isCompletion}
          setDeletePicOpen={setDeletePicOpen}
          open={open}
          setOpen={setOpen}
          editItem={item2}
          title={editTitle}
          user={user}
          type={type}
          setUser={setUser}
          modalType={modalType}
        />
        <BackgroundImgModal
          open={bgImgModal}
          setOpen={setBgImgModal}
          bgImg={user?.profile_background_image}
          user={user}
        />
        <ProfilePictureModal
          open={profilePicModal}
          setOpen={setProfilePicModal}
          image={`${serverURLProfilePicture}/${user?.profile_picture}`}
        />
        <VerifyEmailModal
          setSuccessOpen={setSuccessOpen}
          setSuccessStatus={setSuccessStatus}
          successStatus={successStatus}
          open={verifyEmailOpen}
          setOpen={setVerifyEmailOpen}
        />
        <InterestConditionsModal
          noPlan={noPlan}
          receivedInterestMatch={receivedInterestMatch}
          permissions={permissions}
          user_data={user_data}
          open={interestOpen}
          setOpen={setInterestOpen}
          setSuccessOpen={setSuccessOpen}
          setSuccessStatus={setSuccessStatus}
          item={user}
          successStatus={successStatus}
          btnClicked={btnClicked}
          sentInterestMatch={sentInterestMatch}
          screen={screen}
          interestStatus={interestStatus}
        />
        <SuccessModal
          status={successStatus}
          open={successOpen}
          setOpen={setSuccessOpen}
          title={"Something went wrong!"}
        />
        <AreYouSureModal
          image="/images/modal-img-delete.svg"
          title="Are you Sure, you want to Delete ?"
          setLoading={setLoading}
          loading={loading}
          confirmFunc={handleConfirmFunc}
          open={deletePicOpen}
          setOpen={setDeletePicOpen}
          successOpen={successOpen}
          setSuccessOpen={setSuccessOpen}
        />
        <ProfileCompleteInfoModal
          open={completionModal}
          setOpen={setCompletionModal}
        />
      </div>
    </>
  );
}
