import React, { useEffect, useState } from "react"
import PageHeroSection from "../Components/PageHeroSection"
import Header from "../Components/Header";
import PricingPlans from "../../WebApp/UserDshboard/Components/PricingPlans";
import Footer from "../Components/Footer";
import AppComponent from "../Components/AppComponent";
import Testimonials from "../Components/Testimonials";
import { get_data } from "../../Api";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MobilePricingPlans from "../Components/MobilePricingPlans";
import BottomBar from "../Components/BottomBar";

export default function Pricing() {

    const [allPlansPermisison, setAllPlansPermissions] = useState([])
    const theme = useTheme();
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'));
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));

    const fetchPlansPermissions = async () => {
        const response = await get_data('permission/get-all-permission')
        if (response?.status) {
            setAllPlansPermissions(response?.data?.filter?.(item => item?.is_active))
        }
    }

    useEffect(() => {
        fetchPlansPermissions()
    }, [])

    return (
        <>
            <Header />
            <PageHeroSection title='Pricing' />
            {
                matches_md ?
                    <>
                        <MobilePricingPlans
                            allPlansPermisison={allPlansPermisison}
                            fetchPlansPermissions={fetchPlansPermissions}
                            setAllPlansPermissions={setAllPlansPermissions}
                            screen='Website'
                        />
                    </>
                    :
                    <>
                        <PricingPlans
                            allPlansPermisison={allPlansPermisison}
                            fetchPlansPermissions={fetchPlansPermissions}
                            setAllPlansPermissions={setAllPlansPermissions}
                            screen='Website'
                        />
                    </>
            }
            <Footer />
            <BottomBar />
        </>
    )
}