import { Grid, useMediaQuery } from "@mui/material"
import React from "react"

export default function AboutMission() {

    const isDesktop = useMediaQuery('(min-width: 768px)');

    return (
        <>
            <div className="about-mission-section" style={{ position: 'relative', paddingBottom: isDesktop ? '' : '15%' }}>
                <img src='/images/blur-div.svg' style={{ position: 'absolute', left: 0, bottom: 0, zIndex: -1 }} />
                <img
                    src="/images/petal-1.svg"
                    alt="Right decorative element"
                    style={{
                        position: 'absolute',
                        right: 0,
                        top: '20%',
                        opacity: '30%',
                        width: 'auto',
                        height: '60%',
                        zIndex: -1,
                    }}
                />
                <Grid container spacing={0}>
                    <Grid item md={6}>
                        <h2 className="global-h2" style={{ color: 'black', fontSize: 27 }}>
                            Our Mission
                        </h2>
                        <p className="about-para" style={{ marginTop: '1%', width: '80%' }}>
                            Our mission is simple: to empower individuals seeking meaningful relationships by providing a safe, secure, and supportive environment. We strive to connect people who share similar goals, backgrounds, and interests, fostering connections that have the potential to blossom into something beautiful.
                        </p>
                        <img src="/images/hands.png" style={{ marginTop: '10%' }} />
                    </Grid>
                    <Grid item md={6} style={{ marginTop: isDesktop ? 0 : '5%' }}>
                        <img src="/images/couple-painting.png" />
                    </Grid>
                </Grid>
            </div>
        </>
    )
}