import React, { useState } from "react"
import { AiFillInstagram } from "react-icons/ai";
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function SocialIcons({ variant }) {

    const navigate = useNavigate()

    const socialMedia = [
        {
            name: 'Facebook',
            icon: <FaFacebook />,
            link: 'https://www.facebook.com/profile.php?id=61561637485666&mibextid=ZbWKwL'
        },
        {
            name: 'Instagram',
            icon: <AiFillInstagram />,
            link: 'https://www.instagram.com/dilsey.in_?igsh=MWwzeWd2ZDMzMDZ4cA=='
        },
        {
            name: 'Twitter',
            icon: <FaTwitter />,
            link: ''
        },
    ]

    const handleClick = (item) => {
        navigate(item?.link)
    }

    return (
        <>
            <div className="social-icons-div">
                {
                    socialMedia.map((item, i) => {
                        return (
                            <>
                                <a href={item?.link} target="_blank" style={{ zIndex: 99 }}>
                                    <div className={variant === 'white' ? 'social-icon-white' : 'social-icon'} style={{ zIndex: 99 }}>
                                        {item?.icon}
                                    </div>
                                </a>
                            </>
                        )
                    })
                }
            </div>
        </>
    )
}